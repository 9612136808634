/*
<Project Name> Component
carditem_ventas
*/

import React, { useEffect, useRef } from 'react'
import { ColoresDPana, ColorsDPana } from '../../utils/Color';
import { useState } from "react";
import { Typography, Card, CardContent, CardActionArea, IconButton } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ItemPedido } from '../../models/Entities/Orden.model';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import { enuStatusPedidoDetalle, EnuTiposPrecio } from '../../utils/enums';
import { formatNumber } from 'fast-number-formatter';
import "../style.css";

interface CarditemVentasProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	children?: React.ReactNode;
	className?: string;
	themeColor?: ColoresDPana;
	style?: React.CSSProperties;
	item?: ItemPedido;
	Cantidad?: number;
	handleClick?: any;
	handleUp?: any;
	handleDown?: any;
	esIncuyeIVA: boolean;
	selectedItem?: boolean;
	select?: (item: any) => void;
	tipoPrecio: number;
}
export default function CardItemVentas({ select, selectedItem, children, item, tipoPrecio, handleUp, handleDown, esIncuyeIVA, className, themeColor = ColorsDPana.secundary, style, handleClick, ...restOfProps }: CarditemVentasProps) {
	const [count, setCount] = useState(item?.CantidadTotal ?? 1);
	const [total, setTotal] = useState(0);
	const [van, setVan] = useState('');

	const esDivisa = tipoPrecio === EnuTiposPrecio.precioDivisa ? true : false;
	const itemref = useRef<any>();

	if (count !== item?.CantidadTotal) setCount(item?.CantidadTotal as number);
	const tasaIVA = () => {
		if (item?.TasaIVA)
			return (1 + (item.TasaIVA / 100))
		else
			return 1
	}
	const PrecioUni = (): number => {
		if (item)
			if (esDivisa) {
				if (esIncuyeIVA)
					return item.MontoPrecioDivisa ? item.MontoPrecioDivisa : 0
				else
					return item.MontoPrecioDivisa ? item?.MontoPrecioDivisa / tasaIVA() : 0;
			} else {
				if (esIncuyeIVA)
					return item.MontoPrecio ? item.MontoPrecio : 0
				else
					return item.MontoPrecio ? item?.MontoPrecio / tasaIVA() : 0;
			}
		return 0;
	}

	const Van = () => {
		setTotal(prevState => PrecioUni() * count);
		setVan(prevState => count > 1 ? `${count} X ${PrecioUni ? formatNumber(PrecioUni(), 2) : "0,00"}` : '');
	}
	useEffect(() => {
		Van();
	}, [count]);

	useEffect(() => {
		console.log('Listening: ', total);
		if (count === 1)
			setTotal(prevState => PrecioUni());
		if (selectedItem)
			if (itemref.current) {
				if (select)
					select(itemref.current);
			}
	}, []);
	let ccsItemStatus;
	switch (item?.id_defTipoStatusPedido) {
		case enuStatusPedidoDetalle.SinProcesar:
			if (item.CantidadTotal && item.CantidadTotal > 0)
				ccsItemStatus = { background: "white" }
			else
				ccsItemStatus = { background: "lightcoral" }
			break;
		case enuStatusPedidoDetalle.DespachoCocina:
		case enuStatusPedidoDetalle.PedidoNuevo:
		case enuStatusPedidoDetalle.Procesado:
		case enuStatusPedidoDetalle.ProcesadoDevuelto:
		case enuStatusPedidoDetalle.Recibido:
		case enuStatusPedidoDetalle.RecibidoCocina:
		case enuStatusPedidoDetalle.RecibidoDevuelto:
			ccsItemStatus = { background: "gray" }
			break;
	}
	/* const Counts = () => {
		return {
		  <Col xs={4} md={{ span: 4, offset: 3 }} style={{ marginTop: -40 }}><h5> {count} x {Precio}</h5></Col>  
		}
	} */
	const productotext = () => {
		let texto: string = item?.Descripcion ? item?.Descripcion : "SIN DESCRIPCION";
		let len: number = texto.length;
		let size: Variant | 'inherit' = 'h5';
		if (item?.Descripcion) {
			if (item?.Descripcion?.length < 22) {
				size = 'h5';
			}
			if (len > 22 && len < 35) {
				size = 'subtitle1';
			}
			if (len > 35) {
				size = 'subtitle2';
			}
			if (len > 35) {
				texto = texto.substring(0, len > 40 ? 40 : len) + '...';
				size = 'subtitle2';
			}

		}

		return (
			<Typography className="card-title text-left" gutterBottom variant={size}  >
				{texto}
			</Typography>
		)
	}
	const ccsmedidas = () => {
		return {
			height: 75,
			marginTop: 2
		}
	}

	const clickUP = () => {
		if (selectedItem) {
			Van();
			handleUp(item);
		}
	}
	return (
		<Card ref={itemref}
			style={
				selectedItem
					? { background: "gold", ...ccsmedidas }
					: { ...ccsItemStatus, ...ccsmedidas }}>
			<CardContent style={{ padding: 2 }} >
				<CardActionArea onClick={handleClick}>
					<div className="d-flex flex-column h-100">
						<div className="row no-gutters">
							<div className="topcarditem-field-grid"	>
								<IconButton style={item?.CantidadTotal ? { marginRight: 2 } : { display: 'none', marginRight: 2 }} onClick={() => {
									Van();
									handleDown(item);
								}}>
									{<RemoveSharpIcon color={'error'} sx={{ fontSize: 30 }} />}
								</IconButton>
								<h6 onClick={() => { clickUP() }}> {van}</h6>
								<h5 onClick={() => { clickUP() }} style={{ textAlign: 'right' }}> {
									item?.CantidadTotal ?
										item.CantidadTotal > 0 ?
											total ? (esDivisa ? "$ " : "Bs. ") + formatNumber(total, 2) : (esDivisa ? "$ " : "Bs. ") + "0,00"
											: "-Eliminado-"
										: "-Eliminado-"}</h5>
							</div>
						</div>
						<div className="row no-gutters"
							onClick={() => { clickUP() }}>
							<div className="row">{productotext()}</div>
							<div className="row">
								{item?.Extras?.map((extra, index) => {
									return <small key={index}> {extra} </small>
								})}</div>
						</div>
					</div>

				</CardActionArea>
			</CardContent>
		</Card >
	)


}


