import { clientes } from "../../../../models/clientes";
import { facturas_mediopago } from "../../../../models/facturas_mediopago";

/* const setting = useSetting();
const setSetting = useSetSetting();
const PedidoActual = useOrder();
const setPedidoActual = useSetOrder();
const loginToken = useLoginToken();
const setLoginToken = useSetLoginToken(); */
/*
        setting: useSetting(),
        pedidoactual: useOrder(),
        loginToken: useLoginToken(), */
export interface stateFormValue {
    country: any,
    currency: any,
    montototal: number,
    montocambio: number,
    montovan: number,
    montotasa: number,
    montobase: number,
    logintoken?: any,
    simbolodivisa: string,
    clientes: clientes[],
    pagoactual: facturas_mediopago
    formaspago: facturas_mediopago[]
}
interface stateForm {
    formValues: stateFormValue;
}
const initialState: stateForm = {
    formValues: {
        country: null,
        currency: null,
        montototal: 0,
        montocambio: 0,
        montovan: 0,
        montotasa: 0,
        montobase: 0,
        simbolodivisa: "Bs",
        pagoactual: new facturas_mediopago(),
        clientes: [],
        formaspago: []
    }

};
/* const initialState = {
    formValues: {
        clientes: [{
            ID: 1


        }],
        date: "",
        service: "",
        facebook: "",
        twitter: "",
        idcliente: 1,
        firstname: "Contribuyente",
        lastname: "",
        email: "@gmail.com",
        line1: "",
        rif: "1",
        postal_code: "",
        city: "",
        phone: "",
        zona: "",
        phonew: "+58",

        country: null,
        currency: null,
        montoactual: '0.00',
        montototal: 0,
        montocambio: 0,
        montovan: 0,
        montotasa: 0,
        formaspago: [{
            ID: 0,
            Id_Factura: 0,
            Id_MedioPago: 0,
            Id_TipoBanco: 0,
            DescripicionDocumento: "",
            FechaActual: "",
            MontoTotal: 0,
            nroDocumento: "",
            nroRef: "",
            Comentario: "",
            Beneficiario: "",
            TipoPago: 0,
            MontoTasa: 0,

            simbolodivisa: "Bs",

            id_Moneda: 0,
            idmediopago: 0,

            codigoReferencia: "",
            pagomovilnumber: "",
            banconame: "",
            banconumber: "",
            emailcoin: "",
            bancobeneficiario: "",
            diascredito: 0,
            nombrecredito: "",
            responsablecredito: "",
            comentarios: "",
            tipopago: 0,
        }]
    },
}; */
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'editFormValue':
            state.formValues[action.key.toLowerCase()] = action.value;
            return { ...state };
        case 'emptyFormValue':
            return {
                ...state,
                formValues: initialState.formValues
            };
        default:
    };
    return state;
};
export { initialState, reducer }