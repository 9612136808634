/* import React from "react";
import { ColoresDPana, ColorsDPana } from '../../utils/Color';

interface PanelBodyProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  themeColor?: ColoresDPana;
  style?: React.CSSProperties;
  reference?;
}
const EDGE_DARKEN = 33;
export default function PanelBody({ children, reference, themeColor = ColorsDPana.white, style, ...restOfProps }: PanelBodyProps) {
  //additional style to make the galssy background effect
  const panelBodyStyle: React.CSSProperties = {
    border: "1px solid black",
    backgroundImage: `linear-gradient(90deg, rgb(${Math.min(themeColor[0] - EDGE_DARKEN, 255)},${Math.min(themeColor[1] - EDGE_DARKEN, 255)},${Math.min(themeColor[2] - EDGE_DARKEN, 255)}) 0%, rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]}) 25%, rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]}) 75%, rgb(${Math.max(themeColor[0] - EDGE_DARKEN, 0)},${Math.max(themeColor[1] - EDGE_DARKEN, 0)},${Math.max(themeColor[2] - EDGE_DARKEN, 0)}) 100%)`,
    borderRadius: "8px",
    boxShadow: "inset 0 0 4px darkgray",
    padding: "10px",
    overflow: "overlay",
    ...style
  }

  //spread rest of the props to the parent div
  return <div ref={reference} style={panelBodyStyle} {...restOfProps}>
    {children}
  </div>;
} */
import React from "react";
import { ColoresDPana, ColorsDPana } from '../../utils/Color';

interface PanelBodyProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  themeColor?: ColoresDPana;
  style?: React.CSSProperties;
  reference?: any;
}

export default function PanelBody({ children, reference, themeColor = ColorsDPana.white, style, ...restOfProps }: PanelBodyProps) {
  const panelBodyStyle: React.CSSProperties = {
    border: "1px solid #fff",
    backgroundImage: "linear-gradient(90deg, rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),  )",
    borderRadius: "8px",
    padding: "10px",
    overflow: "overlay",
    ...style
  }

  return <div ref={reference} style={panelBodyStyle} {...restOfProps}>
    {children}
  </div>;
}