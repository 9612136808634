import type { comandas_detalle } from './comandas_detalle';
import type { pedidos_detalle_movimientos } from './pedidos_detalle_movimientos';
import type { sectores_impresion } from './sectores_impresion';
import type { usuarios, } from './usuarios';

export interface comandasAttributes {
  ID: number;
  id_Pedido_detalle_movimiento?: number;
  id_Usuario?: number;
  id_Sector_Impresion?: number;
  id_defTipoStatusDoc?: number;
  id_defStatusComanda?: number;
  nroComanda?: number;
  Mensaje?: string;
  txtComanda?: string;
  FechaAtendida?: Date;
  FechaActual?: Date;
  FechaImpreso?: Date;
  esImpreso?: number;
  esDespachado?: number;
}

export type comandasPk = "ID";

export class comandas implements comandasAttributes {
  ID!: number;
  id_Pedido_detalle_movimiento?: number;
  id_Usuario?: number;
  id_Sector_Impresion?: number;
  id_defTipoStatusDoc?: number;
  id_defStatusComanda?: number;
  nroComanda?: number;
  Mensaje?: string;
  txtComanda?: string;
  FechaAtendida?: Date;
  FechaActual?: Date;
  FechaImpreso?: Date;
  esImpreso?: number;
  esDespachado?: number;

  // comandas hasMany comandas_detalle via id_Comanda
  comandas_detalles!: comandas_detalle[];
  id_Pedido_detalle_movimiento_pedidos_detalle_movimiento!: pedidos_detalle_movimientos;
  id_Sector_Impresion_sectores_impresion!: sectores_impresion;
  id_Usuario_usuario!: usuarios;

}
