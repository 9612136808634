import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { enutipoItemMenu, itemNavButton, itemNavCategory } from '.';
import { config } from "../../utils/configs";

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};
interface Props extends DrawerProps {
    onItemClick: (cat: itemNavCategory, item: itemNavButton) => void;
    titulo?: string;
    children?: React.ReactNode;
    categoriasMenu: itemNavCategory[];
}
const drawerWidth = 240;

export default function Navigator(props: Props) {
    const { ...other } = props;
   /*  const history = useHistory();
    const [tituloHead, settituloHead] = React.useState("Panel Principal");
    const [itemSelected, setitemSelected] = React.useState();
 */
    const titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    xs={6} sm={10}
                >
                    <Typography className="text-white" variant='subtitle1'>
                        {config.nombreEmpresa}
                    </Typography>
                </Grid>


            </Grid>

        )
    }
    const clearItems = (is: any) => {
        if (is && is.length)
            is.forEach((i: any) => {
                i.active = false
            });
    }
    const clearMenu = () => {
        if (props.categoriasMenu && props.categoriasMenu.length)
            props.categoriasMenu.forEach((c: any) => {
                clearItems(c.item);
            });
    }
    useEffect(() => {


    }, [])
    return (
        <Drawer className="h-100 " sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
        }}
            variant="permanent"
            {...other}>
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
                    {titulo()}
                </ListItem>
                {props.categoriasMenu.map((cat: itemNavCategory) => (
                    <Box key={cat.nombre}  >
                        {cat.nombre
                            ? <ListItem sx={{ py: 2, px: 3 }}>
                                <ListItemText className="bd-dark" sx={{ color: '#fff' }}>{cat.nombre}</ListItemText>
                            </ListItem>
                            : ''
                        }
                        {cat.item.map((it: itemNavButton) => (
                            <ListItem disablePadding key={it.nombre}>
                                <ListItemButton selected={it.active} sx={item}
                                    onClick={() => {
                                        clearMenu();
                                        it.active = true;
                                        props.onItemClick(cat, it);
                                    }}>
                                    <ListItemIcon>{it.icon}</ListItemIcon>
                                    <ListItemText>{it.nombre}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </List>
        </Drawer>
    );
}


