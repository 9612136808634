import axios from "axios";
import { CSSProperties, Key, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import CheckLoginToken from "../../components/check-login-token";
import LabeledTextInput from "../../components/labeled-text-input";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import PanelHeader from "../../components/panel-header";
import SimpleToast from "../../components/simple-toast";
import { useLoginToken } from "../../contexts/login-context";
import { useOrder, useSetOrder } from "../../contexts/order-context";
import { ColorsDPana } from "../../utils/Color";
import { findAndReplace } from "../../utils/helpers";
import { clientes } from "../../models/clientes";
import { enuTipoPedidoDespacho } from "../../utils/enums";
import { Container, Grid, Typography } from "@mui/material";
import { config } from '../../utils/configs';
import SpinnerLoading from "../../components/spinner-loading";
import SimpleDialog from "../dialogs/simple-dialog";
import LoginDialog from "../dialogs/login-dialog";
import './style.css';

export default function CustomerSelection() {
    CheckLoginToken();

    const [customerList, setCustomerList] = useState<any[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});
    const [lockEdit, setlockEdit] = useState<boolean>(false);

    const [filter, setFilter] = useState("");
    const [message, setMessage] = useState("");
    const history = useHistory();
    const loginToken = useLoginToken();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();

    const loadCustomers = () => {
        axios.post(config.SERVER_URI + "/clientes/list")
            .then(result => {
                setCustomerList(result.data);
            })
    }

    const selectCustomer = (customer: clientes, esSelect: boolean = false) => {
        setSelectedCustomer(customer);
        if (esSelect)
            setlockEdit(true);

    }

    const createItemListItem = (customer: clientes, key: Key | null | undefined) => {
        //build a display name
        let displayName = customer.Nombres ? customer.Nombres : "";
        if (customer.Apellidos) {
            if (customer.Nombres) {
                displayName += " "
            }
            displayName += customer.Apellidos;
        }
        if (customer.Telefono) {
            if (displayName) {
                displayName += " - ";
            }
            displayName += customer.Telefono;
        }

        if (!displayName) {
            displayName = "Cliente sin Nombre";
        }

        return (
            <Button
                key={key}
                style={{
                    width: "100%",
                    marginBottom: "3px",
                    display: filter ? displayName.toLowerCase().includes(filter.toLocaleLowerCase()) ? "block" : "none" : "block"
                }}
                themeColor={customer === selectedCustomer ? ColorsDPana.primary : ColorsDPana.secundary}
                onClick={() => { selectCustomer(customer, true) }}
            >
                {displayName}
            </Button>
        )
    }

    const addCustomer = () => {
        selectCustomer(new clientes);
        setlockEdit(false)
    }

    const addCustomerToOrder = (customer: any) => {
        if (PedidoActual) {
            PedidoActual.Id_Cliente_cliente = customer;
        }
        setPedidoActual(PedidoActual);
    }

    let savingData = false;
    const saveAndOrder = async () => {
        if (PedidoActual.id_defTipoPedido) {
            PedidoActual.Mesa = undefined;
            if (!selectedCustomer.Rif) {
                setMessage("Debe Ingresar el RIF");
                return;
            }
            if (PedidoActual.id_defTipoPedido === enuTipoPedidoDespacho.Pickup) {
                if (!selectedCustomer.Telefono) {
                    setMessage("Debe Ingresar Número Telefónico");
                    return;
                }

            } else if (PedidoActual.id_defTipoPedido === enuTipoPedidoDespacho.Delivery) {
                if (!selectedCustomer.Telefono) {
                    setMessage("Debe Ingresar Número Telefónico");
                    return;
                }
                if (!selectedCustomer.Direccion) {
                    setMessage("Debe Ingresar Dirección");
                    return;
                }
            }
        }

        if (!savingData) {
            savingData = true;
            try {
                selectedCustomer.id_puntos = 1;
                if (selectedCustomer.ID) {
                    //update existing customer
                    await axios.post(config.SERVER_URI + '/clientes/update', {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: { id: selectedCustomer.ID, ...selectedCustomer }
                    });
                    addCustomerToOrder(selectedCustomer);
                    history.push("/order");
                } else {
                    const result = await axios.post(config.SERVER_URI + '/clientes/create', {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: selectedCustomer
                    });

                    const newCustomer = {
                        ...selectedCustomer,
                        ID: result.data.ID
                    }
                    addCustomerToOrder(newCustomer);
                    history.push("/order");
                }
            } catch (err: any) {
                if (err?.stack.includes("Request failed with status code 401")) {
                    setMessage("Error de Autenticación. Por favor Iniciar sesión")
                    setTimeout(() => {
                        loadLoginDialog("Confirmar PIN");
                    }, 2500);
                } else {
                    setMessage(err?.stack);
                }
            } finally {
                savingData = false;
            }
        } else {
            setMessage("Trabajando... por favor espere.")
        }
        setlockEdit(false);

    }

    const updateStates = (targetCustomer: any, newCustomer: any) => {
        const newCustomerList = findAndReplace(customerList, targetCustomer, newCustomer);
        if (newCustomerList) {
            setCustomerList(newCustomerList);
        }
        selectCustomer(newCustomer);
    }

    const changeField = (fieldName: string) => {
        return (event: any) => {
            const newCustomer = {
                ...selectedCustomer
            };
            newCustomer[fieldName] = event.target.value;
            updateStates(selectedCustomer, newCustomer);
        }
    }

    const labelStyle: CSSProperties = {
        width: "6em",
        textAlign: "center",
        whiteSpace: "nowrap"

    }
    const createInputProps = (fieldName: string) => {
        return {
            style: { gridArea: fieldName },
            value: selectedCustomer ? (selectedCustomer[fieldName] ? selectedCustomer[fieldName] : "") : "",
            onChange: changeField(fieldName),
            labelStyle: labelStyle
        }
    }
    const titulo = () => {

        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={2}
                    >

                        <img className="m-1 text-start" src={'./assets/imagenes/logo-white.png'}
                            style={{
                                position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                            }}

                            onClick={() => {
                                history.push('/gotodpana');
                            }} >
                        </img>


                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={10}
                    >
                        <Typography className="text-white" variant='subtitle1'>
                            dPana POS
                        </Typography>
                    </Grid>

                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={6}
                >
                    <Typography variant='subtitle1'>
                        Selecionar Cliente
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Typography className="text-white-50" variant='subtitle1'>
                        Sistema de facturación en Línea
                    </Typography>
                </Grid>

            </Grid>

        )
    }
    //#region Dialogs
    const [loading, setloading] = useState(false);
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [textloading, settextloading] = useState('Cargando');
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }
    const loadLoginDialog = (titulo: string) => {
        setChildrenSDialog(<LoginDialog handleResponse={loginDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }
    //#endregion

    useEffect(() => {
        loadCustomers();
    }, []);

    return (
        <Container className="vh-100 position-relative py-3">
            <Panel className="w-100 h-100 d-flex flex-column">
                <PanelHeader>
                    {titulo()}
                </PanelHeader>

                <div id="customer-page-container">
                    <div className="d-flex flex-column h-100">
                        <LabeledTextInput className="m-1" title="Filter" value={filter} labelColorTheme={ColorsDPana.accent}
                            onChange={(e) => { setFilter(e.target.value) }} labelStyle={{ width: "4em", textAlign: "center" }} />
                        <PanelBody className="flex-grow-1 overflow-auto" style={{ flexBasis: 0 }}>
                            {customerList.map((customer, index) => createItemListItem(customer, index))}
                        </PanelBody>
                    </div>
                    <div className="d-flex flex-column h-100">
                        <PanelBody className="flex-grow-1">
                            <Grid
                                container
                                spacing={2}
                                direction='column'
                                justifyContent="center"
                                alignItems="space-between"
                                className="m-0 p-1 w-100"
                            >
                                <Grid
                                    item
                                    spacing={1}
                                    container
                                    direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    className="m-0 p-1 w-100"
                                >
                                    <Grid item xs={6} sm={3}                                >
                                        <LabeledTextInput esInactivo={lockEdit}  {...createInputProps("Rif")} title="Rif:" />
                                    </Grid>
                                    <Grid item xs={6} sm={3}                                >
                                        <LabeledTextInput     {...createInputProps("Telefono")} title="Teléfono:" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}                                >
                                        <LabeledTextInput    {...createInputProps("Email")} title="Email:  " />
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    spacing={1}
                                    container
                                    direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    className="m-0 p-1 w-100"
                                >
                                    <Grid item xs={12} sm={6}                                >
                                        <LabeledTextInput esInactivo={lockEdit}     {...createInputProps("Nombres")} title="Nombres:" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}                                >
                                        <LabeledTextInput esInactivo={lockEdit}    {...createInputProps("Apellidos")} title="Apellidos:" />
                                    </Grid>

                                </Grid>
                                <Grid
                                    item
                                    spacing={1}
                                    container
                                    direction='row'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    className="m-0 p-1 w-100"
                                >
                                    <Grid item xs={12} sm={8}                                >
                                        <LabeledTextInput    {...createInputProps("Direccion")} title="Dirección:" />
                                    </Grid>
                                    <Grid item xs={12} sm={4}                                >
                                        <LabeledTextInput   {...createInputProps("Comentario")} title="Comentario:" />
                                    </Grid>

                                </Grid>


                            </Grid>

                        </PanelBody>
                        <div className="d-flex flex-row-reverse">
                            <Button className="m-1" themeColor={ColorsDPana.primary}
                                onClick={() => {
                                    history.goBack();
                                }}
                            >Salir</Button>
                            <Button className="m-1" themeColor={ColorsDPana.primary}
                                onClick={saveAndOrder}
                            >Ordenar</Button>
                            <Button className="m-1" themeColor={ColorsDPana.primary}
                                onClick={addCustomer}
                            >Agregar</Button>
                        </div>
                    </div>
                </div>
            </Panel>
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </Container>
    );
}