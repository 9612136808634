/* import { useContext, createContext, useState } from "react";
import { reducer, initialState } from './reducer';

export const StateContext = createContext(null);
export const SetStateContext = createContext(reducer);

export function useStateValue() {
    return useContext(StateContext);
}
export function setuseStateValue() {
    return useContext(SetStateContext);
}
export interface Props {
    children?: any;
}
export function StateProvider({ children }: Props) {
    const [stateValue, setStateValue] = useState(null);
    return (
        <StateContext.Provider value={stateValue}>
            <SetStateContext.Provider value={setStateValue}>
                {children}
            </SetStateContext.Provider>
        </StateContext.Provider>
    );
} */

import React, { createContext, useContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

const StateContext = createContext<any>(null);

export interface Props {
    children?: any;
}
export const StateProvider = ({ children }: Props) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);