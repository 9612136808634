import type { abonos } from './abonos';
import type { cajas } from './cajas';
import type { clientes_direcciones } from './clientes_direcciones';
import type { clientes_ret_isrl } from './clientes_ret_isrl';
import type { clientes_ret_iva } from './clientes_ret_iva';
import type { clientes_saldo } from './clientes_saldo';
import type { facturas } from './facturas';
import type { pedidos } from './pedidos';
import type { puntos } from './puntos';
import type { reservas_mesas } from './reservas_mesas';
import type { tipogrupoclientes } from './tipogrupoclientes';
import type { tipopagos } from './tipopagos';
import type { ven_municipios } from './ven_municipios';
import type { ven_parroquias } from './ven_parroquias';
import type { world_profesiones } from './world_profesiones';
import type { world_regiones } from './world_regiones';
import type { zonas } from './zonas';

export interface clientesAttributes {
  ID: number;
  id_puntos: number;
  id_TipoGrupoCLiente?: number;
  id_TipoImpuesto_Predet?: number;
  id_MedioPago_Predet?: number;
  id_Estado?: number;
  id_Municipio?: number;
  id_Parroquia?: number;
  id_Profesion?: number;
  id_Zona?: number;
  id_defTipoNacionalidad?: number;
  id_defTipoSexo?: number;
  Rif: string;
  Pasaporte?: string;
  Nombres: string;
  Apellidos: string;
  Pass?: string;
  Contacto?: string;
  Comentario?: string;
  Imagen?: string;
  Telefono?: string;
  TelefonoMovil?: string;
  CodigoPostal?: number;
  Email?: string;
  FechaActual?: Date;
  DiasCredito?: number;
  FechaNac?: Date;
  Direccion?: string;
  esEmpresa?: number;
  esFavorito?: number;
  esActivo?: number;
  esExento?: number;
  esContribuyenteEspecial?: number;
  esBloqueado?: number;
  Historico?: string;
  Twitter?: string;
  Facebook?: string;
  Instagram?: string;
}


export class clientes implements clientesAttributes {
  ID!: number;
  id_puntos!: number;
  id_TipoGrupoCLiente?: number;
  id_TipoImpuesto_Predet?: number;
  id_MedioPago_Predet?: number;
  id_Estado?: number;
  id_Municipio?: number;
  id_Parroquia?: number;
  id_Profesion?: number;
  id_Zona?: number;
  id_defTipoNacionalidad?: number;
  id_defTipoSexo?: number;
  Rif!: string;
  Pasaporte?: string;
  Nombres!: string;
  Apellidos!: string;
  Pass?: string;
  Contacto?: string;
  Comentario?: string;
  Imagen?: string;
  Telefono?: string;
  TelefonoMovil?: string;
  CodigoPostal?: number;
  Email?: string;
  FechaActual?: Date;
  DiasCredito?: number;
  FechaNac?: Date;
  Direccion?: string;
  esEmpresa?: number;
  esFavorito?: number;
  esActivo?: number;
  esExento?: number;
  esContribuyenteEspecial?: number;
  esBloqueado?: number;
  Historico?: string;
  Twitter?: string;
  Facebook?: string;
  Instagram?: string;

  // clientes hasMany abonos via id_Cliente
  abonos!: abonos[];
  cajas!: cajas[];
  clientes_direcciones!: clientes_direcciones[];
  clientes_ret_isrls!: clientes_ret_isrl[];
  clientes_ret_ivas!: clientes_ret_iva[];
  clientes_saldos!: clientes_saldo[];
  facturas!: facturas[];
  pedidos!: pedidos[];
  reservas_mesas!: reservas_mesas[];
  id_puntos_punto!: puntos;
  id_TipoGrupoCLiente_tipogrupocliente!: tipogrupoclientes;
  id_MedioPago_Predet_tipopago!: tipopagos;
  id_Municipio_ven_municipio!: ven_municipios;
  id_Parroquia_ven_parroquia!: ven_parroquias;
  id_Profesion_world_profesione!: world_profesiones;
  id_Estado_world_regione!: world_regiones;
  id_Zona_zona!: zonas;

}
