// import "react-simple-keyboard/build/css/index.css";

import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Container, Box, Theme, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import { AddBusiness, CloseOutlined, HttpsOutlined, LocalOffer, Money, Person, PersonAddAlt1, PointOfSale, PriceChange, PriceCheck, SettingsApplications, Superscript } from "@mui/icons-material";
import SpinnerLoading from "../../../components/spinner-loading";
import SimpleToast from "../../../components/simple-toast";
import SimpleDialog from "../../dialogs/simple-dialog";
import LoginDialog from "../../dialogs/login-dialog";
import ModalDialog from "../../dialogs/modal-dialog";
import TipoPrecios from "./components/tipo-precio";
import AjustarPrecios from "./components/ajustar-precio";
import ModificarDescuentos from "./components/modificar-descuentos";
import ModificarProductos from "./components/modificar-producto";
import CrearEgreso from "./components/crear-egreso";
import CorteXZ from "./components/corte-xz";
import CrearUsuario from "./components/crear-usuario";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}


const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function MenuDialog(props: Props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const history = useHistory();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-1 p-2 bg-black border-bottom bg-gradient w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={2} sm={2}
                >
                    <img className="m-1 text-start" src={'./assets/imagenes/logoicon-white.png'}
                        style={{
                            position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                        }}
                        onClick={() => {
                            history.push('/gotodpana');
                        }} >
                    </img>
                </Grid>
                <Grid
                    className="m-0 p-0 text-white"
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={10} sm={10}
                >
                    <Typography variant='h6'>
                        Opciones de Caja
                    </Typography>
                </Grid>
            </Grid>
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [okbtnMDialog, setokbtnMDialog] = useState<boolean | undefined>(undefined);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const childDialogRes = (e: any) => {
        setOpenSDialog(false);
    }
    const loginDialogRes = (e: boolean, actionMethod: any) => {

        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }

    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);
        if (e)
            setMessage("Pagado")
    }

    const actionMethod = () => {

    }
    //ESTACION
    const loadLoginDialog = (titulo: string) => {
        setChildrenSDialog(<LoginDialog actionMethod={actionMethod} handleResponse={loginDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }
    const loadModificarUsuarios = (titulo: string) => {
        setChildrenMDialog(<CrearUsuario handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }
    const loadConfigEstacion = (titulo: string) => {
        setChildrenSDialog(<LoginDialog handleResponse={childDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }

    //CAJA
    const loadAbrirGaveta = (titulo: string) => {
        setMessage("Verificar Autorizacion")
        setChildrenSDialog(<LoginDialog handleResponse={loginDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }
    const loadCrearEgreso = (titulo: string) => {
        setChildrenMDialog(<CrearEgreso handleResponse={loginDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }
    const loadCorteX = (titulo: string) => {
        setChildrenMDialog(<CorteXZ esCortez={false} handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }
    const loadCorteZ = (titulo: string) => {
        setChildrenMDialog(<CorteXZ esCortez={true} handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }

    //Precios
    const loadTipoPrecio = (titulo: string) => {
        setChildrenMDialog(<TipoPrecios handleResponse={childDialogRes} titulo={titulo} />);
        setokbtnMDialog(false)
        setopenMDIalog(true);
    }
    const loadModificarPrecios = (titulo: string) => {
        setChildrenMDialog(<AjustarPrecios handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }
    const loadModificarDescuentos = (titulo: string) => {
        setChildrenMDialog(<ModificarDescuentos handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }
    const loadModificarProductos = (titulo: string) => {
        setChildrenMDialog(<ModificarProductos handleResponse={childDialogRes} titulo={titulo} />);
        setopenMDIalog(true);
    }

    //#endregion



    return (
        <>
            <Box component="main" className={classes.boxWrapper + ' w-100 my-2 p-0'}>
                <Container maxWidth="md" className={classes.container + ' h-100 m-0 p-0'}>
                    <Titulo />
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className="m-0 p-0 w-100"
                    >
                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            className="m-0 my-2 p-0 w-100"
                        >
                            <Grid className=" bg-danger bg-opacity-50  d-grid justify-content-end m-0 mb-2 p-1 w-100" item xs={1} md={1} >
                                <span className=" pe-1 text-uppercase text-white">Estación</span>
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent='space-between'
                                alignItems="center"
                                className="m-0 p-0 w-100"
                                xs={11} md={11}
                            >
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Bloquear Estación"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <HttpsOutlined className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        history.push('/')
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Bloquear</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Cambiar Usuario"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <Person className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadLoginDialog("Cambiar Usuario");
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Cambiar</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Agregar Usuario"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <PersonAddAlt1 className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadModificarUsuarios("Modificando Usuarios");
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Usuarios</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Configurar Estación"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <SettingsApplications className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setMessage("inf-->Espere mientras cargamos Pedidos...")
                                                    setTimeout(() => {
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Config.</span>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            className="m-0 my-2 p-0 w-100"
                        >
                            <Grid className=" bg-danger bg-opacity-50  d-grid justify-content-end m-0 mb-2 p-1 w-100" item xs={1} md={1} >
                                <span className=" pe-1 text-uppercase text-white">Caja</span>
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="'space-between'"
                                alignItems="center"
                                className="m-0 p-0 w-100"
                                xs={11} md={11}
                            >
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Abrir Gaveta"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <PointOfSale className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadAbrirGaveta("Abrir gaveta Dinero");
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Gaveta</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Menu de Opciones"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <Money className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadCrearEgreso("Crear Egreso Ingreso")
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Egreso</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Imprimir Corte X"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <CloseOutlined className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadCorteX("Imprimir Corte Parcial (X)")
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Corte X</span>
                                </Grid>
                                <Grid className="my-1   m-0 p-0" item xs={3} md={3} >
                                    <Tooltip title={"Imprimir Corte Z"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <Superscript className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadCorteZ("Imprimir Corte Total (Z)")
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Corte Z</span>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            className="m-0 my-2 p-0 w-100"
                        >
                            <Grid className=" bg-danger bg-opacity-50  d-grid justify-content-end m-0 mb-2 p-1 w-100" item xs={1} md={1} >
                                <span className=" pe-1 text-uppercase text-white">Precios</span>
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="'space-between'"
                                alignItems="center"
                                className="m-0 p-0 w-100"
                                xs={11} md={11}
                            >
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Seleccionar Tipo Precio"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <PriceCheck className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadTipoPrecio("Seleccionar Tipo precio")
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Tipo</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Modificar Precios"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <PriceChange className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadModificarPrecios("Modificando Precios")
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Ajustar</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Modificar Descuentos"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <LocalOffer className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        loadModificarDescuentos("Modificar Descuentos");
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Descuent.</span>
                                </Grid>
                                <Grid className="text-center m-0 p-0" item xs={3} md={3}  >
                                    <Tooltip title={"Modificar Productos"} arrow  >
                                        <IconButton className=" m-0 p-0 bg-gradient">
                                            <AddBusiness className="text-white p-0" sx={{ fontSize: '3rem' }}
                                                onClick={() => {
                                                    loadModificarProductos("Crear Producto")
                                                    setTimeout(() => {
                                                    }, 100);
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <span className="fa-sm fw-lighter lh-sm p-0 text-white">Productos</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog setokButton={setokbtnMDialog} okButton={okbtnMDialog} selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}
