import type { almacenes_movimientos_detalle } from './almacenes_movimientos_detalle';
import type { almacenes_stock } from './almacenes_stock';
import type { comandas_detalle } from './comandas_detalle';
import type { compras_detalle } from './compras_detalle';
import type { facturas_detalle } from './facturas_detalle';
import { materias_primas } from './materias_primas';
import type { mesas } from './mesas';
import type { modificadores_pedido } from './modificadores_pedido';
import type { pedidos_detalle } from './pedidos_detalle';
import type { productos_compuestos_detalle } from './productos_compuestos_detalle';
import type { productos_materiaprima } from './productos_materiaprima';
import type { productos_promociones_detalle } from './productos_promociones_detalle';
import type { productos_proveedores } from './productos_proveedores';
import type { proveedores } from './proveedores';
import type { sectores_impresion } from './sectores_impresion';
import type { subcategorias_productos } from './subcategorias_productos';
import { tipoacompanantes } from './tipoacompanantes';
import type { tipodescuentos_detalle } from './tipodescuentos_detalle';
import type { tipoimpuestos } from './tipoimpuestos';
import type { tipoitemventa } from './tipoitemventa';
import { tipomonedas } from './tipomonedas';
import type { tipoproductos } from './tipoproductos';
import type { tipoproductos_grupo } from './tipoproductos_grupo';
import type { tipoproductos_marcas } from './tipoproductos_marcas';

export interface productosAttributes {
  ID: number;
  id_tipo_productogrupo?: number;
  id_TipoProductoMarca?: number;
  id_tipo_producto?: number;
  id_tipo_itemventa?: number;
  id_sector_impresion?: number;
  id_sub_categoria?: number;
  id_proveedor?: number;
  id_impuesto1?: number;
  id_Impuesto2?: number;
  Nombre?: string;
  Imagen?: string;
  CodigoBarras?: string;
  CodigoInterno?: string;
  MontoCosto?: number;
  MontoCostoDivisa?: number;
  MontoPrecio1?: number;
  MontoPrecio2?: number;
  MontoPrecio3?: number;
  MontoPrecioOferta?: number;
  MontoPrecioDivisa?: number;
  Margen1?: number;
  Margen2?: number;
  Margen3?: number;
  PuntosCantidad?: number;
  PuntosRating?: number;
  TiempoCoccion?: number;
  esSoloVenta?: number;
  esSoloCompra?: number;
  esCoccion?: number;
  esPreparado_especial?: number;
  esRetornable?: number;
  esFavorito?: number;
  esMultipleTamano?: number;
  esArmable?: number;
  esPesado?: number;
  esPideprecio?: number;
  esActivo?: number;
  esCompuesto?: number;
  zOrder?: number;
  sizeWidth?: number;
  sizeHeight?: number;
  FechaActual?: Date;
  FechaModificado?: Date;
  FechaVenta?: Date;
  Historico?: string;
  ImagenWeb?: string;
  esEnWebSite?: number;
  DescripcionWeb?: string;
  id_TipoMoneda?: number;
}



export class productos implements productosAttributes {
  ID!: number;
  id_tipo_productogrupo?: number;
  id_TipoProductoMarca?: number;
  id_tipo_producto?: number;
  id_tipo_itemventa?: number;
  id_sector_impresion?: number;
  id_sub_categoria?: number;
  id_proveedor?: number;
  id_impuesto1?: number;
  id_Impuesto2?: number;
  Nombre?: string;
  Imagen?: string;
  CodigoBarras?: string;
  CodigoInterno?: string;
  MontoCosto?: number;
  MontoCostoDivisa?: number;
  MontoPrecio1?: number;
  MontoPrecio2?: number;
  MontoPrecio3?: number;
  MontoPrecioOferta?: number;
  MontoPrecioDivisa?: number;
  Margen1?: number;
  Margen2?: number;
  Margen3?: number;
  PuntosCantidad?: number;
  PuntosRating?: number;
  TiempoCoccion?: number;
  esSoloVenta?: number;
  esSoloCompra?: number;
  esCoccion?: number;
  esPreparado_especial?: number;
  esRetornable?: number;
  esFavorito?: number;
  esMultipleTamano?: number;
  esArmable?: number;
  esPesado?: number;
  esPideprecio?: number;
  esActivo?: number;
  esCompuesto?: number;
  zOrder?: number;
  sizeWidth?: number;
  sizeHeight?: number;
  FechaActual?: Date;
  FechaModificado?: Date;
  FechaVenta?: Date;
  Historico?: string;
  ImagenWeb?: string;
  esEnWebSite?: number;
  DescripcionWeb?: string;
  id_TipoMoneda?: number;

  almacenes_movimientos_detalles!: almacenes_movimientos_detalle[];
  almacenes_stocks!: almacenes_stock[];
  comandas_detalles!: comandas_detalle[];
  compras_detalles!: compras_detalle[];
  facturas_detalles!: facturas_detalle[];
  materias_primas!: materias_primas[];
  // productos hasMany mesas via id_Producto_predet
  mesas!: mesas[];
  modificadores_pedidos!: modificadores_pedido[];
  pedidos_detalles!: pedidos_detalle[];
  productos_compuestos_detalles!: productos_compuestos_detalle[];
  productos_materiaprimas!: productos_materiaprima[];
  productos_promociones_detalles!: productos_promociones_detalle[];
  productos_proveedores!: productos_proveedores[];
  tipoacompanantes!: tipoacompanantes[];
  tipodescuentos_detalles!: tipodescuentos_detalle[];
  id_proveedor_proveedore!: proveedores;
  id_sector_impresion_sectores_impresion!: sectores_impresion;
  id_sub_categoria_subcategorias_producto!: subcategorias_productos;
  id_impuesto1_tipoimpuesto!: tipoimpuestos;
  id_Impuesto2_tipoimpuesto!: tipoimpuestos;
  id_tipo_itemventa_tipoitemventum!: tipoitemventa;
  id_TipoMoneda_tipomoneda!: tipomonedas;
  id_tipo_producto_tipoproducto!: tipoproductos;
  id_tipo_productogrupo_tipoproductos_grupo!: tipoproductos_grupo;
  id_TipoProductoMarca_tipoproductos_marca!: tipoproductos_marcas;


}
