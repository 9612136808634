
import type { comandas } from './comandas';
import type { modificadores_producto } from './modificadores_producto';
import type { productos } from './productos';

export interface comandas_detalleAttributes {
  ID: number;
  id_Comanda?: number;
  id_Producto?: number;
  id_defTipoStatusPedido?: number;
  id_Modificador?: number;
  Cantidad?: number;
  Mensaje?: string;
  esDespachado?: number;
  esImpreso?: number;
  esDevolucion?: number;
}

export type comandas_detallePk = "ID";

export class comandas_detalle implements comandas_detalleAttributes {
  ID!: number;
  id_Comanda?: number;
  id_Producto?: number;
  id_defTipoStatusPedido?: number;
  id_Modificador?: number;
  Cantidad?: number;
  Mensaje?: string;
  esDespachado?: number;
  esImpreso?: number;
  esDevolucion?: number;

  // comandas_detalle belongsTo comandas via id_Comanda
  id_Comanda_comanda!: comandas;
  id_Modificador_modificadores_producto!: modificadores_producto;
  id_Producto_producto!: productos;

}
