import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { Button, Grid } from '@mui/material';
import { LocalPrintshop } from '@mui/icons-material';
import { pedidos } from "../../../models/pedidos";
import { enutipoItemMenu } from '../index';
interface Props {
    children?: React.ReactNode;
    orderList?: pedidos[];
    fechaConsulta?: Date;
    titulo?: string;
    totalVentas?: string;
    formasPago?: any[];
    tipoSelect?: enutipoItemMenu;
}
function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

export default function FormasPago(props: Props) {
    let f = new Date();
    return (
        <React.Fragment>

            <Grid
                container
                className="m-0 p-0 h-100 bg-transparent"
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >

                <Grid
                    className="m-0 p-0 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                        Formas de Pago
                    </Typography>
                    <Grid
                        className="m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="caption">
                            Efectivo:
                        </Typography>
                        <Typography component="p" variant="subtitle2">
                            0,00
                        </Typography>
                    </Grid>
                    <Grid
                        className="m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="caption">
                            Divisa:
                        </Typography>
                        <Typography component="p" variant="subtitle2">
                            0,00
                        </Typography>
                    </Grid>
                    <Grid
                        className="m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="caption">
                            Tarjetas Débito:
                        </Typography>
                        <Typography component="p" variant="subtitle2">
                            0,00
                        </Typography>
                    </Grid>
                    <Grid
                        className="m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="caption">
                            Tarjetas Crédito:
                        </Typography>
                        <Typography component="p" variant="subtitle2">
                            0,00
                        </Typography>
                    </Grid>
                    <Grid
                        className="m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="caption">
                            Otros Pagos:
                        </Typography>
                        <Typography component="p" variant="subtitle2">
                            0,00
                        </Typography>
                    </Grid>
                </Grid>

                <Link className="text-dark fst-italic" href="#" onClick={preventDefault}>
                    Ver Balance
                </Link>
                <Button className=" bg-dark w-100" size='small' variant="contained" endIcon={<LocalPrintshop />}>
                    Imprimir
                </Button>
            </Grid>
        </React.Fragment>
    );
}