import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';

import { Grid, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { enutipoItemMenu } from '../index';
interface Props {
    children?: React.ReactNode;
    fechaConsulta1?: Date;
    fechaConsulta2?: Date;
    titulo?: string;
    totalVentas?: string;
    dataChart?: any[];
    tipoSelect?: enutipoItemMenu;
}
// Generate Sales Data



export default function Chart(props: Props) {
    const theme = useTheme();

    const fechas = () => {
        let f: string = '';
        if (props.fechaConsulta2) {
            return f = `Facturas entre ${dateFormat(props.fechaConsulta1, 'dd/mm/yyyy')}-${dateFormat(props.fechaConsulta2, 'dd/mm/yyyy')}`;
        } else {
            if (dateFormat(props.fechaConsulta1, 'dd/mm/yyyy') === dateFormat(new Date(), 'dd/mm/yyyy'))
                f = dateFormat(props.fechaConsulta1, 'dd/mm/yyyy')
            else
                f = `Facturas del Día ${dateFormat(props.fechaConsulta1, 'dd/mm/yyyy')}`
            return f;
        }
    }
    return (
        <React.Fragment>
            <Grid
                direction='row'
                container
                className="m-0 p-0 h-100 bg-transparent"
                justifyContent="space-between"
                alignItems="center"
            >
                {!fechas().includes('Facturas')

                    ? <> <Typography variant="h6">
                        {'Hoy'}
                    </Typography>
                        <Typography variant="h6"  >
                            {fechas()}
                        </Typography></>
                    : <> <Typography variant="h6">
                        {''}
                    </Typography>
                        <Typography variant='subtitle2'  >
                            {fechas()}
                        </Typography></>
                }

            </Grid>
            <ResponsiveContainer >
                <LineChart
                    data={props.dataChart}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="time"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.primary,
                                ...theme.typography.body1,
                            }}
                        >
                            Ventas ($)
                        </Label>
                    </YAxis>
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="monto"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}