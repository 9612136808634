import * as React from 'react';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { enutipoItemMenu } from '../index';
function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

interface Props {
    children?: React.ReactNode;
    fechaConsulta?: Date;
    titulo?: string;
    tipoSelect?: enutipoItemMenu;
}
const produtos = [
    {
        value: 'Producto1',
        label: '0001',
    },
    {
        value: 'Producto2',
        label: '0002',
    },
    {
        value: 'Producto3',
        label: '0003',
    },
    {
        value: 'Producto4',
        label: '0004',
    },
];
export default function Opciones(props: Props) {
    const { fechaConsulta, ...other } = props;
    const [currency, setCurrency] = React.useState('EUR');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrency(event.target.value);
    };
    return (
        <React.Fragment>

            <Grid
                container
                className="m-0 p-0 h-100 bg-transparent"
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                    Seleccionar Opción
                </Typography>

                <RadioGroup
                    className="w-100"
                    aria-label="gender"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="0" control={<Radio />} label="Resumido" />
                    <FormControlLabel value="1" control={<Radio />} label="Detallado" />
                    <FormControlLabel value="2" control={<Radio />} label="Diario" />
                </RadioGroup>


                {/* <TextField
                    className="w-100"
                    id="outlined-select-currency"
                    select
                    label="Select"
                    value={currency}
                    size='small'
                    onChange={handleChange}
                    helperText="Producto Inicial"
                >
                    {produtos.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    className="w-100"
                    id="outlined"
                    select
                    label="Select"
                    value={currency}
                    size='small'
                    onChange={handleChange}
                    helperText="Producto Final"
                >
                    {produtos.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Button className=" bg-dark text-white  w-100" size='small' variant="contained" endIcon={<Search />}>
                    Buscar
                </Button> */}
                <Grid
                    className="my-0 p-1 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    xs={12} md={12}
                >

                </Grid>
            </Grid>
        </React.Fragment>
    );
}