
import type { facturas_detalle } from './facturas_detalle';
import type { tipodescuentos } from './tipodescuentos';
import type { usuarios } from './usuarios';

export interface facturas_detalle_descuentosAttributes {
  ID: number;
  Id_FacturaDetalle?: number;
  Id_TipoDescuento?: number;
  id_UsuarioAutoriza?: number;
  ValorDescuento?: number;
  Comentario?: number;
}

export type facturas_detalle_descuentosPk = "ID";

export class facturas_detalle_descuentos implements facturas_detalle_descuentosAttributes {
  ID!: number;
  Id_FacturaDetalle?: number;
  Id_TipoDescuento?: number;
  id_UsuarioAutoriza?: number;
  ValorDescuento?: number;
  Comentario?: number;

  // facturas_detalle_descuentos belongsTo facturas_detalle via Id_FacturaDetalle
  Id_FacturaDetalle_facturas_detalle!: facturas_detalle;
  // facturas_detalle_descuentos belongsTo tipodescuentos via Id_TipoDescuento
  Id_TipoDescuento_tipodescuento!: tipodescuentos;
  // facturas_detalle_descuentos belongsTo usuarios via id_UsuarioAutoriza
  id_UsuarioAutoriza_usuario!: usuarios;

}
