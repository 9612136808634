import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { LocalPrintshop } from '@mui/icons-material';
import { pedidos } from "../../../models/pedidos";


interface Props {
    children?: React.ReactNode;
    orderList?: pedidos[];
    fechaConsulta?: Date;
    titulo?: string;
    totalVentas?: string;
    totalBase?: string;
    totalIVA?: string;
    totalFacts?: number;
    esCorteZ?: boolean;

}
export default function Caja(props: Props) {
    const { orderList, fechaConsulta, ...other } = props;

    return (
        <React.Fragment>
            <Grid
                container
                className="m-0 p-0 h-100 bg-transparent"
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    className="m-0 p-0 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                        Total Ventas
                    </Typography>
                    <Typography variant="subtitle2"  >
                        {`Total Base: ${props.totalBase}`}
                    </Typography>
                    <Typography variant="subtitle2"  >
                        {`Total IVA: ${props.totalIVA}`}
                    </Typography>
                    <Typography className="font-monospace" component="p" variant="h4">
                        {props.totalVentas ? props.totalVentas : '0,00'}
                    </Typography>
                </Grid>
                <Grid
                    className="my-0 p-0 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button className="my-1 bg-dark w-100" size='small' variant="contained" endIcon={<LocalPrintshop />}>
                        Reporte X
                    </Button>
                    {props.esCorteZ
                        ?
                        <Button className=" bg-warning text-black w-100" size='small' variant="contained" endIcon={<LocalPrintshop />}>
                            Cierre Caja
                        </Button>
                        : ''}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}