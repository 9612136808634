import { TextField, Grid, Typography } from "@mui/material";
import { useStateValue } from "../StateContext";

const ServiceForm = () => {

    const [{ formValues }, dispatch] = useStateValue();

    return <>
        <Grid className="p-1 m-0" item xs={12}>
            <Typography variant="h6">Información Adicional</Typography>
        </Grid>
        <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className="m-0 p-0 w-100"
        >
            <Grid className="p-1 m-0" item xs={12} sm={6}>
                <TextField
                    type="date"
                    label="Fecha Nacimiento"
                    name="date"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    size='small'
                    value={formValues.date}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "date",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid className="p-1 m-0" item xs={12} sm={6}>
                <TextField
                    label="Ocupación"
                    name="service"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={formValues.service}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "service",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Red Social</Typography>
            </Grid>
            <Grid className="p-1 m-0" item xs={12} sm={6}>
                <TextField
                    label="facebook"
                    name="facebook"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={formValues.facebook}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "facebook",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
            <Grid className="p-1 m-0" item xs={12} sm={6}>
                <TextField
                    label="twitter"
                    name="twitter"
                    variant="outlined"
                    fullWidth
                    size='small'
                    value={formValues.twitter}
                    onChange={e =>
                        dispatch({
                            type: 'editFormValue',
                            key: "twitter",
                            value: e.target.value
                        })
                    }
                />
            </Grid>
        </Grid>
    </>
}

export default ServiceForm;
