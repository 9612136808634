
import { useEffect, useState } from "react";
import { Box, Grid, Typography, FormControl, Paper, TextField, Autocomplete, createFilterOptions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, Select, OutlinedInput, MenuItem } from "@mui/material";
import { AddBox, Password, PersonAddAltRounded } from "@mui/icons-material";
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { usuarios, usuariosAttributes } from "../../../../models/usuarios";
import axios from "axios";
import { config } from "../../../../utils/configs";
import { useLoginToken } from "../../../../contexts/login-context";
import { loadArchivo, loadArchivoDef } from "../../../../utils/services";
import { enuTipoUsuarioNivel, enuTipoUsuarioRestaurant } from "../../../../utils/enums";
import { comboboxData } from "../../../../models/Entities/controlsEntity";
import { sha256 } from "../../../../utils/helpers";

export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}

interface usuariostmp extends usuariosAttributes {
    inputValue?: string
}
const filter = createFilterOptions<usuariostmp>();

interface Password {
    nuevaPass?: string,
    anteriorPass?: string,
    nuevoPin?: any,
    anteriorPin?: any,

}
export default function CrearUsuario(props: Props) {
    /* const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const history = useHistory(); */
    const loginToken = useLoginToken();

    const [passwordChange, setPasswordChange] = useState<Password>();
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const [open, toggleOpen] = useState(false);
    const [usuariostmpLst, setusuariostmpLst] = useState<usuariostmp[]>([]);
    const [value, setValue] = useState<usuariostmp | null>(null);
    const [dialogValue, setDialogValue] = useState<usuarios>(new usuarios);
    const [niveles, setNiveles] = useState<comboboxData[]>([]);
    const [cargos, setCargos] = useState<comboboxData[]>([]);


    const handleChange =
        (prop: keyof usuariostmp) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (value)
                setValue({ ...value, [prop]: event.target.value });
        };
    const handleChangePass =
        (prop: keyof Password) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (passwordChange)
                setPasswordChange({ ...passwordChange, [prop]: event.target.value });
        };
    const validaGuardar = (value: any): boolean => {
        if (value) {
            if (!value.id_defTipoUsuarioRestaurant) {
                setMessage('err--> Indique Cargo');
                return false;
            }
            if (!value.id_defTipoNivelAcceso) {
                setMessage('err--> Indique Nivel');
                return false;
            }
            if (!value.Nombre) {
                setMessage('err--> Indique Nombre');
                return false;
            }
            if (!value.NombreUsuario) {
                setMessage('err--> Indique Nombre Usuario');
                return false;
            }
            if (!value.CodigoPin) {
                setMessage('err--> Indique Código PIN');
                return false;
            }
            if (!value.Pass) {
                setMessage('err--> Indique Contraseña');
                return false;
            }
            return true;
        } else return false
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (validaGuardar(dialogValue)) {
            setValue(dialogValue);
            saveData(dialogValue);
            handleClose();
        }
    }; 
    const handleClose = () => {
        toggleOpen(false);
    };
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>

            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    
 
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    }; 

    //#endregion
    let savingData: boolean = false;
    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setloading(saving);
    }
    const loadArchivos = () => {
        setSavingData(true);
        loadArchivo(
            'usuarios',
            false,
            [
                'id_empleado',
                'id_TipoGrupoUsuario',
                'id_defTipoNivelAcceso',
                'id_defTipoUsuarioRestaurant',
                'Nombre',
                'NombreUsuario',
                'Pass',
                'CodigoPin',
                'DiasVence',
                'FechaActual',
                'FechaCaduca',
                'FechaModificadoPass',
                'esCaduca',
                'esActivo',
            ], undefined, undefined, ['Nombre'], { esActivo: 1 }
        ).then(
            (resolve) => {
                let res: usuarios[] = resolve as usuarios[];
                if (res && res.length) {
                    if (value === null)
                        setValue(res[0])
                    setusuariostmpLst(res);
                }
            },
            (error) => { setMessage('err-->' + error) }

        );
        loadArchivoDef('origenNivel', enuTipoUsuarioNivel, false).then(
            (resolve) => {
                let res = resolve as comboboxData[];
                let f: any = [];
                res.forEach(x => {
                    x.Nombre = x.Nombre?.replaceAll('_', ' ').toUpperCase();
                    if (x.ID && x.ID <= 18)
                        f.push(x)
                });
                setNiveles(f)
            },
            (error) => { setMessage('err-->' + error) }
        );

        loadArchivoDef('originalCargos', enuTipoUsuarioRestaurant, false).then(
            (resolve) => {
                let res = resolve as comboboxData[];
                let f: any = [];
                res.forEach(x => {
                    x.Nombre = x.Nombre?.replaceAll('_', ' ').toUpperCase();
                    if (x.ID && x.ID >= 2)
                        f.push(x)
                });
                setCargos(f)
            },
            (error) => { setMessage('err-->' + error) }
        ).finally(() => setSavingData(false));

    }
    const saveData = (value: usuarios | usuariostmp) => {
        if (!savingData) {
            setSavingData(true, "Guardando datos...");
            try {
                if (value) {
                    if (passwordChange && passwordChange.anteriorPass) {
                        if (verifPassAnterior(passwordChange.anteriorPass, false))
                            value.Pass = passwordChange.nuevaPass ? sha256(passwordChange.nuevaPass) as unknown as string : '';
                        else
                            return;
                    }
                    if (passwordChange && passwordChange.anteriorPin) {
                        if (verifPassAnterior(passwordChange.anteriorPin.toString(), true))
                            value.CodigoPin = Number(passwordChange.nuevoPin);
                        else
                            return;
                    }
                    if (validaGuardar(value)) {
                        setnewPassCahnge();
                        //setesAdd(false);
                        value.esActivo = 1;
                        value.FechaModificadoPass = new Date();
                        if (value.ID) {
                            axios.post(config.SERVER_URI + "/usuarios/update", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: { id: value.ID, ...value }
                            }).then(result => {
                                setMessage('ok--> Datos Actualizados');
                                loadArchivos();
                                setValue(value)
                            }).catch(err => {
                                if (err.response && err.response.status && err.response.status === 401)
                                    setMessage('err-->Sin Autorización Para continuar.');
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        } else {
                            value.FechaActual = new Date();
                            axios.post(config.SERVER_URI + "/usuarios/create", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: value
                            }).then((result: any) => {
                                setMessage('ok--> Datos Creados.');
                                loadArchivos();
                                setValue(result.data)
                            }).catch(err => {
                                setValue(null)
                                if (err.response && err.response.status && err.response.status === 401) {
                                    setMessage('err-->Sin Autorización Para continuar.');
                                }
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        }

                    }
                }
            } catch (error) {

            } finally {
                setnewPassCahnge();
                setSavingData(false);
                setDialogValue(new usuarios)
            }
        } else {
            setMessage('inf--> Trabajando... Por favor espere!')
        }
    };
    const verifPassAnterior = (anterior: string, esPin: boolean) => {
        if (passwordChange && value) {
            if (esPin && anterior) {
                if (passwordChange.nuevoPin && passwordChange.anteriorPin)
                    if (value.CodigoPin.toString() === anterior) {
                        return true;
                    } else {
                        setMessage('err-->PIN anterior incorrecto')
                        return false;
                    }
            }
            if (!esPin && anterior) {
                if (passwordChange.nuevaPass && passwordChange.anteriorPass)
                    if (value.Pass === sha256(anterior)) {
                        return true;
                    } else {
                        setMessage('err-->Password anterior incorrecto')
                        return false;
                    }
            }

        }
    }
    const setnewPassCahnge = () => {
        setPasswordChange({
            nuevaPass: '',
            anteriorPass: '',
            nuevoPin: '',
            anteriorPin: ''
        })
    }
    useEffect(() => {
        setnewPassCahnge();
        if (!open)
            setDialogValue(new usuarios);
    }, [open])
    useEffect(() => {
        setnewPassCahnge();
        loadArchivos()
    }, [])
    return (
        <>
            <Titulo />
            <FormControl className="w-100" component="fieldset">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="m-0 p-2 w-100"
                >
                    <Grid className="w-100 p-1 m-0" item xs={12} sm={12}>
                        <Autocomplete
                            fullWidth
                            className=""
                            autoHighlight
                            value={value}
                            onChange={(event, newValue) => {
                                setnewPassCahnge();
                                if (typeof newValue === 'string') {
                                    toggleOpen(true);
                                    let dv: usuarios = new usuarios;
                                    dv.NombreUsuario = newValue.replace('add ', '').replaceAll('"', '');
                                    dv.Nombre = dv.NombreUsuario;
                                    setDialogValue(dv);
                                } else if (newValue && newValue.inputValue) {
                                    toggleOpen(true);
                                    let dv: usuarios = new usuarios;
                                    dv.NombreUsuario = newValue.Nombre.replace('add ', '').replaceAll('"', '');
                                    dv.Nombre = dv.NombreUsuario;
                                    setDialogValue(dv);
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.NombreUsuario);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        ID: 0,
                                        inputValue: inputValue,
                                        NombreUsuario: `add "${inputValue}"`,
                                        Nombre: "",
                                        Pass: "",
                                        CodigoPin: 0,
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="firstname"
                            options={usuariostmpLst ? usuariostmpLst : []}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.NombreUsuario;
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li" className="p-0 m-0 " {...props}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="m-0 p-2 w-100"
                                    >
                                        <Grid className=" w-100 p-0 m-0 " item xs={12} sm={12}>
                                            {option.NombreUsuario.toLowerCase().includes('add')
                                                ?
                                                <Paper elevation={2} className="p-1 m-0 bg-warning w-100" >
                                                    <AddBox className="text-black pr-1 fs-2" />
                                                    <b className="px-1">{option.NombreUsuario.replace('add', '')}</b>
                                                </Paper>
                                                : option.NombreUsuario
                                            }
                                        </Grid>
                                    </Grid>

                                </Box>
                            )}
                            freeSolo
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="bg-body text-white rounded-3 "
                                    label="Usuario"
                                    name="firstname"
                                    variant="filled"
                                    required
                                    fullWidth
                                    size='small'
                                    value={value?.NombreUsuario}
                                />
                            )}
                        />
                    </Grid>
                    {value
                        ? <>
                            <Grid className="w-100 p-1 m-0" item xs={12} sm={12}>
                                <TextField
                                    label="Nombre Completo:"
                                    name="divisa"
                                    variant='filled'
                                    className="bg-body text-white rounded-3 "
                                    InputLabelProps={{
                                        shrink: true,

                                    }}
                                    fullWidth
                                    size='small'
                                    value={value ? value.Nombre : ''}
                                    onChange={handleChange('Nombre')}
                                />
                            </Grid>
                            <Grid container className="bg-danger bg-opacity-50  w-100 rounded-3 p-1 m-1">
                                <Typography className="col-12 text-white">
                                    Cambiar Contraseña
                                </Typography>

                                <Grid className="w-100 p-1 m-0" item xs={12} sm={6}>
                                    <TextField
                                        label="Contraseña Anterior:"
                                        name="anteriorpass"
                                        variant='filled'
                                        className="bg-body text-white rounded-3 "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='password'
                                        fullWidth
                                        size='small'
                                        value={passwordChange ? passwordChange.anteriorPass : ''}
                                        onChange={handleChangePass('anteriorPass')}
                                    />
                                </Grid>
                                <Grid className="w-100 p-1 m-0" item xs={12} sm={6}>
                                    <TextField
                                        label="Contraseña Nueva:"
                                        name="newpass"
                                        variant='filled'
                                        type='password'
                                        className="bg-body text-white rounded-3 "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size='small'
                                        value={passwordChange ? passwordChange.nuevaPass : ''}
                                        onChange={handleChangePass('nuevaPass')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="bg-danger bg-opacity-50  w-100 rounded-3 p-1 m-1">
                                <Typography className="col-12 text-white">
                                    Cambiar PIN
                                </Typography>

                                <Grid className="w-100 p-1 m-0" item xs={12} sm={6}>
                                    <TextField
                                        label="PIN Anterior:"
                                        name="newpin"
                                        variant='filled'
                                        className="bg-body text-white rounded-3 "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='password'
                                        fullWidth
                                        size='small'
                                        value={passwordChange ? passwordChange.anteriorPin : ''}
                                        onChange={handleChangePass('anteriorPin')}
                                    />
                                </Grid>
                                <Grid className="w-100 p-1 m-0" item xs={12} sm={6}>
                                    <TextField
                                        label="PIN Nuevo:"
                                        name="newpin"
                                        variant='filled'
                                        type='password'
                                        className="bg-body text-white rounded-3 "
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size='small'
                                        value={passwordChange ? passwordChange.nuevoPin : ''}
                                        onChange={handleChangePass('nuevoPin')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid direction='row' className="text-end p-1 m-0 w-100" item xs={12} sm={12}>
                                <Button
                                    size='small'
                                    className="m-md-1  m-auto col-4 bg-black bg-gradient text-white "
                                    onClick={() => {
                                        if (value)
                                            saveData(value);
                                    }}>
                                    Guardar
                                </Button>
                            </Grid>
                        </>
                        : ''
                    }
                </Grid>
            </FormControl>
            <Dialog className="p-1 vw-25" open={open} onClose={handleClose}>
                <DialogTitle className=" text-white bg-dark rounded round-3"><PersonAddAltRounded /> Agregar Usuario</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className="m-0 p-1 w-100"
                    >

                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel id="mesero-label">Nivel</InputLabel>
                                <Select
                                    labelId="mesero-label"
                                    id="nivels"
                                    size='small'
                                    required
                                    value={dialogValue.id_defTipoNivelAcceso}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_defTipoNivelAcceso: Number(event.target.value),
                                        })
                                    }
                                    input={<OutlinedInput label="Nivel" />}
                                >
                                    {
                                        niveles.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel id="mesero-label">Cargo</InputLabel>
                                <Select
                                    labelId="mesero-label"
                                    id="cargos"
                                    size='small'
                                    required
                                    value={dialogValue.id_defTipoUsuarioRestaurant}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_defTipoNivelAcceso: Number(event.target.value),
                                        })
                                    }
                                    input={<OutlinedInput label="Cargo" />}
                                >
                                    {
                                        cargos.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                required
                                label="Nombre"
                                name="rif"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={dialogValue.Nombre}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        Nombre: event.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <TextField
                                required
                                label="Nombre Usuario"
                                name="nombre"
                                variant="outlined"
                                fullWidth
                                size='small'
                                value={dialogValue.NombreUsuario}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        NombreUsuario: event.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <TextField
                                label="Password"
                                name="wahatsapp"
                                variant="outlined"
                                fullWidth
                                required
                                size='small'
                                type='password'
                                value={dialogValue.Pass}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        Pass: event.target.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <TextField
                                required
                                label="Codigo PIN"
                                name="direccion"
                                variant="outlined"
                                fullWidth
                                size='small'
                                type='password'
                                value={dialogValue.CodigoPin}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        CodigoPin: Number(event.target.value),
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                    <DialogContentText className="text-end" >
                        <Typography variant='inherit' >
                            Rellenar los Campos necesarios
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit} variant='contained' >Agregar</Button>
                </DialogActions>
                <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />

            </Dialog>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}
