import Button from "../../components/button";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import PanelHeader from "../../components/panel-header";
import { useRef, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { ColorsDPana } from "../../utils/Color";
import OrderItemDisplay from "../../components/order-item-display";
import { useOrder, useSetOrder } from "../../contexts/order-context";
import { useSetting, useSetSetting } from "../../contexts/setting-context";
import { enutipodlgOpc, imgtoJson } from "../../utils/helpers";
import { useLoginToken } from "../../contexts/login-context";
import CheckLoginToken from "../../components/check-login-token";
import { useHistory } from "react-router-dom";
import SimpleToast from "../../components/simple-toast";
import { categorias } from "../../models/categorias";
import CardItem from "../../components/cardItem";
import SimpleDialog from "../dialogs/simple-dialog";
import { makeStyles } from "@mui/styles";
import Pago from "../forms/pagos/index"
import Orden, { ItemPedido } from "../../models/Entities/Orden.model";
import { pedidos_detalle_modificadores } from "../../models/pedidos_detalle_modificadores";
import { productos } from "../../models/productos";
import { subcategorias_productos } from "../../models/subcategorias_productos";
import { clientes } from "../../models/clientes";
import { enuStatusFactura, enuStatusPagoDocumento, enuStatusPedido, enuStatusPedidoDetalle, enuTipoOrigenPedido, enuTipoPedidoDespacho } from "../../utils/enums";
import CrudOrdenService from "../../services/crudorden.service";
import { formatNumber } from 'fast-number-formatter'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Card, CardContent, Container, Grid, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { Box, ThemeProvider, createTheme } from '@mui/material';
import { config } from "../../utils/configs";
import LoginDialog from "../dialogs/login-dialog";
import SpinnerLoading from "../../components/spinner-loading";
import { LibraryBooks, LocalOffer, Menu } from "@mui/icons-material";
import { mesas } from "../../models/mesas";
import { usuarios } from "../../models/usuarios";
import { loadArchivo } from "../../utils/services";
import dateFormat from "dateformat";
import { productos_promociones } from "../../models/productos_promociones";
import './style.css';
import "./mobile-style.css"
import MenuDialog from "./menu-dialog";
import { stateFormValue } from "../forms/pagos/StateContext/reducer";
import { facturas } from "../../models/facturas";
import { facturas_mediopago } from "../../models/facturas_mediopago";
const theme = createTheme({
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
        success: {
            main: '#009688',
            dark: '#009688',
        },
    },
});
export function Example() {
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    minWidth: 300,
                }}
            >
                <Box sx={{ color: 'text.secondary' }}>Sessions</Box>
                <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                    98.3 K
                </Box>
                <Box
                    sx={{
                        color: 'success.dark',
                        display: 'inline',
                        fontWeight: 'medium',
                        mx: 0.5,
                    }}
                >
                    +18.77%
                </Box>
                <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
                    vs. last week
                </Box>
            </Box>
        </ThemeProvider>
    );
}
const iconsStyles = makeStyles({
    div: {
        margin: 2
    }
});
class ProductoList extends productos {
    idCategoria?: number
}
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

interface State {
    amount: string;
    password: string;
    codigoPin: string;
    nombreUsuario: string;
    cliente: string;
    weightRange: string;
    showPassword: boolean;
    openSnackbar: boolean;
    tipoStatusSnakBar: 'success' | 'info' | 'warning' | 'error';
    messageSnackBar: string;
}

export default function Order() {
    const [values, setValues] = useState<State>({
        amount: '',
        password: '',
        codigoPin: '',
        nombreUsuario: '',
        cliente: '',
        weightRange: '',
        showPassword: false,
        openSnackbar: false,
        tipoStatusSnakBar: 'success',
        messageSnackBar: ''
    });
    CheckLoginToken();
    const [productosList, setproductosList] = useState<ProductoList[]>([]);
    const [categoriaList, setcategoriaList] = useState<categorias[]>([]);
    const [subcategoriaList, setsubcategoriaList] = useState<subcategorias_productos[]>([]);
    const [selectedItem, setSelectedItem] = useState<ItemPedido>(new ItemPedido(undefined));
    const [filterSearch, setFilterSearch] = useState("");
    const [idProductoSelected, setidProductoSelected] = useState(0);
    const [idCategoriaSelected, setidCategoriaSelected] = useState(0);
    const [itemsList, setItemsList] = useState<ItemPedido[]>([]);
    const forceUpdate = useForceUpdate();

    const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const loginToken = useLoginToken();
    const history = useHistory();

    const crudService: CrudOrdenService = new CrudOrdenService();
    //Dialogos
    const bottomRef = useRef<any>();
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const [openPopover, setopenPopover] = useState(false);
    const [mesaSelected, setMesaSelected] = useState<mesas | undefined>(PedidoActual?.Mesa);
    const [tipoDespacho, settipoDespacho] = useState<enuTipoPedidoDespacho>();
    const [nroPedido, setnroPedido] = useState<number>();
    const [Meseroslst, setMeseroslst] = useState<usuarios[]>([]);
    const [meseroSelected, setMeseroSelected] = useState<usuarios | undefined>(PedidoActual?.Id_Usuario_usuario);
    const [clienteSelected, setClienteSelected] = useState<clientes | undefined>(PedidoActual?.Id_Cliente_cliente);
    /* 
    const [Mesaslst, setMesaslst] = useState<mesas[]>([]);
    const [open, setOpen] = useState(false);
    const [tipoOpc, settipoOpc] = useState<enutipodlgOpc>(enutipodlgOpc.ninguno);
    const [idmesaSelected, setidMesaSelected] = useState<number>(0);
    const [conValues, setconValues] = useState(false);
    const [idmeseroSelected, setidMeseroSelected] = useState<number>(0);
    const [eserrLogin, seteserrLogin] = useState(false);
    const [esAceptar, setesAceptar] = useState(false); */

    //DPANA
    const clsIcons = iconsStyles();
    //Migrando
    const [ItemActual, setItemActual] = useState<ItemPedido | null>();
    let newOrder!: Orden;
    //#region Codigo Nuevo
    const CrearOrden = () => {
        newOrder = new Orden();
        switch (tipoDespacho) {
            case enuTipoPedidoDespacho.Desconocido:
                break;
            case enuTipoPedidoDespacho.ComerAqui:
                break;
            case enuTipoPedidoDespacho.llevar:
                break;
            case enuTipoPedidoDespacho.Delivery:
                break;
            case enuTipoPedidoDespacho.Pickup:
                break;
            case enuTipoPedidoDespacho.ConsumoInterno:
                break;
            case enuTipoPedidoDespacho.Pedido:
                break;
            case enuTipoPedidoDespacho.Guardado:
                break;
            case enuTipoPedidoDespacho.Recuperado:
                break;
            case enuTipoPedidoDespacho.VentaDirecta:
                setting.MesaActual = undefined;
                break;
            default:
                break;
        }
        newOrder.initOrden(setting, tipoDespacho ? tipoDespacho : enuTipoPedidoDespacho.VentaDirecta, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
        setSetting(setting);
        setPedidoActual(newOrder);
    }

    const crearItemPedido = (item: productos, cantidad: number = 0, comentario: string = "", mensaje: string = "", promocion: productos_promociones | undefined = undefined) => {
        if (item) {
            let i = "";
            if (item.ImagenWeb) {
                try {
                    i = imgtoJson(item.ImagenWeb.toString());
                    i = i.replace("http://localhost:8080/dl/sushibarstrike_v1_UD4u/", "http://sushibarstrike.com.ve/imagenes/")
                } catch (error) {
                    i = item.ImagenWeb;
                }
            }
            item.Imagen = i;
            item.ImagenWeb = i;

            let tmpItem: any = new ItemPedido(setting);
            if (item) {
                tmpItem.Imagen = item.ImagenWeb;
                tmpItem.PuntosRating = item.PuntosCantidad ?? 0;
                tmpItem.Descripcion = item.Nombre as string;
                tmpItem.Id_Producto = item.ID;
                tmpItem.id_TipoImpuesto = item.id_impuesto1;
                tmpItem.MontoPrecio = setting.getPrecio(item);
                tmpItem.MontoPrecioDivisa = item.MontoPrecioDivisa ?? 0;
                tmpItem.PuntosAplicados = item.PuntosCantidad ?? 0;
                tmpItem.id_TipoMoneda = item.id_TipoMoneda ?? 1;
            }
            if (promocion) {
                tmpItem.Imagen = promocion.Imagen;
                tmpItem.PuntosRating = promocion.Puntos_Cantidad ?? 0;
                tmpItem.Descripcion = promocion.Nombre as string;
                tmpItem.Id_Producto = promocion.ID;
                tmpItem.id_TipoImpuesto = promocion.id_TipoImpuesto;
                tmpItem.MontoPrecio = setting.getPrecio(promocion);
                tmpItem.MontoPrecioDivisa = promocion.MontoPrecioDivisa ?? 0;
                tmpItem.PuntosAplicados = promocion.Puntos_Cantidad ?? 0;
                tmpItem.id_TipoMoneda = promocion.id_TipoMoneda ?? 1;
            }
            //params
            tmpItem.newCantidadTotal = cantidad === 0 ? 1 : cantidad;
            tmpItem.Comentario = comentario;
            tmpItem.Mensaje = mensaje;
            //setting
            tmpItem.TasaIVA = +setting.getTasaIVA(tmpItem.id_TipoImpuesto);
            tmpItem.id_defTipoPrecio = setting.CajaActual ? setting.CajaActual.id_defTipoPrecioPredet : 1;
            //defaults
            tmpItem.FechaActual = new Date();
            tmpItem.CantidadImpreso = 0;
            tmpItem.CantidadDevolucion = 0;
            tmpItem.esDevolucion = 0;
            tmpItem.esImpreso = 0;
            tmpItem.esDespachado = 0;
            tmpItem.id_SectorImpresion = item.id_sector_impresion ?? 0;
            tmpItem.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
            tmpItem.id_ProductoPromocion = 0;
            tmpItem.id_TipoPromocion = 0;
            tmpItem.id_TipoOferta = 0;
            editItem(tmpItem);
        }

    }


    /*   const crearExtra = (extra: modificadores_producto) => {
          ExtraActual = new pedidos_detalle_modificadores;
          ExtraActual.Nombre = extra.Nombre;
          ExtraActual.id_ModificadorProducto = extra.ID;
          ExtraActual.MontoPrecioDivisa = extra.MontoPrecioDivisa1 ?? 0;
          ExtraActual.FechaActual = new Date();
          ExtraActual.CantidadTotal = 1;
          ExtraActual.TasaIVA = setting?.Impuestos ? setting.Impuestos.find(x => x.ID === extra.id_TipoImpuesto)?.MontoTasa as number : 0;
          ExtraActual.id_TipoImpuesto = extra.id_TipoImpuesto;
          ExtraActual.MontoPrecio = extra.MontoPrecio1 ?? 0;
      } */
    const editItem = (item: ItemPedido, addToPedido: boolean = false) => {
        newOrder = PedidoActual;
        if (!newOrder || !newOrder.FechaActual)
            CrearOrden();
        if (newOrder) {
            let tmpitem = newOrder.editItem(item);
            setItemActual(() => tmpitem as ItemPedido);
            setSelectedItem(tmpitem as ItemPedido);
            setPedidoActual(() => newOrder);
        } else
            setMessage("No hay Pedido Actual");
        forceUpdate();
    }
    /* const addExtra = (extra: modificadores_producto) => {
        crearExtra(extra);
        if ((ItemActual as ItemPedido).Extras && (ItemActual as ItemPedido).Extras.length > 0)
            ReemplazarExtras((ItemActual as ItemPedido).Extras, ExtraActual as pedidos_detalle_modificadores);
        else
            editExtra(ExtraActual as pedidos_detalle_modificadores)
        setPedidoActual(newOrder);
    } */
    const incrementaCant = (item: ItemPedido) => {
        item.newCantidadTotal = item.CantidadTotal ?? 0;
        item.newCantidadTotal++;
        editItem(item);
    }
    const decrementaCant = (item: ItemPedido) => {
        item.newCantidadTotal = item.CantidadTotal ?? 0;
        if (item.newCantidadTotal > 0) {
            item.newCantidadTotal--;
            editItem(item);
        }
    }



    //#endregion

    const selectedControl = (ctrl: any) => {
        console.log(ctrl);
    }
    const renderOrderList = () => {
        scrollToBottom();
        return <OrderItemDisplay tipoPrecio={setting.TipoPrecioActual} esIncuyeIVA={setting.esIVAIncluido} selectedControl={(e) => { selectedControl(e) }} order={PedidoActual} handleUp={(e) => { incrementaCant(e) }} handleDown={(e) => { decrementaCant(e) }} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
    }
    const crearButtons = (Data: any, key: any, tipo: number) => {
        let img: string = "";
        let idcat = Data.idCategoria ? Data.idCategoria : Data.ID;
        if (Data.ImagenWeb) {
            try {
                img = imgtoJson(Data.ImagenWeb.toString());
                img = img.replace("http://localhost:8080/dl/sushibarstrike_v1_UD4u/", "http://sushibarstrike.com.ve/imagenes/")
            } catch (error) {
                img = Data.ImagenWeb;
            }
        }
        Data.Imagen = img;
        Data.ImagenWeb = img;
        if (Data.ImagenWeb.length === 0)
            Data.ImagenWeb = "NoImage.jpg";

        return (
            <CardItem Titulo={Data.Nombre} Imagen={img} Tipo={tipo}
                key={key}
                handleClick={() => {
                    tipo === 1 ? changeCategorias(idcat) : setProductos(Data);
                }}
                Descripcion={Data.DescripcionWeb}
                Precio={Data.MontoPrecio1}
                PrecioDivisa={Data.MontoPrecioDivisa}
                idCategoria={idcat}
                themeColor={
                    tipo === 1
                        ? idcat === idCategoriaSelected ? ColorsDPana.primary : ColorsDPana.secundary
                        : Data.ID === idProductoSelected ? ColorsDPana.primary : ColorsDPana.secundary
                }
            /*  style={{
                 display:
                     tipo === 2
                         ? idcat === idCategoriaSelected
                             ? "inline-block"
                             : "none"
                         : "block"
             }} */
            >
            </CardItem>
        );
    }

    const loadProductos = (id: number) => {
        if (id) {
            let items: productos[] = []
            productosList.length = 0;
            axios.post(config.SERVER_URI + "/web/itemsbycat", {
                data: { id: id }
            }).then(res => {
                if (res.status === 200) {
                    let cat: categorias[] = res.data as unknown as categorias[];
                    if (cat && cat[0].subcategorias_productos && cat[0].subcategorias_productos.length)
                        cat[0].subcategorias_productos.forEach(s => {
                            if (s && s.productos && s.productos.length) {
                                s.productos.forEach(p => {
                                    items.push(p);
                                });
                            }
                        });
                    setproductosList(items);
                } else {
                    setMessage("err-->loadProdRes No se pudo traer Items");
                }
            }).catch(err => {
                setMessage("err-->loadProd" + err);
            });
        }
    }

    const loadCategorias = () => {
        axios.post(config.SERVER_URI + "/web/catslist", {
            options: {
                include: ["subcategorias_productos"]
            }
        }).then(result => {
            if (result && result.data) {
                var c: categorias[] = [];
                result.data.forEach((e: categorias) => {
                    if (e.subcategorias_productos && e.subcategorias_productos.length)
                        c.push(e);
                });
                setcategoriaList(c);
            }
        }).catch(err => {
            setMessage("err-->loadCat" + err);
        })
    }

    const setProductos = (data: any) => {
        setidProductoSelected(data.ID);
        crearItemPedido(data);
    }

    const changeCategorias = (id: number) => {
        setidCategoriaSelected(id);
        loadProductos(id);
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);

    const imprimirFactura = () => {
        axios.post('http://127.0.0.1:5000/recipes', { data: PedidoActual })
            .then(function (response) {
                const data = response.data;
                console.log(data);
                setMessage("Respuesta de Impresora")
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const childDialogRes = async (e: enuStatusPagoDocumento, datellePago: stateFormValue) => {
        console.log(e);
        setOpenSDialog(false);
        try {
            switch (e) {
                case enuStatusPagoDocumento.PagadoTotalmente:
                    let f: facturas = new facturas;
                    if (!PedidoActual.Id_Caja) {
                        if (setting.CajaActual) {
                            PedidoActual.Id_Caja = setting.CajaActual.ID;
                            PedidoActual.Id_Caja_caja = setting.CajaActual;
                        } else
                            PedidoActual.Id_Caja = 1;
                    }
                    if (datellePago.clientes && datellePago.clientes.length) {
                        PedidoActual.Id_Cliente_cliente = datellePago.clientes[0];
                        PedidoActual.Id_Cliente = datellePago.clientes[0].ID;
                    }
                    if (datellePago.formaspago) {
                        let fps: facturas_mediopago[] = [];
                        datellePago.formaspago.forEach(fp => {
                            fp.ID = fp.Id_MedioPago;
                            fps.push(fp)
                        });
                        f.facturas_mediopagos = fps;
                        PedidoActual.facturas = [];
                        PedidoActual.facturas.push(f);
                    }
                    await CrudOrdenService.modifFactura(PedidoActual, loginToken, setting, undefined, 0, enuStatusFactura.Facturado)
                        .then((res: any) => {
                            if (res) {
                                setMessage(`ok-->Se ha generado la FACTURA:${res.NroFactura}`)
                                imprimirFactura();
                                vaciarPedido();
                                iraPantalla();
                            } else
                                setMessage(`err-->Factura No Guardada. Intente mas tarde`)

                        })
                        .catch(err => {
                            setMessage("err-->childDialogRes Factura No Guardada. Intente mas tarde" + err);
                        });
                    break;
                case enuStatusPagoDocumento.Ninguno:

                    break;
                case enuStatusPagoDocumento.ParcialmentePagado:

                    break;
                case enuStatusPagoDocumento.SinPagos:

                    break;
                case enuStatusPagoDocumento.PendienteConfirmar:

                    break;
                case enuStatusPagoDocumento.ParcialmentePagado_Credito:

                    break;
                case enuStatusPagoDocumento.ParcialmentePagado_PendienteConfirmar:

                    break;
                case enuStatusPagoDocumento.Pagado_Credito:

                    break;

                default:
                    break;
            }

        } catch (error) {
            setMessage("err-->Error response" + error);
        }
    }
    const loadPagos = () => {
        // setChildrenSDialog(<button />);
        setChildrenSDialog(<Pago handleResponse={childDialogRes} ></Pago>);
        handleClickOpenSimpleDialog();
    }

    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }
    const loadLoginDialog = (titulo: string) => {
        setChildrenSDialog(<LoginDialog handleResponse={loginDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }

    const loadMenuDialog = (titulo: string) => {
        setChildrenSDialog(<MenuDialog handleResponse={childDialogRes} titulo={titulo}  ></MenuDialog>);
        handleClickOpenSimpleDialog();
    }
    //#endregion

    const vSubtotal = () => {
        let stotal: string;
        if (PedidoActual && PedidoActual.MontoTotalBase)
            try {
                stotal = formatNumber(+PedidoActual.MontoTotalBase, 2);
            } catch {
                stotal = PedidoActual.MontoTotalBase.toString();
            }
        else
            stotal = "0,00";
        return stotal;
    }
    const vTotal = () => {
        let stotal: string;
        if (PedidoActual && PedidoActual.MontoTotal)
            try {
                stotal = formatNumber(+PedidoActual.MontoTotal, 2);
            } catch {
                stotal = PedidoActual.MontoTotal.toString();
            }
        else
            stotal = "0,00";
        return stotal;

    }
    const vTotalIVA = () => {
        let stotal: string;
        if (PedidoActual && PedidoActual.MontoTotalBase)
            try {
                stotal = formatNumber(PedidoActual.MontoTotalIVA ? +PedidoActual.MontoTotalIVA : 0, 2);
            } catch {
                stotal = PedidoActual.MontoTotalIVA ? PedidoActual.MontoTotalIVA.toString() : '0.00';
            }
        else
            stotal = "0,00";
        return stotal;

    }
    //#region ScrollBottom
    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollTop = bottomRef.current.scrollHeight - 160;
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            //element.scrollHeight - Math.abs(element.scrollTop) === element.clientHeight
        }
    };
    /*  useEffect(() => {
     }, [props.children]) */
    //#endregion


    const vaciarPedido = () => {
        CrearOrden();
    }
    let sendingOrder = false;
    const sendOrden = async () => {
        return new Promise(async (resolve, reject) => {
            if (!sendingOrder) {
                try {
                    sendingOrder = true;
                    if (!crudService.savingData) {
                        PedidoActual.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                        CrudOrdenService.modifPedido(PedidoActual, loginToken)
                            .then(res => {
                                let orden: Orden = res as Orden;
                                setPedidoActual(orden);
                                resolve(orden);
                                setMesaSelected(undefined);
                            })
                            .catch(error => {
                                if (error.includes("Request failed with status code 401")) {
                                    setMessage("Error de Autenticación. Por favor Iniciar sesión")
                                    setTimeout(() => {
                                        loadLoginDialog("Confirmar PIN");
                                    }, 2500);
                                } else {
                                    setMessage(error?.stack);
                                }
                                sendingOrder = false;
                                reject(error);
                            });
                    } else {
                        setMessage("Trabajando... Por favor espere!")
                    }
                } catch (error: any) {

                    if (error.includes("Request failed with status code 401")) {
                        setMessage("Error de Autenticación. Por favor Iniciar sesión")
                        setTimeout(() => {
                            loadLoginDialog("Confirmar PIN");
                        }, 2500);
                    } else {
                        setMessage(error?.stack);
                    }
                    reject(error);
                }
                finally {
                    sendingOrder = false;
                    crudService.savingData = false;
                }
            } else {
                setMessage("Enviando Orden. Por favor espere que el servidor Responda.");
                reject("Enviando Orden. Por favor espere que el servidor Responda.");
            }
        })
    };
    const loadArchivos = () => {
        loadArchivo(
            'usuarios',
            true,
            ['Nombre', 'Imagen', 'NombreUsuario', 'Pass', 'CodigoPin'], undefined, undefined
        ).then(
            (resolve) => { setMeseroslst(resolve as usuarios[]) },
            (error) => { setMessage('err-->' + error) }
        );
    }
    const iraPantalla = (salir: boolean = false) => {
        selectMesa(mesaSelected, false);
        if (salir) {
            setTimeout(() => {
                setMessage("Saliendo del sistema. Espere por favor")
            }, 1500);
            history.push("/main-menu");
        }
        switch (tipoDespacho) {
            case enuTipoPedidoDespacho.ComerAqui:
                //Datos de Mesa
                history.push("/table");
                break;
            case enuTipoPedidoDespacho.ConsumoInterno:
                break;
            case enuTipoPedidoDespacho.Delivery:
                history.push("/main-menu");
                //si !Cliente pedir datos
                break;
            case enuTipoPedidoDespacho.Desconocido:
                break;
            case enuTipoPedidoDespacho.Guardado:
                break;
            case enuTipoPedidoDespacho.Pedido:
                history.push("/recall");
                //si !Cliente pedir datos
                break;
            case enuTipoPedidoDespacho.Pickup:
                history.push("/main-menu");
                //si !Cliente pedir datos
                break;
            case enuTipoPedidoDespacho.Recuperado:
                break;
            case enuTipoPedidoDespacho.llevar:
                //si !Cliente pedir datos
                break;
            case enuTipoPedidoDespacho.VentaDirecta:
                CrearOrden();
                forceUpdate();
                break;
            default:
                history.push("/main-menu");
                break;
        }
    }
    const tituloCaja = () => {
        setting.getCorrelativos().then(res => {
            if (res) {
                setnroPedido(res.NroPedido ? res.NroPedido : 0);
            }
        })

        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="space-berween"
                    alignItems="center"
                    xs={6} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6} sm={12}
                    >
                        <img className="m-1 text-start" src={'./assets/imagenes/logoicon-white.png'}
                            style={{
                                position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                            }}
                            onClick={() => {
                                selectMesa(mesaSelected, false);
                                history.push('/gotodpana');
                            }}>
                        </img>
                        <Typography variant='subtitle1'>
                            {setting.CajaActual ? setting.CajaActual.Descripcion + ' ' : ''}
                        </Typography>

                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        direction="row"
                        justifyContent="space-berween"
                        alignItems="center"
                        xs={6} sm={12}
                    >
                        <Typography className="px-1" variant='caption'>
                            Usuario:
                        </Typography>
                        <Typography variant='subtitle1'>
                            {setting.UsuarioActual ? setting.UsuarioActual.Nombre : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={6} sm={6}
                >
                    <Typography className="text-white" variant='h5'>
                        dPana POS
                    </Typography>
                    <Typography className="text-white-50" variant='subtitle1'>
                        Sistema de facturación en Línea
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={6} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6} sm={12}
                    >
                        <Typography variant='caption'>
                            Fecha:
                        </Typography>
                        <Typography variant='subtitle1'>
                            {dateFormat(setting.CajaActual?.FechaAperturaUltima, "dd/mm/yyyy")}
                        </Typography>
                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6} sm={12}
                    >
                        <Typography className="px-1" variant='caption'>
                            Pedido:
                        </Typography>
                        <Typography variant='subtitle1'>
                            {PedidoActual && PedidoActual.ID
                                ? PedidoActual.NroPedido
                                : nroPedido ? nroPedido + 1 : 0}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        )
    }
    const btnEnviar = () => {
        switch (tipoDespacho) {
            case enuTipoPedidoDespacho.VentaDirecta:
            case enuTipoPedidoDespacho.Guardado:
                return (
                    <span className="fs-6">Guardar</span>
                )
            case enuTipoPedidoDespacho.Delivery:
                return (
                    <span className="fs-6">Delivery</span>
                )
            case enuTipoPedidoDespacho.Pickup:
                return (
                    <span className="fs-6">Pickup</span>
                )
            default:
                return (<span className="fs-5">Enviar</span>)
        }
    }
    const selectMesa = (mesa: mesas | undefined, select: boolean) => {
        // CrudOrdenService.setBlockMesa(mesaSelectedprev ? mesaSelectedprev.ID : 0, false, loginToken);
        if (select) {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, true, loginToken);
            setMesaSelected(mesa);
            //  setMesaSelectedprev(mesa);
        }
        else {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, false, loginToken);
            setMesaSelected(undefined);
        }
    }

    useEffect(() => {
        if (productosList) {
            /* setMessage(productosList.length.toString()); */
        }
    }, [productosList]);
    useEffect(() => {
        if (ItemActual) {
            setSelectedItem(ItemActual);
        }
    }, [ItemActual]);

    useEffect(() => {
        if (categoriaList) {
            changeCategorias(categoriaList[0]?.ID);
        }

    }, [categoriaList])

    useEffect(() => {
        loadCategorias();
        loadArchivos();
        if (PedidoActual) {
            setMesaSelected(PedidoActual.Mesa);
            setMeseroSelected(PedidoActual.Id_Usuario_usuario);
            setClienteSelected(PedidoActual.Id_Cliente_cliente);
            settipoDespacho(PedidoActual.id_defTipoPedido);
            if (PedidoActual.Mesa)
                selectMesa(PedidoActual.Mesa, true);
        }

    }, [])


    return (
        <Container id="order-page-container">
            <Panel id="order-page-side-menu" className="d-flex flex-column">
                <PanelHeader className="order-page-panel-header">Categorías</PanelHeader>
                <PanelBody className="flex-grow-1 overflow-auto">
                    {
                        categoriaList?.map((button, index) => {
                            return crearButtons(button, 'cat-' + index, 1);
                        })
                    }
                </PanelBody>
            </Panel>
            <Panel id="order-page-main-menu" className="d-flex flex-column">
                <PanelHeader className="order-page-panel-header">{tituloCaja()}</PanelHeader>
                <PanelBody className="flex-grow-1 overflow-auto">
                    {
                        productosList?.map((button, index) => {
                            return crearButtons(button, 'prod-' + index, 2);
                        })
                    }
                </PanelBody>
            </Panel>
            <Panel id="order-page-receipt-preview" className="d-flex flex-column">
                <PanelHeader className="order-page-panel-header">
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={6} sm={12}
                    >
                        {mesaSelected ?
                            <Grid
                                className="m-0 p-0"
                                item
                                container
                                direction="row"
                                justifyContent="space-berween"
                                alignItems="center"
                                xs={6} sm={6}

                            >
                                <> <Typography variant='caption'>
                                    Mesa:
                                </Typography>
                                    <Typography className="text-white" variant='h5'>
                                        {mesaSelected.Nombre}
                                    </Typography>
                                </>
                            </Grid>
                            : ''}
                        <Grid
                            className="m-0 px-1 p-0"
                            item
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            xs={6} sm={mesaSelected ? 6 : 12}

                        >
                            <Typography variant='subtitle1'>
                                {`Productos ${PedidoActual && PedidoActual.NroItems ? PedidoActual.NroItems : ''}`}
                            </Typography>

                        </Grid>

                    </Grid>
                </PanelHeader>
                <PanelBody reference={bottomRef} className="flex-grow-1 overflow-auto" >
                    {renderOrderList()}
                </PanelBody>
                <Card className="p-1" style={{ backgroundColor: 'transparent', color: 'white', maxHeight: "100px", minHeight: "100px" }}>
                    <div className="row">
                        <div className="position-absolute" style={{ zIndex: 1000 }}>

                        </div>
                        <div className="col-sm-12">
                            <CardContent style={{ padding: 3 }}>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    className="m-0 p-0 w-100"
                                >
                                    <Grid
                                        className="m-0 p-0"
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        xs={12} sm={12}
                                    >
                                        <Grid
                                            className="m-0 p-0"
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            xs={12} sm={6}
                                        >
                                            <Tooltip title={"Menu de Opciones"} arrow  >
                                                <IconButton className="text-white p-0">
                                                    <Menu className={clsIcons.div} color='warning' sx={{ fontSize: 30 }}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                loadMenuDialog("");
                                                            }, 100);
                                                        }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Vaciar Pedido"} arrow  >
                                                <IconButton className="text-white p-0">
                                                    <RemoveShoppingCartIcon className={clsIcons.div} color='error' sx={{ fontSize: 30 }}
                                                        onClick={() => {
                                                            if (PedidoActual && !PedidoActual.ID) {
                                                                setMessage("inf-->Vaciando Pedido...")
                                                                forceUpdate();
                                                                setPedidoActual(newOrder);
                                                            } else {
                                                                setMessage("wrn-->Impoasible Vaciar pedido!")
                                                            }
                                                        }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Cargar Pedido"} arrow  >
                                                <IconButton className="text-white p-0">
                                                    <LibraryBooks className={clsIcons.div} color='error' sx={{ fontSize: 30 }}
                                                        onClick={() => {
                                                            setMessage("inf-->Espere mientras cargamos Pedidos...")
                                                            selectMesa(mesaSelected, false);
                                                            setTimeout(() => {
                                                                history.push("/recall");
                                                            }, 2500);
                                                        }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Aplicar Descuento-Recarga"} arrow  >
                                                <IconButton className="text-white p-0">
                                                    <LocalOffer className={clsIcons.div} color='error' sx={{ fontSize: 30 }}
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                //loadMenuDialog("");
                                                            }, 100);
                                                        }} />
                                                </IconButton>
                                            </Tooltip>
                                            {/*    <IconButton className="text-white p-0">
                                                <FaceRetouchingNaturalIcon className={clsIcons.div} color='error' sx={{ fontSize: 30 }} onClick={() => { console.log('Cliente') }} />
                                            </IconButton> */}
                                        </Grid>

                                        <Grid
                                            className="m-0 p-0"
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-start"
                                            xs={12} sm={6}
                                        >
                                            <Grid
                                                className="m-0 p-0"
                                                item
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="flex-end"
                                                xs={12} sm={6}
                                            >
                                                <Typography className="p-0 text-capitalize text-white-50" variant='button'>
                                                    Subtotal
                                                </Typography>
                                                <Typography className="p-0 text-light-emphasis" variant='subtitle1'>
                                                    {vSubtotal()}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                className="m-0 p-0"
                                                item
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="flex-end"
                                                xs={12} sm={6}
                                            >
                                                <Typography className="p-0 text-capitalize text-white-50" variant='inherit'>
                                                    IVA 16%
                                                </Typography>
                                                <Typography className="p-0 text-light-emphasis" variant='subtitle1'>
                                                    {vTotalIVA()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        className="m-0 p-0 text-end"
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems='center'
                                        xs={12} sm={12}
                                    >
                                        <Typography className="p-0" variant='subtitle1'>
                                            Total:
                                        </Typography>
                                        <Typography className="p-0" variant='h4'>
                                            {vTotal()}
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </div>
                    </div>
                </Card>
                <div style={{
                    marginTop: "2px",
                    marginBottom: "2px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "2px"
                }}>
                    <Button themeColor={ColorsDPana.primary}
                        onClick={async () => {
                            if (PedidoActual && PedidoActual.Items && PedidoActual.Items.length) {
                                PedidoActual.id_defTipoSatusPedido = enuStatusPedido.PedidoRecibido;
                                setloading(true);
                                settextloading('Cargando Pedido. Por favor espere')
                                switch (tipoDespacho) {
                                    case enuTipoPedidoDespacho.Desconocido: break;
                                    case enuTipoPedidoDespacho.ComerAqui: break;
                                    case enuTipoPedidoDespacho.llevar: break;
                                    case enuTipoPedidoDespacho.Delivery:
                                    case enuTipoPedidoDespacho.Pickup:
                                        PedidoActual.id_defTipoPedido = PedidoActual.Id_TipoDespachoPedido;
                                        break;
                                    case enuTipoPedidoDespacho.ConsumoInterno: break;
                                    case enuTipoPedidoDespacho.Pedido: break;
                                    case enuTipoPedidoDespacho.Recuperado: break;
                                    case enuTipoPedidoDespacho.Guardado: break;
                                    case enuTipoPedidoDespacho.VentaDirecta:
                                        PedidoActual.id_defTipoPedido = enuTipoPedidoDespacho.Guardado;
                                        break;
                                }
                                sendOrden().then(() => {
                                    vaciarPedido();
                                    let msj = 'ok-->Pedido Enviado con Exito!';
                                    switch (tipoDespacho) {
                                        case enuTipoPedidoDespacho.Desconocido: break;
                                        case enuTipoPedidoDespacho.ComerAqui:
                                            msj = 'ok-->Mesa Actualizada!';
                                            break;
                                        case enuTipoPedidoDespacho.llevar: break;
                                        case enuTipoPedidoDespacho.Delivery: break;
                                        case enuTipoPedidoDespacho.Pickup: break;
                                        case enuTipoPedidoDespacho.ConsumoInterno: break;
                                        case enuTipoPedidoDespacho.Pedido: break;
                                        case enuTipoPedidoDespacho.Guardado: break;
                                        case enuTipoPedidoDespacho.Recuperado: break;
                                        case enuTipoPedidoDespacho.VentaDirecta:
                                            msj = 'ok-->Guardado con Extito!';
                                            break;
                                    }
                                    setMessage(msj);
                                    iraPantalla();
                                }).catch(error => {
                                    //Login
                                    if (error.includes("Request failed with status code 401")) {
                                        setMessage("Error de Autenticación. Iniciando Sesión...")
                                        setTimeout(() => {
                                            loadLoginDialog("Confirmar PIN");
                                        }, 2500);
                                    }
                                })
                                setloading(false);
                            } else {
                                setMessage('wrn-->Por favor Indique un PEDIDO');
                            }
                        }}>{btnEnviar()}</Button>
                    <Button themeColor={ColorsDPana.primary} onClick={() => {
                        if (PedidoActual && PedidoActual.Items) {
                            sendOrden().then(() => {
                                loadPagos();
                            })
                        } else
                            setMessage('wrn-->Por favor Indique PRODUCTOS');

                    }}>Pagar</Button>
                    <Button themeColor={ColorsDPana.primary} onClick={() => {
                        //Eliminar pedidos
                        vaciarPedido();
                        iraPantalla(true);
                    }}>Salir</Button>
                </div>
            </Panel>
            <Popover
                open={openPopover}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                The content of the Popover.
            </Popover>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </Container >
    );
}

