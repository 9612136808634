import { Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginPanel from "../components/login-panel";

export default function Login(): JSX.Element {
    const history = useHistory();
    const onLogin = () => {
        history.push("/main-menu");
    }

    return (
        <Container  >
            <LoginPanel onLogin={onLogin} />
        </Container>
    )
}