import type { facturas } from './facturas';
import type { tipobanco } from './tipobanco';
import type { tipomonedas } from './tipomonedas';
import type { tipopagos } from './tipopagos';

export interface facturas_mediopagoAttributes {
  ID: number;
  Id_Factura: number;
  Id_MedioPago: number;
  Id_TipoBanco?: number;
  id_Moneda?: number;
  nroItem?: number;
  nroDiasCredito?: number;
  DescripicionDocumento?: string;
  FechaActual?: Date;
  MontoTotal?: number;
  MontoTasaMoneda?: number;
  nroDocumento?: string;
  nroRef?: string;
  Comentario?: string;
  Beneficiario?: string;
  Email?: string;
  CVC?: string;
  Imagen1?: string;
  Imagen2?: string;
  nroTelefono?: string;
  esConfirmado?: number;
  esConfirmadoBeneficiario?: number;
  Responsable?: string;
  Simbolo?: string;
}


export class facturas_mediopago implements facturas_mediopagoAttributes {
  ID!: number;
  Id_Factura!: number;
  Id_MedioPago!: number;
  Id_TipoBanco?: number;
  id_Moneda?: number;
  nroItem?: number;
  nroDiasCredito?: number;
  DescripicionDocumento?: string;
  FechaActual?: Date;
  MontoTotal?: number;
  MontoTasaMoneda?: number;
  nroDocumento?: string;
  nroRef?: string;
  Comentario?: string;
  Beneficiario?: string;
  Email?: string;
  CVC?: string;
  Imagen1?: string;
  Imagen2?: string;
  nroTelefono?: string;
  esConfirmado?: number;
  esConfirmadoBeneficiario?: number;
  Responsable?: string;
  Simbolo?: string;

  // facturas_mediopago belongsTo facturas via Id_Factura
  Id_Factura_factura!: facturas;
  Id_TipoBanco_tipobanco!: tipobanco;
  id_Moneda_tipomoneda!: tipomonedas;
  Id_MedioPago_tipopago!: tipopagos;

}
