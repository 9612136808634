import { useEffect, useRef } from "react";
import Orden, { ItemPedido } from "../../models/Entities/Orden.model";
import { enuStatusPedidoDetalle } from "../../utils/enums";
import CardItemVentas from "../carditem_ventas/carditem_ventas";
import "./style.css";

export interface OrderItemDisplayProps {
    order: Orden;
    selectedItem?: ItemPedido;
    setSelectedItem?: React.Dispatch<React.SetStateAction<ItemPedido>>;
    esIncuyeIVA?: boolean;
    handleUp?: (item: any) => void;
    handleDown?: (item: any) => void;
    selectedControl?: (item: any) => void;
    tipoPrecio: number;
}

export interface DisplayItems {
    Cantidad: number;
    orderItems: ItemPedido[];
    displayPrice?: string;
}

export default function OrderItemDisplay({ esIncuyeIVA, order, tipoPrecio, selectedControl, handleUp, handleDown,  setSelectedItem,  selectedItem }: OrderItemDisplayProps) {
    //sort orderitems
    // const orderModel = new OrderModel(order);
    const itemref = useRef<any>();
    const increment = (i: ItemPedido) => {
        if (handleUp)
            handleUp(i);
        if (setSelectedItem)
            setSelectedItem(i);
    }
    const decrement = (i: ItemPedido) => {
        if (handleDown)
            handleDown(i);
        if (setSelectedItem)
            setSelectedItem(i);
    }
    useEffect(() => {

    }, [])
    const selitem = (item: ItemPedido) => {
        if (setSelectedItem)
            setSelectedItem(item);
    }
    const selectControl = (item: any) => {
        if (selectedControl)
            selectedControl(item);
    }
    const renderList = (items: ItemPedido[]) => {
        let renderedItems: JSX.Element[] = [];
        if (items) {
            let idx: number = 0;
            for (const item of items) {
                //render self
                let selfClassName = "order-item-display";
                switch (item.id_defTipoStatusPedido) {
                    case enuStatusPedidoDetalle.DespachoCocina:
                    case enuStatusPedidoDetalle.Procesado:
                    case enuStatusPedidoDetalle.Recibido:
                    case enuStatusPedidoDetalle.RecibidoCocina:
                        selfClassName += " item-procesado";
                        break;
                    case enuStatusPedidoDetalle.RecibidoDevuelto:
                    case enuStatusPedidoDetalle.ProcesadoDevuelto:
                        selfClassName += " item-devolucion";
                        break;
                    case enuStatusPedidoDetalle.AgregandoProducto:
                    case enuStatusPedidoDetalle.PedidoNuevo:
                    case enuStatusPedidoDetalle.SinProcesar:
                        selfClassName += " item-sinprocesar";
                        break;

                    default:
                        break;
                }
                if (item === selectedItem) {
                    selfClassName += " selected";
                    if (itemref.current)
                        itemref.current.focus();
                }
                else {
                    selfClassName.replace("selected", "").replace("item-sinprocesar", "").replace("item-procesado", "").replace("item-devolucion", "");
                }
                // if (modifierLevel === 0) selfClassName += " font-weight-bold";
                renderedItems.push(
                    <div ref={itemref}
                        key={"item-" + idx}
                        tabIndex={idx}
                        onFocus={(e) => {
                            if (selectedControl) {
                                selectedControl(e.currentTarget);
                            }
                        }}
                        className={selfClassName}
                        onClick={(e) => {
                            selitem(item);
                            if (selectedControl) {
                                selectedControl(e.currentTarget);
                            }
                        }}>
                        <CardItemVentas
                            tipoPrecio={tipoPrecio}
                            selectedItem={item === selectedItem}
                            select={(e) => { selectControl(e) }}
                            esIncuyeIVA={esIncuyeIVA as boolean}
                            item={item}
                            handleUp={() => { increment(item); }}
                            handleDown={() => { decrement(item); }}>
                        </CardItemVentas >
                    </div>
                );
                //Agragar Extras    
                /*  if (item.items && item.items.length) {
                     //render children
                     const renderedChildren = renderList(item.items, modifierLevel + 1);
                     renderedItems = renderedItems.concat(renderedChildren);
                 } */
                idx++;
            }
        }

        return renderedItems;
    }

    return (
        <div >
            {
            //TODO: Revisar codigo/*  */
           /*  {renderList(orderModel.items, 0)} */}
            {order ?
                renderList(order.Items)
                : ""
            }
        </div>
    );
}