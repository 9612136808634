import type { cajas } from './cajas';
import type { cajas_detallecortes } from './cajas_detallecortes';
import type { clientes } from './clientes';
import type { empleados } from './empleados';
import type { estaciones_sesiones } from './estaciones_sesiones';
import type { facturas_cortez } from './facturas_cortez';
import type { facturas_descuentos } from './facturas_descuentos';
import type { facturas_detalle } from './facturas_detalle';
import type { facturas_if } from './facturas_if';
import type { facturas_impuestos } from './facturas_impuestos';
import type { facturas_mediopago } from './facturas_mediopago';
import type { facturas_modificadas } from './facturas_modificadas';
import type { facturas_servicios } from './facturas_servicios';
import type { mesas_detalle } from './mesas_detalle';
import type { pedidos } from './pedidos';
import type { tipomonedas } from './tipomonedas';

export interface facturasAttributes {
  ID: number;
  Id_Caja: number;
  Id_Cliente?: number;
  Id_Empleado?: number;
  Id_Estaciones_Sesiones: number;
  id_Pedido?: number;
  id_defTipoSatusFactura?: number;
  FechaActual: Date;
  FechaFactura?: Date;
  MontoTotal?: number;
  MontoTotalBase?: number;
  MontoTotalIVA?: number;
  MontoTotalDescuento?: number;
  MontoPropina?: number;
  NroItems: number;
  NroFactura?: number;
  NroFacturaIF?: string;
  Comentarios?: string;
  PuntosCantidad?: number;
  esTieneDevolucion?: number;
  Historico?: string;
  MontoCambio?: number;
  MontoPagado?: number;
  MontoTotalDivisa?: number;
  MontoTotalBaseDivisa?: number;
  MontoTotalIVADivisa?: number;
  MontoTotalDescuentoDivisa?: number;
  MontoPropinaDivisa?: number;
  MontoCambioDivisa?: number;
  MontoPagadoDivisa?: number;
  id_MonedaDivisa?: number;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;
}

export class facturas implements facturasAttributes {
  ID!: number;
  Id_Caja!: number;
  Id_Cliente?: number;
  Id_Empleado?: number;
  Id_Estaciones_Sesiones!: number;
  id_Pedido?: number;
  id_defTipoSatusFactura?: number;
  FechaActual!: Date;
  FechaFactura?: Date;
  MontoTotal?: number;
  MontoTotalBase?: number;
  MontoTotalIVA?: number;
  MontoTotalDescuento?: number;
  MontoPropina?: number;
  NroItems!: number;
  NroFactura?: number;
  NroFacturaIF?: string;
  Comentarios?: string;
  PuntosCantidad?: number;
  esTieneDevolucion?: number;
  Historico?: string;
  MontoCambio?: number;
  MontoPagado?: number;
  MontoTotalDivisa?: number;
  MontoTotalBaseDivisa?: number;
  MontoTotalIVADivisa?: number;
  MontoTotalDescuentoDivisa?: number;
  MontoPropinaDivisa?: number;
  MontoCambioDivisa?: number;
  MontoPagadoDivisa?: number;
  id_MonedaDivisa?: number;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;

  // facturas belongsTo cajas via Id_Caja
  Id_Caja_caja!: cajas;
  Id_Cliente_cliente!: clientes;
  Id_Empleado_empleado!: empleados;
  Id_Estaciones_Sesiones_estaciones_sesione!: estaciones_sesiones;
  cajas_detallecortes!: cajas_detallecortes[];
  id_FacturaInicial_cajas_detallecortes!: cajas_detallecortes[];
  facturas_cortezs!: facturas_cortez[];
  facturas_descuentos!: facturas_descuentos[];
  facturas_detalles!: facturas_detalle[];
  facturas_ifs!: facturas_if[];
  facturas_impuestos!: facturas_impuestos[];
  facturas_mediopagos!: facturas_mediopago[];
  facturas_modificadas!: facturas_modificadas[];
  facturas_servicios!: facturas_servicios[];
  mesas_detalles!: mesas_detalle[];
  id_Pedido_pedido!: pedidos;
  id_MonedaDivisa_tipomoneda!: tipomonedas;

}
