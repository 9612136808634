import axios from "axios";
import { correlativos } from "../models/correlativos";
import Orden, { ItemPedido } from "../models/Entities/Orden.model";
import SettingsApp from "../models/Entities/Settings";
import { facturas } from "../models/facturas";
import { facturas_descuentos } from "../models/facturas_descuentos";
import { facturas_detalle } from "../models/facturas_detalle";
import { facturas_detalle_descuentos } from "../models/facturas_detalle_descuentos";
import { facturas_detalle_impuestos } from "../models/facturas_detalle_impuestos";
import { facturas_impuestos } from "../models/facturas_impuestos";
import { facturas_mediopago } from "../models/facturas_mediopago";
import { mesas_detalle } from "../models/mesas_detalle";
import { pedidos } from "../models/pedidos";
import { pedidos_descuentos } from "../models/pedidos_descuentos";
import { pedidos_detalle } from "../models/pedidos_detalle";
import { pedidos_detalle_descuentos } from "../models/pedidos_detalle_descuentos";
import { pedidos_detalle_impuestos } from "../models/pedidos_detalle_impuestos";
import { pedidos_detalle_modificadores } from "../models/pedidos_detalle_modificadores";
import { pedidos_detalle_modificadores_mov } from "../models/pedidos_detalle_modificadores_mov";
import { pedidos_detalle_movimientos } from "../models/pedidos_detalle_movimientos";
import { pedidos_impuestos } from "../models/pedidos_impuestos";
import { pedidos_modificadores } from "../models/pedidos_modificadores";
import { config } from "../utils/configs";
import { enuStatusFactura, enuStatusPedido, enuStatusPedidoDetalle, enuTipoEstatusMesa, enuTipoOrigenPedido, enuTipoPedidoDespacho, EnuTiposPrecio } from "../utils/enums";

export type tipoSync = 'facturas' | 'pedidos' | 'pedidos_pendientes' | 'mesas_detalle' | 'comandas';
export default class CrudOrdenService {
    savingData: boolean = false;
    loadingData: boolean = false;
    ordenActual: Orden | undefined;
    
    public static async setBlockMesa(idMesa: number, esModificando: boolean, loginToken: any, setting: SettingsApp | undefined = undefined) {
        await axios.post(config.SERVER_URI + "/mesas/update", {
            ID: loginToken.ID,
            hash: loginToken.hash,
            data: {
                id: idMesa,
                esModificando: esModificando ? 1 : 0
            }
        }).then(res => {

        }).catch(err => {
            console.log(err.response?.data);
        });
        return true;
    }
    public static onOrigenPedido(tipo: enuTipoOrigenPedido, orden: Orden, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        orden.id_defTipoOrigenPedido = tipo;
        switch (tipo) {
            case enuTipoOrigenPedido.Desconocido:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Desconocido;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Desconocido;
                    orden.Historico += "CrearPedido:{origen:'Desconocido'}";
                }
                break;
            case enuTipoOrigenPedido.VentaDirecta:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PendienteFacturar;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.VentaDirecta;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.VentaDirecta;
                    orden.Historico += "CrearPedido:{origen:'VentaDirecta'}";
                }
                break;
            case enuTipoOrigenPedido.Mesa:
                //Ocurre desde las aplicaciones Onsite, POS , Admin
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.ComerAqui;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.ComerAqui;
                    orden.Historico += "CrearPedido:{origen:'ComerAqui'}";
                }
                break;
            case enuTipoOrigenPedido.SitioWeb:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Historico += "CrearPedido:{origen:'Pedido'}";
                }
                break;
            case enuTipoOrigenPedido.WhatsApp:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Historico += "CrearPedido:{origen:'Pedido'}";
                }
                break;
            case enuTipoOrigenPedido.Facebook:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Pedido;

                    orden.Historico += "CrearPedido:{origen:'Pedido'}";
                }
                break;
            case enuTipoOrigenPedido.Instagram:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Pedido;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Pedido;

                    orden.Historico += "CrearPedido:{origen:'Delivery'}";
                }
                break;
            case enuTipoOrigenPedido.AplicacionMovil:
                //Ocurre desde la app Pidelo
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Delivery;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Delivery;
                    orden.Historico += "CrearPedido:{origen:'Delivery'}";
                }
                break;
            case enuTipoOrigenPedido.LlamadaTelefonica:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.PedidoNuevo;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.Delivery;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Delivery;
                    orden.Historico += "CrearPedido:{origen:'Delivery'}";
                }
                break;
            case enuTipoOrigenPedido.POS:
                if (orden.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    orden.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                    orden.id_defTipoPedido = enuTipoPedidoDespacho.VentaDirecta;
                    orden.Id_TipoDespachoPedido = enuTipoPedidoDespacho.VentaDirecta;
                    orden.Historico += "CrearPedido:{origen:'VentaDirecta'}";
                }
                break;
            default:
                break;
        }

        if (esUpdateData)
            return new Promise((resolve, reject) => {
                CrudOrdenService.modifPedidoExpress(orden, loginToken)
                    .then(res => {
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    });
            });
    }
    public static onStatusPedido(status: enuStatusPedido, orden: Orden, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        orden.id_defTipoSatusPedido = status;
        switch (status) {
            case enuStatusPedido.PedidoNuevo:
                if (orden.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    orden.esDespachado = 0;
                }
                break;
            case enuStatusPedido.PedidoAnulado:
                if (orden.ID) {
                    orden.esTieneDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.PedidoDevuelto:
                if (orden.ID) {
                    orden.esTieneDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.PedidoDespachado:
                if (orden.ID) {
                    orden.esDespachado = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.PedidoFacturado:
                if (orden.ID) {
                    orden.esDespachado = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.PendienteFacturar:
                if (orden.ID) {
                    orden.esDespachado = 0;
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.PedidoRecibido:
                if (orden.ID) {
                    orden.esDespachado = 0;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedido.Procesando:
                if (orden.ID) {
                    orden.esDespachado = 0;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    orden.esDespachado = 0;
                    orden.esImpreso = 0;
                }
                break;
        }
        if (esUpdateData)
            return new Promise((resolve, reject) => {
                CrudOrdenService.modifPedidoExpress(orden, loginToken)
                    .then(res => {
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    });
            });
    }

    public static onTipoDespachoPedido(tipo: enuTipoPedidoDespacho, orden: Orden, esUpdate: boolean, upAccess: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        orden.Id_TipoDespachoPedido = tipo;
        switch (tipo) {
            case enuTipoPedidoDespacho.ComerAqui:
                if (orden.ID) {
                    if (orden.Mesa) {
                        let dm: mesas_detalle = new mesas_detalle();
                        if (orden.Mesa.mesas_detalles && orden.Mesa.mesas_detalles.length > 0) {
                            dm.ID = orden.Mesa.mesas_detalles[0].ID;
                            dm.id_Mesa = orden.Mesa.mesas_detalles[0].id_Mesa;
                            dm.id_Pedido = orden.Mesa.mesas_detalles[0].id_Pedido;
                            dm.id_UsuarioApertura = orden.Mesa.mesas_detalles[0].id_UsuarioApertura;
                            dm.id_UsuarioUltimoAcceso = orden.Mesa.mesas_detalles[0].id_UsuarioUltimoAcceso;
                            dm.id_Factura = orden.Mesa.mesas_detalles[0].id_Factura;
                            dm.id_defTipoStatusMesaPedido = orden.Mesa.mesas_detalles[0].id_defTipoStatusMesaPedido;
                            dm.nroItems = orden.Mesa.mesas_detalles[0].nroItems;
                            dm.nroLineas = orden.Mesa.mesas_detalles[0].nroLineas;
                            dm.nroAccesos = orden.Mesa.mesas_detalles[0].nroAccesos;
                            dm.FechaUltimoAcceso = new Date();
                            dm.FechaImpreso = orden.Mesa.mesas_detalles[0].FechaImpreso;
                            dm.FechaApertura = orden.Mesa.mesas_detalles[0].FechaApertura;
                            dm.CantidadImpreso = orden.Mesa.mesas_detalles[0].CantidadImpreso;
                            dm.esCerrado = orden.Mesa.mesas_detalles[0].esCerrado;
                            dm.esImpreso = orden.Mesa.mesas_detalles[0].esImpreso;
                            dm.Historico = orden.Mesa.mesas_detalles[0].Historico;
                        }
                        dm.id_Pedido = orden.ID;
                        dm.nroItems = orden.Items ? orden.Items.length : 0;
                        dm.nroLineas = orden.nroLineas ? orden.nroLineas : 0;
                        dm.id_UsuarioUltimoAcceso = orden.Id_Usuario;
                        if (esUpdate) {
                            if (dm.nroAccesos && upAccess)
                                dm.nroAccesos += 1;
                        }
                        else {
                            dm.id_UsuarioApertura = orden.Id_Usuario_usuario?.ID;
                            dm.id_Mesa = orden.Mesa.ID;
                            dm.id_defTipoStatusMesaPedido = enuTipoEstatusMesa.OcupadaconPedido;
                            dm.nroAccesos = 1;
                            dm.FechaApertura = new Date();
                            dm.FechaUltimoAcceso = new Date();
                            dm.CantidadImpreso = 0;//<--
                            dm.esCerrado = 0;
                            dm.esImpreso = 0;//<--
                            dm.Historico = "";
                        }
                        CrudOrdenService.modifMesa(dm, esUpdate, orden, loginToken);
                    }
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.ConsumoInterno:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.Delivery:
                if (orden.ID) {
                    orden.esDespachado = 0;
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }

                break;
            case enuTipoPedidoDespacho.Desconocido:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.Guardado:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.Pedido:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.Pickup:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.Recuperado:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.llevar:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            case enuTipoPedidoDespacho.VentaDirecta:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
            default:
                if (orden.ID) {
                    if (esUpdate) {

                    } else {

                    }
                } else {

                }
                break;
        }
        if (esUpdateData)
            return new Promise((resolve, reject) => {
                CrudOrdenService.modifPedidoExpress(orden, loginToken)
                    .then(res => {
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    });
            });
    }

    public static modifPedidoExpress(pedido: pedidos, loginToken: any, setting: SettingsApp | undefined = undefined) {
        return new Promise(async (resolve, reject) => {

            let res: any;
            let data: pedidos = new pedidos();
            if (pedido && pedido.ID) {
                data.Id_Caja = pedido.Id_Caja;
                data.Id_Cliente = pedido.Id_Cliente;
                data.Id_TipoDespachoPedido = pedido.Id_TipoDespachoPedido;
                data.Id_Usuario = pedido.Id_Usuario;
                data.Id_Estaciones_Sesiones = pedido.Id_Estaciones_Sesiones;
                data.id_defTipoSatusPedido = pedido.id_defTipoSatusPedido;
                data.id_defTipoOrigenPedido = pedido.id_defTipoOrigenPedido;
                data.id_defTipoPedido = pedido.id_defTipoPedido;
                data.FechaActual = pedido.FechaActual;
                data.FechaPedido = pedido.FechaPedido;
                data.MontoTotal = pedido.MontoTotal ? +pedido.MontoTotal : 0;
                data.MontoTotalBase = pedido.MontoTotalBase ? +pedido.MontoTotalBase : 0;
                data.MontoTotalIVA = pedido.MontoTotalIVA ? +pedido.MontoTotalIVA : 0;
                data.MontoTotalDescuento = pedido.MontoTotalDescuento ? +pedido.MontoTotalDescuento : 0;
                data.MontoCambio = pedido.MontoCambio ? +pedido.MontoCambio : 0;
                data.MontoPagado = pedido.MontoPagado ? +pedido.MontoPagado : 0;
                data.MontoPropina = pedido.MontoPropina ? +pedido.MontoPropina : 0;
                data.NroItems = pedido.NroItems ? +pedido.NroItems : 0;
                data.NroPedido = pedido.NroPedido;
                data.RefPedido = pedido.RefPedido;
                data.Comentarios = pedido.Comentarios;
                data.Mensaje = pedido.Mensaje;
                data.CantidadImpreso = pedido.CantidadImpreso;
                data.esTieneDevolucion = pedido.esTieneDevolucion;
                data.esPagado = pedido.esPagado;
                data.esDespachado = pedido.esDespachado;
                data.esImpreso = pedido.esImpreso;
                data.Historico = pedido.Historico;
                res = await axios.post(config.SERVER_URI + "/pedidos/update", {
                    ID: loginToken.ID,
                    hash: loginToken.hash,
                    data: { id: pedido.ID, ...data }
                });
                if (res.status === 200) {
                    data = res.data as unknown as pedidos;
                    if (data) {
                        resolve(res);
                    }
                } else {
                    reject(res);
                }
            } else {
                reject(res);
            }
            resolve(null);
        })
    }

    public static modifPedidoDetalleExpress(detelle: pedidos_detalle, loginToken: any, setting: SettingsApp | undefined = undefined) {
        return new Promise(async (resolve, reject) => {
            let res: any;
            let data: pedidos;
            if (detelle && detelle.ID) {
                res = await axios.post(config.SERVER_URI + "/pedidos_detalle/update", {
                    ID: loginToken.ID,
                    hash: loginToken.hash,
                    data: { id: detelle.ID, ...detelle }
                });
                if (res.status === 200) {
                    data = res.data as unknown as pedidos;
                    if (data) {
                        resolve(res);
                    }
                } else {
                    reject(res);
                }
            } else {
                reject(res);
            }
        })
    }
    public static async modifMesa(detalle: mesas_detalle, esUpdate: boolean, orden: Orden, loginToken: any, setting: SettingsApp | undefined = undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                CrudOrdenService.setBlockMesa(detalle.id_Mesa, true, loginToken);
                let res: any;
                let data: any;
                if (esUpdate) {
                    /* await loadArchivo("mesas_detalle", false, ["id_Pedido"], undefined, undefined, undefined, { id_Pedido: detalle.id_Pedido }).then(
                        (resolve) => {
                            let dat: mesas_detalle = (resolve as mesas_detalle[])[0]
                            detalle.ID = dat.ID;
                        },
                        (error) => { }
                    ); */
                    let id = detalle.ID ? detalle.ID : 0;
                    detalle.id_UsuarioApertura = detalle.id_UsuarioApertura;

                    res = await axios.post(config.SERVER_URI + "/mesas_detalle/update", {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: { id: id, ...detalle }
                    });
                }
                else {
                    res = await axios.post(config.SERVER_URI + "/mesas_detalle/create", {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: detalle
                    });
                }
                if (res.status === 200) {
                    data = res.data as unknown as mesas_detalle;
                    if (data) {
                        detalle = data;
                        if (orden && orden.Mesa) {
                            if (!orden.Mesa.mesas_detalles) {
                                orden.Mesa.mesas_detalles = [];
                                orden.Mesa.mesas_detalles.push(detalle);
                            } else {
                                orden.Mesa.mesas_detalles[0] = detalle;
                            }
                        }
                        CrudOrdenService.setBlockMesa(detalle.id_Mesa, false, loginToken);
                        resolve(data)
                    }
                    reject("modifMesa-->Sin Resultados");

                }
            } catch (error) {
                CrudOrdenService.setBlockMesa(detalle.id_Mesa, false, loginToken);
                reject("modifMesa-->" + error);
            }
        })

    }

    //😍Iniciaar Carga Pedido
    public static async modifPedido(orden: Orden, loginToken: any, setting: SettingsApp | undefined = undefined) {
        let actualErrors: string[] = [];
        return new Promise(async (resolve, reject) => {
            if (orden && orden.Items) {
                let esUpdate: boolean = orden.ID ? true : false;
                let x: pedidos = new pedidos();
                let NroPedido: number = 0;

                await this.getCorrelativos(loginToken, setting).then(res => {
                    if (res && res.NroPedido)
                        NroPedido = res.NroPedido + 1;
                    else
                        NroPedido = 1;
                })
                //Add Pedido
                x.Id_Caja = orden.Id_Caja;
                x.Id_Cliente = orden.Id_Cliente;
                x.Id_TipoDespachoPedido = orden.Id_TipoDespachoPedido;
                x.Id_Usuario = orden.Id_Usuario;
                x.Id_Estaciones_Sesiones = orden.Id_Estaciones_Sesiones;
                x.id_defTipoSatusPedido = orden.id_defTipoSatusPedido;
                x.id_defTipoOrigenPedido = orden.id_defTipoOrigenPedido;
                x.id_defTipoPedido = orden.id_defTipoPedido;
                x.FechaActual = new Date();
                x.FechaPedido = orden.FechaPedido;
                x.MontoTotal = orden.MontoTotal;
                x.MontoTotalBase = orden.MontoTotalBase;
                x.MontoTotalIVA = orden.MontoTotalIVA;
                x.MontoTotalDescuento = orden.MontoTotalDescuento;
                x.MontoCambio = orden.MontoCambio;
                x.MontoPagado = orden.MontoPagado;
                x.MontoPropina = orden.MontoPropina;
                x.NroItems = orden.NroItems;
                x.NroPedido = esUpdate ? orden.NroPedido : NroPedido;
                x.RefPedido = orden.RefPedido;
                x.Comentarios = orden.Comentarios;
                x.Mensaje = orden.Mensaje;
                x.CantidadImpreso = orden.CantidadImpreso;
                x.esTieneDevolucion = orden.esTieneDevolucion;
                x.esPagado = orden.esPagado;
                x.esDespachado = orden.esDespachado;
                x.esImpreso = orden.esImpreso;
                x.Historico = orden.Historico;
                if (orden.Items && orden.Items.length > 0) {
                    x.pedidos_detalles = orden.Items;
                    for (let i = 0; i < x.pedidos_detalles.length; i++) {
                        x.pedidos_detalles[i].pedidos_detalle_modificadores = orden.Items[i].Extras;
                    }
                }
                x.pedidos_impuestos = orden.pedidos_impuestos;
                x.pedidos_descuentos = orden.pedidos_descuentos;
                x.pedidos_modificadores = orden.pedidos_modificadores;

                if (x.id_defTipoSatusPedido === 0)
                    x.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                if (x.Id_TipoDespachoPedido === 0)
                    x.Id_TipoDespachoPedido = enuTipoPedidoDespacho.Pedido;
                if (x.id_defTipoOrigenPedido === 0)
                    x.id_defTipoOrigenPedido = enuTipoOrigenPedido.POS;

                CrudOrdenService.onOrigenPedido(x.id_defTipoOrigenPedido as number, orden, esUpdate, loginToken)
                CrudOrdenService.onTipoDespachoPedido(x.Id_TipoDespachoPedido as number, orden, esUpdate, false, loginToken)
                CrudOrdenService.onStatusPedido(x.id_defTipoSatusPedido as number, orden, esUpdate, loginToken)

                try {
                    let res: any;
                    let data: pedidos;
                    if (esUpdate) {
                        res = await axios.post(config.SERVER_URI + "/pedidos/update", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: { id: orden.ID, ...x }
                        });
                    }
                    else {
                        res = await axios.post(config.SERVER_URI + "/pedidos/create", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: x
                        });
                    }
                    if (res.status === 200) {
                        data = res.data as unknown as pedidos;
                        if (data) {
                            let IdPedido = data?.ID;
                            orden.ID = IdPedido;
                            orden.Id_Caja = data.Id_Caja;
                            orden.Id_Cliente = data.Id_Cliente;
                            orden.Id_TipoDespachoPedido = data.Id_TipoDespachoPedido;
                            orden.Id_Usuario = data.Id_Usuario;
                            orden.Id_Estaciones_Sesiones = data.Id_Estaciones_Sesiones;
                            orden.id_defTipoSatusPedido = data.id_defTipoSatusPedido;
                            orden.id_defTipoOrigenPedido = data.id_defTipoOrigenPedido;
                            orden.id_defTipoPedido = data.id_defTipoPedido;
                            orden.FechaActual = data.FechaActual;
                            orden.FechaPedido = data.FechaPedido;
                            orden.MontoTotal = data.MontoTotal ? +data.MontoTotal : 0;
                            orden.MontoTotalBase = data.MontoTotalBase ? +data.MontoTotalBase : 0;
                            orden.MontoTotalIVA = data.MontoTotalIVA ? +data.MontoTotalIVA : 0;
                            orden.MontoTotalDescuento = data.MontoTotalDescuento ? +data.MontoTotalDescuento : 0;
                            orden.MontoCambio = data.MontoCambio ? +data.MontoCambio : 0;
                            orden.MontoPagado = data.MontoPagado ? +data.MontoPagado : 0;
                            orden.MontoPropina = data.MontoPropina ? +data.MontoPropina : 0;
                            orden.NroItems = data.NroItems ? +data.NroItems : 0;
                            orden.NroPedido = data.NroPedido;
                            orden.RefPedido = data.RefPedido;
                            orden.Comentarios = data.Comentarios;
                            orden.Mensaje = data.Mensaje;
                            orden.CantidadImpreso = data.CantidadImpreso;
                            orden.esTieneDevolucion = data.esTieneDevolucion;
                            orden.esPagado = data.esPagado;
                            orden.esDespachado = data.esDespachado;
                            orden.esImpreso = data.esImpreso;
                            orden.Historico = data.Historico;
                            /*  orden.mesas_detalles = data.mesas_detalles;
                             orden.Mesa = data.mesas_detalles && data.mesas_detalles.length ? data.mesas_detalles[0].id_Mesa_mesa : undefined; */
                            if (orden.Mesa)
                                orden.Mesa.mesas_detalles = orden.mesas_detalles;
                            orden.Mesero = data.mesas_detalles && data.mesas_detalles.length ? data.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario : undefined;

                            CrudOrdenService.onTipoDespachoPedido(orden.Id_TipoDespachoPedido as number, orden, esUpdate, true, loginToken)

                            //😋Sincronizar Pedido
                            CrudOrdenService.updateSync('pedidos', orden.ID, loginToken);
                            //Correlativos
                            let n: number = esUpdate ? NroPedido - 1 : NroPedido;
                            this.setCorrelativos({ NroPedido: n }, loginToken, setting);
                            //Orden status
                            this.onStatusPedido(enuStatusPedido.Procesando, orden, true, loginToken);
                            //crear impuestos
                            if (data.ID) {
                                //impuestos
                                if (x.pedidos_impuestos && x.pedidos_impuestos.length > 0) {
                                    x.pedidos_impuestos.forEach(async (y: any) => {
                                        let resy: any;
                                        let datay: pedidos_impuestos;
                                        let esUpdatey: boolean = y.ID ? true : false;
                                        y.Id_Pedido = IdPedido;
                                        try {
                                            if (esUpdatey) {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_impuestos/update", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: { id: y.ID, ...y }
                                                });
                                            } else {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_impuestos/create", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: y
                                                });
                                            }
                                            if (resy.status === 200) {
                                                datay = resy.data as unknown as pedidos_impuestos;
                                                if (datay) {
                                                    y = datay;
                                                }
                                            }
                                            else {
                                                actualErrors.push('pedidos_impuestos_res-->' + resy.status);
                                            }
                                        } catch (error) {
                                            actualErrors.push("pedidos_impuestos->\n" + error);
                                        }
                                    });
                                }
                                //descuentos
                                if (x.pedidos_descuentos && x.pedidos_descuentos.length > 0) {
                                    x.pedidos_descuentos.forEach(async (y: any) => {
                                        let resy: any;
                                        let datay: pedidos_descuentos;
                                        let esUpdatey: boolean = y.ID ? true : false;
                                        y.Id_Pedido = IdPedido;
                                        try {
                                            if (esUpdatey) {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_descuentos/update", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: { id: y.ID, ...y }
                                                });
                                            } else {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_descuentos/create", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: y
                                                });
                                            }
                                            if (resy.status === 200) {
                                                datay = resy.data as unknown as pedidos_descuentos;
                                                if (datay) {
                                                    y = datay;
                                                }
                                            }
                                            else {
                                                actualErrors.push('pedidos_descuentos_res-->' + resy.status);
                                            }
                                        } catch (error) {
                                            actualErrors.push("pedidos_descuentos->\n" + error);
                                        }
                                    });
                                }
                                //->  aqui
                                //Movimientos
                                if (x.pedidos_modificadores && x.pedidos_modificadores.length > 0) {
                                    x.pedidos_modificadores.forEach(async (y: any) => {
                                        let resy: any;
                                        let datay: pedidos_modificadores;
                                        let esUpdatey: boolean = y.ID ? true : false;
                                        y.id_Pedido = IdPedido;
                                        try {
                                            if (esUpdatey) {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_modificadores/update", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: { id: y.ID, ...y }
                                                });
                                            } else {
                                                resy = await axios.post(config.SERVER_URI + "/pedidos_modificadores/create", {
                                                    ID: loginToken.ID,
                                                    hash: loginToken.hash,
                                                    data: y
                                                });
                                            }
                                            if (resy.status === 200) {
                                                datay = resy.data as unknown as pedidos_modificadores;
                                                if (datay) {
                                                    y = datay;
                                                }
                                            }
                                            else {
                                                actualErrors.push('pedidos_modificadores_res-->' + resy.status);
                                            }
                                        } catch (error) {
                                            actualErrors.push("pedidos_modificadores->\n" + error);
                                        }
                                    });
                                }

                                if (orden.Items && orden.Items.length > 0) {
                                    await CrudOrdenService.modifPedido_Detalles(x.pedidos_detalles, data.ID, loginToken)
                                        .then((res: any) => {
                                            if (res) {
                                                let pds: pedidos_detalle[] = res as pedidos_detalle[];
                                                for (let i = 0; i < pds.length; i++) {
                                                    orden.Items[i].Extras = pds[i].pedidos_detalle_modificadores;
                                                }
                                                resolve(orden);
                                            } else {
                                                reject(`Promise modifPedido_Detalles-->res ${orden.ID}` + res.status);
                                            }
                                        }).catch(err => {
                                            //TODO:ELiminar pedido malo o deshabilitar
                                            reject("Promise modifPedido_Detalles-->" + err);
                                        })
                                } else {
                                    //TODO:ELiminar pedido malo o deshabilitar
                                    reject('modifPedidos-->Sin Items')
                                }
                            }

                        }
                    }
                    else {
                        reject('modifPedidos_res-->' + res.status);
                    }
                }
                catch (error) {
                    reject('modifPedidos-->' + error);
                };
            }
            else
                reject("Orden sin Productos. No se guardó")

        })


    }
    //😂Continuar carga pedido
    public static modifPedido_Detalles(pds: pedidos_detalle[], id: number, loginToken: any, setting: SettingsApp | undefined = undefined) {
        let actualErrors: string[] = [];
        return new Promise(async (resolve, reject) => {
            if (pds) {
                let esUpdate: boolean = false;
                let i: number = 0;
                const setImpuestos = (xs: pedidos_detalle_impuestos[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await xs.forEach(async (y: any) => {
                                let resy: any;
                                let datay: pedidos_detalle_impuestos;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.Id_pedidoDetalle = idPpal;
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_impuestos/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_impuestos/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as pedidos_detalle_impuestos;
                                        if (datay) {
                                            y = datay;
                                            i++;
                                        }
                                    }
                                    else {
                                        actualErrors.push(`pedidos_detalle_impuestos_res--> ${idPpal}` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`pedidos_detalle_impuestos--> ${idPpal}` + error);
                                }
                            });
                            resolve({ tabla: 'pedidos_detalle_impuestos', length: xs.length, updates: i })
                        } else {
                            resolve({ tabla: 'pedidos_detalle_impuestos', length: 0, updates: 0 })
                        }
                    })
                }
                const setDescuentos = (xs: pedidos_detalle_descuentos[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await xs.forEach(async (y: any) => {
                                let resy: any;
                                let datay: pedidos_detalle_descuentos;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.Id_pedidoDetalle = idPpal;
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_descuentos/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_descuentos/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as pedidos_detalle_descuentos;
                                        if (datay) {
                                            y = datay;
                                            i++;
                                        }
                                    }
                                    else {
                                        actualErrors.push(`pedidos_detalle_descuentos-->res ${idPpal}` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`pedidos_detalle_descuentos--> ${idPpal}` + error);
                                }
                            });
                            resolve({ tabla: 'pedidos_detalle_descuentos', length: xs.length, updates: i })
                        } else {
                            resolve({ tabla: 'pedidos_detalle_descuentos', length: 0, updates: 0 })
                        }

                    })
                }
                const setMovimientos = (xs: pedidos_detalle_movimientos[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await xs.forEach(async (y: any) => {
                                let resy: any;
                                let datay: pedidos_detalle_movimientos;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.id_Pedido_Detalle = idPpal;
                                CrudOrdenService.onStatusPedidoDetalle_movimiento(y.id_defTipoStatusPedido as enuStatusPedidoDetalle, y, esUpdatey, loginToken);
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_movimientos/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_movimientos/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as pedidos_detalle_movimientos;
                                        if (datay) {
                                            y = datay;
                                        }
                                        i++;
                                    }
                                    else {
                                        actualErrors.push(`pedidos_detalle_movimientos-->res ${idPpal}` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`pedidos_detalle_movimientos--> ${idPpal}` + error);
                                }
                            });
                            resolve({ tabla: 'pedidos_detalle_movimientos', length: xs.length, updates: i })
                        } else {
                            resolve({ tabla: 'pedidos_detalle_movimientos', length: 0, updates: 0 })
                        }
                    })
                }
                const setModificadores = (xs: pedidos_detalle_modificadores[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await Promise.all(xs.map(async (y: any) => {
                                let resy: any;
                                let datay: pedidos_detalle_modificadores;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.id_pedido_detalle = idPpal;
                                if (y.id_ModificadorProducto === 0)
                                    y.id_ModificadorProducto = undefined;
                                if (y.id_TipoAcompanante === 0)
                                    y.id_TipoAcompanante = undefined;
                                if (!y.id_defTipoStatusPedido)
                                    y.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
                                CrudOrdenService.onStatusPedidoDetalle_Modificadores(y.id_defTipoStatusPedido as enuStatusPedidoDetalle, y, esUpdatey, loginToken);
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_modificadores/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/pedidos_detalle_modificadores/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as pedidos_detalle_modificadores;
                                        let id_detalleModificador = datay.ID;
                                        if (datay) {
                                            i++;
                                            y = datay;
                                            if (y.pedidos_detalle_modificadores_movs && y.pedidos_detalle_modificadores_movs.length > 0) {
                                                y.pedidos_detalle_modificadores_movs.forEach(async (z: any) => {
                                                    let resz: any;
                                                    let dataz: pedidos_detalle_modificadores_mov;
                                                    let esUpdatey: boolean = z.ID ? true : false;
                                                    z.id_Pedido_DetalleModificador = id_detalleModificador;
                                                    /* z.id_defTipoMovimientoPedido
                                                      */
                                                    CrudOrdenService.onStatusPedidoDetalleModif_movimiento(z.id_defTipoStatusPedido as enuStatusPedidoDetalle, z, esUpdatey, loginToken);
                                                    try {
                                                        if (esUpdatey) {
                                                            resz = await axios.post(config.SERVER_URI + "/pedidos_detalle_modificadores_mov/update", {
                                                                ID: loginToken.ID,
                                                                hash: loginToken.hash,
                                                                data: { id: z.ID, ...z }
                                                            });
                                                        } else {
                                                            resz = await axios.post(config.SERVER_URI + "/pedidos_detalle_modificadores_mov/create", {
                                                                ID: loginToken.ID,
                                                                hash: loginToken.hash,
                                                                data: z
                                                            });
                                                        }
                                                        if (resz.status === 200) {
                                                            dataz = resz.data as unknown as pedidos_detalle_modificadores_mov;
                                                            if (dataz) {
                                                                z = dataz;
                                                            }
                                                        }
                                                        else {
                                                            actualErrors.push('pedidos_detalle_modificadores_mov_res-->' + resz.status);
                                                        }
                                                    } catch (error) {
                                                        actualErrors.push("pedidos_detalle_modificadores_mov->\n" + error);
                                                    }
                                                });
                                            }
                                            return y;
                                        }
                                        else {
                                            actualErrors.push('pedidos_detalle_modificadores_mov_res-->' + resy.status);
                                        }
                                    } else {
                                        actualErrors.push(`pedidos_detalle_modificadores-->res ${idPpal}-->` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`pedidos_detalle_modificadores--> ${idPpal}-->` + error);
                                }
                            })).then(res =>
                                resolve({ tabla: 'pedidos_detalle_modificadores', length: xs.length, updates: i, data: res })
                            );

                        } else {
                            resolve({ tabla: 'pedidos_detalle_modificadores', length: 0, updates: 0 })
                        }
                    })
                }
                /*  Promise.all( */
                await Promise.all(pds.map(async (x: any) => {
                    esUpdate = x.ID ? true : false;
                    x.Id_pedido = id;
                    if (x.id_ProductoPromocion === 0)
                        x.id_ProductoPromocion = undefined;
                    if (x.id_TipoPromocion === 0)
                        x.id_TipoPromocion = undefined;
                    if (x.id_TipoOferta === 0)
                        x.id_TipoOferta = undefined;
                    CrudOrdenService.onStatusPedidoDetalle(x.id_defTipoStatusPedido as enuStatusPedidoDetalle, x, esUpdate, loginToken);
                    try {
                        let resx: any;
                        let datax: pedidos_detalle;
                        if (esUpdate) {
                            resx = await axios.post(config.SERVER_URI + "/pedidos_detalle/update", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: { id: x.ID, ...x }
                            });
                        }
                        else {
                            resx = await axios.post(config.SERVER_URI + "/pedidos_detalle/create", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: x
                            });
                        }
                        if (resx.status === 200) {
                            datax = resx.data as unknown as pedidos_detalle;
                            if (datax) {
                                //TOOD despues de actualizar
                                // CrudOrdenService.onTipoDespachoPedido(orden.Id_TipoDespachoPedido as number, orden, esUpdate, loginToken)
                                let id_detalle: number = datax.ID;
                                x.ID = datax.ID;
                                x.Id_pedido = datax.Id_pedido;
                                x.Id_Producto = datax.Id_Producto;
                                x.id_ProductoPromocion = datax.id_ProductoPromocion;
                                x.id_TipoPromocion = datax.id_TipoPromocion;
                                x.id_TipoOferta = datax.id_TipoOferta;
                                x.id_defTipoStatusPedido = datax.id_defTipoStatusPedido;
                                x.id_defTipoPrecio = datax.id_defTipoPrecio;
                                x.Descripcion = datax.Descripcion;
                                x.NroItem = datax.NroItem;
                                x.CantidadTotal = datax.CantidadTotal ? +datax.CantidadTotal : 0;
                                x.CantidadDevolucion = datax.CantidadDevolucion ? +datax.CantidadDevolucion : 0;
                                x.TasaIVA = datax.TasaIVA ? +datax.TasaIVA : 0;
                                x.MontoPrecio = datax.MontoPrecio ? +datax.MontoPrecio : 0;
                                x.MontoPrecioDivisa = datax.MontoPrecioDivisa ? +datax.MontoPrecioDivisa : 0;
                                x.MontoTotalImpuesto = datax.MontoTotalImpuesto ? +datax.MontoTotalImpuesto : 0;
                                x.MontoTotalBase = datax.MontoTotalBase ? +datax.MontoTotalBase : 0;
                                x.MontoDescuento = datax.MontoDescuento ? +datax.MontoDescuento : 0;
                                x.ValorDescuento = datax.ValorDescuento ? +datax.ValorDescuento : 0;
                                x.PuntosAplicados = datax.PuntosAplicados ? +datax.PuntosAplicados : 0;
                                x.Comentario = datax.Comentario;
                                x.HistoricoDetalle = datax.HistoricoDetalle;
                                x.Mensaje = datax.Mensaje;
                                x.CantidadImpreso = datax.CantidadImpreso;
                                x.FechaActual = datax.FechaActual;
                                x.FechaImpreso = datax.FechaImpreso;
                                x.FechaDespacho = datax.FechaDespacho;
                                x.esDevolucion = datax.esDevolucion;
                                x.esImpreso = datax.esImpreso;
                                x.esDespachado = datax.esDespachado;
                                x.id_TipoImpuesto = datax.id_TipoImpuesto;
                               /*  x.pedidos_detalle_impuestos = x.pedidos_detalle_impuestos;
                                x.pedidos_detalle_descuentos = x.pedidos_detalle_descuentos;
                                x.pedidos_detalle_movimientos = x.pedidos_detalle_movimientos;
 */
                                if (id_detalle) {
                                    setImpuestos(x.pedidos_detalle_impuestos, x.ID)
                                        .then(() => {
                                            setDescuentos(x.pedidos_detalle_descuentos, x.ID)
                                                .then((res) => {
                                                    setMovimientos(x.pedidos_detalle_movimientos, x.ID)
                                                        .then((res) => {
                                                            setModificadores(x.pedidos_detalle_modificadores, x.ID).then((res: any) => {
                                                                if (res.data && res.data.length)
                                                                    x.pedidos_detalle_modificadores = Array.from(res.data);
                                                                //😎marramucia
                                                                if (i === pds.length - 1) {
                                                                    resolve(pds)
                                                                }
                                                                i++
                                                            })
                                                        })
                                                })
                                        })
                                }
                            }
                        }
                        else {
                            actualErrors.push(`pedidos_detalle_modificadores-->res ${x.ID}-->` + resx.status);
                        }
                    } catch (error) {
                        actualErrors.push(`pedidos_detalle_modificadores--> ${x.ID}-->` + error);
                    }
                })).then(res => {

                });

            } else {
                reject("No se puedo Generar Orden. sin Peoductos.")
            }

        })
    }

    public static onStatusPedidoDetalle(status: enuStatusPedidoDetalle, detalle: pedidos_detalle, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        switch (status) {
            case enuStatusPedidoDetalle.PedidoNuevo:
                if (detalle.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                }
                else {
                    detalle.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.SinProcesar:
                if (detalle.ID) {
                    if (esUpdate) {

                    }
                    else {
                        detalle.id_defTipoStatusPedido = enuStatusPedidoDetalle.Procesado;
                    }
                }
                else {
                    detalle.esDespachado = 0;
                    detalle.id_defTipoStatusPedido = enuStatusPedidoDetalle.Procesado;
                }
                break;
            case enuStatusPedidoDetalle.DespachoCocina:
                if (detalle.ID) {
                    detalle.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.Procesado:
                if (detalle.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                } else {

                }
                break;
            case enuStatusPedidoDetalle.Recibido:
                if (detalle.ID) {
                    detalle.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoCocina:
                if (detalle.ID) {
                    detalle.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.ProcesadoDevuelto:
                if (detalle.ID) {
                    detalle.esDespachado = 1;
                    detalle.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoDevuelto: // recibido en cocina y devuelto
                if (detalle.ID) {
                    detalle.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
        }
        if (esUpdateData) {
            return new Promise((resolve, reject) => {
                CrudOrdenService.modifPedidoDetalleExpress(detalle, loginToken)
                    .then(res => {
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    })
            });
        }
    }
    public static onStatusPedidoDetalle_movimiento(status: enuStatusPedidoDetalle, movimiento: pedidos_detalle_movimientos, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        movimiento.id_defTipoStatusPedido = status;
        switch (status) {
            case enuStatusPedidoDetalle.PedidoNuevo:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    movimiento.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.SinProcesar:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    movimiento.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.DespachoCocina:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.Procesado:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                } else {

                }
                break;
            case enuStatusPedidoDetalle.Recibido:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoCocina:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.ProcesadoDevuelto:
                if (movimiento.ID) {
                    movimiento.esDespachado = 1;
                    movimiento.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoDevuelto: // recibido en cocina y devuelto
                if (movimiento.ID) {
                    movimiento.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
        }
    }
    public static onStatusPedidoDetalle_Modificadores(status: enuStatusPedidoDetalle, modificador: pedidos_detalle_modificadores, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        modificador.id_defTipoStatusPedido = status;
        switch (status) {
            case enuStatusPedidoDetalle.PedidoNuevo:
                if (modificador.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    modificador.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.SinProcesar:
                if (modificador.ID) {
                    if (esUpdate) {
                    }
                    else {
                        modificador.id_defTipoStatusPedido = enuStatusPedidoDetalle.Procesado;
                    }
                }
                else {
                    modificador.esDespachado = 0;
                    modificador.id_defTipoStatusPedido = enuStatusPedidoDetalle.Procesado;
                }
                break;
            case enuStatusPedidoDetalle.DespachoCocina:
                if (modificador.ID) {
                    modificador.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.Procesado:
                if (modificador.ID) {
                    if (esUpdate) {

                    }
                    else {

                    }
                } else {
                }
                break;
            case enuStatusPedidoDetalle.Recibido:
                if (modificador.ID) {
                    modificador.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoCocina:
                if (modificador.ID) {
                    modificador.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.ProcesadoDevuelto:
                if (modificador.ID) {
                    modificador.esDespachado = 1;
                    modificador.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoDevuelto: // recibido en cocina y devuelto
                if (modificador.ID) {
                    modificador.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
        }
    }
    public static onStatusPedidoDetalleModif_movimiento(status: enuStatusPedidoDetalle, movimiento: pedidos_detalle_modificadores_mov, esUpdate: boolean, loginToken: any, setting: SettingsApp | undefined = undefined, esUpdateData: boolean = false) {
        movimiento.id_defTipoStatusPedido = status;
        switch (status) {
            case enuStatusPedidoDetalle.PedidoNuevo:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    movimiento.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.SinProcesar:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                else {
                    movimiento.esDespachado = 0;
                }
                break;
            case enuStatusPedidoDetalle.DespachoCocina:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.Procesado:
                if (movimiento.ID) {
                    if (esUpdate) {
                    }
                    else {

                    }
                } else {

                }
                break;
            case enuStatusPedidoDetalle.Recibido:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoCocina:
                if (movimiento.ID) {
                    movimiento.esImpreso = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.ProcesadoDevuelto:
                if (movimiento.ID) {
                    movimiento.esDespachado = 1;
                    movimiento.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
            case enuStatusPedidoDetalle.RecibidoDevuelto: // recibido en cocina y devuelto
                if (movimiento.ID) {
                    movimiento.esDevolucion = 1;
                    if (esUpdate) {
                    }
                    else {

                    }
                }
                break;
        }
    }
    //🤑 Estocada final
    public static loadPedido(idOrden: number, loginToken: any, setting: SettingsApp | undefined = undefined) {
        return new Promise(async (resolve, reject) => {
            let orden: Orden = new Orden();
            //Cargar desde API
            try {
                let IdPedido: number;
                let tmppedido: pedidos = new pedidos();
                await axios.post(config.SERVER_URI + "/order/getordenbyid", {
                    ID: loginToken.ID,
                    hash: loginToken.hash,
                    data: { id: idOrden }
                }).then(res => {
                    tmppedido = res.data[0] as unknown as pedidos;
                    IdPedido = tmppedido?.ID;
                    orden.ID = IdPedido;
                    orden.Id_Caja = tmppedido.Id_Caja;
                    orden.Id_Cliente = tmppedido.Id_Cliente;
                    orden.Id_TipoDespachoPedido = tmppedido.Id_TipoDespachoPedido;
                    orden.Id_Usuario = tmppedido.Id_Usuario;
                    orden.Id_Estaciones_Sesiones = tmppedido.Id_Estaciones_Sesiones;
                    orden.id_defTipoSatusPedido = tmppedido.id_defTipoSatusPedido;
                    orden.id_defTipoOrigenPedido = tmppedido.id_defTipoOrigenPedido;
                    orden.id_defTipoPedido = tmppedido.id_defTipoPedido;
                    orden.FechaActual = tmppedido.FechaActual;
                    orden.FechaPedido = tmppedido.FechaPedido;
                    orden.MontoTotal = tmppedido.MontoTotal ? +tmppedido.MontoTotal : 0;
                    orden.MontoTotalBase = tmppedido.MontoTotalBase ? +tmppedido.MontoTotalBase : 0;
                    orden.MontoTotalIVA = tmppedido.MontoTotalIVA ? +tmppedido.MontoTotalIVA : 0;
                    orden.MontoTotalDescuento = tmppedido.MontoTotalDescuento ? +tmppedido.MontoTotalDescuento : 0;
                    orden.MontoCambio = tmppedido.MontoCambio ? +tmppedido.MontoCambio : 0;
                    orden.MontoPagado = tmppedido.MontoPagado ? +tmppedido.MontoPagado : 0;
                    orden.MontoPropina = tmppedido.MontoPropina ? +tmppedido.MontoPropina : 0;
                    orden.NroItems = tmppedido.NroItems ? +tmppedido.NroItems : 0;
                    orden.NroPedido = tmppedido.NroPedido;
                    orden.RefPedido = tmppedido.RefPedido;
                    orden.Comentarios = tmppedido.Comentarios;
                    orden.Mensaje = tmppedido.Mensaje;
                    orden.CantidadImpreso = tmppedido.CantidadImpreso;
                    orden.esTieneDevolucion = tmppedido.esTieneDevolucion;
                    orden.esPagado = tmppedido.esPagado;
                    orden.esDespachado = tmppedido.esDespachado;
                    orden.esImpreso = tmppedido.esImpreso;
                    orden.Historico = tmppedido.Historico;
                    orden.mesas_detalles = tmppedido.mesas_detalles;
                    orden.Mesa = tmppedido.mesas_detalles && tmppedido.mesas_detalles.length ? tmppedido.mesas_detalles[0].id_Mesa_mesa : undefined;
                    if (orden.Mesa)
                        orden.Mesa.mesas_detalles = orden.mesas_detalles;
                    if (tmppedido.mesas_detalles && tmppedido.mesas_detalles.length)
                        orden.Id_Usuario_usuario = tmppedido.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario;
                    //Impuestos 
                    orden.pedidos_impuestos = tmppedido.pedidos_impuestos;
                    //descuentos
                    orden.pedidos_descuentos = tmppedido.pedidos_descuentos;
                    //Modificadores
                    orden.pedidos_modificadores = tmppedido.pedidos_modificadores;
                    //Mesa

                    //Mesero

                    //Detalles
                    orden.pedidos_detalles = tmppedido.pedidos_detalles;
                    if (tmppedido.pedidos_detalles && tmppedido.pedidos_detalles.length) {
                        tmppedido.pedidos_detalles.forEach(ip => {
                            let itemP: ItemPedido = new ItemPedido(setting);
                            itemP.ID = ip.ID;
                            itemP.Id_pedido = ip.Id_pedido;
                            itemP.Id_Producto = ip.Id_Producto;
                            itemP.id_ProductoPromocion = ip.id_ProductoPromocion;
                            itemP.id_TipoPromocion = ip.id_TipoPromocion;
                            itemP.id_TipoOferta = ip.id_TipoOferta;
                            itemP.id_defTipoStatusPedido = ip.id_defTipoStatusPedido;
                            itemP.id_defTipoPrecio = ip.id_defTipoPrecio;
                            itemP.Descripcion = ip.Descripcion;
                            itemP.NroItem = ip.NroItem;
                            itemP.CantidadTotal = ip.CantidadTotal ? +ip.CantidadTotal : 0;
                            itemP.CantidadDevolucion = ip.CantidadDevolucion ? +ip.CantidadDevolucion : 0;
                            itemP.TasaIVA = ip.TasaIVA ? +ip.TasaIVA : 0;
                            itemP.MontoPrecio = ip.MontoPrecio ? +ip.MontoPrecio : 0;
                            itemP.MontoPrecioDivisa = ip.MontoPrecioDivisa ? +ip.MontoPrecioDivisa : 0;
                            itemP.MontoTotalImpuesto = ip.MontoTotalImpuesto ? +ip.MontoTotalImpuesto : 0;
                            itemP.MontoTotalBase = ip.MontoTotalBase ? +ip.MontoTotalBase : 0;
                            itemP.MontoDescuento = ip.MontoDescuento ? +ip.MontoDescuento : 0;
                            itemP.ValorDescuento = ip.ValorDescuento ? +ip.ValorDescuento : 0;
                            itemP.PuntosAplicados = ip.PuntosAplicados ? +ip.PuntosAplicados : 0;
                            itemP.Comentario = ip.Comentario;
                            itemP.HistoricoDetalle = ip.HistoricoDetalle;
                            itemP.Mensaje = ip.Mensaje;
                            itemP.CantidadImpreso = ip.CantidadImpreso;
                            itemP.FechaActual = ip.FechaActual;
                            itemP.FechaImpreso = ip.FechaImpreso;
                            itemP.FechaDespacho = ip.FechaDespacho;
                            itemP.esDevolucion = ip.esDevolucion;
                            itemP.esImpreso = ip.esImpreso;
                            itemP.esDespachado = ip.esDespachado;
                            itemP.id_TipoImpuesto = ip.id_TipoImpuesto;
                            itemP.pedidos_detalle_descuentos = ip.pedidos_detalle_descuentos;
                            itemP.pedidos_detalle_impuestos = ip.pedidos_detalle_impuestos;
                            itemP.pedidos_detalle_movimientos = ip.pedidos_detalle_movimientos;
                            itemP.Extras = ip.pedidos_detalle_modificadores;
                            itemP.pedidos_detalle_modificadores = ip.pedidos_detalle_modificadores;
                            if (!orden.Items)
                                orden.Items = [];
                            orden.Items.push(itemP);
                        });
                    }
                    resolve(orden)
                }).catch(err => {
                    reject(err)
                });
            } catch (error) {
                reject(error)
            }

        })


    }
    public static findFactura(id: number, esbyOrden: boolean) {
        return new Promise(async (resolve, reject) => {
            let x: facturas = new facturas();
            try {
                let res: any;
                let data: facturas;
                if (esbyOrden) {
                    res = await axios.post(config.SERVER_URI + "/facturas/list", {
                        options: {
                            include: [
                                "facturas_descuentos",
                                "facturas_detalles",
                                "facturas_impuestos",
                                'facturas_mediopagos',
                            ],
                            where: { id_Pedido: id }
                        }
                    });
                } else {
                    res = await axios.post(config.SERVER_URI + "/facturas/get", {
                        id: id,
                        options: {
                            include: [
                                "facturas_descuentos",
                                "facturas_detalles",
                                "facturas_impuestos",
                                'facturas_mediopagos',
                            ]
                        }
                    });
                }
                if (res.status === 200) {
                    data = res.data as unknown as facturas;
                    if (data) {
                        resolve(data);
                    }
                } else {
                    reject('modifPedidos_res-->' + res.status);
                }
            } catch (error) {
                reject(error)
            }
        })
    }
    public static modifFactura(orden: Orden, loginToken: any, setting: SettingsApp | undefined = undefined, factura: facturas | undefined, puntosFactura: number = 0, status: enuStatusFactura = enuStatusFactura.pendienteFacturar) {
        let actualErrors: string[] = [];
        return new Promise(async (resolve, reject) => {
            // buscar si tiene factua
            if (orden && orden.Items) {
                try {
                    let x: facturas = new facturas();
                    let esUpdate: boolean = false;
                    let fres: any;
                    let res: any;
                    let data: facturas;
                    let Nrofactura: number = 0;
                    await this.getCorrelativos(loginToken, setting).then(res => {
                        if (res && res.NroFactura)
                            Nrofactura = res.NroFactura + 1;
                        else
                            Nrofactura = 1;
                    })
                    fres = await this.findFactura(orden.ID, true)
                    /*  if (fres) {
                         x = fres as unknown as facturas;
                         if (x && x.ID)
                             esUpdate = x.ID ? true : false;
                     } */

                    if (esUpdate) {
                        if (factura) {
                            if ((x.facturas_impuestos && x.facturas_impuestos.length) && (factura.facturas_impuestos && factura.facturas_impuestos.length))
                                factura.facturas_impuestos.forEach(y => {
                                    let f = x.facturas_impuestos.find(a => a.ID === y.ID);
                                    if (f)
                                        y = f;
                                    else
                                        x.facturas_impuestos.push(y);

                                });
                            if ((x.facturas_descuentos && x.facturas_descuentos.length) && (factura.facturas_descuentos && factura.facturas_descuentos.length))
                                factura.facturas_descuentos.forEach(y => {
                                    let f = x.facturas_descuentos.find(a => a.ID === y.ID);
                                    if (f)
                                        y = f;
                                    else
                                        x.facturas_descuentos.push(y);
                                })
                            if ((x.facturas_detalles && x.facturas_detalles.length) && (factura.facturas_detalles && factura.facturas_detalles.length))
                                factura.facturas_detalles.forEach(y => {
                                    let f = x.facturas_detalles.find(a => a.ID === y.ID);
                                    if (f)
                                        y = f;
                                    else
                                        x.facturas_detalles.push(y);
                                })
                        }
                    } else {
                        x.Id_Caja = orden.Id_Caja ? orden.Id_Caja : 0;
                        x.Id_Cliente = orden.Id_Cliente;
                        x.Id_Empleado = orden.Id_Usuario_usuario ? orden.Id_Usuario_usuario.id_empleado : 1;
                        x.Id_Estaciones_Sesiones = orden.Id_Estaciones_Sesiones ?? 1;
                        x.id_Pedido = orden.ID;
                        x.id_defTipoSatusFactura = status;
                        x.FechaActual = new Date();
                        x.FechaFactura = orden.FechaPedido ? orden.FechaPedido : undefined;
                        x.MontoTotal = orden.MontoTotal;
                        x.MontoTotalBase = orden.MontoTotalBase;
                        x.MontoTotalIVA = orden.MontoTotalIVA;
                        x.MontoTotalDescuento = orden.MontoTotalDescuento;
                        x.MontoPropina = orden.MontoPropina;
                        x.NroFactura = Nrofactura;
                        x.NroItems = orden.NroItems ? orden.NroItems : 0;
                        x.Comentarios = orden.Comentarios;
                        x.PuntosCantidad = puntosFactura;
                        x.esTieneDevolucion = 0;

                        if (orden.pedidos_impuestos && orden.pedidos_impuestos.length) {
                            x.facturas_impuestos = [];
                            orden.pedidos_impuestos.forEach(y => {
                                let f = new facturas_impuestos();
                                f.MontoBase = y.Montobase;
                                f.Tasa = y.ValorTasa;
                                f.Id_Impuesto = y.Id_Impuesto;
                                x.facturas_impuestos.push(f);
                            });
                        }
                        if (orden.pedidos_descuentos && orden.pedidos_descuentos.length) {
                            x.facturas_descuentos = [];
                            orden.pedidos_descuentos.forEach(y => {
                                let f = new facturas_descuentos();
                                f.ValorDescuento = y.ValorDescuento;
                                f.Comentario = y.Comentario;
                                f.Id_Descuento = y.Id_Descuento;
                                f.id_UsuarioAutoriza = y.id_UsuarioAutoriza;
                                x.facturas_descuentos.push(f);
                            })
                        }

                        if (orden.Items && orden.Items.length) {
                            let n: number = 0;
                            let dexs: facturas_detalle[] = [];
                            x.facturas_detalles = [];
                            orden.Items.forEach(y => {
                                let dds: facturas_detalle_descuentos[] = [];
                                let dis: facturas_detalle_impuestos[] = [];
                                let d = new facturas_detalle();
                                d.Id_Producto = y.Id_Producto;
                                d.id_ProductoPromocion = y.id_ProductoPromocion;
                                d.Id_ModificadorProducto = undefined;
                                d.Id_Modificador = undefined;
                                d.Id_TipoAcompanante = undefined;
                                d.id_TipoPromocion = y.id_TipoPromocion;
                                d.id_TipoOferta = y.id_TipoOferta;
                                d.id_defTipoPrecio = y.id_defTipoPrecio;
                                d.Descripcion = y.Descripcion;
                                d.NroItem = y.NroItem;
                                d.Cantidad = y.CantidadTotal;
                                switch (y.id_defTipoPrecio) {
                                    case EnuTiposPrecio.precioDivisa:
                                        d.MontoPrecio = y.MontoPrecioDivisa;
                                        break;
                                    case EnuTiposPrecio.precioManual:
                                    case EnuTiposPrecio.precioMaximo:
                                    case EnuTiposPrecio.precioMayor:
                                    case EnuTiposPrecio.precioMinimo:
                                    case EnuTiposPrecio.precioOferta:
                                        d.MontoPrecio = y.MontoPrecio;
                                        break;
                                }
                                d.MontoTotalImpuesto = y.MontoTotalImpuesto;
                                d.MontoTotalBase = y.MontoTotalBase;
                                d.MontoDescuento = y.MontoDescuento;
                                d.ValorDescuento = y.ValorDescuento;
                                d.PuntosAplicados = y.PuntosAplicados;
                                d.Comentario = y.Comentario;
                                d.esDevolucion = y.esDevolucion;
                                d.Historico = "Creando Detalle";
                                if (y.pedidos_detalle_descuentos && y.pedidos_detalle_descuentos.length) {
                                    y.pedidos_detalle_descuentos.forEach(z => {
                                        let f = new facturas_detalle_descuentos();
                                        f.ValorDescuento = z.ValorDescuento;
                                        f.Id_TipoDescuento = z.Id_TipoDescuento;
                                        f.id_UsuarioAutoriza = z.id_UsuarioAutoriza;
                                        dds.push(f);
                                    })
                                }
                                if (y.pedidos_detalle_impuestos && y.pedidos_detalle_impuestos.length) {
                                    y.pedidos_detalle_impuestos.forEach(z => {
                                        let f = new facturas_detalle_impuestos();
                                        f.MontoBase = z.MontoBase;
                                        f.Tasa = z.Tasa;
                                        f.Id_TipoImpuesto = z.Id_TipoImpuesto;
                                        dis.push(f);
                                    })
                                }

                                if (y.Extras && y.Extras.length) {
                                    y.Extras.forEach(z => {
                                        let ex = new facturas_detalle();
                                        ex.Id_ModificadorProducto = z.id_ModificadorProducto;
                                        ex.Id_TipoModificador = z.id_TipoModificador;
                                        ex.Id_TipoAcompanante = z.id_TipoAcompanante;
                                        ex.Descripcion = z.Nombre ? z.Nombre : '';
                                        ex.NroItem = y.NroItem;
                                        ex.Cantidad = z.CantidadTotal;
                                        ex.MontoPrecio = z.MontoPrecio;
                                        ex.MontoTotalImpuesto = z.MontoTotalImpuesto;
                                        ex.MontoTotalBase = z.MontoTotalBase;
                                        ex.Comentario = z.Mensaje;
                                        ex.esDevolucion = z.esDevolucion;
                                        ex.Historico = "Creando Detalle";
                                        dexs.push(ex);
                                    })
                                }
                                if (orden.pedidos_modificadores && orden.pedidos_modificadores) {
                                    orden.pedidos_modificadores.forEach(z => {
                                        let ex = new facturas_detalle();
                                        ex.Id_ModificadorProducto = undefined;
                                        ex.Id_Modificador = z.id_modificador_Pedido;
                                        ex.Id_TipoModificador = undefined;
                                        ex.Id_TipoAcompanante = undefined;
                                        ex.id_TipoPromocion = undefined;
                                        ex.id_TipoOferta = undefined;
                                        ex.id_defTipoPrecio = undefined;
                                        // ex.Descripcion = z.;
                                        ex.NroItem = z.NroItem;
                                        ex.Cantidad = z.Cantidad;
                                        ex.MontoPrecio = z.MontoPrecio;
                                        ex.MontoTotalImpuesto = z.MontoIVA;
                                        ex.MontoTotalBase = z.MontoPrecio;
                                        ex.Comentario = z.Mensaje;
                                        ex.Historico = "Creando Detalle";
                                        ex.esDevolucion = z.esDevolucion;
                                        dexs.push(d);
                                    })
                                }
                                x.facturas_detalles.push(d);
                                dexs.forEach(d => {
                                    x.facturas_detalles.push(d);
                                });
                            })
                        }
                    }

                    if (esUpdate) {
                        res = await axios.post(config.SERVER_URI + "/facturas/update", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: { id: x.ID, ...x }
                        });
                    }
                    else {
                        res = await axios.post(config.SERVER_URI + "/facturas/create", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: x
                        });
                    }
                    if (res.status === 200) {
                        data = res.data as unknown as facturas;
                        if (data.ID) {
                            let idfactura: number;
                            idfactura = data.ID;
                            if (!esUpdate)
                                if (orden.pedidos_impuestos && orden.pedidos_impuestos.length) {
                                    x.facturas_impuestos = [];
                                    orden.pedidos_impuestos.forEach(pi => {
                                        let fi: any = {
                                            Id_Impuesto: pi.Id_Impuesto,
                                            Tasa: pi.ValorTasa,
                                            MontoBase: pi.Montobase,
                                        }
                                        x.facturas_impuestos.push(fi);
                                    });
                                }

                            //Impuestos
                            if (x.facturas_impuestos && x.facturas_impuestos.length > 0) {
                                x.facturas_impuestos.forEach(async (y: facturas_impuestos) => {
                                    let resy: any;
                                    let datay: facturas_impuestos;
                                    let esUpdatey: boolean = y.ID ? true : false;
                                    y.Id_Factura = idfactura;
                                    try {
                                        if (esUpdatey) {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_impuestos/update", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: { id: y.ID, ...y }
                                            });
                                        } else {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_impuestos/create", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: y
                                            });
                                        }
                                        if (resy.status === 200) {
                                            datay = resy.data as unknown as facturas_impuestos;
                                            if (datay) {
                                                y = datay;
                                            }
                                        }
                                        else {
                                            actualErrors.push('facturas_impuestos_res-->' + resy.status);
                                        }
                                    } catch (error) {
                                        actualErrors.push("facturas_impuestos->\n" + error);
                                    }
                                });
                            }
                            //decuentos
                            if (x.facturas_descuentos && x.facturas_descuentos.length > 0) {
                                x.facturas_descuentos.forEach(async (y: facturas_descuentos) => {
                                    let resy: any;
                                    let datay: facturas_descuentos;
                                    let esUpdatey: boolean = y.ID ? true : false;
                                    y.Id_Factura = idfactura;
                                    try {
                                        if (esUpdatey) {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_descuentos/update", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: { id: y.ID, ...y }
                                            });
                                        } else {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_descuentos/create", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: y
                                            });
                                        }
                                        if (resy.status === 200) {
                                            datay = resy.data as unknown as facturas_descuentos;
                                            if (datay) {
                                                y = datay;
                                            }
                                        }
                                        else {
                                            actualErrors.push('facturas_descuentos_res-->' + resy.status);
                                        }
                                    } catch (error) {
                                        actualErrors.push("facturas_descuentos->\n" + error);
                                    }
                                });
                            }
                            if (!esUpdate)
                                if (orden.facturas && orden.facturas.length)
                                    x.facturas_mediopagos = orden.facturas[0].facturas_mediopagos;
                            //Medios pago
                            if (x.facturas_mediopagos && x.facturas_mediopagos.length > 0) {
                                x.facturas_mediopagos.forEach(async (y: facturas_mediopago) => {
                                    let resy: any;
                                    let datay: facturas_mediopago;
                                    let esUpdatey: boolean = y.ID ? true : false;
                                    y.Id_Factura = idfactura;
                                    try {
                                        if (esUpdatey) {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_mediopago/update", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: { id: y.ID, ...y }
                                            });
                                        } else {
                                            resy = await axios.post(config.SERVER_URI + "/facturas_mediopago/create", {
                                                ID: loginToken.ID,
                                                hash: loginToken.hash,
                                                data: y
                                            });
                                        }
                                        if (resy.status === 200) {
                                            datay = resy.data as unknown as facturas_mediopago;
                                            if (datay) {
                                                y = datay;
                                            }
                                        }
                                        else {
                                            actualErrors.push('facturas_mediopagos_res-->' + resy.status);
                                        }
                                    } catch (error) {
                                        actualErrors.push("facturas_mediopagos->\n" + error);
                                    }
                                });
                            }
                            //Detalles
                            await CrudOrdenService.modifFacturas_Detalle(x.facturas_detalles, data.ID, loginToken)
                                .then((res: any) => {
                                    if (res) {
                                        x.facturas_detalles = res as facturas_detalle[];
                                        data.facturas_impuestos = x.facturas_impuestos;
                                        data.facturas_descuentos = x.facturas_descuentos;
                                        data.facturas_mediopagos = x.facturas_mediopagos;
                                        data.facturas_detalles = x.facturas_detalles;
                                        if (orden) {
                                            orden.Items.forEach(fd => {
                                                CrudOrdenService.onStatusPedidoDetalle(enuStatusPedidoDetalle.Facturado, fd, true, loginToken, undefined, true)
                                            });
                                            CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoFacturado, orden, true, loginToken, undefined, true);
                                        }
                                        //TODO: Descontar inventario
                                        resolve(data);
                                    } else {
                                        reject(`Promise modifFacturas_Detalle-->res ${orden.ID}` + res.status);
                                    }
                                }).catch(err => {
                                    //TODO:ELiminar pedido malo o deshabilitar
                                    reject("Promise modifFacturas_Detalle-->" + err);
                                })
                            //😋Sincronizar Pedido
                            CrudOrdenService.updateSync('facturas', data.ID, loginToken);
                            //Correlativos
                            let n: number = esUpdate ? Nrofactura - 1 : Nrofactura;
                            this.setCorrelativos({ NroFactura: n }, loginToken, setting);
                            //Orden status
                            this.onStatusPedido(enuStatusPedido.PedidoFacturado, orden, true, loginToken);
                        }
                    } else {
                        reject('modifFectura_res-->' + res.status);
                    }
                } catch (error) {
                    reject(error)
                }
            } else
                reject("No se puedo Generar Factura. sin Peoductos.")

        })
    }

    public static modifFacturas_Detalle(detalles: facturas_detalle[], id: number, loginToken: any, setting: SettingsApp | undefined = undefined) {
        let actualErrors: string[] = [];
        return new Promise(async (resolve, reject) => {
            if (detalles) {
                let esUpdate: boolean = false;
                let i: number = 0;
                const setImpuestos = (xs: facturas_detalle_impuestos[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await xs.forEach(async (y: any) => {
                                let resy: any;
                                let datay: facturas_impuestos;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.Id_pedidoDetalle = idPpal;
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/facturas_impuestos/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/facturas_impuestos/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as facturas_impuestos;
                                        if (datay) {
                                            y = datay;
                                            i++;
                                        }
                                    }
                                    else {
                                        actualErrors.push(`facturas_impuestos_res--> ${idPpal}` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`facturas_impuestos--> ${idPpal}` + error);
                                }
                            });
                            resolve({ tabla: 'facturas_impuestos', length: xs.length, updates: i })
                        } else {
                            resolve({ tabla: 'facturas_impuestos', length: 0, updates: 0 })
                        }
                    })
                }
                const setDescuentos = (xs: facturas_detalle_descuentos[], idPpal: number) => {
                    return new Promise(async (resolve, reject) => {
                        if (xs && xs.length > 0) {
                            let i: number = 0;
                            await xs.forEach(async (y: any) => {
                                let resy: any;
                                let datay: facturas_detalle;
                                let esUpdatey: boolean = y.ID ? true : false;
                                y.Id_pedidoDetalle = idPpal;
                                try {
                                    if (esUpdatey) {
                                        resy = await axios.post(config.SERVER_URI + "/facturas_detalle/update", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: { id: y.ID, ...y }
                                        });
                                    } else {
                                        resy = await axios.post(config.SERVER_URI + "/facturas_detalle/create", {
                                            ID: loginToken.ID,
                                            hash: loginToken.hash,
                                            data: y
                                        });
                                    }
                                    if (resy.status === 200) {
                                        datay = resy.data as unknown as facturas_detalle;
                                        if (datay) {
                                            y = datay;
                                            i++;
                                        }
                                    }
                                    else {
                                        actualErrors.push(`facturas_detalle-->res ${idPpal}` + resy.status);
                                    }
                                } catch (error) {
                                    actualErrors.push(`facturas_detalle--> ${idPpal}` + error);
                                }
                            });
                            resolve({ tabla: 'facturas_detalle', length: xs.length, updates: i })
                        } else {
                            resolve({ tabla: 'facturas_detalle', length: 0, updates: 0 })
                        }

                    })
                }

                /*  Promise.all( */
                await Promise.all(detalles.map(async (x: facturas_detalle) => {
                    esUpdate = x.ID ? true : false;
                    x.Id_Factura = id;
                    if (x.id_ProductoPromocion === 0)
                        x.id_ProductoPromocion = undefined;
                    if (x.id_TipoPromocion === 0)
                        x.id_TipoPromocion = undefined;
                    if (x.id_TipoOferta === 0)
                        x.id_TipoOferta = undefined;
                    if (x.Id_Modificador === 0)
                        x.Id_Modificador = undefined;
                    if (x.Id_ModificadorProducto === 0)
                        x.Id_ModificadorProducto = undefined;
                    if (x.Id_TipoAcompanante === 0)
                        x.Id_TipoAcompanante = undefined;
                    if (x.Id_TipoModificador === 0)
                        x.Id_TipoModificador = undefined;
                    if (x.Id_Producto === 0)
                        x.Id_Producto = undefined;

                    try {
                        let resx: any;
                        let datax: facturas_detalle;
                        if (esUpdate) {
                            resx = await axios.post(config.SERVER_URI + "/facturas_detalle/update", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: { id: x.ID, ...x }
                            });
                        }
                        else {
                            resx = await axios.post(config.SERVER_URI + "/facturas_detalle/create", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: x
                            });
                        }
                        if (resx.status === 200) {
                            datax = resx.data as unknown as facturas_detalle;
                            if (datax) {
                                let id_detalle: number = datax.ID;
                                x = datax;
                              /*   x.facturas_detalle_impuestos = x.facturas_detalle_impuestos;
                                x.facturas_detalle_descuentos = x.facturas_detalle_descuentos; */
                                if (id_detalle) {
                                    setImpuestos(x.facturas_detalle_impuestos, x.ID)
                                        .then(() => {
                                            setDescuentos(x.facturas_detalle_descuentos, x.ID)
                                                .then((res: any) => {
                                                    //😎marramucia
                                                    if (i === detalles.length - 1) {
                                                        resolve(detalles)
                                                    }
                                                    i++
                                                })
                                        })
                                }
                            }
                        }
                        else {
                            actualErrors.push(`facturas_detalle-->res ${x.ID}-->` + resx.status);
                        }
                    } catch (error) {
                        actualErrors.push(`facturas_detalle--> ${x.ID}-->` + error);
                    }
                })).then(res => {

                });

            } else {
                reject('modifFactDet Factura sin Detalles');
            }

        })
    }

    //#region Correlativos
    static async createCorrelativos(idEstacion: number, loginToken: any, setting: SettingsApp | undefined = undefined, correlativo: correlativos | undefined = undefined) {
        let cor: correlativos;
        if (correlativo)
            cor = { id_Estacion: idEstacion, ...correlativo };
        else {
            cor = {
                ID: 0,
                id_Empresa: setting?.EmpresaActual.ID ?? 1,
                id_Estacion: idEstacion,
                NroFactura: 1,
                refFactura: '0001',
                lblFactura: 'FACTURA',
                NroPedido: 1,
                refPedido: '0001',
                lblPedido: 'PEDIDO',
                NroDocCompra: 1,
                refDocCompra: '0001',
                lblDocCompra: 'COMPRA',
                NroComanda: 1,
                refComanda: '0001',
                lblComanda: 'COMANDA',
                NroDocImpreso: 1,
                refDocImpreso: '0001',
                lblDocImpreso: 'DOC.IMP',
                NroEstacion: 1,
                refEstacion: '0001',
                lblEstacion: 'ESTACION',
                NroGasto: 1,
                refGasto: '0001',
                lblGasto: 'GASTO',
                NroMesa: 1,
                refMesa: '0001',
                lblMesa: 'MESA',
                NroNotaConsumo: 1,
                refNotaConsumo: '0001',
                lblNotaConsumo: 'NOTA CONSUMO',
                NroProducto: 1,
                refProducto: '0001',
                lblProducto: 'Nro.',
                NroCaja: 1,
                refCaja: '0001',
                lblCaja: 'CAJA',
                NroSectorMesa: 1,
                refSectorMesa: '0001',
                lblSectorMesa: 'SECTOR',
                NroProveedor: 1,
                refProveedor: '0001',
                lblProveedor: 'PROVEEDOR',
                NroSectorProducto: 1,
                refSectorProducto: '0001',
                lblSectorProducto: 'SECTOR',
                NroSectorImpresion: 1,
                refSectorImpresion: '0001',
                lblSectorImpresion: 'SECTOR IMPRESIÓN',
                NroCategoriaProducto: 1,
                refCategoriaProducto: '0001',
                lblCategoriaProducto: 'CATEGORIA',
            }
        }
        let res;
        res = await axios.post(config.SERVER_URI + "/correlativos/create", {
            ID: loginToken.ID,
            hash: loginToken.hash,
            data: cor
        });
    }
    static async getCorrelativos(loginToken: any, setting: SettingsApp | undefined = undefined) {
        let res: any;
        let count: any;
        let idEstacion: number = setting?.EstacionActual?.ID ?? 1;
        count = await axios.post(config.SERVER_URI + '/correlativos/count', {
            options: {
                where: { id_Estacion: idEstacion }
            }
        });
        if (loginToken) {
            if (count.status === 200) {
                let c: number = count.data.count as unknown as number;
                if (!c)
                    await this.createCorrelativos(idEstacion, loginToken, setting);
            } else
                await this.createCorrelativos(idEstacion, loginToken, setting);
        }
        res = await axios.post(config.SERVER_URI + '/correlativos/list', {
            options: {
                where: { id_Estacion: idEstacion }
            }
        });
        if (res.status === 200) {
            let cor: correlativos[] = res.data as unknown as correlativos[];
            return cor[0];
        } else {
            return undefined;
        }
    }
    static async setCorrelativos(values: any, loginToken: any, setting: SettingsApp | undefined = undefined) {
        let res: any;
        let idEstacion: number = setting?.EstacionActual.ID ?? 1;
        res = await axios.post(config.SERVER_URI + '/correlativos/updatewhere', {
            ID: loginToken.ID,
            hash: loginToken.hash,
            data: values,
            options: {
                where: { id_Estacion: idEstacion }
            }
        });
        if (res.status === 200) {
            return true;
        } else {
            return false;
        }
    }
    //#endregion

    //#region SINCRONIZAR
    public static getSync(tipoDato: tipoSync, setting: SettingsApp | undefined = undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(config.SERVER_URI + "/config/getsyncs", {
                    options: { where: { Nombre: tipoDato, setting } }
                }).then(res => {
                    resolve(res.data[0].sync_id as number)
                }).catch(err => {
                    reject(err)
                });
            } catch (error) {
                reject(error)
            }

        })
    }
    /**
     * Obtiene los Correlativos
     * @returns 
     */
    public static getSyncList() {
        return new Promise(async (resolve, reject) => {
            try {
                await axios.post(config.SERVER_URI + "/config/getsyncslist").then(res => {
                    resolve(res.data)
                }).catch(err => {
                    reject(err)
                });
            } catch (error) {
                reject(error)
            }
        })
    }
    /**
     * 
     * @param tipoDato Funcion para Actualizar Correlativos
     * @param sync_id 
     * @param loginToken 
     * @param setting 
     */
    public static async updateSync(tipoDato: tipoSync, sync_id: number, loginToken: any, setting: SettingsApp | undefined = undefined) {
        await axios.post(config.SERVER_URI + "/config/updatesyncs", {
            ID: loginToken.ID,
            hash: loginToken.hash,
            data: {
                sync_id: sync_id
            },
            options: {
                where: { Nombre: tipoDato }
            }
        }).then(res => {
            return res;
        }).catch(err => {
            console.log(err.response?.data);
        });
    }
    //#endregion
}


