import type { abonos } from './abonos';
import type { almacenes } from './almacenes';
import type { almacenes_movimientos } from './almacenes_movimientos';
import type { almacenes_movimientos_detalle } from './almacenes_movimientos_detalle';
import type { cajas_detalle } from './cajas_detalle';
import type { cajas_detallecortes } from './cajas_detallecortes';
import type { cajas_egresosingresos } from './cajas_egresosingresos';
import type { comandas } from './comandas';
import type { compras } from './compras';
import type { empleados } from './empleados';
import type { estaciones_sesiones } from './estaciones_sesiones';
import type { estaciones_sesiones_detalle } from './estaciones_sesiones_detalle';
import type { facturas_descuentos } from './facturas_descuentos';
import type { facturas_detalle_descuentos } from './facturas_detalle_descuentos';
import type { gastos_detalle } from './gastos_detalle';
import type { mesas_detalle } from './mesas_detalle';
import type { notas_consumo } from './notas_consumo';
import type { pedidos } from './pedidos';
import type { pedidos_descuentos } from './pedidos_descuentos';
import type { pedidos_detalle_descuentos } from './pedidos_detalle_descuentos';
import type { pedidos_detalle_modificadores_mov } from './pedidos_detalle_modificadores_mov';
import type { pedidos_detalle_movimientos } from './pedidos_detalle_movimientos';
import type { reservas_mesas } from './reservas_mesas';
import type { usuarios_detalle } from './usuarios_detalle';
import type { usuarios_grupo } from './usuarios_grupo';
import type { usuarios_roles } from './usuarios_roles';
import type { usuarios_rolesmodulos } from './usuarios_rolesmodulos';

export interface usuariosAttributes {
  ID: number;
  id_empleado?: number;
  id_TipoGrupoUsuario?: number;
  id_cliente?: number;
  id_defTipoNivelAcceso?: number;
  id_defTipoUsuarioRestaurant?: number;
  Nombre: string;
  NombreUsuario: string;
  Pass: string;
  CodigoPin: number;
  Imagen?: string;
  DiasVence?: number;
  FechaActual?: Date;
  FechaCaduca?: Date;
  FechaModificadoPass?: Date;
  esFavorito?: number;
  esCaduca?: number;
  esLogged?: number;
  esActivo?: number;
  esCliente?: number;
}


export class usuarios implements usuariosAttributes {
  ID!: number;
  id_empleado?: number;
  id_TipoGrupoUsuario?: number;
  id_cliente?: number;
  id_defTipoNivelAcceso?: number;
  id_defTipoUsuarioRestaurant?: number;
  Nombre!: string;
  NombreUsuario!: string;
  Pass!: string;
  CodigoPin!: number;
  Imagen?: string;
  DiasVence?: number;
  FechaActual?: Date;
  FechaCaduca?: Date;
  FechaModificadoPass?: Date;
  esFavorito?: number;
  esCaduca?: number;
  esLogged?: number;
  esActivo?: number;
  esCliente?: number;

  // usuarios belongsTo empleados via id_empleado
  id_empleado_empleado!: empleados;
  abonos!: abonos[];
  almacenes!: almacenes[];
  almacenes_movimientos!: almacenes_movimientos[];
  almacenes_movimientos_detalles!: almacenes_movimientos_detalle[];
  cajas_detalles!: cajas_detalle[];
  cajas_detallecortes!: cajas_detallecortes[];
  cajas_egresosingresos!: cajas_egresosingresos[];
  comandas!: comandas[];
  compras!: compras[];
  estaciones_sesiones!: estaciones_sesiones[];
  id_Usuario_Fin_estaciones_sesiones!: estaciones_sesiones[];
  estaciones_sesiones_detalles!: estaciones_sesiones_detalle[];
  facturas_descuentos!: facturas_descuentos[];
  facturas_detalle_descuentos!: facturas_detalle_descuentos[];
  gastos_detalles!: gastos_detalle[];
  mesas_detalles!: mesas_detalle[];
  id_UsuarioUltimoAcceso_mesas_detalles!: mesas_detalle[];
  notas_consumos!: notas_consumo[];
  pedidos!: pedidos[];
  pedidos_descuentos!: pedidos_descuentos[];
  pedidos_detalle_descuentos!: pedidos_detalle_descuentos[];
  pedidos_detalle_modificadores_movs!: pedidos_detalle_modificadores_mov[];
  pedidos_detalle_movimientos!: pedidos_detalle_movimientos[];
  reservas_mesas!: reservas_mesas[];
  usuarios_detalles!: usuarios_detalle[];
  usuarios_roles!: usuarios_roles[];
  usuarios_rolesmodulos!: usuarios_rolesmodulos[];
  id_TipoGrupoUsuario_usuarios_grupo!: usuarios_grupo;


}
