import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Stepper, Step, StepLabel, Button, Typography, Box, Grid, CircularProgress, Theme, TextField, Paper, Alert, } from '@mui/material';
import { SentimentVerySatisfied, SentimentVeryDissatisfied, ControlPoint, AttachEmail, DesktopWindows, Send, Cancel } from '@mui/icons-material';
import StepperIcons from "./StepperIcons";
import ContactForm from "./views/ContactForm";
import PaymentForm from "./views/PaymentForm";
import ServiceForm from "./views/ServiceForm";
/* import { useStripe, useElements, CardCvcElement, } from '@stripe/react-stripe-js'; */
import StepConnector from './StepConnector'
import { useStateValue } from './StateContext';
import { useOrder } from '../../../contexts/order-context';
import { useLoginToken } from '../../../contexts/login-context';
import { formatNumber } from 'fast-number-formatter';
import { ColorsDPana } from '../../../utils/Color';
import { styled } from '@mui/material/styles';
import { enuStatusPagoDocumento } from '../../../utils/enums';
import { facturas_mediopago } from '../../../models/facturas_mediopago';
import { clientes } from '../../../models/clientes';
import axios from 'axios';
import { config } from '../../../utils/configs';
import SpinnerLoading from '../../../components/spinner-loading';
import SimpleToast from '../../../components/simple-toast';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const style = makeStyles((theme: Theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.default
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const StepContent = (step: any) => {
    switch (step.step) {
        case 0:
            return <ContactForm />;
        case 1:
            return <ServiceForm />;
        case 2:
            return <PaymentForm />;
        default:
            return <></>;
    }
}

export interface Props {
    handleResponse?: (status: enuStatusPagoDocumento, detallePago: any) => void;

}
export default function Steppers({ handleResponse }: Props) {
    const classes = style();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [pagoStatus, setPagoStatus] = useState<enuStatusPagoDocumento>(enuStatusPagoDocumento.SinPagos);
    const [pagoMessage, setPagoMessage] = useState("");
    const [montoCambio, setmontoCambio] = useState(0);
    const [message, setMessage] = useState("");
    const [clienteLst, setclienteLst] = useState<clientes[]>([]);
    const [cliente, setCliente] = useState<clientes>();
    const [pagoActual, setpagoActual] = useState<facturas_mediopago>();

    const PedidoActual = useOrder();
    const loginToken = useLoginToken();
    const  textloading="Cargando..." ;
    /*
    const setPedidoActual = useSetOrder();
    const history = useHistory();
    const setting = useSetting();
    const setSetting = useSetSetting(); const stripe = useStripe();
    const elements = useElements(); */

    const [{ formValues }, dispatch] = useStateValue();

    const handleNext = () => {
        if (activeStep === 2) {
            //Pagos en linea
            //capture()
            if (Number(formValues['montocambio']) >= 0) {
                handleAddPago();
                MostrarPago();
            }
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            if (formValues.clientes && formValues.clientes.length) {
                setCliente(formValues.clientes[0]);
            }
        }
    };

    const handleAddPago = () => {
        if (!formValues['formaspago'])
            formValues['formaspago'] = [];
        let pa: facturas_mediopago = formValues['pagoactual'];
        formValues['formaspago'].push(
            {
                ID: undefined,
                Id_Factura: undefined,
                id_Moneda: pa.id_Moneda,
                Id_MedioPago: pa.Id_MedioPago,
                Id_TipoBanco: pa.Id_TipoBanco,
                nroItem: formValues['formaspago'].length + 1,
                Simbolo: pa.Simbolo,
                Responsable: pa.Responsable,
                DescripicionDocumento: pa.DescripicionDocumento,
                FechaActual: new Date,
                MontoTotal: pa.MontoTotal,
                MontoTasaMoneda: pa.MontoTasaMoneda,
                nroDocumento: pa.nroDocumento,
                nroRef: pa.nroRef,
                Comentario: pa.Comentario,
                Beneficiario: pa.Beneficiario,
                Email: pa.Email,
                CVC: pa.CVC,
                Imagen1: pa.Imagen1,
                Imagen2: pa.Imagen2,
                nroDiasCredito: pa.nroDiasCredito,
                nroTelefono: pa.nroTelefono,
                esConfirmado: 1,
                esConfirmadoBeneficiario: 1,
            }
        );
        let van: number = +formValues['montovan'];
        let actual: number = pa.MontoTotal ?? 0;
        let tasa: number = pa.MontoTasaMoneda ?? 1;

        dispatch({
            type: 'editFormValue',
            key: "montovan",
            value: van + (actual * tasa),
        });
        pa.Id_MedioPago = pa.Id_MedioPago;
        pa.DescripicionDocumento = pa.DescripicionDocumento;
        pa.MontoTotal = 0;
        pa.Id_TipoBanco = undefined;
        pa.id_Moneda = pa.id_Moneda;
        pa.Simbolo = pa.Simbolo;
        pa.Responsable = '';
        pa.nroItem = 0;
        pa.FechaActual = new Date();
        pa.MontoTotal = 0;
        pa.MontoTasaMoneda = pa.MontoTasaMoneda;
        pa.nroDocumento = '';
        pa.nroRef = '';
        pa.Comentario = '';
        pa.Beneficiario = '';
        pa.Email = '';
        pa.CVC = '';
        pa.Imagen1 = '';
        pa.Imagen2 = '';
        pa.nroTelefono = '';
        pa.esConfirmado = 0;
        pa.esConfirmadoBeneficiario = 0;
        dispatch({
            type: 'editFormValue',
            key: "pagoactual",
            value: pa,
        });
    }

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleReset = () => setActiveStep(0);
    const MostrarPago = () => {
        setLoading(true);
        if (formValues.clientes && formValues.clientes.length) {
            saveData(formValues.clientes[0]);
            PedidoActual.Id_Cliente = formValues.clientes[0].ID;
        }
        setPagoStatus(enuStatusPagoDocumento.PagadoTotalmente);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setPagoMessage("Pago Satisfactorio!");
        dispatch({ type: 'emptyFormValue' });
        setLoading(false);
    }
    const saveData = (cliente: clientes) => {
        try {
            if (cliente) {
                cliente.esActivo = 1;
                if (cliente.ID) {
                    axios.post(config.SERVER_URI + "/clientes/update", {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: { id: cliente.ID, ...cliente }
                    }).then(result => {
                        setMessage('ok--> Datos Actualizados.');
                        setCliente(cliente)
                    }).catch(err => {
                        if (err.response && err.response.status && err.response.status === 401)
                            setMessage('err--> Sin Autorización Para continuar.');
                        else
                            if (!err.response)
                                setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                            else
                                setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                    });
                } else {
                    cliente.FechaActual = new Date();
                    axios.post(config.SERVER_URI + "/clientes/create", {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: cliente
                    }).then((result: any) => {
                        setMessage('ok--> Datos Creados.');
                        setCliente(result.data)
                    }).catch(err => {
                        // setValue(new clientes);
                        if (err.response && err.response.status && err.response.status === 401) {
                            setMessage('err-->Sin Autorización Para continuar.');
                        }
                        else
                            if (!err.response)
                                setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                            else
                                setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                    });
                }
            }
        } catch (error: any) {
            setMessage('err-->' + error.stack);
        } finally {
        }

    };
    const capture = async () => {
        setLoading(true);
        setPagoStatus(enuStatusPagoDocumento.PagadoTotalmente);
        setPagoMessage("Imprimiendo Tickect. Por favor espere!");
        dispatch({ type: 'emptyFormValue' });
        setLoading(false);
    }
    useEffect(() => {
        if (formValues['montocambio'] === -0) {
            setmontoCambio(0);
            dispatch({
                type: 'editFormValue',
                key: 'montocambio',
                value: 0
            })
        }
    }, [formValues['montocambio']])
    useEffect(() => {
        if (clienteLst && cliente) {
            if (!clienteLst.length)
                clienteLst.push(cliente);
            else
                clienteLst[0] = cliente;
            dispatch({
                type: 'editFormValue',
                key: 'clientes',
                value: clienteLst
            })
        }
    }, [cliente])
    useEffect(() => {
        if (formValues) {
            let van: number = +formValues['montovan'];
            let pa: facturas_mediopago = formValues['pagoactual'];
            let actual: number = 0;
            let tasa: number = 1;
            if (pa) {
                actual = pa.MontoTotal ?? actual;
                tasa = pa.MontoTasaMoneda ?? tasa;
                setpagoActual(pa);
            }
            let cambio: number = Number(formatNumber(((van + (actual * tasa)) - (PedidoActual.MontoTotal ? +PedidoActual.MontoTotal : 0)) / tasa, 2));
            if (cambio === -0)
                cambio = 0;
            setmontoCambio(cambio);
            dispatch({
                type: 'editFormValue',
                key: "montocambio",
                value: cambio,
            });
        }
    }, [formValues['montovan'], formValues['pagoactual']])
    useEffect(() => {
        if (pagoActual) {
            dispatch({
                type: 'editFormValue',
                key: "pagoactual",
                value: pagoActual,
            });
        }
    }, [pagoActual])
    useEffect(() => {
        if (PedidoActual) {
            if (PedidoActual.MontoTotal) {
                dispatch({
                    type: 'editFormValue',
                    key: "montototal",
                    value: +PedidoActual.MontoTotal,
                });
            }
        }
        dispatch({
            type: 'editFormValue',
            key: "formaspago",
            value: [],
        });

        dispatch({
            type: 'editFormValue',
            key: "logintoken",
            value: loginToken,
        });
        dispatch({
            type: 'editFormValue',
            key: "montovan",
            value: 0,
        });
    }, [])
    return (
        <>
            < Stepper alternativeLabel className={classes.stepper} connector={< StepConnector />} activeStep={activeStep} >
                {/* Change the number of loops here based on StepContent */}
                {
                    [1, 2, 3].map(e =>
                        <Step key={e} >
                            <StepLabel StepIconComponent={StepperIcons} />
                        </Step>
                    )
                }
            </Stepper>
            < Box className={classes.mainBox + " m-0 p-1"} >
                {activeStep === 3
                    ? <Grid
                        container
                        spacing={3}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className="m-0 p-2 w-100"
                    >
                        <Grid
                            className="m-0 p-2 w-100"
                            direction='row'
                            justifyContent="center"
                            alignItems="center"
                            container
                            xs={12} sm={12}
                            style={{ background: 'lightblue', color: "darkred", overflow: 'scroll', maxHeight: 300, maxWidth: 500 }}
                            spacing={3}
                        >
                            <Item className="m-1 w-100">
                                <Grid
                                    className="m-0 p-0 w-100"
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={3}
                                    xs={12} sm={12}>
                                    < Button size='small' className="m-1" variant="outlined" onClick={e => { }} startIcon={<DesktopWindows />}   >
                                        Ver Recibo
                                    </Button>
                                    < Button size='small' className="m-1" variant="outlined" onClick={e => { }} startIcon={<AttachEmail />}  >
                                        Enviar Recibo por Email
                                    </Button>
                                </Grid>
                            </Item>

                            <Item className="m-1 w-100">
                                <Grid
                                    className="m-0 p-0  "
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={1}
                                    xs={12} sm={12}>
                                    <Grid
                                        className="m-0 p-0"
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12} sm={6}
                                    >
                                        <TextField
                                            label="Mensaje a Cocina"
                                            name="mensajecocina"
                                            variant='filled'
                                            inputProps={{ style: { fontSize: 12, fontWeight: "bolder" } }}
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            defaultValue={''}
                                            value={formValues.mensajecocina}
                                            onChange={e =>
                                                dispatch({
                                                    type: "editFormValue",
                                                    key: "mensajecocina",
                                                    value: e.target.value
                                                })
                                            }
                                        />
                                        < Button onClick={e => { }} size='small' className={'m-0 p-1'} style={{ minWidth: 40 }} >
                                            <Send />
                                        </Button>
                                    </Grid>
                                    <Grid
                                        className="m-0 p-0"
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12} sm={6}
                                    >
                                        <TextField
                                            label="Mensaje a Cliente"
                                            name="mensajecliente"
                                            variant='filled'
                                            inputProps={{ style: { fontSize: 12, fontWeight: "bolder" } }}
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            defaultValue={'Su orden ya está siendo atendida!'}
                                            value={formValues.mensajecliente}
                                            onChange={e =>
                                                dispatch({
                                                    type: "editFormValue",
                                                    key: "mensajecliente",
                                                    value: e.target.value
                                                })
                                            }
                                        />
                                        < Button onClick={e => { }} size='small' className={'m-0 p-1'} style={{ minWidth: 40 }} >
                                            <Send />
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Item>
                        </Grid>
                        <Box className="m-1 w-100 text-center">
                            {
                                pagoStatus === enuStatusPagoDocumento.PagadoTotalmente
                                    ? <SentimentVerySatisfied fontSize="large" color="primary" />
                                    : <SentimentVeryDissatisfied fontSize="large" color="error" />
                            }
                            < Button onClick={e => {
                                if (handleResponse)
                                    handleResponse(pagoStatus, formValues);
                            }} variant="contained" className={classes.button} startIcon={<Cancel />} >
                                Cerrar
                            </Button>
                        </Box>
                        <Item className="m-1 w-100" color='dark'>
                            <Alert severity='success'>{pagoMessage}</Alert>
                        </Item>
                    </Grid>
                    : <form autoComplete="off" className={classes.form} onSubmit={e => { e.preventDefault(); handleNext() }}>
                        <Grid className="m-0 p-0 w-100" container spacing={2}   >
                            <StepContent step={activeStep} />
                            {
                                activeStep === 2 ?
                                    montoCambio > 0
                                        ? <Grid className="p-2" item xs={12} sm={7} style={{ background: `rgb(${ColorsDPana.accent[0]},${ColorsDPana.accent[1]},${ColorsDPana.accent[2]})`, color: "darkred" }} >
                                            <Typography variant='caption' >
                                                {`Su Cambio:`}
                                            </Typography>
                                            <Typography variant="h4" style={{ fontWeight: "bolder", fontFamily: "monospace", textAlign: "right", color: "darkred" }} >
                                                {`${formValues['simbolodivisa']} ${formatNumber(+montoCambio, 2)} `}
                                            </Typography>
                                        </Grid>
                                        : <Grid className="p-0" container item direction="row" justifyContent="flex-start" alignItems="center" xs={12} sm={7} style={{ color: "darkred" }} >
                                            <Grid className="p-2"
                                                container
                                                item
                                                xs={12} sm={4}
                                                justifyContent="flex-end"
                                                style={{ cursor: 'pointer', background: `rgb(${ColorsDPana.accent[0]},${ColorsDPana.accent[1]},${ColorsDPana.accent[2]})`, color: "darkred" }}
                                                onClick={e => {
                                                    if (pagoActual) {
                                                        setpagoActual({ ...pagoActual, MontoTotal: montoCambio < 0 ? Number(formatNumber(montoCambio, 2)) * -1 : 0 });
                                                    }
                                                }}
                                            >
                                                <Typography variant="caption" className="p-0" style={{ fontWeight: "bolder", fontFamily: "monospace", textAlign: "right", color: "darkred" }} >
                                                    {`Faltan ${pagoActual && pagoActual.DescripicionDocumento}:`}
                                                </Typography>
                                                <Typography variant="h6" className="p-0" style={{ fontWeight: "bolder", fontFamily: "monospace", textAlign: "right", color: "darkred" }} >
                                                    {formatNumber(+montoCambio, 2)}
                                                </Typography>
                                            </Grid>
                                            {(montoCambio !== -0 && montoCambio < 0) && (pagoActual && pagoActual.MontoTotal && pagoActual.MontoTotal > 0)
                                                ? <Grid className="px-2" item xs={12} sm={4} style={{ color: "darkred" }} >
                                                    <Button
                                                        type="submit"
                                                        sx={{ background: `rgba(${ColorsDPana.primary[0]},${ColorsDPana.primary[1]},${ColorsDPana.primary[2]},0.5)`, color: "darkred" }}
                                                        onClick={e => { e.preventDefault(); handleAddPago(); }}>
                                                        <Typography variant='caption' >
                                                            {"Agregar Pago"}
                                                        </Typography>  <ControlPoint />
                                                    </Button>
                                                </Grid>
                                                : ""}
                                        </Grid>
                                    : ""
                            }
                            < Grid className="p-2" container item xs={12} sm={activeStep === 2 ? 5 : 12} justifyContent='flex-end' >
                                <Button disabled={activeStep === 0} className={classes.button} onClick={handleBack} >
                                    Atrás
                                </Button>
                                < Button variant="contained" color="primary" className={classes.button} type="submit" disabled={activeStep === 2 ? loading || montoCambio < 0 : false} >
                                    {
                                        loading
                                            ? <CircularProgress size={24} />
                                            : activeStep === 2 ? 'Pagar' : 'Siguiente'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box >
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Message:" message={message} setMessage={setMessage} />
        </>
    );
}

