import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { pedidos } from "../../../models/pedidos";
import { enutipoItemMenu } from '../index';
import { useState } from 'react';
function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}
const getUTCDate = (date: string) => {
    const d = new Date(date);
    const utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    return utcDate;
}
interface Props {
    children?: React.ReactNode;
    orderList?: pedidos[];
    fechaConsulta?: Date;
    titulo?: string;
    totalVentas?: string;
    totalFacts?: number;
    tipoSelect?: enutipoItemMenu;
    handleClickBuscarFecha?: (tipoSelect?: enutipoItemMenu, fecha1?: Date, fecha2?: Date) => void;
    handleClickBuscarProductos?: (id1?: number, id2?: number) => void;
}
export default function CajaRange(props: Props) {
    const { orderList, fechaConsulta, ...other } = props;
    const [date1, setdate1] = useState<Date>();
    const [date2, setdate2] = useState<Date>();

    const dateNow = fechaConsulta ? fechaConsulta : new Date();
    const year = dateNow.getFullYear();
    const monthWithOffset = dateNow.getUTCMonth() + 1;
    const month =
        monthWithOffset.toString().length < 2
            ? `0${monthWithOffset}`
            : monthWithOffset;
    const date =
        dateNow.getUTCDate().toString().length < 2
            ? `0${dateNow.getUTCDate()}`
            : dateNow.getUTCDate();
    const materialDateInput = `${year}-${month}-${date}`;


    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dd = getUTCDate(event.target.value);
        setdate1(dd);
    };

    const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dd = getUTCDate(event.target.value);
        setdate2(dd);
    };
    React.useEffect(() => {
        setdate1(fechaConsulta ? fechaConsulta : new Date());
        setdate2(fechaConsulta ? fechaConsulta : new Date());
    }, [])
    return (
        <React.Fragment>

            <Grid
                container
                className="m-0 p-0 h-100 bg-transparent"
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    className="m-0 p-0 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                        Total Ventas
                    </Typography>
                    <Typography className="font-monospace p-0 fs-2" component="p" variant="h4">
                        {props.totalVentas ? props.totalVentas : '0,00'}
                    </Typography>

                </Grid>
                <Grid
                    className="my-0 p-1 bg-transparent"
                    item
                    container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <TextField
                        id="date1"
                        label="Dia Inicial"
                        className=" p-0 w-100 alert-warning"
                        sx={{ mb: 1 }}
                        type="date"
                        size='small'
                        variant='outlined'
                        defaultValue={materialDateInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange1}
                    />
                    {props.tipoSelect !== enutipoItemMenu.ventaDiaria
                        ? <TextField
                            id="date2"
                            label="Dia Final"
                            className=" p-0 w-100   alert-warning"
                            type="date"
                            size='small'
                            variant='outlined'
                            defaultValue={materialDateInput}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange2}
                        />
                        : ''
                    }

                    <Button className=" bg-dark text-white  w-100" size='small' sx={{ mt: 1 }} variant="contained" endIcon={<Search />}
                        onClick={() => {
                            if (props.handleClickBuscarFecha) {
                                let d = props.tipoSelect === enutipoItemMenu.ventaDiaria ? undefined : date2;
                                props.handleClickBuscarFecha(props.tipoSelect, date1, d);
                            }
                        }}>
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}