
import type { facturas } from './facturas';
import type { tipodescuentos } from './tipodescuentos';
import type { usuarios } from './usuarios';

export interface facturas_descuentosAttributes {
  ID: number;
  Id_Descuento?: number;
  Id_Factura?: number;
  id_UsuarioAutoriza?: number;
  ValorDescuento?: number;
  Comentario?: string;
}

export type facturas_descuentosPk = "ID";

export class facturas_descuentos implements facturas_descuentosAttributes {
  ID!: number;
  Id_Descuento?: number;
  Id_Factura?: number;
  id_UsuarioAutoriza?: number;
  ValorDescuento?: number;
  Comentario?: string;

  // facturas_descuentos belongsTo facturas via Id_Factura
  Id_Factura_factura!: facturas;
  // facturas_descuentos belongsTo tipodescuentos via Id_Descuento
  Id_Descuento_tipodescuento!: tipodescuentos;
  // facturas_descuentos belongsTo usuarios via id_UsuarioAutoriza
  id_UsuarioAutoriza_usuario!: usuarios;

}
