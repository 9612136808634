
const configs = {
    development: {
        //SERVIDORES
        SERVER_URI: 'https://server.dpana.com.ve/api',
        //SERVER_URI: 'http://localhost:8001/api',
        //CLOUDINARY
        CLOUDINARY_UPLOAD_PRESET: "restaurantedubai_notag",
        CLOUDINARY_UPLOAD_NAME: "kalixto-systems-solutions",
        CLOUDINARY_UPLOAD_TAGS: "hotel dubai, restaurante dubai, dpana,testdpana,comida,pollos,camperos,restaurante,yaguara,caracas,",
        //THEMES
        primary: [255, 157, 4],
        secundary: [254, 105, 1],
        accent: [255, 0, 0],
        textcolor: [139, 15, 27],
        textaccentcolor: [255, 85, 0],
        //LINKS
        gotoadmin: 'https://admin.dpana.com.ve',
        gotodpana: 'https://www.dpana.com.ve',
        gotowhatsappmsg: 'https://wa.me/584143662876?text=Buen%20dia%20estoy%20interesado%20en%20sus%20servicios%3F',
        gotowhatsappshare: 'https://wa.me/?text=I%20found%20a%20great%20website.%20Check%20out%20this%20link%20https%3A%2F%2Fwww.test.dpana.com.ve%2F',
        nombreEmpresa: "La Romanina, C.A.",
        nombreEmpresa2: "La Castellana - Restaurant"
    },
    production: {
        //SERVIDORES
        SERVER_URI: 'https://server.dpana.com.ve/api',
        //CLOUDINARY
        CLOUDINARY_UPLOAD_PRESET: "restaurantedubai_notag",
        CLOUDINARY_UPLOAD_NAME: "kalixto-systems-solutions",
        CLOUDINARY_UPLOAD_TAGS: "hotel dubai, restaurante dubai, dpana,testdpana,comida,pollos,camperos,restaurante,yaguara,caracas,",
        //THEMES
        primary: [255, 157, 4],
        secundary: [254, 105, 1],
        accent: [255, 0, 0],
        textcolor: [139, 15, 27],
        textaccentcolor: [255, 85, 0],
        /* primary: [207, 10, 10],
        secundary: [87, 4, 4],
        accent: [224, 152, 16],
        textcolor: [255, 255, 255],
        textaccentcolor: [207, 10, 10], */
        //LINKS
        gotoadmin: 'https://admin.dpana.com.ve',
        gotodpana: 'https://www.dpana.com.ve',
        gotowhatsappmsg: 'https://wa.me/584143662876?text=Buen%20dia%20estoy%20interesado%20en%20sus%20servicios%3F',
        gotowhatsappshare: 'https://wa.me/?text=I%20found%20a%20great%20website.%20Check%20out%20this%20link%20https%3A%2F%2Fwww.test.dpana.com.ve%2F',
        nombreEmpresa: "La Romanina, C.A.",
        nombreEmpresa2: "La Castellana - Restaurant"
    },
};
module.exports.config = configs[process.env.NODE_ENV];