// import "react-simple-keyboard/build/css/index.css";

import { DialogResponse } from "../../utils/models";
import LoginPanel from "../../components/login-panel";
import { Container, Box, Theme } from "@mui/material";
import { makeStyles, } from '@mui/styles';
export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    dialogResponse?: DialogResponse;
    handleResponse?: any;
    titulo?: string;
    esPIN?: boolean | undefined;
    actionMethod?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
    boxWrapper: {
        minHeight: "calc(20vh + 150px)"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    }
}));
export default function LoginDialog({ actionMethod, esPIN, titulo, handleResponse, ...restOfProps }: Props) {
    const classes = useStyles();
   /*  const [inputs, setInputs] = useState<any>({});
    const [layoutName, setLayoutName] = useState("default");
    const [inputName, setInputName] = useState("default");
    const keyboard = useRef<any>(); */

    const onLogin = (login: boolean) => {
        if (handleResponse)
            handleResponse(login, actionMethod ? login ? actionMethod() : undefined : undefined);
    }

    return (
        <Box component="main" className={classes.boxWrapper + ' my-2 p-0'}>
            <Container maxWidth="md" className={classes.container + ' m-0 p-0'}>
                <LoginPanel esDialog onLogin={onLogin} esPIN={esPIN} header={titulo} />
            </Container>
        </Box>

    )
}
/*    const onChangeAll = (inputs: SetStateAction<{}>) => {

        setInputs({ ...inputs });
        console.log("Inputs changed", inputs);
    };

    const handleShift = () => {
        const newLayoutName = layoutName === "default" ? "shift" : "default";
        setLayoutName(newLayoutName);
    };

    const onKeyPress = (button: string) => {
        console.log("Button pressed", button);

        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;

        setInputs({
            ...inputs,
            [inputName]: inputVal
        });
        if (keyboard.current)
            keyboard.current.setInput(inputVal);
    };

    const getInputValue = (inputName: any) => {
        return inputs[inputName] || "";
};
<Keyboard
                keyboardRef={(r: any) => (keyboard.current = r)}
                layoutName={layoutName}
                onChangeAll={onChangeAll}
                onKeyPress={onKeyPress}
                disableCaretPositioning={true}
                layout={{
                    default: ["1 2 3", "4 5 6", "7 8 9", "{//} 0 {//}", "{bksp}"],
                    shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
                }}

            />
let keyboard = new Keyboard({
    onChange: input => onChange(input),
    onKeyPress: button => onKeyPress(button),
    layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
        shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
    },
    theme: "hg-theme-default hg-layout-numeric numeric-theme"
});

let keyboard = new Keyboard({
    onChange: input => onChange(input),
    onKeyPress: button => onKeyPress(button),
    mergeDisplay: true,
    layoutName: "default",
    layout: {
        default: [
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{shift} z x c v b n m {backspace}",
            "{numbers} {space} {ent}"
        ],
        shift: [
            "Q W E R T Y U I O P",
            "A S D F G H J K L",
            "{shift} Z X C V B N M {backspace}",
            "{numbers} {space} {ent}"
        ],
        numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"]
    },
    display: {
        "{numbers}": "123",
        "{ent}": "return",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "⇧",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC"
    }
});
 */
/**
 * Update simple-keyboard when input is changed directly
 */
/* document.querySelector(".input").addEventListener("input", event => {
    keyboard.setInput(event.target.value);
}); */

/* console.log(keyboard);

function onChange(input) {
    document.querySelector(".input").value = input;
    console.log("Input changed", input);
}

function onKeyPress(button) {
    console.log("Button pressed", button);


    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{numbers}" || button === "{abc}") handleNumbers();
}

function handleShift() {
    let currentLayout = keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    keyboard.setOptions({
        layoutName: shiftToggle
    });
}

function handleNumbers() {
    let currentLayout = keyboard.options.layoutName;
    let numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";

    keyboard.setOptions({
        layoutName: numbersToggle
    });
}*/
/* class App extends Component {
    state = {
        layoutName: "default",
        input: ""
    };

    onChange = input => {
        this.setState({
            input: input
        });
        console.log("Input changed", input);
    };

    onKeyPress = button => {
        console.log("Button pressed", button);

        if (button === "{shift}" || button === "{lock}") this.handleShift();
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    onChangeInput = event => {
        let input = event.target.value;
        this.setState(
            {
                input: input
            },
            () => {
                this.keyboard.setInput(input);
            }
        );
    };

    render() {
        return (
            <div>
                <input
                    value={this.state.input}
                    placeholder={"Tap on the virtual keyboard to start"}
                    onChange={e => this.onChangeInput(e)}
                />
                <Keyboard
                    keyboardRef={r => (this.keyboard = r)}
                    onChange={input => this.onChange(input)}
                    onKeyPress={button => this.onKeyPress(button)}
                    theme={"hg-theme-default hg-layout-default myTheme"}
                    layoutName={this.state.layoutName}
                    layout={{
                        default: [
                            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                            "{tab} q w e r t y u i o p [ ] \\",
                            "{lock} a s d f g h j k l ; ' {enter}",
                            "{shift} z x c v b n m , . / {shift}",
                            ".com @ {space}"
                        ],
                        shift: [
                            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                            "{tab} Q W E R T Y U I O P { } |",
                            '{lock} A S D F G H J K L : " {enter}',
                            "{shift} Z X C V B N M < > ? {shift}",
                            ".com @ {space}"
                        ]
                    }}
                    buttonTheme={[
                        {
                            class: "hg-red",
                            buttons: "Q W E R T Y q w e r t y"
                        },
                        {
                            class: "hg-highlight",
                            buttons: "Q q"
                        }
                    ]}
                />
            </div>
        );
    }
}

render(<App />, document.getElementById("root"));
 */

//Ocultar y mostrar Teclado
/* class App extends Component {
    state = {
        layoutName: "ip",
        inputName: "input1",
        input: {},
        // Just for demo purposes
        submittedData: ""
    };

    onChangeAll = inputObj => {
        this.setState({
            input: inputObj
        });

        console.log("Input changed", inputObj);
    };

    onKeyPress = button => {
        console.log("Button pressed", button);

        if (button === "{shift}" || button === "{lock}") this.handleShift();

        if (button === "{clear}") this.clearScreen();
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    onChangeInput = event => {
        let inputVal = event.target.value;

        let updatedInputObj = {
            ...this.state.input,
            [this.state.inputName]: inputVal
        };

        this.setState(
            {
                input: updatedInputObj
            },
            () => {
                this.keyboard.setInput(inputVal);
            }
        );
    };

    setActiveInput = inputName => {
        this.setState(
            {
                inputName: inputName,
                keyboardOpen: true
            },
            () => {
                console.log("Active input", inputName);
            }
        );
    };

    closeKeyboard = () => {
        this.setState({
            keyboardOpen: false
        });
    };

    submit = () => {
        this.setState({
            submittedData: JSON.stringify(this.state.input)
        });
        console.log(this.state.input);
    };

    clearScreen = () => {
        let input = { ...this.state.input };
        let inputName = this.state.inputName;
        input[inputName] = "";

        this.setState({ input }, () => {
            this.keyboard.clearInput(inputName);
            console.log(
                "cleared",
                input,
                this.keyboard.options.inputName,
                this.keyboard.input,
                this.keyboard.getInput()
            );
        });
    };

    render() {
        let { input, keyboardOpen, submittedData } = this.state;

        return (
            <div>
                <h3>Tap on an input to reveal the keyboard</h3>
                <div className="inputsContainer">
                    <input
                        onFocus={() => this.setActiveInput("input1")}
                        value={input["input1"] || ""}
                        placeholder={"Input 1"}
                        onChange={e => this.onChangeInput(e)}
                    />
                    <input
                        onFocus={() => this.setActiveInput("input2")}
                        value={input["input2"] || ""}
                        placeholder={"Input 2"}
                        onChange={e => this.onChangeInput(e)}
                    />
                    <input
                        onFocus={() => this.setActiveInput("input3")}
                        value={input["input3"] || ""}
                        placeholder={"Input 3"}
                        onChange={e => this.onChangeInput(e)}
                    />
                    <input
                        onFocus={() => this.setActiveInput("input4")}
                        value={input["input4"] || ""}
                        placeholder={"Input 4"}
                        onChange={e => this.onChangeInput(e)}
                    />
                    <input
                        onFocus={() => this.setActiveInput("input5")}
                        value={input["input5"] || ""}
                        placeholder={"Input 5"}
                        onChange={e => this.onChangeInput(e)}
                    />
                    <input
                        onFocus={() => this.setActiveInput("input6")}
                        value={input["input6"] || ""}
                        placeholder={"Input 6"}
                        onChange={e => this.onChangeInput(e)}
                    />
                </div>
                <div className={`keyboardContainer ${!keyboardOpen ? "hidden" : ""}`}>
                    <Keyboard
                        keyboardRef={r => (this.keyboard = r)}
                        inputName={this.state.inputName}
                        layoutName={this.state.layoutName}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                        layout={{
                            ip: ["1 2 3", "4 5 6", "7 8 9", ". 0 {clear}", "{bksp} {enter}"]
                        }}
                        display={{
                            "{clear}": "C",
                            "{bksp}": "backspace",
                            "{enter}": "enter"
                        }}
                    />
                    <button className="submitBtn" onClick={this.submit}>
                        Submit
                    </button>
                    <button className="closeBtn" onClick={this.closeKeyboard}>
                        Close Keyboard
                    </button>
                </div>
                {submittedData &&
                    <div className={"submittedData"}><h4>Submitted Data:</h4><div className="data">{submittedData}</div></div>
                }
            </div>
        );
    }
} */
