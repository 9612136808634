
import { useEffect, useState } from "react";
import { Box, Theme, Grid, Typography, FormControl, FormControlLabel, Paper, TextField, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, createFilterOptions, InputLabel, MenuItem, OutlinedInput, Select, Checkbox } from "@mui/material";
import { makeStyles, styled, } from '@mui/styles';
import { AddBox, LocalOffer } from "@mui/icons-material";
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { productos, productosAttributes } from "../../../../models/productos";
import { loadArchivo } from "../../../../utils/services";
import { useLoginToken } from "../../../../contexts/login-context";
import axios from "axios";
import { config } from "../../../../utils/configs";
import { comboboxData } from "../../../../models/Entities/controlsEntity";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}
interface productostmp extends productosAttributes {
    inputValue?: string
}
const style = {
    color: 'rgb(255,255,255)',
    '&.Mui-checked': {
        color: '#fff',
    },

};
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'red',
    },
    '& label:hover': {
        color: 'gold',
    },
    '& label': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
        color: 'white'
    },
    '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'red',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'red',
        },
    },
});
const styledark = {
    color: '#000',
    '&.Mui-checked': {
        color: '#000',
    },

};
const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
const filter = createFilterOptions<productostmp>();

export default function Modificarproductos(props: Props) {
    /* const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder(); */
    const loginToken = useLoginToken(); 

    const [open, toggleOpen] = useState(false);
    const [productosList, setproductosList] = useState<productostmp[]>([]);
    const [value, setValue] = useState<productostmp | null>(null);
    const [dialogValue, setDialogValue] = useState<productos>(new productos);

    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [impuestos, setImpuestos] = useState<comboboxData[]>([]);
    const [tipoProducto, setTiposProducto] = useState<comboboxData[]>([]);
    const [subCategorias, setsubCategorias] = useState<comboboxData[]>([]);
    const [sectoresImpresion, setsectoresImpresion] = useState<comboboxData[]>([]);
    const textloading='Cargando ...' ;
    const handleChange =
        (prop: keyof productostmp) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (value)
                setValue({ ...value, [prop]: event.target.value });
        };

    const handleChangeDlg =
        (prop: keyof productos) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (dialogValue)
                setDialogValue({ ...dialogValue, [prop]: event.target.value });
        };
    const validaGuardar = (value: productos | productostmp): boolean => {
        if (value) {
            if (!value.MontoPrecio1) {
                setMessage('err--> Indique Monto Precio');
                return false;
            }
            if (!value.Nombre) {
                setMessage('err--> Indique Descripción');
                return false;
            }
            if (!value.DescripcionWeb) {
                setMessage('err--> Indique Descripción Larga');
                return false;
            }
            if (!value.MontoPrecioDivisa) {
                setMessage('err--> Indique Descripción');
                return false;
            }

            return true;
        } else return false
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (validaGuardar(dialogValue)) {
            setValue(dialogValue);
            saveData(dialogValue);
            handleClose();
        }
    };
    const handleOpen = () => {
        toggleOpen(true);
    };
    const handleClose = () => {
        toggleOpen(false);
    };
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>
            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const childDialogRes = async (e: any) => {

    }

    /* const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    }; */
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    /* const dialogResponse = (e: any) => {
        setOpenSDialog(false);
    } */
    //#endregion

    let savingData: boolean = false;
    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setloading(saving);
    }
    const loadArchivos = () => {
        setSavingData(true);
        loadArchivo(
            'productos',
            false,
            ['id_tipo_producto',
                'id_sector_impresion',
                'id_sub_categoria',
                'id_impuesto1',
                'Nombre',
                'CodigoBarras',
                'MontoPrecio1',
                'MontoPrecio2',
                'MontoPrecio3',
                'MontoPrecioOferta',
                'MontoPrecioDivisa',
                'PuntosCantidad',
                'PuntosRating',
                'esPideprecio',
                'esActivo',
                'FechaActual',
                'FechaModificado',
                'ImagenWeb',
                'DescripcionWeb',
                'id_TipoMoneda'], undefined, undefined, ['Nombre'], { esActivo: 1 }
        ).then(
            (resolve) => {
                let res: productos[] = resolve as productos[];
                if (res && res.length) {
                    if (value === null)
                        setValue(res[0])
                    setproductosList(res);
                }
            },
            (error) => { setMessage('err-->' + error) }
        ).finally(() => setSavingData(false));

        loadArchivo('tipoimpuestos', false, ['Nomenclatura', 'MontoTasa'], ['Nomenclatura', 'MontoTasa']).then(
            (resolve) => { setImpuestos(resolve as comboboxData[]) },
            (error) => { setMessage('err-->' + error) }
        );
        loadArchivo('sectores_impresion', true, ['Nombre']).then(
            (resolve) => { setsectoresImpresion(resolve as comboboxData[]) },
            (error) => { setMessage('err-->' + error) }
        );
        loadArchivo('subcategorias_productos', true, ['Nombre']).then(
            (resolve) => { setsubCategorias(resolve as comboboxData[]) },
            (error) => { setMessage('err-->' + error) }
        );
        loadArchivo('tipoproductos').then(
            (resolve) => { setTiposProducto(resolve as comboboxData[]) },
            (error) => { setMessage('err-->' + error) }
        );
    }

    const saveData = (value: productos | productostmp) => {
        if (!savingData) {
            setSavingData(true, "Guardando datos...");
            try {
                if (value) {
                    if (validaGuardar(value)) {
                        value.esActivo = 1;
                        value.FechaModificado = new Date();
                        if (value.ID) {
                            axios.post(config.SERVER_URI + "/productos/update", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: { id: value.ID, ...value }
                            }).then(result => {
                                setMessage('ok--> Datos Actualizados.');
                                loadArchivos();
                                setValue(value)
                            }).catch(err => {
                                if (err.response && err.response.status && err.response.status === 401)
                                    setMessage('err-->Sin Autorización Para continuar.');
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        } else {
                            value.FechaActual = new Date();
                            axios.post(config.SERVER_URI + "/productos/create", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: value
                            }).then((result: any) => {
                                setMessage('ok--> Datos Creados.');
                                loadArchivos();
                                setValue(result.data)
                            }).catch(err => {
                                setValue(null);
                                if (err.response && err.response.status && err.response.status === 401) {
                                    setMessage('err-->Sin Autorización Para continuar.');
                                }
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        }
                    }
                }
            } catch (error) {

            } finally {
                setSavingData(false);
                setDialogValue(new productos);
            }
        } else {
            setMessage('inf--> Trabajando... Por favor espere!')
        }
    };
    const newProducto = (newValue: string | undefined) => {
        let dv: productos = new productos;
        dv.Nombre = newValue ? newValue.replace('add ', '').replaceAll('"', '') : '';
        dv.MontoPrecio1 = 0;
        dv.MontoPrecioDivisa = 0;
        dv.id_impuesto1 = 2;
        dv.DescripcionWeb = dv.Nombre;
        dv.esActivo = 1;
        dv.esPideprecio = 0;
        dv.id_TipoMoneda = 1;
        dv.id_sector_impresion = 1;
        dv.id_sub_categoria = 1;
        dv.id_tipo_producto = 1;
        setDialogValue(dv);
        toggleOpen(true);
    }
    useEffect(() => {

        if (!open)
            setDialogValue(new productos);
        else {

        }
    }, [open])
    useEffect(() => {
        loadArchivos()
    }, [])
    return (
        <>
            <Titulo />
            <FormControl className="w-100" component="fieldset">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="m-0 p-2 w-100"
                >
                    <Grid className="w-100 p-2 m-0 " item xs={12} sm={12}>
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            value={value}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    newProducto(newValue);
                                } else if (newValue && newValue.inputValue) {
                                    newProducto(newValue.Nombre);
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.Nombre);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        ID: 0,
                                        inputValue: inputValue,
                                        Nombre: `add "${inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="firstname"
                            options={productosList ? productosList : []}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.Nombre ? option.Nombre : '';
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li" className="p-0 m-0 " {...props}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="m-0 p-2 w-100"
                                    >
                                        <Grid className=" w-100 p-0 m-0 " item xs={12} sm={12}>
                                            {option.Nombre && option.Nombre.toLowerCase().includes('add')
                                                ?
                                                <Paper elevation={2} className="p-1 m-0 bg-warning w-100" >
                                                    <AddBox className="text-black pr-1 fs-2" />
                                                    <b className="px-1">{option.Nombre.replace('add', '')}</b>
                                                </Paper>
                                                : option.Nombre
                                            }
                                        </Grid>
                                    </Grid>

                                </Box>
                            )}
                            freeSolo
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    className="  text-white   rounded-3 "
                                    label="Buscar por Nombre"
                                    name="firstname"
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={value?.Nombre}
                                />
                            )}
                        />
                    </Grid>
                    {value
                        ? <>
                            <Grid className="p-1 m-0" item xs={12} sm={6}>
                                <FormControl className=" p-0 m-0 w-100">
                                    <InputLabel className="rounded-3 px-1 alert-danger fs-5 fw-bold" id="cats">SubCategoria</InputLabel>
                                    <Select
                                        labelId="cats"
                                        id="cats"
                                        size='small'
                                        required
                                        value={value?.id_sub_categoria}
                                        variant='filled'
                                        inputProps={{
                                            className: "bg-body rounded-3"
                                        }}
                                        onChange={(event) =>
                                            setValue({
                                                ...value,
                                                id_sub_categoria: Number(event.target.value),
                                            })}
                                        input={
                                            <OutlinedInput className="alert-danger" label="SubCategorias" />}
                                    >
                                        {
                                            subCategorias.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid className="p-1 m-0" item xs={12} sm={6}>
                                <FormControl className=" p-0 m-0 w-100">
                                    <InputLabel className="rounded-3 px-1 alert-danger fs-5 fw-bold" id="tipo">Tipo</InputLabel>
                                    <Select
                                        labelId="tipo"
                                        id="tipo"
                                        size='small'
                                        required
                                        value={value?.id_tipo_producto}
                                        variant='filled'
                                        inputProps={{
                                            className: "bg-body rounded-3"
                                        }}
                                        onChange={(event) =>
                                            setValue({
                                                ...value,
                                                id_tipo_producto: Number(event.target.value),
                                            })}

                                        input={<OutlinedInput label="Tipo" />}
                                    >
                                        {
                                            tipoProducto.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                                <TextField
                                    label="Nombre"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value?.Nombre}
                                    onChange={handleChange('Nombre')}
                                />
                            </Grid>
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                                <TextField
                                    label="Descripción"
                                    name="descr"
                                    variant='filled'
                                    className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value?.DescripcionWeb}
                                    onChange={handleChange('DescripcionWeb')}
                                />
                            </Grid>
                            <Grid className="p-1 pt-2 m-0" item xs={12} sm={6}>
                                <FormControl className=" p-0 m-0 w-100">
                                    <InputLabel className="rounded-3 px-1 alert-danger fs-5 fw-bold" id="imprimiren">Imprimir en</InputLabel>
                                    <Select
                                        labelId="imprimiren"
                                        id="imp"
                                        size='small'
                                        required
                                        value={value?.id_impuesto1}
                                        variant='filled'
                                        inputProps={{
                                            className: "bg-body rounded-3"
                                        }}
                                        onChange={(event) =>
                                            setValue({
                                                ...value,
                                                id_impuesto1: Number(event.target.value),
                                            })}
                                        input={<OutlinedInput label="Imprimir en" />}
                                    >
                                        {
                                            sectoresImpresion.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid className="p-1 pt-2 m-0" item xs={12} sm={6}>
                                <FormControl className=" p-0 m-0 w-100">
                                    <InputLabel className="rounded-3 px-1 alert-danger fs-5 fw-bold" id="mesero-label">Impuesto</InputLabel>
                                    <Select
                                        labelId="mesero-label"
                                        id="nivels"
                                        size='small'
                                        required
                                        value={value?.id_impuesto1}
                                        variant='filled'
                                        inputProps={{
                                            className: "bg-body rounded-3"
                                        }}
                                        onChange={(event) =>
                                            setValue({
                                                ...value,
                                                id_impuesto1: Number(event.target.value),
                                            })}
                                        input={<OutlinedInput label="Nivel" />}
                                    >
                                        {
                                            impuestos.map((e, index) => {
                                                return (
                                                    <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                className=" p-1 m-0 w-100" xs={12} sm={12}
                                item
                                container
                                direction='row'
                                justifyContent="space-between"
                                alignItems="flex-start" >
                                <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={6}>
                                    <TextField
                                        label="Precio"
                                        name="tasa"
                                        variant='filled'
                                        className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: "text-end "
                                        }}
                                        fullWidth
                                        size='small'
                                        value={value?.MontoPrecio1}
                                        type='number'
                                        onChange={handleChange('MontoPrecio1')}
                                    />
                                </Grid>
                                <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={6}>
                                    <TextField
                                        label="Precio Divisa"
                                        name="tasa"
                                        variant='filled'
                                        className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            className: "text-end "
                                        }}
                                        fullWidth
                                        size='small'
                                        value={value?.MontoPrecioDivisa}
                                        type='number'
                                        onChange={handleChange('MontoPrecioDivisa')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                className=" p-1 mt-1 w-100 text-white border rounded-3"
                                xs={12} sm={12}
                                item
                                container
                                direction='row'
                                justifyContent="space-between"
                                alignItems="flex-start" >
                                <FormControlLabel
                                    className="text-white p-0 m-0"
                                    checked={value.esFavorito ? value.esFavorito ? true : false : false}
                                    control={<Checkbox />}
                                    onChange={(event: any) =>
                                        setValue({
                                            ...value,
                                            esFavorito: Number(event.target.checked),
                                        })}
                                    label="Favorito"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    className="text-white p-0 m-0"
                                    checked={value.esPideprecio ? value.esPideprecio ? true : false : false}
                                    control={<Checkbox />}
                                    onChange={(event: any) =>
                                        setValue({
                                            ...value,
                                            esPideprecio: Number(event.target.checked),
                                        })}
                                    label="Pide Precio"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    className="text-white p-0 m-0"
                                    checked={value.esActivo ? value.esActivo ? true : false : false}
                                    control={<Checkbox />}
                                    onChange={(event: any) =>
                                        setValue({
                                            ...value,
                                            esActivo: Number(event.target.checked),
                                        })}
                                    label="Activo"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid direction='row' className="text-end p-1 m-0 w-100" item xs={12} sm={12}>
                                <Button
                                    size='small'
                                    className="m-md-1  m-auto col-4 bg-black bg-gradient text-white "
                                    onClick={() => {
                                        if (value)
                                            saveData(value);
                                    }}>
                                    Guardar
                                </Button>
                            </Grid>
                        </>
                        : ''

                    }

                </Grid>
            </FormControl>

            <Dialog className="p-1 vw-25" open={open} onClose={handleClose}>
                <DialogTitle className=" text-white bg-dark rounded round-3"><LocalOffer /> Agregar Descuento/Recarga</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className="m-0 p-1 w-100"
                    >
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel className="rounded-3 px-1 alert-warning fs-5 fw-bold" id="cats">SubCategoria</InputLabel>
                                <Select
                                    labelId="cats"
                                    id="cats"
                                    size='small'
                                    required
                                    value={dialogValue?.id_sub_categoria}
                                    variant='filled'
                                    inputProps={{
                                        className: "bg-body rounded-3"
                                    }}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_sub_categoria: Number(event.target.value),
                                        })}
                                    input={
                                        <OutlinedInput label="SubCategorias" />}
                                >
                                    {
                                        subCategorias.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className="p-1 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel className="rounded-3 px-1 alert-warning fs-5 fw-bold" id="tipo">Tipo</InputLabel>
                                <Select
                                    labelId="tipo"
                                    id="tipo"
                                    size='small'
                                    required
                                    value={dialogValue?.id_tipo_producto}
                                    variant='filled'
                                    inputProps={{
                                        className: "bg-body rounded-3"
                                    }}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_tipo_producto: Number(event.target.value),
                                        })}

                                    input={<OutlinedInput label="Tipo" />}
                                >
                                    {
                                        tipoProducto.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                            <TextField
                                label="Nombre"
                                name="tasa"
                                variant='filled'
                                className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                size='small'
                                value={dialogValue?.Nombre?.toUpperCase()}
                                onChange={handleChangeDlg('Nombre')}
                            />
                        </Grid>
                        <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                            <TextField
                                label="Descripción"
                                name="descr"
                                variant='filled'
                                className="bg-body rounded-3 col-xs-12 col-sm-6"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                size='small'
                                value={dialogValue?.DescripcionWeb?.toUpperCase()}
                                onChange={handleChangeDlg('DescripcionWeb')}
                            />
                        </Grid>
                        <Grid className="p-1 pt-2 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel className="rounded-3 px-1 alert-warning fs-5 fw-bold" id="imprimiren">Imprimir en</InputLabel>
                                <Select
                                    labelId="imprimiren"
                                    id="imp"
                                    size='small'
                                    required
                                    value={dialogValue?.id_impuesto1}
                                    variant='filled'
                                    inputProps={{
                                        className: "bg-body rounded-3"
                                    }}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_impuesto1: Number(event.target.value),
                                        })}
                                    input={<OutlinedInput label="Imprimir en" />}
                                >
                                    {
                                        sectoresImpresion.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className="p-1 pt-2 m-0" item xs={12} sm={6}>
                            <FormControl className=" p-0 m-0 w-100">
                                <InputLabel className="rounded-3 px-1 alert-warning fs-5 fw-bold" id="mesero-label">Impuesto</InputLabel>
                                <Select
                                    labelId="mesero-label"
                                    id="nivels"
                                    size='small'
                                    required
                                    value={dialogValue?.id_impuesto1}
                                    variant='filled'
                                    inputProps={{
                                        className: "bg-body rounded-3"
                                    }}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            id_impuesto1: Number(event.target.value),
                                        })}
                                    input={<OutlinedInput label="Nivel" />}
                                >
                                    {
                                        impuestos.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            className=" p-1 m-0 w-100" xs={12} sm={12}
                            item
                            container
                            direction='row'
                            justifyContent="space-between"
                            alignItems="flex-start" >
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={6}>
                                <TextField
                                    label="Precio"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: "text-end "
                                    }}
                                    fullWidth
                                    size='small'
                                    value={dialogValue?.MontoPrecio1}
                                    type='number'
                                    onChange={handleChangeDlg('MontoPrecio1')}
                                />
                            </Grid>
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={6}>
                                <TextField
                                    label="Precio Divisa"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: "text-end "
                                    }}
                                    fullWidth
                                    size='small'
                                    value={dialogValue?.MontoPrecioDivisa}
                                    type='number'
                                    onChange={handleChangeDlg('MontoPrecioDivisa')}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            className=" p-1 mt-1 w-100 text-black border rounded-3"
                            xs={12} sm={12}
                            item
                            container
                            direction='row'
                            justifyContent="space-between"
                            alignItems="flex-start" >
                            <FormControlLabel
                                className="text-black p-0 m-0"
                                checked={dialogValue.esFavorito ? dialogValue.esFavorito ? true : false : false}
                                control={<Checkbox sx={{ ...style }} />}
                                onChange={(event: any) =>
                                    setValue({
                                        ...dialogValue,
                                        esFavorito: Number(event.target.checked),
                                    })}
                                label="Favorito"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                className="text-black p-0 m-0"
                                checked={dialogValue.esPideprecio ? dialogValue.esPideprecio ? true : false : false}
                                control={<Checkbox />}
                                onChange={(event: any) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        esPideprecio: Number(event.target.checked),
                                    })}
                                label="Pide Precio"
                                labelPlacement="end"
                            /><FormControlLabel
                                className="text-black p-0 m-0"
                                checked={dialogValue.esActivo ? dialogValue.esActivo ? true : false : false}
                                control={<Checkbox />}
                                onChange={(event: any) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        esActivo: Number(event.target.checked),
                                    })}
                                label="Activo"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid direction='row' className="text-end p-1 m-0 w-100" item xs={12} sm={12}>
                            <Button
                                size='small'
                                className="m-md-1  m-auto col-4 bg-black bg-gradient text-white "
                                onClick={() => {
                                    if (dialogValue)
                                        saveData(dialogValue);
                                }}>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogContentText className="text-end" >
                        <Typography variant='inherit' >
                            Rellenar los Campos necesarios
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit} variant='contained' >Agregar</Button>
                </DialogActions>
                <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            </Dialog>

            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}
