
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Theme, Grid, Typography, Paper } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { pedidos } from "../../../../models/pedidos";
import { enutipoItemMenu } from "../../../report";
import Caja from "../../../report/components/caja";
import FormasPago from "../../../report/components/formas-pago";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
    orderList?: pedidos[];
    fechaConsulta1?: Date;
    fechaConsulta2?: Date;
    totalVentas?: string;
    totalBase?: string;
    totalIVA?: string;
    dataChart?: any[];
    formasPago?: any[];
    esCortez?: boolean,
    tipoSelect?: enutipoItemMenu;
}
const style = {
    color: 'rgb(255,255,255)',
    '&.Mui-checked': {
        color: 'rgb(0,0,0)',
    },

};
const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function CorteXZ(props: Props) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(2);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number((event.target as HTMLInputElement).value));
    };
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>


            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
 
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);
        if (e)
            setMessage("Pagado")
    }
    //#endregion

    return (
        <>
            <Titulo />
            <Grid
                sx={{ width: 450 }}
                container spacing={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="m-0 p-0 w-100"
            >

                <Grid
                    className=" m-0 p-1"
                    item xs={12} md={6}  >
                    <Paper
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 220,
                        }}
                    ><FormasPago formasPago={props.formasPago} />
                    </Paper>
                </Grid>
                <Grid
                    className=" m-0 p-1"
                    item xs={12} md={6} >
                    <Paper
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 220,
                        }}
                    ><Caja
                            fechaConsulta={props.fechaConsulta1}
                            totalVentas={props.totalVentas}
                            totalBase={props.totalBase}
                            totalIVA={props.totalIVA}
                            totalFacts={props.orderList ? props.orderList.length : 0}
                            esCorteZ={props.esCortez}
                        />

                    </Paper>
                </Grid>

            </Grid>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}