import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Grid, Typography } from '@mui/material';
import { pedidos } from "../../../models/pedidos";
import { useEffect } from 'react';
import dateFormat from 'dateformat';
import { LocalPrintshop } from '@mui/icons-material';
import { enutipoItemMenu } from '../index';
interface Props {
    children?: React.ReactNode;
    orderList?: pedidos[];
    fechaConsulta1?: Date;
    fechaConsulta2?: Date;
    titulo?: string;
    tipoSelect?: enutipoItemMenu;
}
// Generate Order Data
function createData(
    id: string,
    date: string,
    cliente: string,
    origen: string,
    formaPago: string,
    total: number
) {
    return { id, date, cliente, origen, formaPago, total };
}

/* 
function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}
 */
export default function Pedidos(props: Props) {
    const loadRows = (): any[] => {
        if (props.orderList && props.orderList.length) {
            let os: any[] = [];
            props.orderList.forEach(o => {
                let i: string = '';
                if (o.facturas && o.facturas.length && o.facturas[0].NroFactura)
                    i = `F-${o.facturas[0].NroFactura}`;
                else
                    i = o.NroPedido ? `P-${o.NroPedido?.toString()}` : `ID-${o.ID.toString()}`;
                os.push(createData(i, dateFormat(o.FechaActual, 'dd/mm/yy HH:MM'), o.Id_Cliente_cliente?.Nombres, o.Mensaje ? o.Mensaje : '', '', o.MontoTotal ?? 0))
            });
            return os;
        } else {
            return [];
        }
    }
    const fechas = () => {
        let f: string = "";
        if (props.fechaConsulta2) {
            return (
                f = `Facturas entre   ${dateFormat(props.fechaConsulta1, 'dd/mm/yyyy')}-${dateFormat(props.fechaConsulta2, 'dd/mm/yy')}`
            )
        } else {
            return (
                f = `Facturas del Día ${dateFormat(props.fechaConsulta1, 'dd/mm/yyyy')}`
            )
        }
    }
    useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <Grid
                direction='row'
                container
                className="my-1 p-0 h-100 bg-transparent"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    direction='row'
                    item
                    container
                    className="m-0 p-0"
                    justifyContent="fles-start"
                    xs={6} md={6}
                    alignItems="center"
                >{

                    }
                    <Typography sx={{ mr: 1 }} variant="caption">
                        {fechas()}
                    </Typography>

                </Grid>
                <Grid
                    direction='row'
                    item
                    container
                    className="m-0 p-0"
                    justifyContent='flex-end'
                    alignItems="center"
                    xs={6} md={6}
                >
                    <Typography sx={{ mr: 1 }} variant="caption"  >
                        {`Facturas: ${props.orderList ? props.orderList.length : 0}`}
                    </Typography>
                    <Button className=" bg-dark text-white " size='small' variant="contained" endIcon={<LocalPrintshop />}>
                        Imprimir
                    </Button>
                </Grid>

            </Grid>

            <Table size="small">
                <TableHead className="alert-dark">
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Origen</TableCell>
                        <TableCell>Formas Pago</TableCell>
                        <TableCell align="right">Monto Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadRows()
                        ? loadRows().map((row: any) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.cliente}</TableCell>
                                <TableCell>{row.origen}</TableCell>
                                <TableCell>{row.formaPago}</TableCell>
                                <TableCell align="right">{`$${row.total}`}</TableCell>
                            </TableRow>
                        ))
                        : ''
                    }
                </TableBody>
            </Table>
            <Link className="txt-black" href="#" onClick={(event)=>event.preventDefault} sx={{ mt: 3 }}>
                Ver más
            </Link>

        </React.Fragment>
    );
}

