import { Container, Table } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import CheckLoginToken from "../../components/check-login-token";
import LabeledTextInput from "../../components/labeled-text-input";
import NumberPad from "../../components/number-pad";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import PanelHeader from "../../components/panel-header";
import SimpleToast from "../../components/simple-toast";
import VerticalCenter from "../../components/vertical-center";
import { useLoginToken } from "../../contexts/login-context";
import { useOrder, useSetOrder } from "../../contexts/order-context";
import { ColorsDPana } from "../../utils/Color";
import { config } from "../../utils/configs";
import { enuStatusPedido } from "../../utils/enums";
import { Status } from "../../utils/helpers";
import "./style.css";

export default function Payment() {
    CheckLoginToken();

    const [montoActual, setmontoActual] = useState("");
    const [paymentList, setPaymentList] = useState<any[]>([]);

    const order = useOrder();
    const setOrder = useSetOrder();
    const history = useHistory();
    const loginToken = useLoginToken();
    const [message, setMessage] = useState("");

    const getAmountDue = (payments = paymentList) => {
        const total = order.MontoTotal;
        if (total) {
            let totalPaid = 0;
            for (const payment of payments) {
                totalPaid += payment.montoActual;
            }
            return total - totalPaid;
        }

        return NaN;
    }

    const createPaymentListItem = (payment: any, key: any) => {
        const date = new Date(payment.createdAt);
        return (
            <tr key={key} style={{
                textDecoration: payment.status === Status.VOIDED ? "line-through" : "none"
            }}>
                <td>{payment.type}</td>
                <td>{payment.montoActual}</td>
                <td>
                    {
                        date.toLocaleTimeString('en-US', {
                            hour12: false,
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })
                    }
                </td>
            </tr>
        );
    }

    let isPaying = false;
    const pay = async (paymentType: string) => {
        //TODO: handle payment and updating of order status on server with a transaction and lock.
        if (!isPaying) {
            try {
                isPaying = true;
                let amountNum = parseFloat(montoActual);
                setmontoActual("0");

                if (amountNum > 0) {
                    const totalBeforePayment = getAmountDue();
                    if (amountNum > totalBeforePayment) {
                        const change = amountNum - totalBeforePayment;
                        amountNum = totalBeforePayment;
                        setMessage("Change: $" + change.toFixed(2));
                    }

                    if (amountNum < 0.005) {
                        //paying a less than payable montoActual
                        setMessage("Please enter a valid montoActual.")
                        isPaying = false;
                        return;
                    }

                    await axios.post(config.SERVER_URI + "/payment/create", {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        data: {
                            montoActual: amountNum,
                            type: paymentType,
                            status: "PAID",
                            ServerId: loginToken.ID,
                            OrderId: order.ID
                        }
                    });
                    //update payment list
                    const result = await loadPayments();
                    //update order status
                    const amountDue = getAmountDue(result.data);
                    setPaymentList(result.data);
                    //set the leftover montoActual to the input field
                    setmontoActual(amountDue.toFixed(2));
                    const newOrder = { ...order };
                    //if the leftover montoActual is less then payable
                    if (amountDue <= 0.005) {
                        //change order to paid
                        newOrder.id_defTipoSatusPedido = enuStatusPedido.PedidoFacturado;
                    } else {
                        //change order to open
                        newOrder.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                    }
                    //TODO:Crear Orden Nieva
                    //setOrder(newOrder);
                    const data: any = {
                        ID: loginToken.ID,
                        hash: loginToken.hash,
                        orderId: order.ID,
                        status: newOrder.id_defTipoSatusPedido
                    }
                    await axios.post('/api/order/update-meta', data);
                } else {
                    setMessage("You must input a valid montoActual.")
                }
            } catch (err: any) {
                setMessage(err.stack);
            } finally {
                isPaying = false;
            }
        }
    }

    const loadPayments = () => {
        return axios.post(config.SERVER_URI + "/payment/list", {
            options: {
                where: {
                    OrderId: order.ID
                }
            }
        });
    }

    useEffect(() => {
        loadPayments().then(results => {
            setPaymentList(results.data);
            setmontoActual(getAmountDue(results.data).toFixed(2));
        }).catch(err => {
            console.error(err);
            setMessage(err.stack);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="vh-100 position-relative py-3">
            <VerticalCenter>
                <Panel className="d-flex flex-column mx-auto"
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                        overflow: "overlay"
                    }}>
                    <PanelHeader>
                        Payment
                    </PanelHeader>
                    <div className="payment-page-grid">
                        <PanelBody className="h-100">
                            {/*  <OrderItemDisplay order={order} /> */}
                        </PanelBody>
                        <PanelBody className="h-100 d-flex flex-column">
                            <div style={{
                                fontSize: "1.2em",
                                fontWeight: 600,
                                textAlign: "center"
                            }}>
                                Amount Due: ${getAmountDue().toFixed(2)}
                            </div>
                            <div className="flex-grow-1">
                                <Table className="h-100 text-center">
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Monto</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentList.map((payment, index) => createPaymentListItem(payment, index))}
                                    </tbody>
                                </Table>
                            </div>
                        </PanelBody>
                        <PanelBody className="h-100 payment-page-controll">
                            <div className="d-flex flex-column h-100 justify-content-between">
                                <div>
                                    <LabeledTextInput
                                        className="my-3"
                                        title="Amount:"
                                        value={montoActual}
                                        onChange={(e) => { setmontoActual(e.target.value) }}
                                    />
                                    <NumberPad text={montoActual} setText={setmontoActual} onEnter={() => { pay("Cash") }} />
                                </div>
                                <div className="d-flex flex-row-reverse mt-3">
                                    <Button className="m-1" themeColor={ColorsDPana.gray} style={{ width: "4em" }}
                                        onClick={() => {
                                            history.push("/main-menu");
                                        }}
                                    >Exit</Button>
                                    <Button className="m-1" themeColor={ColorsDPana.kiwi_green} style={{ width: "4em" }}
                                        onClick={() => {
                                            pay("Cash");
                                        }}
                                    >Cash</Button>
                                    <Button className="m-1" themeColor={ColorsDPana.dark_gold} style={{ width: "4em" }}
                                        onClick={() => {
                                            pay("Card");
                                        }}
                                    >Card</Button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </Panel>
                <SimpleToast title="Message:" message={message} setMessage={setMessage} />
            </VerticalCenter>
        </Container>
    );
}