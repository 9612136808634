import React, { useEffect, useState } from 'react';
import { TextField, Grid, Typography, IconButton, Box, Divider, InputAdornment, FormControl, InputLabel, FilledInput } from "@mui/material";
//import Autocomplete from '@material-ui/lab/Autocomplete';
/* import { CardCvcElement, CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js"; */
import { useSetSetting, useSetting } from '../../../../contexts/setting-context';
import { useOrder, useSetOrder } from '../../../../contexts/order-context';
import { useLoginToken } from '../../../../contexts/login-context';
import { useHistory } from 'react-router-dom';
import { formatNumber } from 'fast-number-formatter';
import { useStateValue } from '../StateContext';
import Tooltip from '@mui/material/Tooltip';
import { AccountBalance, AlternateEmail, Close, CreditCard, CreditScore, Delete, FormatBold, ImportantDevices, LocalParking, Money, MoneyOff, Paid, Screenshot } from '@mui/icons-material';
import { ColorsDPana } from '../../../../utils/Color';
import { tipoPagoIcon } from '../../../../models/Entities/entities';
import NumberFormat from 'react-number-format';
import { enuTipoPago } from '../../../../utils/enums';
import { facturas_mediopago } from '../../../../models/facturas_mediopago';
const cardsLogoIcon: tipoPagoIcon[] = [
    { id: 1, nombre: "Bolivares", imagen: <Money />, id_defTipoPago: enuTipoPago.Efectivo, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 2, nombre: "Dolar", imagen: <Paid />, id_defTipoPago: enuTipoPago.EFECTIVO_Moneda_Extranjera, montoTasa: 4.5, simbolo: '$', id_TipoMoneda: 2 },
    { id: 3, nombre: "Debito", imagen: <CreditCard />, id_defTipoPago: enuTipoPago.Tarjeta_Debito, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 4, nombre: "PagoMovil", imagen: <Screenshot />, id_defTipoPago: enuTipoPago.Pago_Movil, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 5, nombre: "MasterCard Credito", imagen: <CreditScore />, id_defTipoPago: enuTipoPago.Tarjeta_Credito_MasterCard, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 6, nombre: "Visa Credito", imagen: <CreditScore />, id_defTipoPago: enuTipoPago.Tarjeta_Credito_VISA, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 7, nombre: "Zelle", imagen: <AlternateEmail />, id_defTipoPago: enuTipoPago.Transferencia_Bancaria_Zelle, montoTasa: 4, simbolo: '$', id_TipoMoneda: 2 },
    { id: 8, nombre: "Bitcoin", imagen: <FormatBold />, id_defTipoPago: enuTipoPago.CriptoMoneda_BitCoin, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 9, nombre: "Petro", imagen: <LocalParking />, id_defTipoPago: enuTipoPago.CriptoMoneda_Petro, montoTasa: 32500, simbolo: "P$", id_TipoMoneda: 3 },
    { id: 10, nombre: "Transferencia", imagen: <AccountBalance />, id_defTipoPago: enuTipoPago.Transferencia_Bancaria, montoTasa: 1, simbolo: 'Bs', id_TipoMoneda: 1 },
    { id: 11, nombre: "Trans. Extranjera", imagen: <ImportantDevices />, id_defTipoPago: enuTipoPago.Transferencia_Extranjera, montoTasa: 1, simbolo: '$', id_TipoMoneda: 2 },
    { id: 12, nombre: "Crédito", imagen: <MoneyOff />, id_defTipoPago: enuTipoPago.Credito, montoTasa: 1, id_TipoMoneda: 1 }
];
interface State {
    amount: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
}
interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
//const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
const NumberFormatCustom = React.forwardRef<any, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: any) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    },
);
const PaymentForm = () => {
    const [values, setValues] = useState<State>({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [simboloMonedaLocal, setsimboloMonedaLocal] = useState('Bs.');
    const [simboloDivisa, setsimboloDivisa] = useState('$');
    const [montoMonedaLocal, setmontoMonedaLocal] = useState(0);
    const [montoTasaDivisa, setmontoTasaDivisa] = useState(0);
    const [montoTotalDivisa, setmontoTotalDivisa] = useState(0);
    const [tipoPagoSelected, settipoPagoSelected] = useState<tipoPagoIcon>(cardsLogoIcon[0]);

    const [{ formValues }, dispatch] = useStateValue();
    const PedidoActual = useOrder();
    const [formaPagoLst, setformaPagoLst] = useState<facturas_mediopago[]>([]);
    const [pagoActual, setPagoActual] = useState<facturas_mediopago>();
    /* 
    const setPedidoActual = useSetOrder();
    const loginToken = useLoginToken();
    const history = useHistory();
    const setting = useSetting();
    const setSetting = useSetSetting(); */

    const handleChange =
        (prop: keyof facturas_mediopago) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (pagoActual) {
                if (prop === 'MontoTotal')
                    event.target.value = event.target.value.replace(/[^0-9,.]/g, '')
                setPagoActual({ ...pagoActual, [prop]: event.target.value });
            }
        };
    const handleMouseDownBorrar = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

    };
    const loadDatosAdicionales = () => {
        switch (tipoPagoSelected.id_defTipoPago) {
            case enuTipoPago.Ninguno:
                return (
                    <>
                    </>
                )
            case enuTipoPago.NoPago:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Efectivo:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Credito:
                return (
                    <>
                        {beneficiario(true)}
                        {responsable(true)}
                        {diasCredito(true)}
                    </>
                )
            case enuTipoPago.NotaDebito:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Nota_Credito:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Tarjeta_Debito:
                return (
                    <>
                        {codigoReferencia()}
                        {banco()}
                    </>
                )
            case enuTipoPago.Tarjeta_Credito_VISA:
                return (
                    <>
                        {codigoReferencia()}
                        {banco()}
                        {nroTarjeta()}
                    </>
                )
            case enuTipoPago.Tarjeta_Alimentacion:
                return (
                    <>
                    </>
                )
            case enuTipoPago.CestaTicket:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Cupon:
                return (
                    <>
                        {codigoReferencia(true)}
                    </>
                )
            case enuTipoPago.Transferencia_Bancaria:
                return (
                    <>
                        {codigoReferencia(true)}
                        {banco()}
                        {telfBeneficiario()}
                        {beneficiario()}
                    </>
                )
            case enuTipoPago.CriptoMoneda_Otra:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Pago_Movil:
                return (
                    <>
                        {codigoReferencia(true)}
                        {telfBeneficiario(true)}
                        {banco()}
                    </>
                )
            case enuTipoPago.Cheque:
                return (
                    <>
                        {codigoReferencia()}
                        {banco()}
                        {telfBeneficiario()}
                        {beneficiario()}
                    </>
                )
            case enuTipoPago.Pagos_Internos:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Prepagado:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Cortesía:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Bono_Consumo:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Anulación:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Consumos_Internos:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Bio_Pago:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Pago_Digital:
                return (
                    <>
                    </>
                )
            case enuTipoPago.EFECTIVO_Moneda_Extranjera:
                return (
                    <>
                        {codigoReferencia()}
                        {telfBeneficiario()}
                        {comentarios()}
                    </>
                )
            case enuTipoPago.Tarjeta_Moneda_Extranjera:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Otros_Pagos_Moneda_Extranjera:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Otros_Pagos:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Tarjeta_Credito_MasterCard:
                return (
                    <>
                        {codigoReferencia()}
                        {banco()}
                        {nroTarjeta()}
                    </>
                )
            case enuTipoPago.Tarjeta_Credito_Otra:
                return (
                    <>
                    </>
                )
            case enuTipoPago.Transferencia_Bancaria_Zelle:
                return (
                    <>
                        {codigoReferencia(true)}
                        {telfBeneficiario(true)}
                        {correoElectronico(true)}
                        {beneficiario(true)}
                        {comentarios()}
                    </>
                )
            case enuTipoPago.CriptoMoneda_BitCoin:
                return (
                    <>
                        {codigoReferencia(true)}
                        {telfBeneficiario(true)}
                        {correoElectronico(true)}
                        {beneficiario()}
                        {comentarios()}
                    </>
                )
            case enuTipoPago.CriptoMoneda_Petro:
                return (
                    <>
                        {codigoReferencia(true)}
                        {telfBeneficiario(true)}
                        {correoElectronico(true)}
                        {beneficiario()}
                        {comentarios()}
                    </>
                )
            case enuTipoPago.Transferencia_Extranjera:
                return (
                    <>
                        {codigoReferencia(true)}
                        {banco(true)}
                        {telfBeneficiario(true)}
                        {correoElectronico(true)}
                        {beneficiario(true)}
                        {comentarios()}
                    </>
                )


            default:
                break;
        }
    }
    const codigoReferencia = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={3}>
                <TextField
                    label="Nro. Ref."
                    name="NroRef"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.nroRef}
                    onChange={handleChange('nroRef')}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }
    const telfBeneficiario = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={3}>
                <TextField
                    label="Nro. Teléfono"
                    name="Nrotelefono"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.nroTelefono}
                    onChange={handleChange('nroTelefono')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }
    const beneficiario = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={6}>
                <TextField
                    label="Nombre"
                    name="Beneficiario"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.Beneficiario}
                    onChange={handleChange('Beneficiario')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>)
    }
    const responsable = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={6}>
                <TextField
                    label="Responsable"
                    name="responsablecredito"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.Responsable}
                    onChange={handleChange('Responsable')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }
    const diasCredito = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={3}>
                <TextField
                    label="Días Crédito"
                    name="diascredito"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.nroDiasCredito}
                    onChange={handleChange('nroDiasCredito')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }
    const banco = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={6}>
                <TextField
                    label="Banco"
                    name="banconame"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.Id_TipoBanco}
                    onChange={handleChange('Id_TipoBanco')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>)
    }
    const nroTarjeta = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={12} sm={3}>
                <TextField
                    label="Número Tarjeta"
                    name="ccnumber"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.nroDocumento}
                    onChange={handleChange('nroDocumento')}
                    InputProps={{

                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }
    const cvc = (requerido: boolean = false) => {
        return (
            <Grid className="py-1" item xs={6} sm={3}>
                <TextField
                    label="CVC"
                    name="cvc"
                    variant="outlined"
                    required={requerido}
                    fullWidth
                    size='small'
                    value={pagoActual?.CVC}
                    onChange={handleChange('CVC')}
                    InputProps={{
                        /* inputProps: {
                            component: CardCvcElement
                        },
                        inputComponent: StripeInput */
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        )
    }


    const correoElectronico = (requerido: boolean = false) => {
        return (<Grid className="py-1" item xs={12} sm={6}>
            <TextField
                label="Email"
                name="emailcoin"
                variant="outlined"
                required={requerido}
                fullWidth
                size='small'
                value={pagoActual?.Email}
                onChange={handleChange('Email')}
                InputProps={{


                }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>)
    }
    const comentarios = (requerido: boolean = false) => {
        return (<Grid className="py-1" item xs={12} sm={6}>
            <TextField
                label="Comentarios"
                name="comentarios"
                variant="outlined"
                required={requerido}
                fullWidth
                size='small'
                value={pagoActual?.Comentario}
                onChange={handleChange('Comentario')}
                InputProps={{

                }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>)
    }
    const removeTipoPago = (id: number) => {
        if (formValues['formaspago'] && formValues['formaspago'].length > 0) {
            let van: number = 0;
            formValues['formaspago'].splice(formValues['formaspago'].findIndex((x: any) => x.nroItem === id), 1);
            let i: number = 1;
            formValues['formaspago'].forEach((x: any) => {
                x.nroItem = i; i++;
                van += +x.MontoTotal;
            })
            dispatch({
                type: 'editFormValue',
                key: "montovan",
                value: van,
            });
        }
    }
    const handleChangeState =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };


    useEffect(() => {
        dispatch({
            type: 'editFormValue',
            key: 'pagoactual',
            value: pagoActual
        })

    }, [pagoActual])
    useEffect(() => {
        if (formValues['pagoactual']) {
            setPagoActual(formValues['pagoactual']);
        }

    }, [formValues['pagoactual']?.MontoTotal])
    useEffect(() => {
        let mt: number = 0;
        if (formValues)
            mt = formValues['montocambio'];
        if (tipoPagoSelected) {
            let tp: any = {
                Id_MedioPago: tipoPagoSelected.id,
                Id_TipoBanco: undefined,
                id_Moneda: tipoPagoSelected.id_TipoMoneda,
                nroItem: 0,
                Responsable: '',
                DescripicionDocumento: '',
                Simbolo: tipoPagoSelected.simbolo,
                FechaActual: new Date(),
                MontoTotal: PedidoActual.MontoTotal ? +PedidoActual.MontoTotal : 0,
                MontoTasaMoneda: tipoPagoSelected.montoTasa,
                nroDocumento: '',
                nroRef: '',
                Comentario: '',
                Beneficiario: '',
                Email: '',
                CVC: '',
                Imagen1: '',
                Imagen2: '',
                nroTelefono: '',
                esConfirmado: 0,
                esConfirmadoBeneficiario: 0,
                nroDiasCredito: 0,

            }
            setPagoActual(tp);
        }
        if (PedidoActual)
            setmontoTotalDivisa(tipoPagoSelected.montoTasa > 1 ? (PedidoActual.MontoTotal ? +PedidoActual.MontoTotal : 0) / tipoPagoSelected.montoTasa : 0);
        setmontoTasaDivisa(tipoPagoSelected.montoTasa);
        setsimboloDivisa(tipoPagoSelected.simbolo as string);
    }, [tipoPagoSelected])

    useEffect(() => {
        settipoPagoSelected(cardsLogoIcon[0]);
        let tasaDiv: number = cardsLogoIcon[0].montoTasa;
        setmontoTasaDivisa(tasaDiv);
        if (PedidoActual) {
            if (PedidoActual.MontoTotal) {
                setmontoMonedaLocal(+PedidoActual.MontoTotal);
                setmontoTotalDivisa(tasaDiv > 1 ? +PedidoActual.MontoTotal / tasaDiv : 0);
            }
        }
    }, [])

    return <>
        <Grid className="p-1 m-0" item xs={12}>
            <Typography variant="h6">Formas de Pago</Typography>
        </Grid>
        <Grid className="p-1 m-0"
            container
            item xs={12}
            sm={12}
            style={{ background: "lightgray" }}
        >
            <Grid item xs={12} sm={4} style={{ background: "darkred", color: "white", padding: 5 }}>
                <Typography className="p-0" variant="h6" style={{ textAlign: "center" }}>{tipoPagoSelected.nombre} </Typography>
                <Grid className="p-0" container item xs={12} sm={12} justifyContent="space-between" style={{ color: "white" }}>
                    <Typography variant="caption" className="p-0" style={{ fontFamily: "arial", textAlign: "left" }} >
                        {`Total ${simboloMonedaLocal}:`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontWeight: "bolder", textAlign: "right" }}>{formatNumber(montoMonedaLocal, 2)} </Typography>
                </Grid>
                {montoTasaDivisa > 1
                    ? <>
                        <Grid className="p-0" container item xs={12} sm={12} justifyContent="space-between" style={{ color: "white" }}>
                            <Typography variant="caption" className="p-0" style={{ fontFamily: "arial", textAlign: "left" }} >
                                {`Total ${simboloDivisa} :`}
                            </Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: "bolder", textAlign: "right" }}>{formatNumber(montoTotalDivisa, 2)} </Typography>
                        </Grid>
                        <Grid className="p-0" container item xs={12} sm={12} justifyContent="space-between" style={{ color: "white" }}>
                            <Typography variant="caption" className="p-0" style={{ fontFamily: "arial", textAlign: "left" }} >
                                {`Tasa Cambio:`}
                            </Typography>
                            <Typography variant="caption" style={{ fontWeight: "bolder", textAlign: "right" }}>{formatNumber(montoTasaDivisa, 2)} </Typography>
                        </Grid>
                    </>
                    : ""}
            </Grid>
            <Grid container item xs={12} sm={8} justifyContent="center">
                {cardsLogoIcon.map((e) => {
                    return (
                        <div className='m-1' style={{ background: "white" }}>
                            <Tooltip title={e.nombre} arrow sx={{ backgroundColor: `rgb(${ColorsDPana.accent[0]},${ColorsDPana.accent[1]},${ColorsDPana.accent[2]})` }} >
                                <IconButton className="p-1 m-1" key={e.nombre}
                                    onClick={() => { settipoPagoSelected(e) }}
                                    sx={{ width: 40, height: 40, color: `rgb(${ColorsDPana.secundary[0]},${ColorsDPana.secundary[1]},${ColorsDPana.secundary[2]})` }}
                                >
                                    {e.imagen}
                                </IconButton>
                            </Tooltip>
                        </div>);
                })}
            </Grid>
        </Grid>
        <Grid className="p-1 m-0" container item xs={12} sm={12} alignItems="flex-start"  >
            {formValues['formaspago'] && formValues['formaspago'].length > 0
                ? <Grid className="p-1" container item xs={12} sm={4} justifyContent="space-between" style={{ color: "white", backgroundColor: 'lightgray' }}>
                    <Box className="p-0 m-0" sx={{ flexGrow: 1, overflow: 'auto', maxHeight: 90 }}>
                        {formValues['formaspago'].map((e: any, index: any) => {
                            return (
                                <div key={index} style={{ background: "white" }}>
                                    <Tooltip title={cardsLogoIcon?.find(x => x.id === e.Id_MedioPago) ? cardsLogoIcon?.find(x => x.id === e.Id_MedioPago)?.nombre as string : ""} arrow  >
                                        <Grid className="p-1" container item xs={12} sm={12} alignItems="center" justifyContent="space-between" style={{ color: `rgb(${ColorsDPana.secundary[0]},${ColorsDPana.secundary[1]},${ColorsDPana.secundary[2]})` }}>
                                            <Grid className="p-0" item xs={2} sm={2}>
                                                <IconButton
                                                    onClick={() => { removeTipoPago(e.nroItem); }}
                                                    sx={{ width: 20, height: 20, color: 'red' }}
                                                > <Delete /> </IconButton>
                                            </Grid>
                                            <Grid className="p-0" item xs={2} sm={2}>
                                                <Typography variant="caption" className="p-0" style={{ fontFamily: "arial", textAlign: "left" }} >
                                                    {cardsLogoIcon?.find(x => x.id === e.Id_MedioPago)?.imagen}
                                                </Typography>
                                            </Grid>
                                            <Grid className="p-0" item xs={8} sm={8} justifyContent="flex-end">
                                                <Typography className="p-0" variant="subtitle2" style={{ fontWeight: "bolder", textAlign: "right" }}>{`${cardsLogoIcon?.find(x => x.id === e.Id_MedioPago)?.simbolo} ${formatNumber(+e.MontoTotal, 2)}`} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                    <Divider variant="middle" sx={{ color: 'gray' }} />
                                </div>
                            );
                        })}
                    </Box>
                </Grid>
                : ""
            }
            <Grid className="p-0" container item xs={12} sm={formValues['formaspago'] && formValues['formaspago'].length === 0 ? 12 : 8} justifyContent="space-between" style={{ color: "white" }}>
                <FormControl sx={{ m: 1 }} fullWidth variant="filled" style={{ background: "darkred", color: 'white' }}>
                    <InputLabel htmlFor="montoactual" style={{ color: 'white' }}>Monto</InputLabel>
                    <FilledInput
                        id="montoactual"
                        autoFocus
                        fullWidth
                        value={pagoActual?.MontoTotal}
                        onChange={handleChange('MontoTotal')}
                        inputProps={{
                            style: { fontSize: 40, fontWeight: "bolder", fontFamily: "monospace", textAlign: "right", background: "darkred", color: "white" },
                        }}
                        startAdornment={
                            <InputAdornment position="start"  >
                                <Typography variant="h6" className="p-0" style={{ color: 'white' }} >
                                    {tipoPagoSelected.simbolo}
                                </Typography>
                            </InputAdornment>}
                        endAdornment={pagoActual?.MontoTotal
                            ? <InputAdornment position="end" >
                                <IconButton
                                    onClick={e => {
                                        setPagoActual({ ...pagoActual, MontoTotal: 0 });
                                    }}
                                    onMouseDown={handleMouseDownBorrar}
                                    edge="end"
                                    sx={{ color: 'white' }}
                                >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                            : ''
                        }
                    />
                </FormControl>

            </Grid>

        </Grid>
        <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="m-0 p-1 border rounded-3 w-100 alert-danger"
        >
            {loadDatosAdicionales()}
        </Grid>

    </>
}

export default PaymentForm;

/* const currencies = [
    {
        "symbol": "AED",
        "name": "United Arab Emirates Dirham",
        "symbol_native": "د.إ.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AED",
        "name_plural": "UAE dirhams"
    },
    {
        "symbol": "Af",
        "name": "Afghan Afghani",
        "symbol_native": "؋",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AFN",
        "name_plural": "Afghan Afghanis"
    },
    {
        "symbol": "ALL",
        "name": "Albanian Lek",
        "symbol_native": "Lek",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ALL",
        "name_plural": "Albanian lekë"
    },
    {
        "symbol": "AMD",
        "name": "Armenian Dram",
        "symbol_native": "դր.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AMD",
        "name_plural": "Armenian drams"
    },
    {
        "symbol": "AR$",
        "name": "Argentine Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ARS",
        "name_plural": "Argentine pesos"
    },
    {
        "symbol": "AU$",
        "name": "Australian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AUD",
        "name_plural": "Australian dollars"
    },
    {
        "symbol": "man.",
        "name": "Azerbaijani Manat",
        "symbol_native": "ман.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AZN",
        "name_plural": "Azerbaijani manats"
    },
    {
        "symbol": "KM",
        "name": "Bosnia-Herzegovina Convertible Mark",
        "symbol_native": "KM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BAM",
        "name_plural": "Bosnia-Herzegovina convertible marks"
    },
    {
        "symbol": "Tk",
        "name": "Bangladeshi Taka",
        "symbol_native": "৳",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BDT",
        "name_plural": "Bangladeshi takas"
    },
    {
        "symbol": "BGN",
        "name": "Bulgarian Lev",
        "symbol_native": "лв.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BGN",
        "name_plural": "Bulgarian leva"
    },
    {
        "symbol": "BD",
        "name": "Bahraini Dinar",
        "symbol_native": "د.ب.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "BHD",
        "name_plural": "Bahraini dinars"
    },
    {
        "symbol": "FBu",
        "name": "Burundian Franc",
        "symbol_native": "FBu",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BIF",
        "name_plural": "Burundian francs"
    },
    {
        "symbol": "BN$",
        "name": "Brunei Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BND",
        "name_plural": "Brunei dollars"
    },
    {
        "symbol": "Bs",
        "name": "Bolivian Boliviano",
        "symbol_native": "Bs",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BOB",
        "name_plural": "Bolivian bolivianos"
    },
    {
        "symbol": "R$",
        "name": "Brazilian Real",
        "symbol_native": "R$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BRL",
        "name_plural": "Brazilian reals"
    },
    {
        "symbol": "BWP",
        "name": "Botswanan Pula",
        "symbol_native": "P",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BWP",
        "name_plural": "Botswanan pulas"
    },
    {
        "symbol": "BYR",
        "name": "Belarusian Ruble",
        "symbol_native": "BYR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BYR",
        "name_plural": "Belarusian rubles"
    },
    {
        "symbol": "BZ$",
        "name": "Belize Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BZD",
        "name_plural": "Belize dollars"
    },
    {
        "symbol": "CA$",
        "name": "Canadian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CAD",
        "name_plural": "Canadian dollars"
    },
    {
        "symbol": "CDF",
        "name": "Congolese Franc",
        "symbol_native": "FrCD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CDF",
        "name_plural": "Congolese francs"
    },
    {
        "symbol": "CHF",
        "name": "Swiss Franc",
        "symbol_native": "CHF",
        "decimal_digits": 2,
        "rounding": 0.05,
        "code": "CHF",
        "name_plural": "Swiss francs"
    },
    {
        "symbol": "CL$",
        "name": "Chilean Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CLP",
        "name_plural": "Chilean pesos"
    },
    {
        "symbol": "CN¥",
        "name": "Chinese Yuan",
        "symbol_native": "CN¥",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CNY",
        "name_plural": "Chinese yuan"
    },
    {
        "symbol": "CO$",
        "name": "Colombian Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "COP",
        "name_plural": "Colombian pesos"
    },
    {
        "symbol": "₡",
        "name": "Costa Rican Colón",
        "symbol_native": "₡",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CRC",
        "name_plural": "Costa Rican colóns"
    },
    {
        "symbol": "CV$",
        "name": "Cape Verdean Escudo",
        "symbol_native": "CV$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CVE",
        "name_plural": "Cape Verdean escudos"
    },
    {
        "symbol": "Kč",
        "name": "Czech Republic Koruna",
        "symbol_native": "Kč",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CZK",
        "name_plural": "Czech Republic korunas"
    },
    {
        "symbol": "Fdj",
        "name": "Djiboutian Franc",
        "symbol_native": "Fdj",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "DJF",
        "name_plural": "Djiboutian francs"
    },
    {
        "symbol": "Dkr",
        "name": "Danish Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DKK",
        "name_plural": "Danish kroner"
    },
    {
        "symbol": "RD$",
        "name": "Dominican Peso",
        "symbol_native": "RD$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DOP",
        "name_plural": "Dominican pesos"
    },
    {
        "symbol": "DA",
        "name": "Algerian Dinar",
        "symbol_native": "د.ج.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DZD",
        "name_plural": "Algerian dinars"
    },
    {
        "symbol": "Ekr",
        "name": "Estonian Kroon",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EEK",
        "name_plural": "Estonian kroons"
    },
    {
        "symbol": "EGP",
        "name": "Egyptian Pound",
        "symbol_native": "ج.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EGP",
        "name_plural": "Egyptian pounds"
    },
    {
        "symbol": "Nfk",
        "name": "Eritrean Nakfa",
        "symbol_native": "Nfk",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ERN",
        "name_plural": "Eritrean nakfas"
    },
    {
        "symbol": "Br",
        "name": "Ethiopian Birr",
        "symbol_native": "Br",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ETB",
        "name_plural": "Ethiopian birrs"
    },
    {
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    },
    {
        "symbol": "£",
        "name": "British Pound Sterling",
        "symbol_native": "£",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GBP",
        "name_plural": "British pounds sterling"
    },
    {
        "symbol": "GEL",
        "name": "Georgian Lari",
        "symbol_native": "GEL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GEL",
        "name_plural": "Georgian laris"
    },
    {
        "symbol": "GH₵",
        "name": "Ghanaian Cedi",
        "symbol_native": "GH₵",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GHS",
        "name_plural": "Ghanaian cedis"
    },
    {
        "symbol": "FG",
        "name": "Guinean Franc",
        "symbol_native": "FG",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "GNF",
        "name_plural": "Guinean francs"
    },
    {
        "symbol": "GTQ",
        "name": "Guatemalan Quetzal",
        "symbol_native": "Q",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GTQ",
        "name_plural": "Guatemalan quetzals"
    },
    {
        "symbol": "HK$",
        "name": "Hong Kong Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HKD",
        "name_plural": "Hong Kong dollars"
    },
    {
        "symbol": "HNL",
        "name": "Honduran Lempira",
        "symbol_native": "L",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HNL",
        "name_plural": "Honduran lempiras"
    },
    {
        "symbol": "kn",
        "name": "Croatian Kuna",
        "symbol_native": "kn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HRK",
        "name_plural": "Croatian kunas"
    },
    {
        "symbol": "Ft",
        "name": "Hungarian Forint",
        "symbol_native": "Ft",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "HUF",
        "name_plural": "Hungarian forints"
    },
    {
        "symbol": "Rp",
        "name": "Indonesian Rupiah",
        "symbol_native": "Rp",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IDR",
        "name_plural": "Indonesian rupiahs"
    },
    {
        "symbol": "₪",
        "name": "Israeli New Sheqel",
        "symbol_native": "₪",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ILS",
        "name_plural": "Israeli new sheqels"
    },
    {
        "symbol": "Rs",
        "name": "Indian Rupee",
        "symbol_native": "টকা",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "INR",
        "name_plural": "Indian rupees"
    },
    {
        "symbol": "IQD",
        "name": "Iraqi Dinar",
        "symbol_native": "د.ع.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IQD",
        "name_plural": "Iraqi dinars"
    },
    {
        "symbol": "IRR",
        "name": "Iranian Rial",
        "symbol_native": "﷼",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IRR",
        "name_plural": "Iranian rials"
    },
    {
        "symbol": "Ikr",
        "name": "Icelandic Króna",
        "symbol_native": "kr",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ISK",
        "name_plural": "Icelandic krónur"
    },
    {
        "symbol": "J$",
        "name": "Jamaican Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "JMD",
        "name_plural": "Jamaican dollars"
    },
    {
        "symbol": "JD",
        "name": "Jordanian Dinar",
        "symbol_native": "د.أ.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "JOD",
        "name_plural": "Jordanian dinars"
    },
    {
        "symbol": "¥",
        "name": "Japanese Yen",
        "symbol_native": "￥",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "JPY",
        "name_plural": "Japanese yen"
    },
    {
        "symbol": "Ksh",
        "name": "Kenyan Shilling",
        "symbol_native": "Ksh",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KES",
        "name_plural": "Kenyan shillings"
    },
    {
        "symbol": "KHR",
        "name": "Cambodian Riel",
        "symbol_native": "៛",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KHR",
        "name_plural": "Cambodian riels"
    },
    {
        "symbol": "CF",
        "name": "Comorian Franc",
        "symbol_native": "FC",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KMF",
        "name_plural": "Comorian francs"
    },
    {
        "symbol": "₩",
        "name": "South Korean Won",
        "symbol_native": "₩",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KRW",
        "name_plural": "South Korean won"
    },
    {
        "symbol": "KD",
        "name": "Kuwaiti Dinar",
        "symbol_native": "د.ك.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "KWD",
        "name_plural": "Kuwaiti dinars"
    },
    {
        "symbol": "KZT",
        "name": "Kazakhstani Tenge",
        "symbol_native": "тңг.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KZT",
        "name_plural": "Kazakhstani tenges"
    },
    {
        "symbol": "LB£",
        "name": "Lebanese Pound",
        "symbol_native": "ل.ل.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "LBP",
        "name_plural": "Lebanese pounds"
    },
    {
        "symbol": "SLRs",
        "name": "Sri Lankan Rupee",
        "symbol_native": "SL Re",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LKR",
        "name_plural": "Sri Lankan rupees"
    },
    {
        "symbol": "Lt",
        "name": "Lithuanian Litas",
        "symbol_native": "Lt",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LTL",
        "name_plural": "Lithuanian litai"
    },
    {
        "symbol": "Ls",
        "name": "Latvian Lats",
        "symbol_native": "Ls",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LVL",
        "name_plural": "Latvian lati"
    },
    {
        "symbol": "LD",
        "name": "Libyan Dinar",
        "symbol_native": "د.ل.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "LYD",
        "name_plural": "Libyan dinars"
    },
    {
        "symbol": "MAD",
        "name": "Moroccan Dirham",
        "symbol_native": "د.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MAD",
        "name_plural": "Moroccan dirhams"
    },
    {
        "symbol": "MDL",
        "name": "Moldovan Leu",
        "symbol_native": "MDL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MDL",
        "name_plural": "Moldovan lei"
    },
    {
        "symbol": "MGA",
        "name": "Malagasy Ariary",
        "symbol_native": "MGA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MGA",
        "name_plural": "Malagasy Ariaries"
    },
    {
        "symbol": "MKD",
        "name": "Macedonian Denar",
        "symbol_native": "MKD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MKD",
        "name_plural": "Macedonian denari"
    },
    {
        "symbol": "MMK",
        "name": "Myanma Kyat",
        "symbol_native": "K",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MMK",
        "name_plural": "Myanma kyats"
    },
    {
        "symbol": "MOP$",
        "name": "Macanese Pataca",
        "symbol_native": "MOP$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MOP",
        "name_plural": "Macanese patacas"
    },
    {
        "symbol": "MURs",
        "name": "Mauritian Rupee",
        "symbol_native": "MURs",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MUR",
        "name_plural": "Mauritian rupees"
    },
    {
        "symbol": "MX$",
        "name": "Mexican Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MXN",
        "name_plural": "Mexican pesos"
    },
    {
        "symbol": "RM",
        "name": "Malaysian Ringgit",
        "symbol_native": "RM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MYR",
        "name_plural": "Malaysian ringgits"
    },
    {
        "symbol": "MTn",
        "name": "Mozambican Metical",
        "symbol_native": "MTn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MZN",
        "name_plural": "Mozambican meticals"
    },
    {
        "symbol": "N$",
        "name": "Namibian Dollar",
        "symbol_native": "N$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NAD",
        "name_plural": "Namibian dollars"
    },
    {
        "symbol": "₦",
        "name": "Nigerian Naira",
        "symbol_native": "₦",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NGN",
        "name_plural": "Nigerian nairas"
    },
    {
        "symbol": "C$",
        "name": "Nicaraguan Córdoba",
        "symbol_native": "C$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NIO",
        "name_plural": "Nicaraguan córdobas"
    },
    {
        "symbol": "Nkr",
        "name": "Norwegian Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NOK",
        "name_plural": "Norwegian kroner"
    },
    {
        "symbol": "NPRs",
        "name": "Nepalese Rupee",
        "symbol_native": "नेरू",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NPR",
        "name_plural": "Nepalese rupees"
    },
    {
        "symbol": "NZ$",
        "name": "New Zealand Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NZD",
        "name_plural": "New Zealand dollars"
    },
    {
        "symbol": "OMR",
        "name": "Omani Rial",
        "symbol_native": "ر.ع.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "OMR",
        "name_plural": "Omani rials"
    },
    {
        "symbol": "B/.",
        "name": "Panamanian Balboa",
        "symbol_native": "B/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PAB",
        "name_plural": "Panamanian balboas"
    },
    {
        "symbol": "S/.",
        "name": "Peruvian Nuevo Sol",
        "symbol_native": "S/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PEN",
        "name_plural": "Peruvian nuevos soles"
    },
    {
        "symbol": "₱",
        "name": "Philippine Peso",
        "symbol_native": "₱",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PHP",
        "name_plural": "Philippine pesos"
    },
    {
        "symbol": "PKRs",
        "name": "Pakistani Rupee",
        "symbol_native": "₨",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PKR",
        "name_plural": "Pakistani rupees"
    },
    {
        "symbol": "zł",
        "name": "Polish Zloty",
        "symbol_native": "zł",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PLN",
        "name_plural": "Polish zlotys"
    },
    {
        "symbol": "₲",
        "name": "Paraguayan Guarani",
        "symbol_native": "₲",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PYG",
        "name_plural": "Paraguayan guaranis"
    },
    {
        "symbol": "QR",
        "name": "Qatari Rial",
        "symbol_native": "ر.ق.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "QAR",
        "name_plural": "Qatari rials"
    },
    {
        "symbol": "RON",
        "name": "Romanian Leu",
        "symbol_native": "RON",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RON",
        "name_plural": "Romanian lei"
    },
    {
        "symbol": "din.",
        "name": "Serbian Dinar",
        "symbol_native": "дин.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RSD",
        "name_plural": "Serbian dinars"
    },
    {
        "symbol": "RUB",
        "name": "Russian Ruble",
        "symbol_native": "руб.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RUB",
        "name_plural": "Russian rubles"
    },
    {
        "symbol": "RWF",
        "name": "Rwandan Franc",
        "symbol_native": "FR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RWF",
        "name_plural": "Rwandan francs"
    },
    {
        "symbol": "SR",
        "name": "Saudi Riyal",
        "symbol_native": "ر.س.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SAR",
        "name_plural": "Saudi riyals"
    },
    {
        "symbol": "SDG",
        "name": "Sudanese Pound",
        "symbol_native": "SDG",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SDG",
        "name_plural": "Sudanese pounds"
    },
    {
        "symbol": "Skr",
        "name": "Swedish Krona",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SEK",
        "name_plural": "Swedish kronor"
    },
    {
        "symbol": "S$",
        "name": "Singapore Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SGD",
        "name_plural": "Singapore dollars"
    },
    {
        "symbol": "Ssh",
        "name": "Somali Shilling",
        "symbol_native": "Ssh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SOS",
        "name_plural": "Somali shillings"
    },
    {
        "symbol": "SY£",
        "name": "Syrian Pound",
        "symbol_native": "ل.س.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SYP",
        "name_plural": "Syrian pounds"
    },
    {
        "symbol": "฿",
        "name": "Thai Baht",
        "symbol_native": "฿",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "THB",
        "name_plural": "Thai baht"
    },
    {
        "symbol": "DT",
        "name": "Tunisian Dinar",
        "symbol_native": "د.ت.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "TND",
        "name_plural": "Tunisian dinars"
    },
    {
        "symbol": "T$",
        "name": "Tongan Paʻanga",
        "symbol_native": "T$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TOP",
        "name_plural": "Tongan paʻanga"
    },
    {
        "symbol": "TL",
        "name": "Turkish Lira",
        "symbol_native": "TL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TRY",
        "name_plural": "Turkish Lira"
    },
    {
        "symbol": "TT$",
        "name": "Trinidad and Tobago Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TTD",
        "name_plural": "Trinidad and Tobago dollars"
    },
    {
        "symbol": "NT$",
        "name": "New Taiwan Dollar",
        "symbol_native": "NT$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TWD",
        "name_plural": "New Taiwan dollars"
    },
    {
        "symbol": "TSh",
        "name": "Tanzanian Shilling",
        "symbol_native": "TSh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "TZS",
        "name_plural": "Tanzanian shillings"
    },
    {
        "symbol": "₴",
        "name": "Ukrainian Hryvnia",
        "symbol_native": "₴",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UAH",
        "name_plural": "Ukrainian hryvnias"
    },
    {
        "symbol": "USh",
        "name": "Ugandan Shilling",
        "symbol_native": "USh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UGX",
        "name_plural": "Ugandan shillings"
    },
    {
        "symbol": "$U",
        "name": "Uruguayan Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UYU",
        "name_plural": "Uruguayan pesos"
    },
    {
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
    {
        "symbol": "UZS",
        "name": "Uzbekistan Som",
        "symbol_native": "UZS",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UZS",
        "name_plural": "Uzbekistan som"
    },
    {
        "symbol": "Bs.F.",
        "name": "Venezuelan Bolívar",
        "symbol_native": "Bs.F.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "VEF",
        "name_plural": "Venezuelan bolívars"
    },
    {
        "symbol": "₫",
        "name": "Vietnamese Dong",
        "symbol_native": "₫",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "VND",
        "name_plural": "Vietnamese dong"
    },
    {
        "symbol": "FCFA",
        "name": "CFA Franc BEAC",
        "symbol_native": "FCFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XAF",
        "name_plural": "CFA francs BEAC"
    },
    {
        "symbol": "CFA",
        "name": "CFA Franc BCEAO",
        "symbol_native": "CFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XOF",
        "name_plural": "CFA francs BCEAO"
    },
    {
        "symbol": "YR",
        "name": "Yemeni Rial",
        "symbol_native": "ر.ي.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "YER",
        "name_plural": "Yemeni rials"
    },
    {
        "symbol": "R",
        "name": "South African Rand",
        "symbol_native": "R",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ZAR",
        "name_plural": "South African rand"
    },
    {
        "symbol": "ZK",
        "name": "Zambian Kwacha",
        "symbol_native": "ZK",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ZMK",
        "name_plural": "Zambian kwachas"
    } 
]*/
const currencies = [

    {
        "symbol": "Bs.F.",
        "name": "BOLIVAR",
        "symbol_native": "Bs.F.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "VEF",
        "name_plural": "BOLIVARES"
    },
    {
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
    {
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    }

]
