import type { facturas } from './facturas';
import type { tipoimpuestos } from './tipoimpuestos';

export interface facturas_impuestosAttributes {
  ID: number;
  Id_Factura?: number;
  Id_Impuesto: number;
  Tasa?: number;
  MontoBase?: number;
}

export type facturas_impuestosPk = "ID";

export class facturas_impuestos implements facturas_impuestosAttributes {
  ID!: number;
  Id_Factura?: number;
  Id_Impuesto!: number;
  Tasa?: number;
  MontoBase?: number;

  // facturas_impuestos belongsTo facturas via Id_Factura
  Id_Factura_factura!: facturas;
  // facturas_impuestos belongsTo tipoimpuestos via Id_Impuesto
  Id_Impuesto_tipoimpuesto!: tipoimpuestos;

}
