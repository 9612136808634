
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Theme, Grid, Typography, FormControl, TextField, Autocomplete, Button } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { productos } from "../../../../models/productos";
import { loadArchivo } from "../../../../utils/services";
import axios from "axios";
import { config } from "../../../../utils/configs";
import { useSetSetting, useSetting } from "../../../../contexts/setting-context";
import { useOrder, useSetOrder } from "../../../../contexts/order-context";
import { useLoginToken } from "../../../../contexts/login-context";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}
const style = {
    color: 'rgb(255,255,255)',
    '&.Mui-checked': {
        color: '#fff',
    },

};
const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function AjustarPrecios(props: Props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [productosList, setproductosList] = useState<productos[]>([]);
    const [value, setValue] = useState<productos | null>(null);
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const loginToken = useLoginToken();

   /*  const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const history = useHistory();
 */
    const handleChange =
        (prop: keyof productos) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (value)
                setValue({ ...value, [prop]: event.target.value });

        };

/* 
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }; */
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>


            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
     
    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);

    }
    //#endregion
    let savingData: boolean = false;
    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setloading(saving);
    }
    const loadArchivos = () => {
        setSavingData(true);
        loadArchivo(
            'productos',
            false,
            ['id_tipo_producto',
                'id_sector_impresion',
                'id_sub_categoria',
                'id_impuesto1',
                'Nombre',
                'CodigoBarras',
                'MontoPrecio1',
                'MontoPrecio2',
                'MontoPrecio3',
                'MontoPrecioOferta',
                'MontoPrecioDivisa',
                'PuntosCantidad',
                'PuntosRating',
                'esPideprecio',
                'esActivo',
                'FechaActual',
                'FechaModificado',
                'ImagenWeb',
                'DescripcionWeb',
                'id_TipoMoneda'], undefined, undefined, ['Nombre'], { esActivo: 1 }
        ).then(
            (resolve) => {
                let res: productos[] = resolve as productos[];
                if (res && res.length) {
                    if (value === null)
                        setValue(res[0])
                    setproductosList(res);
                }
            },
            (error) => { setMessage('err-->' + error) }

        ).finally(() => setSavingData(false));
    }
    const saveData = () => {
        if (!savingData) {
            setSavingData(true, "Guardando datos...");
            try {
                if (value) {
                    if (!value.MontoPrecio1) {
                        setMessage('ok--> Indique Precio');
                        return;
                    }
                    if (!value.MontoPrecioDivisa) {
                        setMessage('ok--> Indique Precio Divisa');
                        return;
                    }
                    //setesAdd(false);
                    if (value.ID) {
                        axios.post(config.SERVER_URI + "/productos/update", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: { id: value.ID, ...value }
                        }).then(result => {
                            setMessage('ok--> Datos Actualizados.');
                            loadArchivos();
                            setValue(value)
                        }).catch(err => {
                            if (err.response && err.response.status && err.response.status === 401)
                                setMessage('err-->Sin Autorización Para continuar.');
                            else
                                if (!err.response)
                                    setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                else
                                    setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);

                        });
                    } else {
                        axios.post(config.SERVER_URI + "/productos/create", {
                            ID: loginToken.ID,
                            hash: loginToken.hash,
                            data: value
                        }).then((result: any) => {
                            setMessage('ok--> Datos Creados.');
                            loadArchivos();
                            setValue(result.data)
                        }).catch(err => {
                            if (err.response && err.response.status && err.response.status === 401) {
                                setMessage('err-->Sin Autorización Para continuar.');
                            }
                            else
                                if (!err.response)
                                    setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                else
                                    setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                        });;
                    }
                }
            } catch (error) {

            } finally {
                setSavingData(false);
            }
        } else {
            setMessage('inf--> Trabajando... Por favor espere!')
        }
    };
    useEffect(() => {
        loadArchivos()
    }, [])
    return (
        <>
            <Titulo />
            <FormControl className="w-100" component="fieldset">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    className="m-0 p-2 w-100"
                >
                    <Grid className=" w-100 p-1 m-0 " item xs={12} sm={12}>
                        <Autocomplete
                            value={value}
                            onChange={(event: any, newValue: productos | null) => {
                                setValue(newValue);
                            }}
                            inputValue={value ? value.Nombre : ''}
                            onInputChange={(event, newInputValue) => {
                                if (value)
                                    setValue({
                                        ...value,
                                        Nombre: newInputValue,
                                    })
                            }}
                            id="controllable-states-demo"
                            options={productosList}
                            autoHighlight
                            getOptionLabel={(option) => option.Nombre ? option.Nombre : ''}
                            renderOption={(props, option) => (
                                <Box
                                    component="li" className="p-0 m-0 " {...props}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="m-0 p-2 w-100"
                                    >
                                        <Grid className=" w-100 p-0 m-0 " item xs={12} sm={12}>
                                            {option.Nombre}
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction='row'
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            className=" w-100 p-0 m-0  alert-warning " xs={12} sm={12}>
                                            <Typography className="p-0 m-0 font-monospace" variant='inherit'>
                                                Bs {option.MontoPrecio1}
                                            </Typography>
                                            <Typography className="p-0 m-0 font-monospace" variant='inherit'>
                                                $ {option.MontoPrecioDivisa}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Box>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Producto"
                                    name="nombre"
                                    variant='filled'
                                    className="bg-body rounded-3 "
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value ? value.Nombre : ''}
                                    onChange={(event: any) => {
                                        if (value)
                                            setValue({
                                                ...value,
                                                Nombre: event.target.value,
                                            })
                                    }}
                                />}
                        />
                    </Grid>
                    <Grid className="   p-1 m-0 w-100" item xs={12} sm={7}>
                        <TextField
                            label="Precio:"
                            name="precio"
                            variant='filled'
                            className="bg-body rounded-3 "
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                className: "text-end font-monospace fs-5"
                            }}
                            fullWidth
                            size='small'
                            value={value ? value.MontoPrecio1 : ''}
                            type='number'
                            onChange={handleChange('MontoPrecio1')}
                        />
                    </Grid>
                    <Grid className="  p-1 m-0 w-100" item xs={12} sm={7}>
                        <TextField
                            label="Precio Divisa:"
                            name="divisa"
                            variant='filled'
                            className="bg-body rounded-3 "
                            InputLabelProps={{
                                shrink: true,

                            }}
                            inputProps={{
                                className: "text-end font-monospace fs-5"
                            }}
                            fullWidth
                            size='small'
                            value={value ? value.MontoPrecioDivisa : ''}
                            type='number'
                            onChange={handleChange('MontoPrecioDivisa')}
                        />
                    </Grid>
                    <Grid className="text-end p-1 m-0 w-100" item xs={12} sm={12}>
                        <Button
                            className="m-md-1  m-auto col-4 bg-black bg-gradient text-white "
                            onClick={() => { saveData(); }}>
                            Guardar
                        </Button>
                    </Grid>

                </Grid>
            </FormControl>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}
