import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { ColoresDPana, ColorsDPana } from "../../utils/Color";
import { Dialog, DialogContent, DialogTitle, Theme } from "@mui/material";
import { DialogProvider, useDialog } from "../../contexts/dialog-context";

const UseStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    }
}));

export interface SimpleDialogProps {
    open: boolean;
    setOpen: any;
    selectedValue: string;
    onClose: (value: string) => void;
    children?: React.ReactNode;
    ThemeColor?: ColoresDPana;
    style?: React.CSSProperties;
    title: string;
    childResponse?: "OK" | "Cancel" | "Yes" | "No" | "Retry";
    handleResponse?: () => void;
    component?: "licencia" | "pago" | "" | "" | "";
}

export default function SimpleDialog(props: SimpleDialogProps) {
    const classes = UseStyles();
    const { onClose, selectedValue, open, children, ThemeColor = ColorsDPana.secundary, title = "" } = props;

    const dialog = useDialog();

    const handleClose = () => {
        onClose(selectedValue);
    };

    useEffect(() => {
        if (open)
            if (dialog.Response === "OK") {
                alert("Cerrar");
            }
    }, [])

    return (
        <DialogProvider >
            <Dialog className={classes.modal} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}   >
                {title !== ''
                    ? <DialogTitle id="simple-dialog-title" style={{ background: `rgb(${ThemeColor[0]},${ThemeColor[1]},${ThemeColor[2]})` }}> {title} </DialogTitle>
                    : ''}
                <DialogContent style={{ padding: 10, background: `rgb(${ThemeColor[0]},${ThemeColor[1]},${ThemeColor[2]})` }}>
                    {children}
                </DialogContent>
            </Dialog>
        </DialogProvider>
    );
}


