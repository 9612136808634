import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./views/login-page";
import ContextProviders from "./contexts";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import MainMenu from "./views/main-menu";
import Order from "./views/order";
import CustomerSelection from "./views/customer-selection";
import TableSelection from "./views/table-selection";
import Recall from "./views/recall";
import Payment from "./views/payment";
import Report from "./views/report/index";
import LandingPage from "./views/landing-page";
import Receipt from "./components/receipt";
import { config } from "./utils/configs";

library.add(fas);
library.add(fab);

function App() {
  return (
    <div className="App">
      <ContextProviders>
        <Router basename={process.env.PUBLIC_URL} >
          <Route path="/" exact component={LandingPage} />
          {/* <Route path="/setup" component={Setup} /> */}
          <Route path="/login" exact component={Login} />
          <Route path="/main-menu" exact component={MainMenu} />
          <Route path="/order" exact component={Order} />
          <Route path="/customer" exact component={CustomerSelection} />
          <Route path="/table" exact component={TableSelection} />
          <Route path="/recall" exact component={Recall} />
          <Route path="/payment" exact component={Payment} />
          <Route path="/report" exact component={Report} />
          <Route path="/receipt/:orderId" component={Receipt} />
          <Route path="/gotoadmin"
            exact render={() => window.location.href = config.gotoadmin} />
          <Route path="/gotodpana"
            render={() => window.open(config.gotodpana, '_blank')} />
          <Route path="/gotowhatsappmsg"
            render={() => window.location.href = "https://wa.me/584143662876?text=Buen%20dia%20estoy%20interesado%20en%20sus%20servicios%3F"} />
          <Route path="/gotowhatsappshare"
            render={() => window.open("https://wa.me/?text=I%20found%20a%20great%20website.%20Check%20out%20this%20link%20https%3A%2F%2Fwww.test.dpana.com.ve%2F", '_blank')} />
        </Router>
      </ContextProviders>
    </div>
  );
}

export default App;
