import React, { HTMLInputTypeAttribute } from "react";
import { SoloNumeros } from "../../utils/helpers";
import "./style.css";

export interface TextInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    children?: undefined;
    esNumeric?: boolean;
    esInactivo?: boolean;
    type?: HTMLInputTypeAttribute | undefined;
}
/* const styles = theme => ({
    input: {
        "&:disabled": {
            color: "green"
        }
    }
}); */
export default function TextInput({ type = 'text', esInactivo, esNumeric, ...restOfProps }: TextInputProps) {
    let escontieneDecimal: boolean = false;
    let control = React.useRef<HTMLInputElement>(null)
    const keydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (esNumeric) {
            escontieneDecimal = control.current?.value.includes('.') as boolean;
            if (!escontieneDecimal) {
                if (e.keyCode === 190) {
                    escontieneDecimal = true;
                }
            } else {
                if (e.keyCode === 190) {
                    e.preventDefault();
                }
            }
        }
        return esNumeric ? SoloNumeros(e) : true;
    }
    return (
        <input ref={control} disabled={esInactivo} type={type} className={"syrup-text-input" + esInactivo ? ' alert-dart' : ''} {...restOfProps}
            onKeyDown={(e) => keydown(e)}>
        </input>)
}