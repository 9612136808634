
import { useEffect, useState } from "react";
import { Box, Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Paper, TextField, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, createFilterOptions } from "@mui/material";
import { makeStyles, styled, } from '@mui/styles';
import { AddBox, LocalOffer } from "@mui/icons-material";
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { tipodescuentos, tipodescuentosAttributes } from "../../../../models/tipodescuentos";
import { loadArchivo } from "../../../../utils/services";
import { useLoginToken } from "../../../../contexts/login-context";
import axios from "axios";
import { config } from "../../../../utils/configs";
import { fechatoDatePicker } from "../../../../utils/helpers";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}
interface tipodescuentostmp extends tipodescuentosAttributes {
    inputValue?: string
}
const style = {
    color: 'rgb(255,255,255)',
    '&.Mui-checked': {
        color: '#fff',
    },

};
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'red',
    },
    '& label:hover': {
        color: 'gold',
    },
    '& label': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
        color: 'white'
    },
    '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'red',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'red',
        },
    },
});
const styledark = {
    color: '#000',
    '&.Mui-checked': {
        color: '#000',
    },

};
const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
const filter = createFilterOptions<tipodescuentostmp>();

export default function ModificarDescuentos(props: Props) {
    const loginToken = useLoginToken();

    const [open, toggleOpen] = useState(false);
    const [descuentosList, setdescuentosList] = useState<tipodescuentostmp[]>([]);
    const [value, setValue] = useState<tipodescuentostmp | null>(null);
    const [dialogValue, setDialogValue] = useState<tipodescuentos>(new tipodescuentos);

    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const textloading='Cargando...';

    const handleChange =
        (prop: keyof tipodescuentostmp) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (value)
                setValue({ ...value, [prop]: event.target.value });
        };

    const handleChangeDlg =
        (prop: keyof tipodescuentos) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (dialogValue)
                setDialogValue({ ...dialogValue, [prop]: event.target.value });
        };
    const validaGuardar = (value: tipodescuentos | tipodescuentostmp): boolean => {
        if (value) {
            if (!value.MontoTasa) {
                setMessage('err--> Indique Valor');
                return false;
            }
            if (!value.Descripcion) {
                setMessage('err--> Indique Descripción');
                return false;
            }
            return true;
        } else return false
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (validaGuardar(dialogValue)) {
            setValue(dialogValue);
            saveData(dialogValue);
            handleClose();
        }
    };
    const handleClose = () => {
        toggleOpen(false);
    };
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>


            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);

    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    //#endregion

    let savingData: boolean = false;
    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setloading(saving);
    }
    const loadArchivos = () => {
        setSavingData(true);
        loadArchivo(
            'tipodescuentos',
            false,
            ['Descripcion',
                'MontoTasa',
                'FechaIni',
                'FechaFin',
                'FechaActual',
                'FechaModificado',
                'esMontoTasaPorcentual',
                'esDescuento',
                'esPorItem',
                'esActivo'
            ], undefined, undefined, ['Descripcion'], { esActivo: 1 }
        ).then(
            (resolve) => {
                let res: tipodescuentos[] = resolve as tipodescuentos[];
                if (res && res.length) {
                    if (value === null)
                        setValue(res[0])
                    setdescuentosList(res);
                }
            },
            (error) => { setMessage('err-->' + error) }

        ).finally(() => setSavingData(false));
    }

    const saveData = (value: tipodescuentos | tipodescuentostmp) => {
        if (!savingData) {
            setSavingData(true, "Guardando datos...");
            try {
                if (value) {
                    if (validaGuardar(value)) {
                        value.esActivo = 1;
                        value.FechaModificado = new Date();
                        if (value.ID) {
                            axios.post(config.SERVER_URI + "/tipodescuentos/update", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: { id: value.ID, ...value }
                            }).then(() => {
                                setMessage('ok--> Datos Actualizados.');
                                loadArchivos();
                                setValue(value)
                            }).catch(err => {
                                if (err.response && err.response.status && err.response.status === 401)
                                    setMessage('err-->Sin Autorización Para continuar.');
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        } else {
                            value.FechaActual = new Date();
                            axios.post(config.SERVER_URI + "/tipodescuentos/create", {
                                ID: loginToken.ID,
                                hash: loginToken.hash,
                                data: value
                            }).then((result: any) => {
                                setMessage('ok--> Datos Creados.');
                                loadArchivos();
                                setValue(result.data)
                            }).catch(err => {
                                setValue(null);
                                if (err.response && err.response.status && err.response.status === 401) {
                                    setMessage('err-->Sin Autorización Para continuar.');
                                }
                                else
                                    if (!err.response)
                                        setMessage('err-->' + err.message ? err.message : "Error Desconocido");
                                    else
                                        setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
                            });
                        }
                    }
                }
            } catch (error) {

            } finally {
                setSavingData(false);
                setDialogValue(new tipodescuentos);
            }
        } else {
            setMessage('inf--> Trabajando... Por favor espere!')
        }
    };

    useEffect(() => {

        if (!open)
            setDialogValue(new tipodescuentos);
        else {
            setDialogValue({
                ...dialogValue,
                MontoTasa: 1,
                esActivo: 1,
                esDescuento: 1,
                esMontoTasaPorcentual: 1,
                esPorItem: 1
            })
        }
    }, [open])
    useEffect(() => {
        loadArchivos()
    }, [])
    return (
        <>
            <Titulo />
            <FormControl className="w-100" component="fieldset">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="m-0 p-2 w-100"
                >
                    <Grid className="w-100 p-1 m-0 " item xs={12} sm={12}>
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            value={value}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    toggleOpen(true);
                                    let dv: tipodescuentos = new tipodescuentos;
                                    dv.Descripcion = newValue.replace('add ', '').replaceAll('"', '');
                                    dv.MontoTasa = 0;
                                    setDialogValue(dv);
                                } else if (newValue && newValue.inputValue) {
                                    toggleOpen(true);
                                    let dv: tipodescuentos = new tipodescuentos;
                                    dv.Descripcion = newValue.Descripcion.replace('add ', '').replaceAll('"', '');
                                    dv.MontoTasa = 0;
                                    setDialogValue(dv);
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.Descripcion);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        ID: 0,
                                        inputValue: inputValue,
                                        Descripcion: `add "${inputValue}"`,
                                        MontoTasa: 0,
                                        esActivo: 1,
                                        esDescuento: 1,
                                        esMontoTasaPorcentual: 1,
                                        esPorItem: 1
                                    });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="firstname"
                            options={descuentosList ? descuentosList : []}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.Descripcion;
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li" className="p-0 m-0 " {...props}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="m-0 p-2 w-100"
                                    >
                                        <Grid className=" w-100 p-0 m-0 " item xs={12} sm={12}>
                                            {option.Descripcion.toLowerCase().includes('add')
                                                ?
                                                <Paper elevation={2} className="p-1 m-0 bg-warning w-100" >
                                                    <AddBox className="text-black pr-1 fs-2" />
                                                    <b className="px-1">{option.Descripcion.replace('add', '')}</b>
                                                </Paper>
                                                : option.Descripcion
                                            }
                                        </Grid>
                                    </Grid>

                                </Box>
                            )}
                            freeSolo
                            renderInput={(params) => (

                                <CssTextField
                                    {...params}
                                    className="  text-white   rounded-3 "
                                    label="Buscar por Descripción"
                                    name="firstname"
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={value?.Descripcion}
                                />
                            )}
                        />
                    </Grid>
                    {value
                        ? <>
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                                <TextField
                                    label="Tasa/Valor"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value?.Descripcion}
                                    onChange={handleChange('Descripcion')}
                                />
                            </Grid>
                            <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                                <TextField
                                    label="Tasa/Valor"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body  rounded-3 col-xs-12 col-sm-6"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: "text-end "
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value?.MontoTasa}
                                    type='number'
                                    onChange={handleChange('MontoTasa')}
                                />
                            </Grid>
                            <Grid
                                className=" p-1 m-0 w-100" xs={12} sm={12}
                                item
                                container
                                direction='row'
                                justifyContent="space-between"
                                alignItems="flex-start" >
                                <Grid item className=" p-1 m-0 w-100" xs={12} sm={6}>
                                    <TextField
                                        label="Fecha Ini:"
                                        name="tasa"
                                        variant='filled'
                                        className="bg-body rounded-3"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size='small'
                                        value={fechatoDatePicker(value.FechaIni)}
                                        type='date'
                                        onChange={handleChange('FechaIni')}
                                    />
                                </Grid>
                                <Grid item className=" p-1 m-0 w-100" xs={12} sm={6}>
                                    <TextField
                                        label="Fecha Fin:"
                                        name="tasa"
                                        variant='filled'
                                        className="bg-body  rounded-3"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size='small'
                                        value={fechatoDatePicker(value.FechaFin)}
                                        type='date'
                                        onChange={handleChange('FechaFin')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className=" p-1 mt-1 w-100 text-white border rounded-3" item xs={12} sm={12}>
                                <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={value.esMontoTasaPorcentual} name="row-radio-buttons-group1" value={value.esMontoTasaPorcentual} onChange={handleChange('esMontoTasaPorcentual')}>
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={true} control={<Radio sx={{ ...style }} />} label="Porcentaje" />
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={false} control={<Radio sx={{ ...style }} />} label="Valor" />
                                </RadioGroup>
                            </Grid>
                            <Grid className=" p-1 mt-1 w-100 text-white border rounded-3" item xs={12} sm={12} >
                                <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={value.esDescuento} name="row-radio-buttons-group2" value={value.esDescuento} onChange={handleChange('esDescuento')}>
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={true} control={<Radio sx={{ ...style }} />} label="Descuento" />
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={false} control={<Radio sx={{ ...style }} />} label="Recarga" />
                                </RadioGroup>
                            </Grid>
                            <Grid className=" p-1 mt-1 w-100 text-white border rounded-3" item xs={12} sm={12}>
                                <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={value.esPorItem} name="row-radio-buttons-group3" value={value.esPorItem} onChange={handleChange('esPorItem')}>
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={true} control={<Radio sx={{ ...style }} />} label="Por Item" />
                                    <FormControlLabel className="col-xs-12 col-6 m-0" value={false} control={<Radio sx={{ ...style }} />} label="Factura" />
                                </RadioGroup>
                            </Grid>
                            <Grid direction='row' className="text-end p-1 m-0 w-100" item xs={12} sm={12}>
                                <Button
                                    size='small'
                                    className="m-md-1  m-auto col-4 bg-black bg-gradient text-white "
                                    onClick={() => {
                                        if (value)
                                            saveData(value);
                                    }}>
                                    Guardar
                                </Button>
                            </Grid>
                        </>
                        : ''

                    }

                </Grid>
            </FormControl>

            <Dialog className="p-1 vw-25" open={open} onClose={handleClose}>
                <DialogTitle className=" text-white bg-dark rounded round-3"><LocalOffer /> Agregar Descuento/Recarga</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className="m-0 p-1 w-100"
                    >
                        <Grid className="p-1 m-0" item xs={12} sm={12}>
                            <TextField
                                required
                                label="Descripción"
                                name="nombre"
                                variant="filled"
                                fullWidth
                                size='small'
                                value={dialogValue.Descripcion}
                                onChange={handleChangeDlg('Descripcion')}
                            />
                        </Grid>
                        <Grid className=" p-1 m-0 w-100 text-end" item xs={12} sm={12}>
                            <TextField
                                label="Tasa/Valor"
                                name="tasa"
                                variant='filled'
                                className="bg-body rounded-3 w-50"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: "text-end "
                                }}
                                fullWidth
                                size='small'
                                value={dialogValue?.MontoTasa}
                                type='number'
                                onChange={handleChangeDlg('MontoTasa')}
                            />
                        </Grid>
                        <Grid
                            className=" p-1 m-0 w-100" xs={12} sm={12}
                            item
                            container
                            direction='row'
                            justifyContent="space-between"
                            alignItems="flex-start" >
                            <Grid item className=" p-1 m-0 w-100" xs={12} sm={6}>
                                <TextField
                                    label="Fecha Ini:"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body rounded-3"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={fechatoDatePicker(dialogValue?.FechaIni)}
                                    type='date'
                                    onChange={handleChangeDlg('FechaIni')}
                                />
                            </Grid>
                            <Grid item className=" p-1 m-0 w-100" xs={12} sm={6}>
                                <TextField
                                    label="Fecha Fin:"
                                    name="tasa"
                                    variant='filled'
                                    className="bg-body  rounded-3"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={fechatoDatePicker(dialogValue?.FechaFin)}
                                    type='date'
                                    onChange={handleChangeDlg('FechaFin')}
                                />
                            </Grid>


                        </Grid>
                        <Grid className=" p-1 mt-1 w-100 text-black border rounded-3" item xs={12} sm={12}>
                            <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={dialogValue.esMontoTasaPorcentual} name="row-radio-buttons-group1" value={dialogValue.esMontoTasaPorcentual} onChange={handleChangeDlg('esMontoTasaPorcentual')}>
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={1} control={<Radio sx={{ ...styledark }} />} label="Porcentaje" />
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={0} control={<Radio sx={{ ...styledark }} />} label="Valor" />
                            </RadioGroup>
                        </Grid>
                        <Grid className=" p-1 mt-1 w-100 text-black border rounded-3" item xs={12} sm={12} >
                            <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={dialogValue.esDescuento} name="row-radio-buttons-group2" value={dialogValue.esDescuento} onChange={handleChangeDlg('esDescuento')}>
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={1} control={<Radio sx={{ ...styledark }} />} label="Descuento" />
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={0} control={<Radio sx={{ ...styledark }} />} label="Recarga" />
                            </RadioGroup>
                        </Grid>
                        <Grid className=" p-1 mt-1 w-100 text-black border rounded-3" item xs={12} sm={12}>
                            <RadioGroup className=" p-0 m-0 justify-content-start" row defaultValue={dialogValue.esPorItem} name="row-radio-buttons-group3" value={dialogValue.esPorItem} onChange={handleChangeDlg('esPorItem')}>
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={1} control={<Radio sx={{ ...styledark }} />} label="Por Item" />
                                <FormControlLabel className="col-xs-12 col-6 m-0" value={0} control={<Radio sx={{ ...styledark }} />} label="Factura" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <DialogContentText className="text-end" >
                        <Typography variant='inherit' >
                            Rellenar los Campos necesarios
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSubmit} variant='contained' >Agregar</Button>
                </DialogActions>
                <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            </Dialog>

            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}
