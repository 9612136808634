import Button from "../../components/button"
import VerticalCenter from '../../components/vertical-center';
import { ColoresDPana, ColorsDPana } from '../../utils/Color';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import CheckLoginToken from '../../components/check-login-token';
import { useOrder, useSetOrder } from '../../contexts/order-context';
import { useLoginToken, useSetLoginToken } from '../../contexts/login-context';
import './style.css'
import { Button as MuiButton, Card, Container, Grid, Popover, Typography } from "@mui/material";
import Badge from '@mui/material/Badge';
import { useEffect, useState } from "react";
import { useSetSetting, useSetting } from "../../contexts/setting-context";
import { tipoSync } from "../../services/crudorden.service";
import Orden from "../../models/Entities/Orden.model";
import { loadArchivo } from "../../utils/services";
import SimpleToast from "../../components/simple-toast";
import SpinnerLoading from "../../components/spinner-loading";
import SimpleDialog from "../dialogs/simple-dialog";
import LoginDialog from "../dialogs/login-dialog";
import { pedidos } from "../../models/pedidos";
import { enuStatusPedido, enuTipoOrigenPedido, enuTipoPedidoDespacho } from "../../utils/enums";
import PersonIcon from '@mui/icons-material/Person';
import { config } from '../../utils/configs';
import logo from './assets/imagenes/logo.png';
interface itemSync {
    id: number;
    tipo: tipoSync;
    tabla: string;
    fechaActual: Date;
}

export default function MainMenu() {
    const history = useHistory();
    const setting = useSetting();
    const setSetting = useSetSetting();
    const setPedidoActual = useSetOrder();
    const setLoginToken = useSetLoginToken();
    const loginToken = useLoginToken();
    const [lastPedidoID, setLastPedidoID] = useState();
    const [lastPedidoList, setlastPedidoList] = useState<itemSync[]>([]);
    const [pendingPedidos, setpendingPedidos] = useState<pedidos[]>([]);
    const [message, setMessage] = useState("");

    const [openPopover, setopenPopover] = useState(false);
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const [open, setOpen] = useState(false);
    const CrearOrden = (tipoPedido: enuTipoPedidoDespacho = enuTipoPedidoDespacho.VentaDirecta) => {
        let newOrder: Orden = new Orden;
        newOrder = new Orden();
        newOrder.initOrden(setting, tipoPedido, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
        setSetting(setting);
        setPedidoActual(newOrder);
    }
    const createButton = function (text: string, icon: IconProp, url: string, themeColor: ColoresDPana = ColorsDPana.primary, beforeMove = () => { }) {
        return (
            <div style={{ paddingTop: "40%", minHeight: "4em", position: "relative" }}>
                : <Button
                    onClick={() => {
                        beforeMove();
                        if (url.includes('/order')) {
                            CrearOrden(enuTipoPedidoDespacho.VentaDirecta);
                        }
                        if (url.includes('/gotoadmin')) {
                            window.open(config.gotoadmin, '_blank')
                        } else
                            history.push(url);
                    }}
                    themeColor={themeColor}
                    style={{
                        position: "absolute",
                        width: "100%",
                        whiteSpace: "nowrap",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }}
                >
                    {text === "Ordenes"
                        ? <Badge
                            badgeContent={pendingPedidos ? pendingPedidos.filter(x => Number(x.id_defTipoSatusPedido) <= 1).length : 0}
                            color='success'
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                            max={1000}
                        >
                            <FontAwesomeIcon icon={icon} style={{ fontSize: "2em" }} />

                        </Badge>
                        : <FontAwesomeIcon icon={icon} style={{ fontSize: "2em" }} />
                    }
                    <br />
                    {text}
                </Button>
            </div>
        )
    }

    const loadPedidosPendientes = () => {
        loadArchivo(
            'pedidos',
            false,
            ['esImpreso', 'esPagado', 'esDespachado', 'NroItems', 'NroPedido', 'FechaPedido', 'FechaActual', 'id_defTipoPedido', 'id_defTipoOrigenPedido', 'id_defTipoSatusPedido'],
            undefined, 'ID',
            undefined,
            { esImpreso: 0, esPagado: 0, esDespachado: 0 }
        ).then(
            (resolve) => { setpendingPedidos(resolve as Orden[]) },
            (error) => { setMessage('err-->' + error) }
        );
    }


    //#region Dialogs
    const handleClose = () => {
        setopenPopover(false);
    };
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };

    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }
    const loadLoginDialog = () => {
        setChildrenSDialog(<LoginDialog handleResponse={loginDialogRes} titulo='Cambio de Usuario' esPIN={true} ></LoginDialog>);
        handleClickOpenSimpleDialog();
    }
    //#endregion


    useEffect(() => {
        let isMounted = true
        const intervalId = setInterval(async () => {
            if (!isMounted) return
            /*  let lastid: any = await CrudOrdenService.getSync('pedidos');
             let actId = setting.lastSyncs && setting.lastSyncs.length > 0 ? setting.lastSyncs.find((x: any) => x.Nombre === 'pedidos')?.sync_id : 0;
             if (actId && lastid)
                 if (actId < lastid.sync_id) {
                     setLastPedidoID(lastid.sync_id);
                     setopenPopover(true);
                 } */
        }, 5000)

        return () => {
            clearInterval(intervalId); //This is important
            isMounted = false // Let's us know the component is no longer mounted.
        }
    }, [useState])
    useEffect(() => {
        loadPedidosPendientes();
    }, [])
    return (
        <>
            <Container className="vh-100">
                {
                    <CheckLoginToken />
                }
                <VerticalCenter>

                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className="m-0 p-0 w-100"
                    >
                        <Grid
                            item
                            container
                            xs={2} md={2}
                            className="m-0 p-0  "
                        >
                            <div className="text-center img-responsive p-2">
                                <img src={'./assets/imagenes/logo.png'} className="rounded" style={{ maxWidth: 150 }} alt="..." /></div>
                        </Grid>
                        <Grid
                            item
                            container
                            direction='column-reverse'
                            justifyContent="center"
                            alignItems="flex-end"
                            xs={10} md={6}
                            className="m-0 p-0  "
                        >
                            <span className="h6 p-0 text-left text-white">{config.nombreEmpresa2}</span>
                            <div className="h1 text-center  text-white">{config.nombreEmpresa}</div>
                        </Grid>
                        <Grid
                            item
                            container
                            spacing={1}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            className="m-0 p-0 h-100 text-white"
                            xs={12} md={3}
                        >
                            <Typography variant='caption'>
                                Bienvenido
                            </Typography>
                            <Typography variant='h6'>
                                {loginToken.Nombre}
                            </Typography>
                            <MuiButton className="bg-danger bg-opacity-25" size='small' variant='contained' startIcon={<PersonIcon />}
                                onClick={() => { loadLoginDialog( ) }}>
                                Cambiar
                            </MuiButton>
                        </Grid>

                    </Grid>

                    <div className="main-menu-grid">

                        {createButton("Ordenes", "search", "/recall", ColorsDPana.primary)}
                        {createButton("Mesas", "chair", "/table", ColorsDPana.secundary, () => {
                            /* setOrder({
                                id_defTipoPedido : enuTipoPedidoDespacho.ComerAqui
                            }); */
                        })}
                        {createButton("Pick up", "phone", "/customer", ColorsDPana.secundary, () => {
                            let newOrder: Orden = new Orden;
                            newOrder = new Orden();
                            newOrder.initOrden(setting, enuTipoPedidoDespacho.Pickup, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
                            setSetting(setting);
                            setPedidoActual(newOrder);
                        })}
                        {createButton("Delivery", "car", "/customer", ColorsDPana.secundary, () => {
                            let newOrder: Orden = new Orden;
                            newOrder = new Orden();
                            newOrder.initOrden(setting, enuTipoPedidoDespacho.Delivery, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
                            setSetting(setting);
                            setPedidoActual(newOrder);
                        })}
                        {createButton("POS", "shopping-bag", "/order", ColorsDPana.secundary, () => {
                            /* setOrder({
                                id_defTipoPedido: enuTipoPedidoDespacho.Pickup
                            }); */
                        })}
                        {createButton("Reportes", "file-alt", "/report", ColorsDPana.secundary)}
                        {createButton("dPana Admin", "cog", "/gotoadmin", ColorsDPana.secundary)}
                        {createButton("Logout", "sign-out-alt", "/login", ColorsDPana.secundary, () => {
                            setLoginToken({});
                        })}
                    </div>
                    <div className="h text-center m-3 text-white"  ><span className="h6 text-left text-white"  ><small>Powered by</small> </span>KALIXTO</div>

                </VerticalCenter>
            </Container>
            <Popover
                open={openPopover}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 5, left: 5 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
                style={{ opacity: '0.9' }}
            >
                <Card className="p-1 bg-warning" sx={{ display: 'flex', maxWidth: 400 }}  >
                    <Grid container spacing={2}>
                        <Grid xs={12} md={12} item>
                            <Typography component="div" variant="h6">
                                {`Nuevo Pedido ${lastPedidoID}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Popover>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpen} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>
    )
}

