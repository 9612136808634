
import UrlImagen from "../models/Entities/urlImage.model";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
//import crypto from "crypto";
import * as bcrypt from 'bcryptjs';

export function truncateString(str: string, num: number) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
}

export enum enutipodlgOpc {
    ninguno,
    mesero,
    mesa,
    cliente,
    pedir
}
export function fechatoDatePicker(fecha: Date | undefined): string {
    if (fecha) {
        const dateNow = new Date(fecha);
        if (dateNow) {
            const year = dateNow.getFullYear();
            const monthWithOffset = dateNow.getUTCMonth() + 1;
            const month =
                monthWithOffset.toString().length < 2
                    ? `0${monthWithOffset}`
                    : monthWithOffset;
            const date =
                dateNow.getUTCDate().toString().length < 2
                    ? `0${dateNow.getUTCDate()}`
                    : dateNow.getUTCDate();
            return `${year}-${month}-${date}`;
        } return '';

    } return '';
}
interface transcurridoModel {
    dias?: number,
    horas?: number,
    minutos?: number,
    minutosGral?: number,
    segundos?: number,
    text?: string,
    fecha?: Date
}
export function tiempoTranscurrido(Fecha: any): transcurridoModel | undefined {
    Fecha = new Date(Fecha);
    let result: transcurridoModel;
    if (Fecha) {
        let now = new Date();;
        var diffMs = (now.getTime() - Fecha.getTime()); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        var ms = diffMs;
        /* 
        let hh = dateFormat(Fecha, "HH:MM")
        var min = Math.floor((ms / 1000 / 60) << 0);
        var sec = Math.floor((ms / 1000) % 60); */

        let dias: string = diffDays > 0 ? diffDays + 'd ' : '';
        let minutos: string = diffMins > 0 ? diffMins + 'm' : '';
        let horas: string = diffHrs > 0 ? diffHrs + 'h ' : '';
        let text: string = `${dias}${horas}${minutos}`;
        result = {
            minutosGral: diffMs / 60000,
            dias: diffDays,
            horas: diffHrs,
            minutos: diffMins,
            fecha: Fecha,
            text: text
        }
        return result;
    }
    return undefined;
}
//TODO:Nueo 27/01/2024
/* const saltRounds = 10;
const password = 'miContraseña';
// Verificar la contraseña
const hashedPassword = '$2a$10$OxIw5dC.G/HF3hN3m/4E0.w1wYyNbOzayUWf8s6p/MFjJpupNAn/W';
bcrypt.compare(password, hashedPassword, function(err, result) {
    if (!err) {
        console.log('La contraseña es válida:', result);
    } else {
        console.error('Error al verificar la contraseña:', err);
    }
}); */
// Generar un hash de contraseña 
export function sha256(str: string) {
    debugger;
    let res:string="";
    bcrypt.hash(str, 10, function(err, hash) {
        // Guardar el hash en la base de datos
        if (!err) {
            console.log('Hash de contraseña:', hash);
            res=hash;
        } else {
            console.error('Error al generar el hash:', err);
        }
    });
return res;
   // return crypto.createHmac("SHA256", "userDefinedSecret").update(str).digest("hex");
}


export function addDefaultSrcOnError(ev: any) {
    if (ev) {
        ev.target.src = './assets/imagenes/comida1.jpg';
        ev.target.style = 'opacity:0.2';
    }
}
export function addDefaultImageComida(): string {
    return './assets/imagenes/comida1.jpg';
}
export function useStateBasedOnProps<T>(propValue: T): [T, Dispatch<SetStateAction<T>>] {
    const [, update] = useState(false);

    const [state, setState] = useMemo(() => {
        const state = {} as { value?: T; prevPropValue?: T };
        return [
            state,
            (value: SetStateAction<T>) => {
                if (value instanceof Function) {
                    state.value = value(state.value!);
                } else {
                    state.value = value;
                }
                update((tick) => !tick);
            }
        ];
    }, [update]);

    if (state.prevPropValue !== propValue) {
        state.prevPropValue = propValue;
        state.value = propValue;
    }

    return [state.value!, setState];
}
export const camelCase = (str: string) => {
    let string = str.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ')
        .reduce((result: any, word: string) => result + capitalize(word.toLowerCase()))
    return string.charAt(0).toLowerCase() + string.slice(1)
}

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)


export const undefToNumber = (num: any): number => {
    //  !Number.isNaN(Number(+x.MontoPrecio))
    if (Number.isNaN(num))
        alert("Numero NAN")
    if (Number.isNaN(Number(num)) || !num) {
        return 0;
    } else {
        return +Number(num);
    }
}
export function getFormattedDate(date: Date): string {
    //  date = convertTZ(date, "America/Caracas");
    /*  let d: string = new Date(date).toISOString().
         replace(/T/, ' ').      // replace T with a space
         replace(/\..+/, '')     // delete the dot and everything after */
    return date.toLocaleString("es-SP", {
        timeZone: "America/Caracas",
        hour12: false,
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
}
function convertTZ(date: any, tzString: any) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}
export function isValidDate(s: string) {
    // Assumes s is "mm/dd/yyyy"
    if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
        return false;
    }
    const parts = s.split('/').map((p: string) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
}
export function mysqlToDate(timestamp: string) {
    //function parses mysql datetime string and returns javascript Date object
    //input has to be in this format: 2007-06-05 15:26:02
    var regex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])T(?:([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
    var parts: any = timestamp.replace(regex, "$1 $2 $3 $4 $5 $6").split(' ');
    return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
}
export function SoloNumeros(e: { keyCode: number; preventDefault: () => void; }) {
    //alert(e.keyCode);
    if (
        (e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105) &&
        e.keyCode !== 190 && e.keyCode !== 110 && e.keyCode !== 8 && e.keyCode !== 9) {
        e.preventDefault();
    }
};
export function esContieneDecimales(e: { keyCode: number; preventDefault: () => void; }) {
    //alert(e.keyCode);
    if (
        (e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105) &&
        e.keyCode !== 190 && e.keyCode !== 110 && e.keyCode !== 8 && e.keyCode !== 9) {
        e.preventDefault();
    }
};
export function formatNumber(inputNumber: any) {
    let formetedNumber = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return (formetedNumber);
};
/* export function deepEqual(object1: { [x: string]: any; }, object2: { [x: string]: any; }) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
} */
export function deepEqual(object1: { [x: string]: any; }, object2: { [x: string]: any; }): boolean {
    // Verifica si son el mismo objeto (referencia)
    if (object1 === object2) {
        return true;
    }

    // Verifica si ambos son objetos
    if (!(object1 instanceof Object) || !(object2 instanceof Object)) {
        return false;
    }

    // Obtiene las claves de ambos objetos
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    // Verifica si la cantidad de claves es la misma
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Verifica cada propiedad recursivamente
    for (const key of keys1) {
        if (!object2.hasOwnProperty(key)) {
            return false; // La segunda no tiene la misma propiedad
        }
        if (!deepEqual(object1[key], object2[key])) {
            return false; // Las propiedades no son iguales
        }
    }

    // Si pasó todas las verificaciones, los objetos son iguales
    return true;
}
/**
 * Devuelve primer valor de json 
 * 
 */
export function imgtoJson(json: string) {
    let j: any[] = JSON.parse(json);
    if (j) {
        if (j.length > 0)
            return j[0].url;
        else
            return "";
    }
    else
        return "";
}

function isObject(object: null) {
    return object != null && typeof object === 'object';
}

/**
 * make a copy of the array, then replace the first target found with the replacement
 * if there isn't a replacement, then just remove the item instead,
 * 
 * only first target is replaced
 * 
 * return false if the target isn't in the array.
 */
export function findAndReplace(array: any[], target: any, replacement?: any | null) {
    let newArray = [...array];
    for (let i = 0; i < newArray.length; i++) {
        const item = newArray[i];
        if (item === target) {
            if (replacement) {
                newArray[i] = replacement;
            } else {
                newArray.splice(i, 1);
            }
            return newArray;
        }
    }
    return false;
}
export const AudioPlay = (file: any) => {
    new Audio(`../assets/sounds/${file}`).play();    //under folder public
};

export function calcularIVA(tasa: number, monto: number, esbase: boolean = true): number {
    if (esbase) {
        return monto / (1 + (tasa / 100));
    } else {
        return monto * (1 + (tasa / 100));
    }
}

export function convertURLImage(item: any) {
    let urlimg: UrlImagen[] = [];
    try {
        if (item.ImagenWeb == '')
            return;
        try {
            urlimg = JSON.parse(item.ImagenWeb);
            if (urlimg.length > 0) {
                if (urlimg[0].url != '') {
                    let s = "http://localhost:8080/dl/sushibarstrike_v1_UD4u/db/"
                    item.Imagen = urlimg[0].url.replace(s, "http://sushibarstrike.com.ve/imagenes/db/");
                }
            }
        } catch (error) {
            console.log("errlog:" + error);
        }
    } catch (error) {
        console.log("errlog:" + error);
    }
}

/* export async function  toastCtrl(msj: string, tipomsj: 'error' | 'info' | 'warning' | 'acept', submsj: string = '', duracion: number = 1000, posicion: 'top' | 'bottom' | 'middle' = 'top') {
    let toastCtrl = new ToastController();
    let cls: string = 'toastAcept';

    
    switch (tipomsj) {
        case 'acept':
            cls = 'toastAcept'
            break;
        case 'error':
            cls = 'toastError'
            break;
        case 'info':
            cls = 'toastInfo'
            break;
        case 'warning':
            cls = 'toastWarning'
            break;

    }
    const toast = await toastCtrl.create({
        message: `<div ><strong>${msj}</strong></div><h6>${submsj}</h6>`,
        mode: 'ios',
        duration: duracion,
        position: posicion,
        cssClass: cls
    });
    toast.present();
}
 */
export const Status = {
    NEW: "NEW",
    DELETED: "DELETED",
    VOIDED: "VOIDED",
    OPEN: "OPEN"
}