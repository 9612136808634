import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react"

export type Dialog = {
    Response?: "OK" | "Cancel" | "Yes" | "No" | "Retry";
    handleOK?: () => void;
    handleCancel?: () => void;
    handleYes?: () => void;
    handleNo?: () => void;
    handleRetry?: () => void;
};

export const DialogContext = createContext<Dialog>({});
export const SetDialogContext = createContext<React.Dispatch<React.SetStateAction<Dialog>>>({} as React.Dispatch<React.SetStateAction<Dialog>>);

export function useDialog() {
    return useContext(DialogContext);
}

export function useSetDialog() {
    return useContext(SetDialogContext);
}
export interface Props {
    children?: any;
}
export function DialogProvider({ children }: Props) {
    const [DialogApp, setDialogApp] = useState<Dialog>({});

    return (
        <DialogContext.Provider value={DialogApp}>
            <SetDialogContext.Provider value={setDialogApp}>
                {children}

            </SetDialogContext.Provider>
        </DialogContext.Provider>
    );
}