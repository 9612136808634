import type { estaciones_sesiones } from './estaciones_sesiones';
import type { pedidos_detalle_modificadores } from './pedidos_detalle_modificadores';
import type { usuarios } from './usuarios';

export interface pedidos_detalle_modificadores_movAttributes {
  ID: number;
  id_Pedido_DetalleModificador?: number;
  id_Usuario?: number;
  id_EstacionesSesiones?: number;
  id_defTipoMovimientoPedido?: number;
  id_defTipoStatusPedido?: number;
  Cantidad?: number;
  Mensaje?: string;
  FechaActual?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;
}

export type pedidos_detalle_modificadores_movPk = "ID";

export class pedidos_detalle_modificadores_mov implements pedidos_detalle_modificadores_movAttributes {
  ID!: number;
  id_Pedido_DetalleModificador?: number;
  id_Usuario?: number;
  id_EstacionesSesiones?: number;
  id_defTipoMovimientoPedido?: number;
  id_defTipoStatusPedido?: number;
  Cantidad?: number;
  Mensaje?: string;
  FechaActual?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;

  // pedidos_detalle_modificadores_mov belongsTo estaciones_sesiones via id_EstacionesSesiones
  id_EstacionesSesiones_estaciones_sesione!: estaciones_sesiones;
  // pedidos_detalle_modificadores_mov belongsTo pedidos_detalle_modificadores via id_Pedido_DetalleModificador
  id_Pedido_DetalleModificador_pedidos_detalle_modificadore!: pedidos_detalle_modificadores;
  // pedidos_detalle_modificadores_mov belongsTo usuarios via id_Usuario
  id_Usuario_usuario!: usuarios;

}
