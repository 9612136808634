import React from "react";
import { ColoresDPana, ColorsDPana } from '../../utils/Color';

interface LabelProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    children?: React.ReactNode;
    themeColor?: ColoresDPana;
    style?: React.CSSProperties;
}

const GRADIENT_RANGE = 60;
const DOT_RADIUS = 2;
const DOT_TRANSPARENCY = 0.1;

export default function Label({ children, style, themeColor = ColorsDPana.dark_gray, ...restOfProps }: LabelProps) {
    const labelStyle: React.CSSProperties = {
        color: "white",
        background: `linear-gradient(to top, rgb(${Math.max(themeColor[0] - GRADIENT_RANGE, 0)}, ${Math.max(themeColor[1] - GRADIENT_RANGE, 0)}, ${Math.max(themeColor[2] - GRADIENT_RANGE, 0)}), rgb(${themeColor[0]}, ${themeColor[1]}, ${themeColor[2]}), rgb(${Math.min(themeColor[0] + GRADIENT_RANGE, 255)}, ${Math.min(themeColor[1] + GRADIENT_RANGE, 255)}, ${Math.min(themeColor[2] + GRADIENT_RANGE, 255)})),
        repeating-linear-gradient(45deg, rgba(255,255,255,${DOT_TRANSPARENCY}) ${DOT_RADIUS}px, rgba(255,255,255,0) ${DOT_RADIUS * 2}px, rgba(255,255,255,${DOT_TRANSPARENCY}) ${DOT_RADIUS * 3}px),
        repeating-linear-gradient(135deg, rgba(255,255,255,${DOT_TRANSPARENCY}) ${DOT_RADIUS}px, rgba(255,255,255,0) ${DOT_RADIUS * 2}px, rgba(255,255,255,${DOT_TRANSPARENCY}) ${DOT_RADIUS * 3}px) `,
        textShadow: "0 0 4px black",
        padding: "2px",
        margin: 0,
        backgroundBlendMode: "overlay",
        ...style
    }

    return <label style={labelStyle} {...restOfProps}>{children}</label>;
}