
import { useState } from "react";
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { useSetSetting, useSetting } from "../../../../contexts/setting-context";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;

}
const style = {
    color: '#fff',
    '&.Mui-checked': {
        color: '#fff',
    },

};
const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function TipoPrecios(props: Props) {

    const setting = useSetting();
    const setSetting = useSetSetting();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(setting.TipoPrecioActual);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(Number((event.target as HTMLInputElement).value));
        setting.TipoPrecioActual = Number((event.target as HTMLInputElement).value);
        setSetting(setting);
    };
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>


            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);

    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    //#endregion

    return (
        <>
            <Titulo />
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel className="text-white text-uppercase" value="1" control={<Radio sx={{ ...style }} />} label="Precio Manual" />
                    <FormControlLabel className="text-white text-uppercase" value="2" control={<Radio sx={{ ...style }} />} label="precio Detal " />
                    <FormControlLabel className="text-white text-uppercase" value="3" control={<Radio sx={{ ...style }} />} label="precio Mayor" />
                    <FormControlLabel className="text-white text-uppercase" value="4" control={<Radio sx={{ ...style }} />} label="precio Minimo" />
                    <FormControlLabel className="text-white text-uppercase" value="5" control={<Radio sx={{ ...style }} />} label="precio Divisa" />
                    <FormControlLabel className="text-white text-uppercase" value="6" control={<Radio sx={{ ...style }} />} label="precio Oferta " />
                </RadioGroup>
            </FormControl>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}