import React from "react";
import { CSSProperties } from "react";
import { ColoresDPana } from "../utils/Color";
import InputGroup from "./input-group";
import Label from "./label";
import TextInput, { TextInputProps } from "./text-input";

export interface LabeledTextInputProps extends
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title: string,
    value: string,
    labelStyle?: CSSProperties,
    labelColorTheme?: ColoresDPana,
    inputProps?: TextInputProps,
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    sololectura?: boolean,
    multiLine?: boolean,
    esNumeric?: boolean,
    esInactivo?: boolean
}

export default function LabeledTextInput({ esInactivo, multiLine, sololectura, esNumeric, title, value, onChange,
    labelColorTheme, inputProps, labelStyle, ...restOfProps }: LabeledTextInputProps) {
    if (title.length >= 10) {
        if (labelStyle)
            labelStyle.width = "8em";
    }
    if (title.length >= 20) {
        if (labelStyle)
            labelStyle.width = "12em";
    }
    return (
        <InputGroup  {...restOfProps}>
            <Label style={labelStyle} themeColor={labelColorTheme}>{title}</Label>
            <TextInput esInactivo={esInactivo} className="w-100" value={value} readOnly={sololectura} multiple={multiLine} esNumeric={esNumeric} onChange={onChange}  {...inputProps} />
        </InputGroup>
    );
}