import { Container, Grid } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import PanelHeader from "../../components/panel-header";
import SimpleToast from "../../components/simple-toast";
import { ColorsDPana } from "../../utils/Color";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Navigator from './navigator';
import Header from './header';
import { config } from "../../utils/configs";
import SpinnerLoading from "../../components/spinner-loading";
import { pedidos } from "../../models/pedidos";
import { enuStatusPedido, enuTipoOrigenPedido } from "../../utils/enums";
import dateFormat from "dateformat";
import { AddBusiness, PointOfSale, TrendingUp, AddShoppingCart, ViewStream, MenuBook, ShowChart, ShoppingBag, AutoAwesomeMotion, AutoStories, ClearAll, DinnerDining, DryCleaning, Apps } from "@mui/icons-material";
import DashBoard from "./dashboard";
import { formatNumber } from "fast-number-formatter";
import { comboboxData } from "../../models/Entities/controlsEntity";
import { loadArchivoDef } from "../../utils/services";
import './style.css';
import "./mobile-style.css"

export interface itemNavButton {
    id: enutipoItemMenu,
    nombre: string,
    icon: any,
    active: boolean,
    component?: any
}
export interface itemNavCategory {
    id: number,
    nombre: string,
    item: itemNavButton[]
}

export enum enutipoItemMenu {
    ninguno,
    dashBoard,
    ventaDiaria,
    ventaMes,
    ventaPorGrupo,
    ventaProducto,
    ventaLibroVentas,
    compraDiaria,
    compraMes,
    compraPorGrupo,
    compraProducto,
    compraLibrocompras,
}
function createData(time: string, monto?: number) {
    return { time, monto };
}

function sortFunctionDESC(a: any, b: any) {
    var dateA = new Date(a.FechaActual).getTime();
    var dateB = new Date(b.FechaActual).getTime();
    return dateA < dateB ? 1 : -1;

};
function sortFunctionASC(a: any, b: any) {
    var dateA = new Date(a.FechaActual).getTime();
    var dateB = new Date(b.FechaActual).getTime();
    return dateA > dateB ? 1 : -1;

};
function sortDataChart(a: any, b: any) {
    var dateA = new Date(a.fecha).getTime();
    var dateB = new Date(b.fecha).getTime();
    return dateA > dateB ? 1 : -1;
};
interface chartModel {
    time?: string,
    monto?: number
}

let theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: `rgb(${ColorsDPana.black[0]}, ${ColorsDPana.black[1]}, ${ColorsDPana.black[2]})`,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: theme.palette.common.white,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})`,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: `rgb(${ColorsDPana.accent[0]}, ${ColorsDPana.accent[1]}, ${ColorsDPana.accent[2]})`,
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(2),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
    },
};
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

const drawerWidth = 256;
export default function Report() {
    const [tituloHead, settituloHead] = useState("Panel Principal");
    const history = useHistory();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [itemCatSelected, setitemCatSelected] = useState<itemNavCategory>();
    const [itemNavSelected, setitemNavSelected] = useState<itemNavButton>();
   // const [tipoItemSelected, settipoItemSelected] = useState<enutipoItemMenu>(enutipoItemMenu.dashBoard);

    const [message, setMessage] = useState("");
    const [loading, setLoaing] = useState(false);
    const [textloading, settextloading] = useState('');

    let savingData: boolean = false;

    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setLoaing(saving);
    }
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickItemNav = (cat: itemNavCategory, item: itemNavButton) => {
        if (cat && item) {
            let c = cat.nombre ? `${cat.nombre} - ${item.nombre}` : item.nombre;
            item.active = true;
            settituloHead(c);
            setitemCatSelected(cat);
            setitemNavSelected(item);
        }
    }
    const loadOrders = async () => {
        setSavingData(true);
        let Pedidos: pedidos[];
        await axios.post(config.SERVER_URI + "/pedidos/list", {
            options: {
                attributes: [
                    'ID',
                    'Id_Caja',
                    'Id_Cliente',
                    'Id_TipoDespachoPedido',
                    'Id_Usuario',
                    'Id_Estaciones_Sesiones',
                    'id_defTipoSatusPedido',
                    'id_defTipoOrigenPedido',
                    'id_defTipoPedido',
                    'FechaActual',
                    'FechaPedido',
                    'MontoTotal',
                    'MontoTotalBase',
                    'MontoTotalIVA',
                    'MontoTotalDescuento',
                    'MontoCambio',
                    'MontoPagado',
                    'NroPedido',
                    'RefPedido',
                    'esPagado',
                    'esDespachado',
                    'esImpreso',
                ],
                order: [
                    ['ID', 'DESC'],
                    ['id_defTipoSatusPedido', 'ASC'],
                ],
                includes: ['Id_Cliente_cliente', 'facturas'],
                where: { id_defTipoSatusPedido: enuStatusPedido.PedidoFacturado }
            }
        }).then(res => {
            if (res.status === 200) {
                Pedidos = res.data
                if (Pedidos && Pedidos.length) {
                    setOrderList(Pedidos);

                }
            } else {
                setMessage('err-->resPedido:' + res.statusText)
            }
            setSavingData(false);

        }).catch(err => {
            setMessage(err.stack);
            setSavingData(false);
        })
    }

    //#region 
    const [orderList, setOrderList] = useState<any[]>([]);
    const [totalVentas, setTotalVentas] = useState("0,00");
    const [totalBase, setTotalBase] = useState("0,00");
    const [totalIVA, setTotalIVA] = useState("0,00");
    const [orderListFiltred, setorderListFiltred] = useState<pedidos[]>();
    const [fechaConsulta1, setfechaConsulta1] = useState<Date>();
    const [fechaConsulta2, setfechaConsulta2] = useState<Date>();
    const [dataChart, setDataChart] = useState<chartModel[]>();
    const [categoriasMenu, setcategoriasMenu] = useState<any[]>([]);
    const [defOrigen, setdefOrigen] = useState<comboboxData[]>([]);
    const forceUpdate = useForceUpdate();

    const sectionHeaderStyle = {
        marginTop: "1em"
    }
    const loadArchivos = () => {

        loadArchivoDef('OrigenPedido', enuTipoOrigenPedido, false).then(
            (resolve) => {
                let res = resolve as comboboxData[];
                setdefOrigen(resolve as comboboxData[])
            },
            (error) => { setMessage('err-->' + error) }
        );
    };
    const handleClickBuscarFecha = (tipoSelect?: enutipoItemMenu, fecha1?: Date, fecha2?: Date) => {
        if (tipoSelect === enutipoItemMenu.ventaDiaria || tipoSelect === enutipoItemMenu.compraDiaria) {
            setfechaConsulta1(fecha1);
            setfechaConsulta2(undefined)
        } else {
            setfechaConsulta1(fecha1);
            setfechaConsulta2(fecha2)
        }
    }
    const handleClickBuscarProductos = (id1?: number, id2?: number) => {

    }
    const componentsProps = {
        orderList: orderListFiltred,
        totalVentas: totalVentas,
        totalBase: totalBase,
        totalIVA: totalIVA,
        fechaConsulta1: fechaConsulta1,
        fechaConsulta2: fechaConsulta2,
        dataChart: dataChart,
        handleClickBuscarFecha: handleClickBuscarFecha,
        handleClickBuscarProductos: handleClickBuscarProductos
    }

    const categorias: itemNavCategory[] = [
        {
            id: 0,
            nombre: '',
            item: [
                {
                    nombre: 'Panel Principal',
                    icon: <Apps />,
                    active: false,
                    id: enutipoItemMenu.dashBoard,
                    component: <DashBoard tipoSelect={enutipoItemMenu.dashBoard} {...componentsProps} />

                }]
        },
        {
            id: 1,
            nombre: 'Ventas',
            item: [
                {
                    nombre: 'Diarias',
                    icon: <PointOfSale />,
                    active: false,
                    id: enutipoItemMenu.ventaDiaria,
                    component: <DashBoard tipoSelect={enutipoItemMenu.ventaDiaria} {...componentsProps} />
                },
                {
                    nombre: 'Por Rango',
                    icon: <TrendingUp />,
                    active: false,
                    id: enutipoItemMenu.ventaMes,
                    component: <DashBoard tipoSelect={enutipoItemMenu.ventaMes} {...componentsProps} />
                },
                {
                    nombre: 'Por Productos',
                    icon: <AddShoppingCart />,
                    active: false,
                    id: enutipoItemMenu.ventaProducto,
                    component: <DashBoard tipoSelect={enutipoItemMenu.ventaProducto} {...componentsProps} />
                },
                {
                    nombre: 'Por Grupo',
                    icon: <ViewStream />,
                    active: false,
                    id: enutipoItemMenu.ventaPorGrupo,
                    component: <DashBoard tipoSelect={enutipoItemMenu.ventaPorGrupo} {...componentsProps} />
                },
                {
                    nombre: 'Libro de Ventas',
                    icon: <MenuBook />,
                    active: false,
                    id: enutipoItemMenu.ventaLibroVentas,
                    component: <DashBoard tipoSelect={enutipoItemMenu.ventaLibroVentas} {...componentsProps} />
                },
            ],
        },
        {
            id: 2,
            nombre: 'Compras',
            item: [
                {
                    nombre: 'Por Rango',
                    icon: <ShowChart />,
                    active: false,
                    id: enutipoItemMenu.compraDiaria,
                    component: <DashBoard tipoSelect={enutipoItemMenu.compraDiaria}  {...componentsProps} />
                },
                {
                    nombre: 'Por Productos',
                    icon: <ShoppingBag />,
                    active: false,
                    id: enutipoItemMenu.compraProducto,
                    component: <DashBoard tipoSelect={enutipoItemMenu.compraProducto}  {...componentsProps} />
                },
                {
                    nombre: 'Por Grupo',
                    icon: <AutoAwesomeMotion />,
                    active: false,
                    id: enutipoItemMenu.compraPorGrupo,
                    component: <DashBoard tipoSelect={enutipoItemMenu.compraPorGrupo}  {...componentsProps} />
                },
                {
                    nombre: 'Libro de Compras',
                    icon: <AutoStories />,
                    active: false,
                    id: enutipoItemMenu.compraLibrocompras,
                    component: <DashBoard tipoSelect={enutipoItemMenu.compraLibrocompras} {...componentsProps} />
                },
            ],
        },
        {
            id: 3,
            nombre: 'Inventario',
            item: [
                {
                    nombre: 'Productos',
                    icon: <DinnerDining />,
                    active: false,
                    id: enutipoItemMenu.ventaMes,
                    component: <DashBoard  {...componentsProps} />
                },
                {
                    nombre: 'Materia Prima',
                    icon: <AddBusiness />,
                    active: false,
                    id: enutipoItemMenu.ventaMes,
                    component: <DashBoard  {...componentsProps} />
                },
                {
                    nombre: 'Existencia',
                    icon: <DryCleaning />,
                    active: false,
                    id: enutipoItemMenu.ventaMes,
                    component: <DashBoard  {...componentsProps} />

                },
                {
                    nombre: 'Lista de Precios',
                    icon: <ClearAll />,
                    active: false,
                    id: enutipoItemMenu.ventaMes,
                    component: <DashBoard  {...componentsProps} />
                },
            ],
        }
    ];

    const sumaTotalMonto = (args: pedidos[]) => {
        var total = 0;
        args.forEach(x => {
            total += x.MontoTotal ? +x.MontoTotal : 0;
        });
        return total;
    }
    const sumaTotalBase = (args: pedidos[]) => {
        var total = 0;
        args.forEach(x => {
            total += x.MontoTotalBase ? +x.MontoTotalBase : 0;
        });
        return total;
    }
    const sumaTotalIVA = (args: pedidos[]) => {
        var total = 0;
        args.forEach(x => {
            total += x.MontoTotalIVA ? +x.MontoTotalIVA : 0;
        });
        return total;
    }
    const titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={2}
                    >
                        <img className="m-1 text-start" src={'./assets/imagenes/logoicon-white.png'}
                            style={{
                                position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                            }}

                            onClick={() => {
                                history.push('/gotodpana');
                            }} >
                        </img>


                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={10}
                    >
                        <Typography className="text-white" variant='subtitle1'>
                            dPana POS
                        </Typography>
                    </Grid>

                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={6}
                >
                    <Typography variant='subtitle1'>
                        Generador de Reportes
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Typography className="text-white-50" variant='subtitle1'>
                        Sistema de facturación en Línea
                    </Typography>
                </Grid>
            </Grid>
        )
    }
    useEffect(() => {
        forceUpdate();
    }, [categoriasMenu])
    useEffect(() => {
        if (orderListFiltred && orderListFiltred.length) {
            orderListFiltred.forEach(
                e => {
                    e.Mensaje = defOrigen ? defOrigen.find(x => x.ID === e.id_defTipoOrigenPedido)?.Nombre : ""
                });
        }
        if (itemCatSelected && itemNavSelected) {
            handleClickItemNav(itemCatSelected ? itemCatSelected : categorias[0], itemNavSelected ? itemNavSelected : categorias[0].item[0]);
            let i = categorias.find(x => x === itemCatSelected)?.item.find(y => y === itemNavSelected);
            if (i)
                i.active = true;
        } else {
            categorias[0].item[0].active = true;
            handleClickItemNav(categorias[0], categorias[0].item[0]);
        }

    }, [orderListFiltred])
    useEffect(() => {
        if (orderList && orderList.length) {
            if (fechaConsulta1) {
                let sorted: any[] = []
                if (fechaConsulta2) {
                    sorted = orderList.filter(x => dateFormat(x.FechaActual, "dd-mm-yy") >= dateFormat(fechaConsulta1, "dd-mm-yy") && dateFormat(x.FechaActual, "dd-mm-yy") <= dateFormat(fechaConsulta2, "dd-mm-yy")).sort(sortFunctionASC);
                }
                else {
                    sorted = orderList.filter(x => dateFormat(x.FechaActual, "dd-mm-yy") === dateFormat(fechaConsulta1, "dd-mm-yy")).sort(sortFunctionDESC);
                }
                setorderListFiltred(sorted);
                let dc: chartModel[] = [];
                setTotalVentas(formatNumber(sumaTotalMonto(sorted), 2));
                setTotalBase(formatNumber(sumaTotalBase(sorted), 2));
                setTotalIVA(formatNumber(sumaTotalIVA(sorted), 2));
                sorted.forEach(d => {
                    dc.push(createData(dateFormat(d.FechaActual, "HH:MM"), d.MontoTotal));
                });
                setDataChart(dc.sort(sortDataChart));
                componentsProps.orderList = sorted;
                setcategoriasMenu(categorias);
            }
        }
    }, [orderList, fechaConsulta1, fechaConsulta2])
    //#endregion


    useEffect(() => {
        setcategoriasMenu(categorias);
        forceUpdate();
    }, [fechaConsulta1, fechaConsulta2])
    useEffect(() => {
        loadArchivos();
        loadOrders();
        setfechaConsulta1(new Date())
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <Container className="vh-100 position-relative py-3">
                <Panel className="w-100 h-100 d-flex flex-column">
                    <PanelHeader>
                        {titulo()}
                    </PanelHeader>
                    <PanelBody className="flex-grow-1 overflow-auto " themeColor={ColorsDPana.light_gray} style={{ flexBasis: 0 }}>
                        <Box className="h-100" sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Box
                                component="nav"
                                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                            >
                                {isSmUp ? null : (
                                    <Navigator
                                        categoriasMenu={categoriasMenu}
                                        PaperProps={{ style: { width: drawerWidth } }}
                                        variant="temporary"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        onItemClick={handleClickItemNav}
                                    />
                                )}
                                <Navigator
                                    categoriasMenu={categoriasMenu}
                                    PaperProps={{ style: { width: drawerWidth, position: 'relative', overflow: 'auto' } }}
                                    sx={{ display: { sm: 'block', xs: 'none' } }}
                                    onItemClick={handleClickItemNav}
                                />
                            </Box>
                            <Box
                                className="bg-dark bg-opacity-75"
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    overflow: 'auto',
                                }}
                            >
                                <Header onDrawerToggle={handleDrawerToggle} titulo={tituloHead} />
                                {itemNavSelected
                                    ? itemNavSelected.component
                                    : ''
                                }
                            </Box>

                        </Box>
                    </PanelBody>
                    <div className="d-flex flex-wrap justify-content-end">
                        <Button className="m-1" themeColor={ColorsDPana.primary} style={{ width: "6em" }}
                            onClick={() => {
                                history.push('/main-menu');
                            }}
                        >Salir</Button>
                    </div>
                </Panel>
            </Container>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />

        </ThemeProvider >

    );
}

