import type { facturas } from './facturas';
import type { mesas } from './mesas';
import type { mesas_detalle_notasconsumo } from './mesas_detalle_notasconsumo';
import { pedidos } from './pedidos';
import type { usuarios } from './usuarios';

export interface mesas_detalleAttributes {
  ID: number;
  id_Mesa: number;
  id_Pedido?: number;
  id_UsuarioApertura?: number;
  id_UsuarioUltimoAcceso?: number;
  id_Factura?: number;
  id_defTipoStatusMesaPedido?: number;
  nroItems?: number;
  nroLineas?: number;
  nroAccesos?: number;
  FechaUltimoAcceso?: Date;
  FechaImpreso?: Date;
  FechaApertura?: Date;
  CantidadImpreso?: number;
  esCerrado?: number;
  esImpreso?: number;
  Historico?: string;
}

export type mesas_detallePk = "ID";

export class mesas_detalle implements mesas_detalleAttributes {
  ID!: number;
  id_Mesa!: number;
  id_Pedido?: number;
  id_UsuarioApertura?: number;
  id_UsuarioUltimoAcceso?: number;
  id_Factura?: number;
  id_defTipoStatusMesaPedido?: number;
  nroItems?: number;
  nroLineas?: number;
  nroAccesos?: number;
  FechaUltimoAcceso?: Date;
  FechaImpreso?: Date;
  FechaApertura?: Date;
  CantidadImpreso?: number;
  esCerrado?: number;
  esImpreso?: number;
  Historico?: string;

  id_Factura_factura!: facturas;
  id_Mesa_mesa!: mesas;
  mesas_detalle_notasconsumos!: mesas_detalle_notasconsumo[];
  id_Pedido_pedido!: pedidos;
  id_UsuarioApertura_usuario!: usuarios;
  id_UsuarioUltimoAcceso_usuario!: usuarios;

}
