/**
 * this file contains all contexts providers
 */

import React from "react";
import { LoginProvider } from "./login-context";
import { OrderProvider } from "./order-context";
import { SettingProvider } from "./setting-context";
export interface Props {
    children?: any;
}
export default function ContextProviders({ children }: Props) {
    return (
        <SettingProvider>
            <LoginProvider>
                <OrderProvider>
                    {children}
                </OrderProvider>
            </LoginProvider>
        </SettingProvider>
    )
}