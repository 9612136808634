import type { pedidos } from './pedidos';
import type { pedidos_detalle_descuentos } from './pedidos_detalle_descuentos';
import type { pedidos_detalle_impuestos } from './pedidos_detalle_impuestos';
import type { pedidos_detalle_modificadores } from './pedidos_detalle_modificadores';
import type { pedidos_detalle_movimientos } from './pedidos_detalle_movimientos';
import type { productos } from './productos';
import type { productos_promociones } from './productos_promociones';
import type { tipoimpuestos } from './tipoimpuestos';
import type { tipomonedas } from './tipomonedas';
import type { tipoofertas } from './tipoofertas';
import type { tipopromociones } from './tipopromociones';

export interface pedidos_detalleAttributes {
  ID: number;
  Id_pedido: number;
  Id_Producto?: number;
  id_ProductoPromocion?: number;
  id_TipoPromocion?: number;
  id_TipoOferta?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  id_defTipoPrecio?: number;
  id_defTipoStatusPedido?: number;
  Descripcion: string;
  NroItem?: number;
  CantidadTotal?: number;
  CantidadDevolucion?: number;
  TasaIVA?: number;
  MontoPrecio?: number;
  MontoPrecioDivisa?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  MontoDescuento?: number;
  ValorDescuento?: number;
  PuntosAplicados?: number;
  Comentario?: string;
  HistoricoDetalle?: string;
  Mensaje?: string;
  CantidadImpreso?: number;
  FechaActual?: Date;
  FechaImpreso?: Date;
  FechaDespacho?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;
  MontoTasaDivisa?: number;
}

export class pedidos_detalle implements pedidos_detalleAttributes {
  ID!: number;
  Id_pedido!: number;
  Id_Producto?: number;
  id_ProductoPromocion?: number;
  id_TipoPromocion?: number;
  id_TipoOferta?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  id_defTipoPrecio?: number;
  id_defTipoStatusPedido?: number;
  Descripcion!: string;
  NroItem?: number;
  CantidadTotal?: number;
  CantidadDevolucion?: number;
  TasaIVA?: number;
  MontoPrecio?: number;
  MontoPrecioDivisa?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  MontoDescuento?: number;
  ValorDescuento?: number;
  PuntosAplicados?: number;
  Comentario?: string;
  HistoricoDetalle?: string;
  Mensaje?: string;
  CantidadImpreso?: number;
  FechaActual?: Date;
  FechaImpreso?: Date;
  FechaDespacho?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;
  MontoTasaDivisa?: number;

  // pedidos_detalle belongsTo pedidos via Id_pedido
  Id_pedido_pedido!: pedidos;
  pedidos_detalle_descuentos!: pedidos_detalle_descuentos[];
  pedidos_detalle_impuestos!: pedidos_detalle_impuestos[];
  pedidos_detalle_modificadores!: pedidos_detalle_modificadores[];
  pedidos_detalle_movimientos!: pedidos_detalle_movimientos[];
  Id_Producto_producto!: productos;
  id_ProductoPromocion_productos_promocione!: productos_promociones;
  id_TipoImpuesto_tipoimpuesto!: tipoimpuestos;
  id_TipoMoneda_tipomoneda!: tipomonedas;
  id_TipoOferta_tipooferta!: tipoofertas;
  id_TipoPromocion_tipopromocione!: tipopromociones;

}
