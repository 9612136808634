
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import VerticalCenter from "../../components/vertical-center";
import { Container } from "@mui/material";
import "./style.css";
import logo from './assets/imagenes/logo.png';

export default function LandingPage() {
    const [show, setShow] = useState(false);
    const history = useHistory();
    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <Container
            className="vh-100"
            onClick={() => {
                history.push("/login");
            }}
        >
            <VerticalCenter>
                <div className={"syrup-title" + (show ? " syrup-title-show" : "")}>
                    <div className="syrup-image-top"><img src={'./assets/imagenes/logo.png'} /></div>
                    <div className=" text-white-50 fs-5" style={{
                        top: "45%",
                    }}>
                        Sistema de Facturación en Línea
                    </div>
                    <div className="syrup-title-top">dPana</div>
                    <div className="syrup-title-bottom">POS</div>

                </div>
                <div className="h text-center m-3 text-white"  ><span className="h6 text-left text-white"  ><small>Powered by</small> </span>KALIXTO</div>
            </VerticalCenter>

        </Container>
    )
}