import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { ColoresDPana, ColorsDPana } from "../../utils/Color";
import { Box, Button, Container, Fade, Modal, Theme } from "@mui/material";
import { useDialog, useSetDialog } from "../../contexts/dialog-context";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: '1px solid #fff',
    boxShadow: 24,
    p: 1

};
const UseStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    }, boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "50vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },

}));


export interface SimpleDialogProps {
    open: boolean;
    setOpen: any;
    selectedValue?: any;
    onClose: (value: string) => void;
    children?: React.ReactNode;
    ThemeColor?: ColoresDPana;
    style?: React.CSSProperties;
    title: string;
    childResponse?: "OK" | "Cancel" | "Yes" | "No" | "Retry";
    handleResponse?: (e: any) => void;
    okButton?: boolean;
    setokButton?: any;
}

export default function ModalDialog(props: SimpleDialogProps) {
    const classes = UseStyles();
    const { onClose, okButton = false, setokButton, selectedValue, setOpen, open, children, ThemeColor = ColorsDPana.secundary, title = "" } = props;

    const dialog = useDialog();

    const handleClose = () => {
        setOpen(false);
        setokButton(false);
        onClose(selectedValue);
    };

    useEffect(() => {
        if (open)
            if (dialog.Response === "OK") {
                alert("Cerrar");
            }
    }, [])

    return (
        <Modal
            className={classes.modal}
            hideBackdrop
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Fade in={open}>
                <Box
                    component="main"
                    sx={{ ...style, maxHeight: 'calc(100vh -60px)', background: `rgb(${ThemeColor[0]},${ThemeColor[1]},${ThemeColor[2]})` }}
                    className={classes.boxWrapper + ' align-content-between d-grid text-center w-100 my-2 p-0'}>
                    <Container maxWidth="md" className={classes.container + ' text-center w-100 m-0 p-0'}>
                        {children}
                    </Container>
                    <div>
                        <Button className="m-md-1  m-auto col-3 bg-black bg-gradient text-white" onClick={handleClose}>Cerrar</Button>
                        {okButton
                            ? < Button className="m-md-1  m-auto col-3 bg-black bg-gradient text-white " onClick={handleClose}>OK</Button>
                            : ''
                        }
                    </div>
                </Box>
            </Fade>
        </Modal>

    );
}


