
import type { facturas_detalle } from './facturas_detalle';
import type { tipoimpuestos } from './tipoimpuestos';

export interface facturas_detalle_impuestosAttributes {
  ID: number;
  Id_FacturaDetalle?: number;
  Id_TipoImpuesto: number;
  Tasa?: number;
  MontoBase?: number;
}

export type facturas_detalle_impuestosPk = "ID";

export class facturas_detalle_impuestos implements facturas_detalle_impuestosAttributes {
  ID!: number;
  Id_FacturaDetalle?: number;
  Id_TipoImpuesto!: number;
  Tasa?: number;
  MontoBase?: number;

  // facturas_detalle_impuestos belongsTo facturas_detalle via Id_FacturaDetalle
  Id_FacturaDetalle_facturas_detalle!: facturas_detalle;
  // facturas_detalle_impuestos belongsTo tipoimpuestos via Id_TipoImpuesto
  Id_TipoImpuesto_tipoimpuesto!: tipoimpuestos;

}
