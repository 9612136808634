import { makeStyles, } from '@mui/styles';
import { AppBar, Grid, Theme, Toolbar, Typography } from '@mui/material';
import { config } from "../../../utils/configs";

const useStyles = makeStyles((theme: Theme) => ({
    topAppBar: {
        minHeight: "50px",
        minWidth: 440,

    },
    toolbar: {
        alignItems: "flex-start",
        justifyContent: "center",

    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
}));

const AppBars = (title: any, logoLink: any) => {
    const classes = useStyles();
    return (
        <AppBar position="relative" elevation={1} color="default" className={classes.topAppBar + " p-0"}>
            <Toolbar className={classes.toolbar + " p-0"}>
                {title !== undefined &&
                    <Grid container item direction="row"
                        className={" p-0"}
                        justifyContent="flex-start"
                        alignItems="center" xs={12} sm={12}>
                        <Grid item className='mx-1' sm={1}>
                            <img src={'./assets/imagenes/logo.png'} alt="logo" height="45px" />
                        </Grid>
                        <Grid item className='mx-1' sm={'auto'}>
                            <Typography variant="h4" >
                                {"dPana POS"}
                            </Typography>
                            <Typography variant='subtitle2' >
                                {"Sistema para Restaurantes"}
                            </Typography>
                        </Grid>
                        <Grid className='mx-1' sm
                            container
                            direction='column'
                            justifyContent="center"
                            alignItems="flex-end">
                            <Typography variant='h5'  >
                                {"Módulo de Pagos"}
                            </Typography>
                            <Typography variant='caption'  >
                                {config.nombreEmpresa}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Toolbar>
        </AppBar>
    );
}

export default AppBars;