import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react"
import Orden from "../models/Entities/Orden.model";

export const OrderContext = createContext<Orden>(new Orden());
export const SetOrderContext = createContext<React.Dispatch<React.SetStateAction<Orden>>>({} as React.Dispatch<React.SetStateAction<Orden>>);

export function useOrder() {
    return useContext(OrderContext);
}
export function useSetOrder() {
    return useContext(SetOrderContext);
}
export interface Props {
    children?: any;
}
export function OrderProvider({ children }: Props) {
    const [order, setOrder] = useState<Orden>(new Orden());
    return (
        <OrderContext.Provider value={order}>
            <SetOrderContext.Provider value={setOrder}>
                {children}
            </SetOrderContext.Provider>
        </OrderContext.Provider>
    );
}