import { useState } from "react";
import PanelHeader from "../../components/panel-header";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import Button from "../../components/button";
import { useHistory } from "react-router-dom";
import { useOrder, useSetOrder } from "../../contexts/order-context";
import { ColorsDPana } from "../../utils/Color";
import axios from "axios";
import { useEffect } from "react";
import SimpleToast from "../../components/simple-toast";
import CheckLoginToken from "../../components/check-login-token";
import { useSetSetting, useSetting } from "../../contexts/setting-context";
import Orden from "../../models/Entities/Orden.model";
import { enuStatusPedido, enuTipoEstatusMesa, enuTipoOrigenPedido, enuTipoPedidoDespacho } from "../../utils/enums";
import CardMesa from "../../components/cardmesa";
import { mesas } from "../../models/mesas";
import CrudOrdenService from "../../services/crudorden.service";
import { useLoginToken } from "../../contexts/login-context";
import { getFormattedDate } from "../../utils/helpers";
import { sectores_mesas } from "../../models/sectores_mesas";
import { Card, Container, Grid, IconButton, Popover, Typography } from "@mui/material";
import { config } from "../../utils/configs";
import SpinnerLoading from "../../components/spinner-loading";
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import './style.css';
import "./mobile-style.css"

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}
export default function TableSelection() {
    CheckLoginToken();
    const forceUpdate = useForceUpdate();

    const history = useHistory();
    const setting = useSetting();
    const setSetting = useSetSetting();
    const setPedidoActual = useSetOrder();
    const loginToken = useLoginToken();

    const [tableAreaList, setTableAreaList] = useState<any[]>([]);
    const [tableList, setTableList] = useState<any[]>([]);
    const [selectedTable, setSelectedTable] = useState<any | null>(null);
    const [selectedTableArea, setSelectedTableArea] = useState<any | null>(null);
    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    //POPOVER INFO
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClickInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseInfo = () => {
        setAnchorEl(null);
    };
    const openInfo = Boolean(anchorEl);
    const id = openInfo ? 'simple-popover' : undefined;
    const liberarMesas = () => {
        if (tableList) {
            setMessage("Espere por favor...")
            tableList.forEach((t: mesas) => {
                if (t.esModificando)
                    CrudOrdenService.setBlockMesa(t.ID, false, loginToken);
            });
        }
    }
    const createTableAreaListItem = (tableArea: sectores_mesas, key: string | number) => {
        return (
            <Button
                key={"area-" + key}
                style={{
                    margin: 1, minWidth: '6rem'
                }}
                themeColor={selectedTableArea === tableArea ? ColorsDPana.primary : ColorsDPana.secundary}
                onClick={() => {
                    setSelectedTableArea(tableArea);
                    setSelectedTable(null);
                    loadMesas(tableArea.id);
                }}
            >
                {tableArea.Nombre}
            </Button>
        )
    }
    const createTableListItem = (mesa: mesas, key: string | number) => {
        let monto: number = 0;
        let esImpreso!: boolean;
        let fechaModif!: Date;
        let mesero: string = "";
        let status: number = 1;
        if (!mesa) {
            return ''
        } else {
            if (mesa?.mesas_detalles?.length && mesa.mesas_detalles[0].id_Pedido) {
                monto = mesa?.mesas_detalles[0].id_Pedido_pedido?.MontoTotal ?? 0;
                fechaModif = mesa?.mesas_detalles[0].FechaUltimoAcceso as Date;
                esImpreso = mesa?.mesas_detalles[0].esImpreso === 1 ? true : false;
                status = mesa?.mesas_detalles[0].id_defTipoStatusMesaPedido as number;
                mesero = mesa.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario.NombreUsuario
            }
            else {

            }
            if (mesa.esModificando) {
                status = enuTipoEstatusMesa.Bloqueada as number;
            }
            return (
                <CardMesa
                    Titulo={mesa.Nombre}
                    MontoTotal={monto ? monto : 0}
                    style={{
                        position: "absolute",
                        left: mesa.X,
                        top: mesa.Y
                    }}
                    Mesero={mesero}
                    disabled={mesa.esModificando ? true : false}
                    Fecha={fechaModif ? new Date(getFormattedDate(fechaModif)) : undefined}
                    selected={selectedTable === mesa}
                    status={status}
                    esImpreso={esImpreso}
                    nOcupantes={mesa.nroPersonas_max}
                    esHabitacion={mesa.esHabitacion === undefined ? false : mesa.esHabitacion === 1 ? true : false}
                    Width={mesa.Width}
                    Height={mesa.Height}
                    esObjeto={mesa.esObjeto === undefined ? false : mesa.esObjeto === 1 ? true : false}
                    zIndex={mesa.zIndex}
                    esForceSize={mesa.esForceSize === undefined ? false : mesa.esForceSize === 1 ? true : false}
                    esRepeatBackground={mesa.esRepeatBackground === undefined ? false : mesa.esRepeatBackground === 1 ? true : false}
                    Rotacion={mesa.Rotacion}
                    Imagen={mesa.Imagen}
                    Left={mesa.X}
                    Top={mesa.Y}
                    key={"table-" + key}
                    onClick={() => {
                        if (!mesa.esObjeto) {
                            if (selectedTable === mesa) {
                                if (status !== enuTipoEstatusMesa.Bloqueada)
                                    goToOrder();
                                else {
                                    setMessage('wrn-->Espere mientras editan...');
                                }
                            } else
                                setSelectedTable(mesa);
                        }
                    }}
                    themeColor={selectedTable === mesa ? ColorsDPana.accent : ColorsDPana.secundary}
                />
            )
        }
    }
    const vaciarPedido = () => {
        setPedidoActual(new Orden());
    }
    const loadPedido = async (id: number) => {
        setloading(true);
        settextloading('Cargando Pedido. Por favor espere')
        vaciarPedido();
        await CrudOrdenService.loadPedido(id, loginToken).then(async (res: any) => {
            let orden: Orden = res as Orden;
            loadOrder(orden);
        }).catch(err => {
            setMessage(`err-->Al cargar Pedido.\n${err}`)
        })
        setloading(false);
    }
    const goToOrder = () => {
        if (selectedTable) {
            setting.MesaActual = selectedTable;
            if (selectedTable.mesas_detalles && selectedTable.mesas_detalles.length) {
                loadPedido(selectedTable.mesas_detalles[0].id_Pedido);
                return;
            }
            let orden: Orden = new Orden();
            orden = new Orden();
            loadOrder(orden);
        } else {
            setMessage("Debe Seleccionar una Mesa antes de ordenar.")
        }
    }
    const loadOrder = (orden: Orden) => {
        orden.id_defTipoPedido = enuTipoPedidoDespacho.ComerAqui;
        orden.Mesa = selectedTable;
        orden.Mesero = setting.UsuarioActual;
        orden.initOrden(setting, orden.id_defTipoPedido, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
        setSetting(setting);
        setPedidoActual(orden);
        history.push("/order");
    }
    const loadMesas = (id: number) => {
        axios.post(config.SERVER_URI + "/adminonline/getsectorcmesas", {
            ID: loginToken.ID,
            hash: loginToken.hash,
            data: { id: id }
        })
            .then(res => {
                if (res.status === 200) {
                    setTableList(res.data[0].mesas);
                    setSelectedTable(setting.MesaActual);
                } else {
                    setMessage('err-->loadMesasRes ');
                }
            })
            .catch(err => {
                setMessage('err-->loadMesas' + err);
            })
    }
    const loadAreas = async () => {
        axios.post(config.SERVER_URI + "/sectores_mesas/list")
            .then(res => {
                if (res.status === 200) {
                    let sectores: sectores_mesas[] = res.data as unknown as sectores_mesas[];
                    setTableAreaList(sectores);
                    if (sectores && sectores.length) {
                        if (selectedTableArea)
                            loadMesas(selectedTableArea.ID)
                        else {
                            setSelectedTableArea(sectores[0]);
                            loadMesas(sectores[0].id)
                        }
                    }
                } else {
                    setMessage('err-->loadAreasRes ');
                }
            })
            .catch(err => {
                setMessage('err-->loadAreas' + err);
            })
    }
    const titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="m-0 p-0 w-100 lh-1"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={3} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={5} sm={2}
                    >
                        <Link
                            underline="hover"
                            sx={{ ":hover": { color: `rgb(${ColorsDPana.light_gray[0]},${ColorsDPana.light_gray[1]},${ColorsDPana.light_gray[2]})` }, display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            onClick={() => window.open("https://www.dpana.com.ve", '_blank')}
                            href="#"
                        >
                            <img className="m-1 text-start" src={'./assets/imagenes/logoicon-white.png'}
                                style={{
                                    position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                                }}
                            />
                        </Link>
                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={7} sm={10}
                    >
                        <Typography className="text-white lh-1" variant='subtitle1'>
                            dPana POS
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={5}
                >
                    <Typography className=" lh-1" variant='subtitle1'>
                        Despachador de Pedidos
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Typography className="text-white-50  lh-1" variant='subtitle1'>
                        Sistema de facturación en Línea
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={1} sm={1}
                >
                    <IconButton onClick={handleClickInfo} className="text-white">
                        <MenuIcon />
                    </IconButton>
                </Grid>

            </Grid>

        )
    }
    useEffect(() => {
        let isMounted = true
        const intervalId = setInterval(async () => {
            if (!isMounted) return
            if (setting.idAreaSelected)
                loadMesas(setting.idAreaSelected)
        }, 5000)
        return () => {
            clearInterval(intervalId); //This is important
            isMounted = false // Let's us know the component is no longer mounted.
        }
    }, [useState])
    useEffect(() => {
        if (selectedTableArea && selectedTableArea.id) {
            setting.idAreaSelected = selectedTableArea.id;
        }
        if (selectedTable)
            setting.MesaActual = selectedTable;
        setSetting(setting);
        forceUpdate();
    }, [tableList, tableAreaList, selectedTableArea, selectedTable]);
    useEffect(() => {
        loadAreas();
    }, []);
    return (
        <Container className="vh-100 position-relative py-3">
            <Panel className="w-100 h-100 d-flex flex-column">
                <PanelHeader>
                    {titulo()}
                </PanelHeader>
                <Container id="table-page-container">
                    <PanelBody className="text-center p-1" id="table-areas" >
                        {tableAreaList && tableAreaList.length
                            ? tableAreaList.map((tableArea, index) => createTableAreaListItem(tableArea, index))
                            : ""}
                    </PanelBody>
                    <div id="table-mesas" className="d-flex flex-column h-100">
                        <PanelBody className="flex-grow-1 position-relative drop-mesas ">
                            {tableList && tableList.length
                                ? tableList.map((table, index) => createTableListItem(table, index))
                                : ""}
                        </PanelBody>
                        <div className="d-flex flex-row-reverse">
                            <Button style={{ width: "4em", margin: "3px" }} themeColor={ColorsDPana.primary}
                                onClick={() => {
                                    history.push('/main-menu');
                                }}
                            >Salir</Button>
                            <Button style={{ width: "4em", margin: "3px" }} themeColor={ColorsDPana.primary} onClick={goToOrder}>Ordenar</Button>
                        </div>
                    </div>
                </Container>
            </Panel>
            <Popover
                id={id}
                open={openInfo}
                anchorEl={anchorEl}
                onClose={handleCloseInfo}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Card className="p-1 pe-2 bg-dark" sx={{ display: 'flex', maxWidth: 150 }}  >
                    <Grid xs={12} md={12}
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Button className=' p-1 m-1 pe-3 text-light w-100 justify-content-between' onClick={() => { liberarMesas(); }}>
                            <Typography className="px-0 text-start  " variant='caption'>Desbloquear Mesas</Typography>
                        </Button>
                        <Button className=' p-1 m-1 pe-3 text-light w-100 justify-content-between' onClick={() => { liberarMesas(); }}>
                            <Typography className="px-0 text-start  " variant='caption'>Cambio Usuario</Typography>
                        </Button>
                    </Grid>
                </Card>

            </Popover>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
        </Container>
    );
}