import { Container, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button";
import CheckLoginToken from "../../components/check-login-token";
import Panel from "../../components/panel";
import PanelBody from "../../components/panel-body";
import PanelHeader from "../../components/panel-header";
import SimpleToast from "../../components/simple-toast";
import { useLoginToken, useSetLoginToken } from "../../contexts/login-context";
import { useOrder, useSetOrder } from "../../contexts/order-context";
import { clientes } from "../../models/clientes";
import Orden from "../../models/Entities/Orden.model";
import { pedidos } from "../../models/pedidos";
import CrudOrdenService from "../../services/crudorden.service";
import { ColorsDPana } from "../../utils/Color";
import { enuStatusPedido, enuTipoOrigenPedido, enuTipoPedidoDespacho } from "../../utils/enums";
import { tiempoTranscurrido } from "../../utils/helpers";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { config } from "../../utils/configs";
import { makeStyles } from "@mui/styles";
import { loadArchivoDef } from "../../utils/services";
import { comboboxData } from "../../models/Entities/controlsEntity";
import SpinnerLoading from "../../components/spinner-loading";
import { mesas } from "../../models/mesas";
import { mesas_detalle } from "../../models/mesas_detalle";
import dateFormat from "dateformat";
import { useSetSetting, useSetting } from "../../contexts/setting-context";

/* const useStyles = makeStyles({
    td: {
        maxWidth: 170,
        maxHeight: 200,
        minWidth: 170,
        margin: 2,
        position: "relative",
        display: "inline-block"
    }
}); */
interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columnsPedidos: Column[] = [
    { id: 'ID', label: '#', minWidth: 100 },
    { id: 'id_defTipoPedido', label: 'Tipo', minWidth: 100 },
    { id: 'id_origen', label: 'Origen', minWidth: 100 },
    {
        id: 'Id_Caja',
        label: 'Caja',
        minWidth: 100,
    },
    {
        id: 'Id_Cliente_cliente',
        label: 'Cliente',
        minWidth: 170,
    },
    {
        id: 'MontoTotal',
        label: 'Monto Total',
        minWidth: 120,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'FechaActual',
        label: 'Fecha',
        minWidth: 120,
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'id_defTipoSatusPedido',
        label: 'Status',
        minWidth: 150,
        align: 'right',
    }
];

const columnsDelivery: Column[] = [
    { id: 'ID', label: '#', minWidth: 100 },
    { id: 'id_defTipoPedido', label: 'Tipo', minWidth: 100 },
    { id: 'origen', label: 'Origen', minWidth: 100 },
    {
        id: 'Id_Caja',
        label: 'Caja',
        minWidth: 100,
    },
    {
        id: 'Id_Cliente_cliente',
        label: 'Cliente',
        minWidth: 150,
    },
    {
        id: 'MontoTotal',
        label: 'Monto Total',
        minWidth: 120,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'FechaActual',
        label: 'Fecha',
        minWidth: 150,
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'tiempo',
        label: 'Transcurrido',
        minWidth: 100,
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 100,
        align: 'right',
    }
];
const columnsEnMesas: Column[] = [
    {
        id: '#Pedido',
        label: '# Pedido',
        minWidth: 100
    },
    {
        id: 'id_Mesa',
        label: 'Mesa',
        minWidth: 100
    },
    {
        id: 'idorigen',
        label: 'Origen',
        minWidth: 100
    },
    {
        id: 'id_Mesero',
        label: 'Mesero',
        minWidth: 100,
    },
    {
        id: 'id_Cliente',
        label: 'Cliente',
        minWidth: 150
    },
    {
        id: 'FechaActual',
        label: 'Apertura',
        minWidth: 150,
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'tiempo',
        label: 'Sin Pedidos',
        minWidth: 120,
    },

    {
        id: 'MontoTotal',
        label: 'Monto Total',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'id_defTipoSatusPedido',
        label: 'Status',
        minWidth: 120,
        align: 'right',
    },
];

const columnsGuardados: Column[] = [
    { id: 'ID', label: '#', minWidth: 100 },
    { id: 'id_defTipoPedido', label: 'Tipo', minWidth: 100 },
    {
        id: 'Id_Caja',
        label: 'Caja',
        minWidth: 120,
    },
    {
        id: 'Id_Cliente_cliente',
        label: 'Cliente',
        minWidth: 120,
    },
    {
        id: 'MontoTotal',
        label: 'Monto Total',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'fecha',
        label: 'Fecha',
        minWidth: 150,
    },
    {
        id: 'tiempo',
        label: 'Transcurrido',
        minWidth: 120,
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
];

enum enutipoList {
    Todos,
    Pendientes,
    EnMesa,
    Facturado,
    Delivery,
    Pickup,
    Guardado
}
export default function Recall() {
    CheckLoginToken();

    const [orderList, setOrderList] = useState<any[]>([]);
    const [orderListFiltred, setorderListFiltred] = useState<any[]>([]);
    const [tipoList, setTipoList] = useState<enutipoList>(enutipoList.Pendientes);
    const [showPaid, setShowPaid] = useState(false);

    const [message, setMessage] = useState("");

    const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const loginToken = useLoginToken();
    const history = useHistory();



    const [selectedOren, setselectedOren] = useState<Orden>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const [columnsHeader, setcolumnsHeader] = useState<Column[]>();
    const [mesaSelected, setmesaSelected] = useState<mesas_detalle>();
    const [defTipo, setdefTipo] = useState<comboboxData[]>([]);
    const [defStatus, setdefStatus] = useState<comboboxData[]>([]);
    const [defOrigen, setdefOrigen] = useState<comboboxData[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const loadArchivos = () => {
        loadArchivoDef('TipoPedido', enuTipoPedidoDespacho, false).then(
            (resolve) => { setdefTipo(resolve as comboboxData[]) },
            (error) => { setMessage('err-->' + error) }
        );
        loadArchivoDef('StatusPedido', enuStatusPedido, false).then(
            (resolve) => {
                let res = resolve as comboboxData[];
                res.forEach(x => {
                    if (x.Nombre?.toLowerCase().includes('recibido'))
                        x.Nombre = 'VISTO';
                    if (x.Nombre?.toLowerCase().includes('procesando'))
                        x.Nombre = '**POR VER**';
                    if (x.Nombre?.toLowerCase().includes('facturado'))
                        x.Nombre = 'FACTURADO';

                });
                setdefStatus(resolve as comboboxData[])
            },
            (error) => { setMessage('err-->' + error) }
        );
        loadArchivoDef('OrigenPedido', enuTipoOrigenPedido, false).then(
            (resolve) => {
                /*  res.forEach(x => {
                     if (x.Nombre?.toLowerCase().includes('recibido'))
                         x.Nombre = 'VISTO';
                     if (x.Nombre?.toLowerCase().includes('procesando'))
                         x.Nombre = '**POR VER**';
                     if (x.Nombre?.toLowerCase().includes('facturado'))
                         x.Nombre = 'FACTURADO';
 
                 }); */
                setdefOrigen(resolve as comboboxData[])
            },
            (error) => { setMessage('err-->' + error) }
        );
    };
    const loadMesas = () => {
        axios.post(config.SERVER_URI + "/adminonline/getmesascpedido")
            .then(res => {
                if (res.status === 200) {
                    let ms: mesas[] = res.data as unknown as mesas[];
                    setorderListFiltred(ms);
                    //setOrderList(pedidos);
                } else {
                    setMessage('err-->loadMesasRes ');
                }
            })
            .catch(err => {
                setMessage('err-->loadMesas' + err);
            })
    }
    const loadOrders = () => {
        let Pedidos: pedidos[];
        axios.post(config.SERVER_URI + "/pedidos/list", {
            options: {
                attributes: [
                    'ID',
                    'Id_Caja',
                    'Id_Cliente',
                    'Id_TipoDespachoPedido',
                    'Id_Usuario',
                    'Id_Estaciones_Sesiones',
                    'id_defTipoSatusPedido',
                    'id_defTipoOrigenPedido',
                    'id_defTipoPedido',
                    'FechaActual',
                    'FechaPedido',
                    'MontoTotal',
                    'MontoTotalBase',
                    'MontoTotalIVA',
                    'MontoTotalDescuento',
                    'MontoCambio',
                    'MontoPagado',
                    'NroPedido',
                    'RefPedido',
                    'esPagado',
                    'esDespachado',
                    'esImpreso',
                ],
                order: [
                    ['ID', 'DESC'],
                    ['id_defTipoSatusPedido', 'ASC'],
                ],
                includes: ['Id_Cliente_cliente']
            }
        }).then(res => {
            if (res.status === 200) {
                Pedidos = res.data
                setOrderList(Pedidos);
            } else {
                setMessage('err-->resPedido:' + res.statusText)
            }

        }).catch(err => {
            setMessage(err.stack);
        })
    }
    const loadPedido = async (id: number) => {
        if (PedidoActual.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
            setloading(true);
            settextloading('Cargando Pedido. Por favor espere')
            vaciarPedido();
            await CrudOrdenService.loadPedido(id, loginToken).then(async (res: any) => {
                let orden: Orden = res as Orden;
                switch (orden.id_defTipoPedido) {
                    case enuTipoPedidoDespacho.Guardado:
                    case enuTipoPedidoDespacho.Delivery:
                    case enuTipoPedidoDespacho.Pickup:

                        break;
                    default:
                        orden.id_defTipoPedido = enuTipoPedidoDespacho.Pedido;
                        break;
                }
                orden.initOrden(setting, orden.id_defTipoPedido, enuStatusPedido.PedidoRecibido, enuTipoOrigenPedido.POS);
                //orden.settingApp = setting;
                setSetting(setting);
                setPedidoActual(orden);
                history.push("/order");
            }).catch(err => {
                setMessage(`err-->Al cargar Pedido.\n${err}`)
            })
            setloading(false);
        } else {
            setMessage('inf-->Pedido Facturado!');
        }
    }
    const displayCLiente = (cliente: clientes): string => {
        if (cliente) {
            let displayName = cliente.Nombres ? cliente.Apellidos : "";
            if (cliente.Apellidos) {
                if (cliente.Nombres) {
                    displayName += " "
                }
                displayName += cliente.Apellidos;
            }
            if (cliente.TelefonoMovil) {
                if (displayName) {
                    displayName += " - ";
                }
                displayName += cliente.TelefonoMovil ? cliente.TelefonoMovil : cliente.Telefono;
            }
            if (!displayName) {
                displayName = "No Asignado";
            }
            return displayName;
        } else
            return "-"

    }
    const vaciarPedido = () => {
        setPedidoActual(new Orden);
    }
    const createOrderListItem = (orderArg: any, key: string | number) => {
        let cls: string = '';


        switch (tipoList) {
            case enutipoList.Todos:

                switch (orderArg.id_defTipoSatusPedido) {
                    case enuStatusPedido.PedidoRecibido:
                        cls += ' alert-success';
                        break;
                    case enuStatusPedido.PedidoFacturado:
                        cls += ' opacity-50';
                        break;
                }
                return (
                    <>
                        <TableRow
                            className={
                                PedidoActual
                                    ? PedidoActual === orderArg
                                        ? `${cls} text-light bg-danger`
                                        : cls
                                    : ""
                            }
                            hover
                            key={'order-' + key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                            onClick={async () => {
                                if (PedidoActual === orderArg) {
                                    if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                        if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                            await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                    }
                                    loadPedido(orderArg.ID);
                                } else {
                                    setPedidoActual(orderArg);
                                }
                            }}
                        >
                            <TableCell className="p-1" sx={{ color: 'inherit' }} >{orderArg.NroPedido}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defTipo ?
                                    defTipo.find(x => x.ID === orderArg.id_defTipoPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defOrigen ?
                                    defOrigen.find(x => x.ID === orderArg.id_defTipoOrigenPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{orderArg.Id_Caja}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{displayCLiente(orderArg.Id_Cliente_cliente)}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right">${orderArg?.MontoTotal ? orderArg?.MontoTotal : "0,00"}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{dateFormat(orderArg.FechaActual, 'dd-mm-yy HH-MM')}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right"
                                style={{
                                    color: orderArg?.id_defTipoSatusPedido === enuStatusPedido.PedidoNuevo ? "green" : "inherit"
                                }}>
                                {defStatus ?
                                    defStatus.find(x => x.ID === orderArg?.id_defTipoSatusPedido)?.Nombre
                                    : ""
                                }
                            </TableCell>
                        </TableRow>
                    </>
                )
            case enutipoList.Pendientes:
                switch (orderArg.id_defTipoSatusPedido) {
                    case enuStatusPedido.PedidoRecibido:
                        cls += ' alert-success';
                        break;
                    case enuStatusPedido.PedidoFacturado:
                        cls += ' opacity-50';
                        break;
                }
                return (
                    <>
                        <TableRow
                            className={
                                PedidoActual
                                    ? PedidoActual === orderArg
                                        ? `${cls} text-light bg-danger`
                                        : cls
                                    : ""
                            }
                            hover
                            key={'order-' + key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                            onClick={async () => {
                                if (PedidoActual === orderArg) {
                                    if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                        if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                            await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                    }
                                    loadPedido(orderArg.ID);
                                } else {
                                    setPedidoActual(orderArg);
                                }
                            }}
                        >
                            <TableCell className="p-1" sx={{ color: 'inherit' }} >{orderArg.NroPedido}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defTipo ?
                                    defTipo.find(x => x.ID === orderArg.id_defTipoPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defOrigen ?
                                    defOrigen.find(x => x.ID === orderArg.id_defTipoOrigenPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{orderArg.Id_Caja}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{displayCLiente(orderArg.Id_Cliente_cliente)}</TableCell>
                            <TableCell className="p-1 font-monospace fs-5" sx={{ color: 'inherit' }} align="right">${orderArg?.MontoTotal ? orderArg?.MontoTotal : "0,00"}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{dateFormat(orderArg.FechaActual, 'dd-mm-yy HH-MM')}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right"
                                style={{
                                    color: orderArg?.id_defTipoSatusPedido === enuStatusPedido.PedidoNuevo ? "green" : "inherit"
                                }}>
                                {defStatus ?
                                    defStatus.find(x => x.ID === orderArg?.id_defTipoSatusPedido)?.Nombre
                                    : ""
                                }
                            </TableCell>
                        </TableRow>
                    </>
                )
            case enutipoList.EnMesa:
                if (orderArg.mesas_detalles && orderArg.mesas_detalles.length > 0) {
                    let detalle: mesas_detalle = orderArg.mesas_detalles[0];
                    if (detalle.id_Pedido_pedido) {
                        let d = dateFormat(detalle.FechaApertura, "dd-mm HH:MM");
                        const apertura = () => {
                            return (
                                detalle.FechaApertura
                                    ? d
                                    : <b className="text-danger">Fecha Inválida</b>
                            )
                        }
                        const transcurrido = tiempoTranscurrido(detalle.FechaUltimoAcceso);
                        switch (detalle.id_Pedido_pedido.id_defTipoSatusPedido) {
                            case enuStatusPedido.PedidoRecibido:
                                cls += ' alert-success';
                                break;
                            case enuStatusPedido.PedidoFacturado:
                                cls += ' opacity-50';
                                break;
                        }
                        return (
                            <>
                                <TableRow
                                    className={
                                        mesaSelected === detalle
                                            ? `${cls} text-light bg-danger`
                                            : cls
                                    }
                                    hover
                                    key={'mesa-' + key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                                    onClick={async () => {
                                        if (mesaSelected === detalle) {
                                            if (detalle.id_Pedido_pedido.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                                if (detalle.id_Pedido_pedido.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                                    await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                            }
                                            loadPedido(detalle.id_Pedido_pedido.ID);
                                        } else {
                                            setmesaSelected(detalle);
                                        }
                                    }}
                                > <TableCell className="p-1" sx={{ color: 'inherit' }} align="center"> {detalle.id_Pedido_pedido.NroPedido
                                    ? detalle.id_Pedido_pedido.NroPedido
                                    : <b className="text-danger">-</b>
                                }
                                    </TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{orderArg.Nombre}</TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                        {defOrigen ?
                                            defOrigen.find(x => x.ID === detalle.id_Pedido_pedido.id_defTipoOrigenPedido)?.Nombre
                                            : ""}
                                    </TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                        {detalle.id_UsuarioApertura_usuario
                                            ? detalle.id_UsuarioApertura_usuario.Nombre
                                            : <b className="text-danger">Sin Mesero</b>}
                                    </TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{displayCLiente(detalle.id_Pedido_pedido.Id_Cliente_cliente)}</TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{apertura()}</TableCell>
                                    <TableCell className={transcurrido && transcurrido.minutosGral && transcurrido.minutosGral >= 45 ? " text-danger  " : "" + "p-1"} sx={{ color: 'inherit' }} align="center"> {transcurrido?.text ?? ''}</TableCell>
                                    <TableCell className="p-1 font-monospace fs-5" sx={{ color: 'inherit' }} align="right">${detalle.id_Pedido_pedido.MontoTotal}</TableCell>
                                    <TableCell className="p-1" sx={{ color: 'inherit' }} align="right">{
                                        defStatus ?
                                            defStatus.find(x => x.ID === detalle.id_Pedido_pedido?.id_defTipoSatusPedido)?.Nombre
                                            : <b className="text-danger">-</b>
                                    }</TableCell>

                                </TableRow>
                            </>
                        )
                    }
                }
                return <></>;
            case enutipoList.Facturado:
                switch (orderArg.id_defTipoSatusPedido) {
                    case enuStatusPedido.PedidoRecibido:
                        cls += ' alert-success';
                        break;
                    case enuStatusPedido.PedidoFacturado:
                        cls += ' opacity-50';
                        break;
                }
                return (
                    <>
                        <TableRow
                            className={
                                PedidoActual
                                    ? PedidoActual === orderArg
                                        ? `${cls} text-light bg-danger`
                                        : cls
                                    : ""
                            }
                            hover
                            key={'order-' + key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                            onClick={async () => {
                                if (PedidoActual === orderArg) {
                                    if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                        if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                            await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                    }
                                    loadPedido(orderArg.ID);
                                } else {
                                    setPedidoActual(orderArg);
                                }
                            }}
                        >
                            <TableCell className="p-1" sx={{ color: 'inherit' }} >{orderArg.NroPedido}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defTipo ?
                                    defTipo.find(x => x.ID === orderArg.id_defTipoPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defOrigen ?
                                    defOrigen.find(x => x.ID === orderArg.id_defTipoOrigenPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{orderArg.Id_Caja}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{displayCLiente(orderArg.Id_Cliente_cliente)}</TableCell>
                            <TableCell className="p-1 font-monospace fs-5" sx={{ color: 'inherit' }} align="right">${orderArg?.MontoTotal ? orderArg?.MontoTotal : "0,00"}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{dateFormat(orderArg.FechaActual, 'dd-mm-yy HH-MM')}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right"
                                style={{
                                    color: orderArg?.id_defTipoSatusPedido === enuStatusPedido.PedidoNuevo ? "green" : "inherit"
                                }}>
                                {defStatus
                                    ? defStatus.find(x => x.ID === orderArg?.id_defTipoSatusPedido)?.Nombre
                                    : ""
                                }
                            </TableCell>
                        </TableRow>
                    </>
                )
            case enutipoList.Guardado:
                switch (orderArg.id_defTipoSatusPedido) {
                    case enuStatusPedido.PedidoRecibido:
                        cls += ' alert-success';
                        break;
                    case enuStatusPedido.PedidoFacturado:
                        cls += ' opacity-50';
                        break;
                }
                const transcurrido1 = tiempoTranscurrido(orderArg.FechaActual);
                return (
                    <>
                        <TableRow
                            className={
                                PedidoActual
                                    ? PedidoActual === orderArg
                                        ? `${cls} text-light bg-danger`
                                        : cls
                                    : ""
                            }
                            hover
                            key={'order-' + key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                            onClick={async () => {
                                if (PedidoActual === orderArg) {
                                    if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                        if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                            await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                    }
                                    loadPedido(orderArg.ID);
                                } else {
                                    setPedidoActual(orderArg);
                                }
                            }}
                        >
                            <TableCell className="p-1" sx={{ color: 'inherit' }} >{orderArg.NroPedido}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defTipo ?
                                    defTipo.find(x => x.ID === orderArg.id_defTipoPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{orderArg.Id_Caja}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{displayCLiente(orderArg.Id_Cliente_cliente)}</TableCell>
                            <TableCell className="p-1 font-monospace fs-5" sx={{ color: 'inherit' }} align="right">${orderArg?.MontoTotal ? orderArg?.MontoTotal : "0,00"}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{dateFormat(orderArg.FechaActual, 'dd-mm-yy HH-MM')}
                            </TableCell>
                            <TableCell className={transcurrido1 && transcurrido1.minutosGral && transcurrido1.minutosGral >= 45 ? " text-danger " : "" + "p-1"} sx={{ color: 'inherit' }} align="center">{transcurrido1?.text}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right"
                                style={{
                                    color: orderArg?.id_defTipoSatusPedido === enuStatusPedido.PedidoNuevo ? "green" : "inherit"
                                }}>
                                {defStatus ?
                                    defStatus.find(x => x.ID === orderArg?.id_defTipoSatusPedido)?.Nombre
                                    : ""
                                }
                            </TableCell>
                        </TableRow>
                    </>
                )
                break;
            case enutipoList.Delivery:
            case enutipoList.Pickup:
                switch (orderArg.id_defTipoSatusPedido) {
                    case enuStatusPedido.PedidoRecibido:
                        cls += ' alert-success';
                        break;
                    case enuStatusPedido.PedidoFacturado:
                        cls += ' opacity-50';
                        break;
                }
                const transcurrido = tiempoTranscurrido(orderArg.FechaActual);
                return (
                    <>
                        <TableRow
                            className={
                                PedidoActual
                                    ? PedidoActual === orderArg
                                        ? `${cls} text-light bg-danger`
                                        : cls
                                    : ""
                            }
                            hover
                            key={'order-' + key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
                            onClick={async () => {
                                if (PedidoActual === orderArg) {
                                    if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoFacturado) {
                                        if (orderArg.id_defTipoSatusPedido !== enuStatusPedido.PedidoRecibido)
                                            await CrudOrdenService.onStatusPedido(enuStatusPedido.PedidoRecibido, orderArg, true, loginToken, undefined, true)
                                    }
                                    loadPedido(orderArg.ID);
                                } else {
                                    setPedidoActual(orderArg);
                                }
                            }}
                        >
                            <TableCell className="p-1" sx={{ color: 'inherit' }} >{orderArg.NroPedido}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defTipo ?
                                    defTipo.find(x => x.ID === orderArg.id_defTipoPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">
                                {defOrigen ?
                                    defOrigen.find(x => x.ID === orderArg.id_defTipoOrigenPedido)?.Nombre
                                    : ""}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right">{orderArg.Id_Caja}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right">{displayCLiente(orderArg.Id_Cliente_cliente)}</TableCell>
                            <TableCell className="p-1 font-monospace  fs-5" sx={{ color: 'inherit' }} align="right">${orderArg?.MontoTotal ? orderArg?.MontoTotal : "0,00"}</TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="left">{dateFormat(orderArg.FechaActual, 'dd-mm-yy HH-MM')}
                            </TableCell>
                            <TableCell className={transcurrido && transcurrido.minutosGral && transcurrido.minutosGral >= 45 ? " text-danger " : "" + "p-1"} sx={{ color: 'inherit' }} align="center">{transcurrido?.text}
                            </TableCell>
                            <TableCell className="p-1" sx={{ color: 'inherit' }} align="right"
                                style={{
                                    color: orderArg?.id_defTipoSatusPedido === enuStatusPedido.PedidoNuevo ? "green" : "inherit"
                                }}>
                                {defStatus ?
                                    defStatus.find(x => x.ID === orderArg?.id_defTipoSatusPedido)?.Nombre
                                    : ""
                                }
                            </TableCell>

                        </TableRow>
                    </>
                )
        }


    }
    const titulo = () => {

        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={2}
                    >
                        <img className="m-1 text-start" src={'./assets/imagenes/logoicon-white.png'}
                            style={{
                                position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                            }}
                            onClick={() => {
                                history.push('/gotodpana');
                            }} >
                        </img>

                    </Grid>
                    <Grid
                        className="m-0 p-0"
                        item
                        container
                        xs={6} sm={10}
                    >
                        <Typography className="text-white" variant='subtitle1'>
                            dPana POS
                        </Typography>
                    </Grid>

                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={6}
                >
                    <Typography variant='subtitle1'>
                        Lista de Ordenes y Pedidos
                    </Typography>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={4} sm={3}
                >
                    <Typography className="text-white-50" variant='subtitle1'>
                        Sistema de facturación en Línea
                    </Typography>
                </Grid>

            </Grid>

        )
    }

    useEffect(() => {
        switch (tipoList) {
            case enutipoList.Todos:
                setcolumnsHeader(columnsPedidos)
                setorderListFiltred(orderList)
                break;
            case enutipoList.Pendientes:
                setcolumnsHeader(columnsPedidos);
                setorderListFiltred(orderList.filter(
                    x =>
                        x.id_defTipoSatusPedido === enuStatusPedido.Procesando
                ))
                break;
            case enutipoList.EnMesa:
                setcolumnsHeader(columnsEnMesas);
                loadMesas();
                //setorderListFiltred(orderList.filter(x => x.id_defTipoSatusPedido === enuStatusPedido.Ninguno))

                break;
            case enutipoList.Facturado:
                setcolumnsHeader(columnsPedidos)
                setorderListFiltred(orderList.filter(x => x.id_defTipoSatusPedido === enuStatusPedido.PedidoFacturado))
                break;
            case enutipoList.Guardado:
                setcolumnsHeader(columnsGuardados)
                setorderListFiltred(orderList.filter(
                    x => x.id_defTipoPedido === enuTipoPedidoDespacho.Guardado
                ))
                break;
            case enutipoList.Pickup:
            case enutipoList.Delivery:
                setcolumnsHeader(columnsDelivery)
                setorderListFiltred(orderList.filter(
                    x => x.id_defTipoPedido === enuTipoPedidoDespacho.Delivery ||
                        x.id_defTipoPedido === enuTipoPedidoDespacho.Pickup
                ))
                break;
        }
    }, [tipoList]);

    useEffect(() => {
        setorderListFiltred(orderList.filter(
            x =>
                x.id_defTipoSatusPedido === enuStatusPedido.Procesando
        ))
    }, [orderList]);

    useEffect(() => {
        loadArchivos();
        loadOrders();

    }, []);

    return (
        <Container className="vh-100 position-relative py-3">
            <Panel className="w-100 h-100 d-flex flex-column">
                <PanelHeader>
                    {titulo()}
                </PanelHeader>
                <PanelBody className="flex-grow-1 overflow-auto " style={{ flexBasis: 0 }}>
                    <Paper sx={{ pb: 3, width: '100%', height: '100%', overflow: 'hidden', display: 'grid', gridTemplateRows: '95% auto' }}>
                        <TableContainer className="px-1" sx={{ height: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead className="p-0 alert-dark">
                                    <TableRow >
                                        {columnsHeader
                                            ? columnsHeader.map((column) => (
                                                <TableCell
                                                    className="p-1 bg-warning "
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))
                                            : ""
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className="border">
                                    {orderListFiltred
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((order, index) => createOrderListItem(order, index))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid
                            xs={12} sm={12}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className="m-0 px-1 bg-black"
                        >
                            <Grid
                                className=" p-0 "
                                item
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                                xs={12} sm={7}
                            >
                                <IconButton className={tipoList === enutipoList.Pendientes ? 'text-white bg-black ' : ' text-secondary p-0'} onClick={() => { setTipoList(enutipoList.Pendientes) }}>
                                    <Typography variant='button'>
                                        Penidentes
                                    </Typography>
                                </IconButton>
                                <IconButton className={tipoList === enutipoList.EnMesa ? 'text-white bg-black ' : ' text-secondary p-0'} onClick={() => { setTipoList(enutipoList.EnMesa) }}>
                                    <Typography variant='button'>
                                        En Mesas
                                    </Typography>
                                </IconButton>
                                <IconButton className={tipoList === enutipoList.Guardado ? 'text-white bg-black ' : ' text-secondary  p-0'} onClick={() => { setTipoList(enutipoList.Guardado) }}  >
                                    <Typography variant='button'>
                                        Guardado
                                    </Typography>
                                </IconButton>
                                <IconButton className={tipoList === enutipoList.Delivery ? 'text-white bg-black ' : ' text-secondary  p-0'} onClick={() => { setTipoList(enutipoList.Delivery) }}>
                                    <Typography variant='button'>
                                        Delivery
                                    </Typography>
                                </IconButton>
                                <IconButton className={tipoList === enutipoList.Facturado ? 'text-white bg-black ' : ' text-secondary  p-0'} onClick={() => { setTipoList(enutipoList.Facturado) }}>
                                    <Typography variant='button'>
                                        Facturado
                                    </Typography>
                                </IconButton>
                                <IconButton className={tipoList === enutipoList.Todos ? 'text-white bg-black ' : ' text-secondary p-0'} onClick={() => { setTipoList(enutipoList.Todos) }}>
                                    <Typography variant='button'>
                                        Todos
                                    </Typography>
                                </IconButton>

                            </Grid>
                            <Grid
                                className="m-0 p-0 bg-secondary"
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="center"
                                xs={12} sm={5}
                            >
                                <TablePagination
                                    className="p-1 "
                                    sx={{ height: '100%', overflow: 'hidden' }}
                                    showFirstButton
                                    showLastButton
                                    rowsPerPageOptions={[15, 25, 100, { value: -1, label: 'Todo' }]}
                                    component="div"
                                    count={orderListFiltred.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={
                                        <Typography variant='button'>
                                            Filas
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>

                    </Paper>
                </PanelBody>
                <div className="d-flex flex-row-reverse">
                    <Button className="m-1" themeColor={ColorsDPana.primary}
                        onClick={() => {
                            history.push('/main-menu');
                        }}
                    >Salir</Button>
                    <Button className="m-1" themeColor={ColorsDPana.primary}
                        onClick={() => {
                            loadPedido(PedidoActual.ID);
                        }}
                    >Abrir</Button>
                    {/*  <Button className="m-1" themeColor={ColorsDPana.primary}
                        onClick={() => {
                            setShowPaid(!showPaid);
                        }}
                    >{showPaid ? "Ocultar Pago" : "Mostrar Pago"}</Button> */}
                </div>
            </Panel>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
        </Container>
    );
}


