import type { comandas } from './comandas';
import type { estaciones_sesiones } from './estaciones_sesiones';
import type { pedidos_detalle } from './pedidos_detalle';
import type { pedidos_detalle_modificadores } from './pedidos_detalle_modificadores';
import type { usuarios } from './usuarios';

export interface pedidos_detalle_movimientosAttributes {
  ID: number;
  id_Pedido_Detalle?: number;
  id_Usuario?: number;
  id_EstacionesSesiones?: number;
  id_defTipoMovimientoPedido?: number;
  id_defTipoStatusPedido?: number;
  Cantidad?: number;
  Mensaje?: string;
  FechaActual?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;
}

export type pedidos_detalle_movimientosPk = "ID";

export class pedidos_detalle_movimientos implements pedidos_detalle_movimientosAttributes {
  ID!: number;
  id_Pedido_Detalle?: number;
  id_Usuario?: number;
  id_EstacionesSesiones?: number;
  id_defTipoMovimientoPedido?: number;
  id_defTipoStatusPedido?: number;
  Cantidad!: number;
  Mensaje?: string;
  FechaActual?: Date;
  esDevolucion?: number;
  esImpreso?: number;
  esDespachado?: number;

  // pedidos_detalle_movimientos belongsTo estaciones_sesiones via id_EstacionesSesiones
  id_EstacionesSesiones_estaciones_sesione!: estaciones_sesiones;
  // pedidos_detalle_movimientos belongsTo pedidos_detalle via id_Pedido_Detalle
  id_Pedido_Detalle_pedidos_detalle!: pedidos_detalle;
  // pedidos_detalle_movimientos hasMany comandas via id_Pedido_detalle_movimiento
  comandas!: comandas[];
  // pedidos_detalle_movimientos hasMany pedidos_detalle_modificadores via id_pedido_detalleMovimiento
  pedidos_detalle_modificadores!: pedidos_detalle_modificadores[];
  // pedidos_detalle_movimientos belongsTo usuarios via id_Usuario
  id_Usuario_usuario!: usuarios;

}
