import { config } from '../utils/configs';

export const ColorsDPana = {
  white: [255, 255, 255],
  gray: [150, 150, 150],
  black: [0, 0, 0],
  gold: [255, 187, 0],
  dark_gold: [176, 129, 0],
  almost_white: [240, 240, 240],
  light_gray: [222, 222, 222],
  dark_gray: [96, 96, 96],
  sky_blue: [50, 169, 237],
  skin_pink: [253, 165, 155],
  fire_red: [222, 46, 10],
  wakanda_purple: [75, 0, 154],
  brick_red: [176, 68, 56],
  kiwi_green: [93, 146, 0],
  light_green: [143, 196, 20],
  melon_green: [68, 170, 79],
  darksky_blue: [41, 137, 188],
  //KSS
  marron: [128, 0, 0],
  rojo: [255, 0, 0],
  rojoOscuro: [139, 0, 0],
  marronOscuro: [0, 128, 0],
  verde: [128, 0, 0],
  azul: [0, 0, 255],
  amarillo: [255, 255, 0],
  //Tema
  primary: config.primary || [255, 0, 0],
  secundary: config.secundary || [139, 0, 0],
  accent: config.accent || [0, 128, 0],
  textcolor: config.textcolor || [255, 0, 0],
  textaccentcolor: config.textaccentcolor || [139, 0, 0],

} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ColoresDPana = typeof ColorsDPana[keyof typeof ColorsDPana];
