import { Container, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { enutipoItemMenu } from "..";
import { pedidos } from "../../../models/pedidos";
import Caja from "../components/caja";
import CajaRange from "../components/caja-range";
import Chart from "../components/chart";
import FormasPago from "../components/formas-pago";
import Opciones from "../components/opciones";
import Pedidos from "../components/pedidos";
import Productos from "../components/productos";

interface Props {
    children?: React.ReactNode;
    orderList?: pedidos[];
    fechaConsulta1?: Date;
    fechaConsulta2?: Date;
    totalVentas?: string;
    totalBase?: string;
    totalIVA?: string;
    dataChart?: any[];
    formasPago?: any[];
    tipoSelect?: enutipoItemMenu;
    handleClickBuscarFecha?: (tipoSelect?: enutipoItemMenu, fecha1?: Date, fecha2?: Date) => void;
    handleClickBuscarProductos?: (id1?: number, id2?: number) => void;
}
export default function DashBoard(props: Props) {
    const { orderList, fechaConsulta2, fechaConsulta1, ...other } = props;

    useEffect(() => {


    }, [])
    return (
        <Container className="bg-transparent bg-opacity-75" maxWidth="lg" sx={{ m: 0 }}>
            <Grid
                className="w-100 m-0 p-1"
                container spacing={3}>
                {/* Chart */}
                <Grid
                    className=" m-0 p-1"
                    item xs={12} md={6} >
                    <Paper
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 220,
                        }}
                    >
                        <Chart dataChart={props.dataChart} fechaConsulta1={fechaConsulta1} fechaConsulta2={fechaConsulta2} />
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid
                    className=" m-0 p-1"
                    item xs={12} md={3}  >
                    <Paper
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 220,
                        }}
                    >
                        {props.tipoSelect === enutipoItemMenu.ventaPorGrupo || props.tipoSelect === enutipoItemMenu.ventaProducto ||
                            props.tipoSelect === enutipoItemMenu.compraPorGrupo || props.tipoSelect === enutipoItemMenu.compraProducto
                            ? <Productos />
                            : props.tipoSelect === enutipoItemMenu.ventaLibroVentas || props.tipoSelect === enutipoItemMenu.compraLibrocompras
                                ? <Opciones />
                                : <FormasPago formasPago={props.formasPago} />
                        }

                    </Paper>
                </Grid>
                <Grid
                    className=" m-0 p-1"
                    item xs={12} md={3} >
                    <Paper
                        sx={{
                            m: 0,
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 220,
                        }}
                    >
                        {props.tipoSelect === enutipoItemMenu.dashBoard
                            ? <Caja
                                fechaConsulta={props.fechaConsulta1}
                                totalVentas={props.totalVentas}
                                totalBase={props.totalBase}
                                totalIVA={props.totalIVA}
                                totalFacts={props.orderList ? props.orderList.length : 0}


                            />
                            : <CajaRange
                                fechaConsulta={props.fechaConsulta1}
                                totalVentas={props.totalVentas}
                                handleClickBuscarProductos={props.handleClickBuscarProductos}
                                handleClickBuscarFecha={props.handleClickBuscarFecha}
                                tipoSelect={props.tipoSelect}
                            />
                        }

                    </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid

                    className=" m-0 p-1"
                    item xs={12}>
                    <Paper sx={{ m: 1, p: 1, display: 'flex', flexDirection: 'column' }}>
                        <Pedidos orderList={orderList} fechaConsulta1={props.fechaConsulta1} fechaConsulta2={fechaConsulta2} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>

    )

}