import type { cajas } from './cajas';
import type { clientes } from './clientes';
import type { estaciones_sesiones } from './estaciones_sesiones';
import type { facturas } from './facturas';
import type { mesas_detalle } from './mesas_detalle';
import type { pedidos_descuentos } from './pedidos_descuentos';
import type { pedidos_detalle } from './pedidos_detalle';
import type { pedidos_impuestos } from './pedidos_impuestos';
import type { pedidos_modificadores } from './pedidos_modificadores';
import type { pedidos_pendientes } from './pedidos_pendientes';
import type { tipomonedas } from './tipomonedas';
import type { usuarios } from './usuarios';

export interface pedidosAttributes {
  ID: number;
  Id_Caja?: number;
  Id_Cliente?: number;
  Id_TipoDespachoPedido?: number;
  Id_Usuario?: number;
  Id_Estaciones_Sesiones?: number;
  Id_MonedaDivisa?: number;
  id_defTipoSatusPedido?: number;
  id_defTipoOrigenPedido?: number;
  id_defTipoPedido?: number;
  FechaActual?: Date;
  FechaPedido?: Date;
  MontoTotalBase?: number;
  MontoTotal?: number;
  MontoTotalIVA?: number;
  MontoTotalDescuento?: number;
  MontoCambio?: number;
  MontoPagado?: number;
  MontoPropina?: number;
  NroItems?: number;
  NroLineas?: number;
  NroPedido?: number;
  RefPedido?: string;
  Comentarios?: string;
  Mensaje?: string;
  CantidadImpreso?: number;
  esTieneDevolucion?: number;
  esPagado?: number;
  esDespachado?: number;
  esImpreso?: number;
  Historico?: string;
  MontoTotalBaseDivisa?: number;
  MontoTotalDivisa?: number;
  MontoTotalIVADivisa?: number;
  MontoCambioDivisa?: number;
  MontoPagadoDivisa?: number;
  MontoPropinaDivisa?: number;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;
}

export class pedidos implements pedidosAttributes {
  ID!: number;
  Id_Caja?: number;
  Id_Cliente?: number;
  Id_TipoDespachoPedido?: number;
  Id_Usuario?: number;
  Id_Estaciones_Sesiones?: number;
  Id_MonedaDivisa?: number;
  id_defTipoSatusPedido?: number;
  id_defTipoOrigenPedido?: number;
  id_defTipoPedido?: number;
  FechaActual?: Date;
  FechaPedido?: Date;
  MontoTotalBase?: number;
  MontoTotal?: number;
  MontoTotalIVA?: number;
  MontoTotalDescuento?: number;
  MontoCambio?: number;
  MontoPagado?: number;
  MontoPropina?: number;
  NroItems?: number;
  NroLineas?: number;
  NroPedido?: number;
  RefPedido?: string;
  Comentarios?: string;
  Mensaje?: string;
  CantidadImpreso?: number;
  esTieneDevolucion?: number;
  esPagado?: number;
  esDespachado?: number;
  esImpreso?: number;
  Historico?: string;
  MontoTotalBaseDivisa?: number;
  MontoTotalDivisa?: number;
  MontoTotalIVADivisa?: number;
  MontoCambioDivisa?: number;
  MontoPagadoDivisa?: number;
  MontoPropinaDivisa?: number;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;

  // pedidos belongsTo cajas via Id_Caja
  Id_Caja_caja!: cajas;
  Id_Cliente_cliente!: clientes;
  Id_Estaciones_Sesiones_estaciones_sesione!: estaciones_sesiones;
  facturas!: facturas[];
  mesas_detalles!: mesas_detalle[];
  pedidos_descuentos!: pedidos_descuentos[];
  pedidos_detalles!: pedidos_detalle[];
  pedidos_impuestos!: pedidos_impuestos[];
  pedidos_modificadores!: pedidos_modificadores[];
  pedidos_pendientes!: pedidos_pendientes[];
  Id_MonedaDivisa_tipomoneda!: tipomonedas;
  Id_Usuario_usuario!: usuarios;

}
