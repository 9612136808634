import { withStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import StepConnector from "@mui/material/StepConnector";

// STYLE THE STEPPER CONNECTOR
const Connector = withStyles((theme: Theme) => ({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            background: theme.palette.primary.main
        },
    },
    completed: {
        '& $line': {
            background: theme.palette.primary.main
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}))(StepConnector);

export default Connector;