import { useEffect } from "react";
import { Container, Paper, Box, Theme } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import CustomizedSteppers from "./Stepper";
import { enuStatusPagoDocumento } from "../../../utils/enums";

const useStyles = makeStyles((theme: Theme) => ({
    boxWrapper: {
        minHeight: "calc(20vh + 150px)"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    }
}));
export interface Props {
    handleResponse?: (status: enuStatusPagoDocumento, detallePago: any) => void;
}
export default function Main({ handleResponse }: Props) {
    const classes = useStyles();
    //const [stripePromise, setStripePromise] = useState(null)

    useEffect(() => {
        /*  const retrievePublishableKey = async () => {
             const publishableKey = await publishableKeyGet()
             const stripe = loadStripe(publishableKey);
             setStripePromise(stripe)
         }
         retrievePublishableKey() */
    }, [])

    return (
        <Box component="main" className={classes.boxWrapper + ' my-2 p-0'}>
            <Container maxWidth="md" className={classes.container + ' m-0 p-0'}>
                <Paper className='m-0' elevation={5}>
                    <CustomizedSteppers handleResponse={handleResponse} />
                    {/*  {stripePromise
                    ? <Elements stripe={stripePromise}>
                    </Elements>
                    : null
                } */}
                </Paper>
            </Container>
        </Box>
    )
}

