
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Theme, Grid, Typography, FormControl, TextField, Autocomplete } from "@mui/material";
import { makeStyles, } from '@mui/styles';
import SpinnerLoading from "../../../../components/spinner-loading";
import SimpleToast from "../../../../components/simple-toast";
import SimpleDialog from "../../../dialogs/simple-dialog";
import ModalDialog from "../../../dialogs/modal-dialog";
import { tipoegresoingreso } from "../../../../models/tipoegresoingreso";
import { loadArchivo } from "../../../../utils/services";
export interface Props {
    handleResponse?: any;
    titulo?: string;
    openModal?: boolean;
}
const style = {
    color: 'rgb(255,255,255)',
    '&.Mui-checked': {
        color: 'rgb(0,0,0)',
    },

};
const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
    },
    boxWrapper: {
        minHeight: "calc(20vh + 150px)",
        minWidth: "calc(20vh + 150px)",
        maxWidth: "30vh"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
}));


export default function CrearEgreso(props: Props) {
    const [ , setOpen] = useState(false);
    const [egresosList, setegresosList] = useState<tipoegresoingreso[]>([]);
    const [value, setValue] = useState<tipoegresoingreso | null>(null);

    /*  const handleChange = (event: any) => {
         event.preventDefault();
         setValue(
             {
                  
             }
         );
         handleClose();
     }; */

    const [message, setMessage] = useState("");
    const [loading, setloading] = useState(false);
    const  textloading  = 'Cargando...';
     
    const Titulo = () => {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="m-0 p-0 text-white bg-black border-bottom bg-gradient w-100"
            >
                <Typography variant='h6'>
                    {props.titulo}
                </Typography>


            </Grid >
        )
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const childDialogRes = async (e: any) => {

    }

    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);

    }
    //#endregion
    let savingData: boolean = false;
    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setloading(saving);
    }
    const loadArchivos = () => {
        setSavingData(true);
        loadArchivo(
            'tipoegresoingreso',
            false,
            [
                'Descripcion',
                'MontoMaximo',
                'MontoMinimo',
                'ArchivoAsociado',
                'esFiscal',
                'esImprimeRecibo',
                'esPideAutorizacion',
                'esPideComentario',
                'esPideID_asociado',
                'esPideNombreEntrega',
                'esPideNombreRecibe',
                'esPideNroDoc_Asociado',
                'esEgreso',
            ], undefined, undefined, ['Nombre'], { esActivo: 1 }
        ).then(
            (resolve) => { setegresosList(resolve as tipoegresoingreso[]) },
            (error) => { setMessage('err-->' + error) }

        ).finally(() => setSavingData(false));
    }
    useEffect(() => {
        if (egresosList === null)
            loadArchivos()
    }, [])
    return (
        <>
            <Titulo />
            <FormControl className="w-100" component="fieldset">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="m-0 p-2 w-100"
                >
                    <Grid className=" w-100 p-1 m-0 " item xs={12} sm={12}>
                        <Autocomplete
                            value={value}
                            onChange={(event: any, newValue: tipoegresoingreso | null) => {
                                setValue(newValue);
                            }}
                            inputValue={value ? value.Descripcion : ''}
                            onInputChange={(event, newInputValue) => {
                                if (value)
                                    setValue({
                                        ...value,
                                        Descripcion: newInputValue,
                                    })
                            }}
                            id="controllable-states-demo"
                            options={egresosList}
                            renderInput={(params) =>

                                <TextField
                                    {...params}
                                    label="Descripcion"
                                    name="precio"
                                    variant='filled'
                                    className="bg-body rounded-3 "
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    size='small'
                                    value={value ? value.Descripcion : ''}
                                    onChange={(event: any) => {
                                        if (value)
                                            setValue({
                                                ...value,
                                                Descripcion: event.target.value,
                                            })
                                    }}
                                />}
                        />
                    </Grid>


                </Grid>
            </FormControl>

            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
            <ModalDialog selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
            <SimpleDialog selectedValue={"selectedValue"} setOpen={setOpenSDialog} open={openSDIalog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>

    )
}


