import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Card, CardActionArea, CardContent, CardMedia, Theme, Typography } from '@mui/material';
import { ColoresDPana, ColorsDPana } from '../../utils/Color';
import "./style.css";
export enum TipoCardItem {
  None,
  Menu,
  Producto,
  Extra
}

interface CardItemProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  themeColor?: ColoresDPana;
  style?: React.CSSProperties;
  Titulo?: string;
  Descripcion?: string;
  Precio?: string;
  PrecioDivisa?: string;
  Imagen?: string;
  Tipo?: number;
  handleClick?: any;
  idCategoria?: number;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 170,
      maxHeight: 150,
      minWidth: 170,
      margin: 2,
      position: "relative",
      display: "inline-block"
    },
    media: {
      height: 100,
    },
    titulo: {
      height: 40,
      color: "#ffffff",
      background: "#231212",
      padding: "3px!important",
    },
    badge: {
      width: '100%',
      height: 30,
      position: "absolute",
      zIndex: 999,
      color: "##ffc107",
      display: "inline-block",
      right: 0,
      top: 0,
      alignItems: "right",
      alignContent: "bottom",
      margin: 5,
      textAlign: "right"
    },
    Descripcion: {
      height: 60,
      color: "#ffffff",
      background: "#231212",
      padding: 5,
      lineHeight: 0.8,
      alignItems: "left",
      justifyContent: "left"
    },
    icon: {
      height: 4
    },
    iconDivisa: {
      height: 9
    }
  }));

const GLARE = 60;
const BORDER_WHITE = 30;

export default function CardItem({ children, Titulo, Descripcion, Precio, PrecioDivisa, idCategoria, Imagen, className, themeColor = ColorsDPana.secundary, Tipo, style, handleClick, ...restOfProps }: CardItemProps) {
  const classes = useStyles();
  const buttonStyle: React.CSSProperties = {
    border: `4px solid rgb(${themeColor[0] + BORDER_WHITE},${themeColor[1] + BORDER_WHITE},${themeColor[2] + BORDER_WHITE})`,
    backgroundImage: `linear-gradient( rgb(${Math.min(255, themeColor[0] + GLARE)},${Math.min(255, themeColor[1] + GLARE)},${Math.min(255, themeColor[2] + GLARE)}), rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]}))`,
    ...style
  }

  return (
    <Card className={classes.root} style={buttonStyle} onClick={handleClick} >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={Imagen}
        />
        <CardContent className={classes.titulo}  >
          <Typography gutterBottom variant="subtitle2" component="h6" style={{ lineHeight: '1' }}>
            {Titulo}
          </Typography>
          {Precio && <div className={classes.badge + " fa-lg"}> <span>Bs.</span> {Precio}</div>}
          {PrecioDivisa && <div className={classes.badge + " fa-sm opacity-75"} style={{ top: '1rem' }}> <span>$</span> {PrecioDivisa}</div>}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}