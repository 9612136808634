import React from 'react';
import { StateProvider } from './StateContext';
import { ThemeProvider } from '@mui/styles';
import theme from './constants/theme';
import Header from './Header';
import Main from "./Main"
import { ColoresDPana } from '../../../utils/Color';
import { enuStatusPagoDocumento } from '../../../utils/enums';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  themeColor?: ColoresDPana;
  style?: React.CSSProperties;
  Titulo?: string;
  Descripcion?: string;
  handleResponse?: (status: enuStatusPagoDocumento, detallePago: any) => void;


}

export default function FormPago({ handleResponse, themeColor, style, Titulo, ...restOfProps }: Props) {
  /* const setting = useSetting();
  const setSetting = useSetSetting();
  const PedidoActual = useOrder();
  const setPedidoActual = useSetOrder();
  const loginToken = useLoginToken();
  const history = useHistory(); */

  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <div style={{ flexGrow: 1 }}>
          <Header title="Módulo Pagos" logoLink="logo.svg" />
          <Main handleResponse={handleResponse} />
          {/*  <Footer /> */}
        </div>
        {/* <LegalNoticePopup /> */}
      </StateProvider>
    </ThemeProvider>
  )
}
/* <ThemeProvider theme={theme}>
  <StateProvider>
    <div style={{ flexGrow: 1 }}>
      <Header title="Pagos" logoLink="logo.svg" />
      <Main />
      <Footer />
    </div>
    <LegalNoticePopup />
  </StateProvider>
</ThemeProvider> */