import type { facturas_descuentos } from './facturas_descuentos';
import type { facturas_detalle_descuentos } from './facturas_detalle_descuentos';
import type { pedidos_descuentos } from './pedidos_descuentos';
import type { pedidos_detalle_descuentos } from './pedidos_detalle_descuentos';
import type { tipodescuentos_detalle } from './tipodescuentos_detalle';

export interface tipodescuentosAttributes {
  ID: number;
  Descripcion: string;
  MontoTasa?: number;
  FechaIni?: Date;
  FechaFin?: Date;
  FechaActual?: Date;
  FechaModificado?: Date;
  esMontoTasaPorcentual?: number;
  esDescuento?: number;
  esPorItem?: number;
  esActivo?: number;
}

export type tipodescuentosPk = "ID";

export class tipodescuentos implements tipodescuentosAttributes {
  ID!: number;
  Descripcion!: string;
  MontoTasa?: number;
  FechaIni?: Date;
  FechaFin?: Date;
  FechaActual?: Date;
  FechaModificado?: Date;
  esMontoTasaPorcentual?: number;
  esDescuento?: number;
  esPorItem?: number;
  esActivo?: number;

  // tipodescuentos hasMany facturas_descuentos via Id_Descuento
  facturas_descuentos!: facturas_descuentos[];
  // tipodescuentos hasMany facturas_detalle_descuentos via Id_TipoDescuento
  facturas_detalle_descuentos!: facturas_detalle_descuentos[];
  // tipodescuentos hasMany pedidos_descuentos via Id_Descuento
  pedidos_descuentos!: pedidos_descuentos[];
  // tipodescuentos hasMany pedidos_detalle_descuentos via Id_TipoDescuento
  pedidos_detalle_descuentos!: pedidos_detalle_descuentos[];
  // tipodescuentos hasMany tipodescuentos_detalle via id_TipoDescuento
  tipodescuentos_detalles!: tipodescuentos_detalle[];

}
