import { comboboxData } from "../models/Entities/controlsEntity";
import { config } from "./configs"
import axios from "axios";

export const loadArchivo = (nombreTabla: string, esSelectOpcion: boolean = true, camposTabla: string[] | undefined = undefined, camposUnidos: any[] | undefined = undefined, campoId: string | undefined = undefined, orden: any = undefined, condicion: any | undefined = undefined) => {
    return new Promise((resolve, reject) => {
        let campo: string;
        if (!campoId)
            campoId = "ID"
        if (!orden)
            orden = [[campoId, 'ASC']]
        if (!camposTabla) {
            camposTabla = [campoId, "Descripcion"];
            campo = "Descripcion";
        }
        else {
            camposTabla.push(campoId);
            campo = camposTabla[0];
        }
        axios.post(config.SERVER_URI + `/${nombreTabla}/list`, {
            options: {
                order: orden,
                attributes: camposTabla,
                where: condicion
            }
        }).then(result => {
            let d: any[] = [];
            if (esSelectOpcion)
                d.push({ ID: 0, Nombre: "Seleccionar Opción" });
            result.data.forEach((item: any) => {
                let c: comboboxData = {};
                let s: string = "";
                c.ID = item[campoId ? campoId : "ID"];
                if (camposUnidos) {
                    if (camposUnidos.length === 1)
                        s = `${item[campoId ? campoId : "ID"]} ${item[camposUnidos[0]]} `
                    else
                        camposUnidos.forEach(e => {
                            s += `${item[e]} `;
                        });
                }
                else
                    s = item[campo];

                d.push({ ID: item.ID, Nombre: s, ...item });
            });
            resolve(d)
        }).catch(err => {
            reject(`${nombreTabla}: ` + (err.response ? err.response.data ? err.response.data : err.response : err));
        });
    });;
};

export const loadArchivoDef = (nombreCampo: string, enu: any, esSelectOpcion: boolean = true) => {
    return new Promise((resolve, reject) => {
        try {
            let d: comboboxData[] = [];
            if (esSelectOpcion)
                d.push({ ID: 0, Nombre: "Seleccionar Opción" })
            for (const [propertyKey, propertyValue] of Object.entries(enu)) {
                if (!Number.isNaN(Number(propertyKey))) {
                    continue;
                }
                d.push({ ID: propertyValue as number, Nombre: propertyKey });
            }
            resolve(d);
        } catch (error) {
            reject(`${nombreCampo}: ` + error);
        }
    });
}